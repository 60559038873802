import { Component, inject, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import {
    MatDialogRef,
    MatDialogClose,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ColumnState, FilterModel } from 'ag-grid-community';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { ErrorResponse, Order } from '@models/api.model';
import { ColumnOption, ExportDocumentExcelQuery } from '@models/shared.model';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { getFileNameFromHeader } from '@helpers/file';
import {
    DiscrepancyColumn,
    DiscrepancyViewColumnOptions,
    DocumentColumn,
    DocumentViewColumnOptions,
    TableView,
} from '@constants/table';
import {
    getFilterQuery,
    getSortQuery,
} from '../../documents-and-discrepancies.utils';
import DATA_QA from '@automation/data-qa.json';

export interface ExcelExportDialogComponentData {
    tableView: TableView;
    workflowAndRepo: WorkflowAndRepository | undefined;
    mine: boolean;
    status: number[];
    filterModel: FilterModel | undefined;
    columnState: ColumnState[] | undefined;
}

@Component({
    standalone: true,
    templateUrl: 'excel-export-dialog.component.html',
    styleUrl: 'excel-export-dialog.component.scss',
    imports: [
        MatIconModule,
        FlatButtonComponent,
        MatDialogClose,
        LoaderComponent,
        CheckboxComponent,
        TextButtonComponent,
    ],
})
export class ExcelExportDialogComponent implements OnDestroy {
    readonly maxLimit = 10000;
    docColumnOptions: ColumnOption<DocumentColumn>[] = structuredClone(
        DocumentViewColumnOptions,
    );
    discColumnOptions: ColumnOption<DiscrepancyColumn>[] = structuredClone(
        DiscrepancyViewColumnOptions,
    );
    loading: boolean = false;
    error: boolean = false;
    maxLimitError: boolean = false;
    showMaxLimitReduceInfo = false;
    excludeFilters: boolean = false;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private discrepancyService = inject(DiscrepancyService);
    private documentService = inject(DocumentService);
    private dialogRef = inject(MatDialogRef<ExcelExportDialogComponent>);
    readonly data: ExcelExportDialogComponentData = inject(MAT_DIALOG_DATA);

    get discOptions() {
        return this.discColumnOptions.filter((item) => item.group === 'disc');
    }

    get selectedDocumentColumns() {
        return this.docColumnOptions
            .filter((item) => item.checked)
            .map((item) => item.id);
    }

    get selectedDiscrepancyColumns() {
        return this.discOptions
            .filter((item) => item.checked)
            .map((item) => item.id);
    }

    get allDocSelected() {
        return this.docColumnOptions.every((item) => item.checked);
    }

    get allDiscSelected() {
        return this.discOptions.every((item) => item.checked);
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    updateSelectedDocColumns(checked: boolean, id: DocumentColumn) {
        const item = this.docColumnOptions.find((item) => item.id === id);
        if (item) {
            item.checked = checked;
        }
    }

    toggleAllDocColumns(status: boolean): void {
        this.docColumnOptions = this.docColumnOptions.map((item) =>
            item.disabled ? item : { ...item, checked: status },
        );
    }

    updateSelectedDiscColumns(checked: boolean, id: DiscrepancyColumn) {
        const item = this.discColumnOptions.find((item) => item.id === id);
        if (item) {
            item.checked = checked;
        }
    }

    toggleAllDiscColumns(status: boolean): void {
        this.discColumnOptions = this.discColumnOptions.map((item) =>
            item.disabled ? item : { ...item, checked: status },
        );
    }

    export() {
        this.loading = true;
        this.error = false;
        this.maxLimitError = false;

        const query = this.prepareExportQuery();
        const service =
            this.data.tableView === TableView.Document
                ? this.documentService
                : this.discrepancyService;

        this._subscriptions['export'] = service.export(query).subscribe({
            next: (res) => this.download(res),
            error: (err) => this.handleError(err.error),
            complete: () => {
                this.loading = false;
                this.dialogRef.close();
            },
        });
    }

    private prepareExportQuery(): ExportDocumentExcelQuery {
        const sortModel = (this.data.columnState ?? [])
            .filter((s) => s.sort !== null)
            .map((item) => ({ colId: item.colId, sort: item.sort as Order }));

        return {
            order: 'desc',
            ...this.data.workflowAndRepo,
            status: this.data.status,
            ...(this.excludeFilters
                ? {}
                : getFilterQuery(this.data.filterModel ?? null)),
            ...(this.excludeFilters ? {} : getSortQuery(sortModel)),
            mine: this.data.mine,
            excludeFilters: this.excludeFilters,
            selectedDocumentColumns: this.selectedDocumentColumns,
            selectedDiscrepancyColumns: this.selectedDiscrepancyColumns,
        };
    }

    private download(res: HttpResponse<any>) {
        const url = URL.createObjectURL(res.body);
        const a = document.createElement('a');
        a.href = url;
        a.download = getFileNameFromHeader(res.headers);
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    private handleError(error: ErrorResponse) {
        this.loading = false;

        if (error.type === 'InvalidOperationException') {
            this.maxLimitError = true;
        } else {
            this.error = true;
        }
    }
}
