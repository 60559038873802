import { Component, inject, OnInit } from '@angular/core';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import 'ag-grid-enterprise';

import { NavbarComponent, Tabs } from '@shared/navbar/navbar.component';
import { BrandingBarComponent } from '@shared/branding-bar/branding-bar.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { AuthService } from '@services/api/auth/auth.service';
import { PostLoginService } from '@services/post-login.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        BrandingBarComponent,
        NavbarComponent,
        LoaderComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    activeTabId: Tabs = Tabs.DOCUMENT_AND_DISCREPANCIES;
    tabs: typeof Tabs = Tabs;
    loading = true;
    navbarVisible = false;
    isLoggedIn = false;

    private router = inject(Router);
    private sanitizer = inject(DomSanitizer);
    private authService = inject(AuthService);
    private iconRegistry = inject(MatIconRegistry);
    private postLoginService = inject(PostLoginService);

    constructor() {
        this.isLoggedIn = this.authService.isLoggedIn();
        this.setIconResolver();
    }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.loading = false;
                this.setNavbarVisibility(event.url);
            }
        });

        if (this.isLoggedIn) {
            this.postLoginService.init();
        }
    }

    updateActiveTab(tabId: Tabs) {
        this.activeTabId = tabId;
    }

    logout() {
        this.authService.logout();
    }

    private setNavbarVisibility(url: string) {
        this.navbarVisible =
            this.isLoggedIn && !url.startsWith('/documents-and-discrepancies/');
    }

    private setIconResolver() {
        const resolver: IconResolver = (name) =>
            this.sanitizer.bypassSecurityTrustResourceUrl(
                `/assets/icons/${name}.svg`,
            );
        this.iconRegistry.addSvgIconResolver(resolver);
    }
}
