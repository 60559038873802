import { generateNumbers } from '@helpers/array';

export const preparePages = (pageRangeValue: string): number[] => {
    if (pageRangeValue.includes('-')) {
        const [min, max] = pageRangeValue
            .trim()
            .split('-')
            .map((i) => Number(i));

        return generateNumbers(min, max);
    } else {
        return [Number(pageRangeValue)];
    }
};
