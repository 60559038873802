import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DiscrepanciesTabComponent } from 'app/components/documents-and-discrepancies/discrepancies-tab/discrepancies-tab.component';

@Component({
    standalone: true,
    imports: [DiscrepanciesTabComponent],
    template: `<app-discrepancies-tab
        mode="detail"
        [documentId]="documentId" />`,
})
export class DiscrepanciesPageComponent implements OnInit {
    documentId!: number;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        const id = this.route.parent?.snapshot.paramMap.get('id');
        if (id) {
            this.documentId = parseInt(id);
        }
    }
}
