import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { SimpleResponse } from '@models/api.model';
import { SharedMapper } from '@services/api/shared.mapper';
import { DropdownItem } from '@models/shared.model';
import { ApiService } from '@services/api/api.service';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    private readonly _path = 'workflow/v1';

    constructor(
        private api: ApiService,
        private sharedMapper: SharedMapper,
    ) {}

    getWorkflows(): Observable<DropdownItem[]> {
        return this.api
            .get<DropdownItem[]>(this._path + endpoints.workflows)
            .pipe(
                map((response: SimpleResponse<DropdownItem[]>) =>
                    response.data.map(this.sharedMapper.toDropdownItem),
                ),
                catchError(() => of([])),
            );
    }
}
