import { Component, OnInit } from '@angular/core';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { ColumnOption } from '@models/shared.model';
import {
    DiscrepancyColumn,
    DiscrepancyViewColumnOptions,
} from '@constants/table';

@Component({
    selector: 'app-dicrepancy-view-column-options',
    standalone: true,
    imports: [CheckboxComponent],
    template: ` <p class="mb-3">Column Options</p>
        <div class="column-options">
            @for (item of docColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="updateColumnDisplay($event, item.id)" />
            }
        </div>
        <div class="column-options nested ml-n3 mr-n3 mt-1 p-3">
            @for (item of discColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="updateColumnDisplay($event, item.id)" />
            }
        </div>`,

    styles: `
        :host {
            width: 100%;

            .column-options {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                &.nested {
                    background-color: #f5f6f7;
                }
            }
        }
    `,
})
export class DiscrepancyViewColumnOptionsComponent implements OnInit {
    discViewColumnOptions: ColumnOption<DiscrepancyColumn>[] = [];
    hiddenColumnOptions: DiscrepancyColumn[] = [];

    constructor(private discrepancyService: DiscrepancyService) {}

    get docColumnOptions() {
        return this.discViewColumnOptions.filter(
            (item) => item.group === 'doc',
        );
    }

    get discColumnOptions() {
        return this.discViewColumnOptions.filter(
            (item) => item.group === 'disc',
        );
    }

    ngOnInit(): void {
        this.handleColumnOptions();
    }

    updateColumnDisplay(checked: boolean, id: DiscrepancyColumn) {
        this.discrepancyService.updateDiscColumnOptions({ checked, id });
    }

    private handleColumnOptions() {
        this.discrepancyService.discColumnOptions$.subscribe({
            next: (options) =>
                (this.hiddenColumnOptions = options.hiddenColumns),
        });

        this.discViewColumnOptions = DiscrepancyViewColumnOptions.map(
            (option) => {
                const isHidden = this.hiddenColumnOptions.some(
                    (hiddenOption) => hiddenOption === option.id,
                );

                if (isHidden) {
                    return { ...option, checked: false };
                }
                return option;
            },
        );
    }
}
