@if (loading) {
    <app-loader />
} @else {
    @if (discrepancies.length <= 0) {
        No discrepancy found.
    } @else {
        <app-tabs
            tabLabelClass="text-s"
            tabContentClass="ml-n4 pl-4"
            [tabs]="tabsData"
            [syncWithRouter]="mode === 'detail'"
            [initialSelectedTabIndex]="selectedTabIndex"
            (tabSelected)="handleTabChange($event)">
            @if (mode === 'detail') {
                <router-outlet />
            } @else {
                @if (selectedDiscrepancy) {
                    <app-discrepancy-details [id]="selectedDiscrepancy" />
                }
            }
            @if (mode === 'detail' && documentDetails) {
                <app-document-upload
                    [discrepancies]="discrepancies"
                    [documentDetails]="documentDetails" />
            }
            @if (mode === 'detail' && documentDetails && maxDiscrepancyCount) {
                <app-add-discrepancy-button
                    headerContent
                    class="ml-auto mr-4 mb-2"
                    [disabled]="!documentDetails.canAddDiscrepancy"
                    [maxDiscCount]="maxDiscrepancyCount"
                    (addClicked)="openAddDiscModal()" />
            }
        </app-tabs>
    }
}
