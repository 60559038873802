import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
    MatDialog,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DropzoneComponent } from '@shared/dropzone/dropzone.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { DuplicateDocumentsDialogComponent } from '../duplicate-documents-dialog/duplicate-documents-dialog.component';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';
import { convertToFileList } from 'app/utils/helpers/file';
import { selectRevisionList } from 'app/store/selectors/document.selectors';
import { DiscrepancyIdNo } from 'app/models/discrepancy/item';
import { FileType } from '@constants/file';

@Component({
    standalone: true,
    templateUrl: 'upload-correction-dialog.component.html',
    imports: [
        CommonModule,
        MatIconModule,
        FlatButtonComponent,
        MatDialogClose,
        LoaderComponent,
        DropzoneComponent,
        CheckboxComponent,
    ],
})
export class UploadCorrectionDialogComponent implements OnInit {
    acceptedFiles: FileType[] = [FileType.PDF];
    files: FileList | null = null;
    selectedDiscrepancies: number[] = [];
    progress: number | null = null;
    error: boolean = false;
    loading: boolean = false;
    revisionList$: Observable<string[]> | undefined;
    revisionList: string[] = [];
    uploadedFileExt: string = '';

    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private store: Store,
        public dialog: MatDialog,
        private documentService: DocumentService,
        public dialogRef: MatDialogRef<UploadCorrectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            id: number;
            discrepancies: DiscrepancyIdNo[];
            file: File | null;
        },
    ) {}

    ngOnInit(): void {
        if (this.data.file) {
            this.files = convertToFileList([this.data.file]);
        }

        this.revisionList$ = this.store.select(selectRevisionList);
        this._subscriptions['revision-list'] = this.revisionList$.subscribe({
            next: (list: string[]) => (this.revisionList = [...list]),
        });
    }

    onFileReady(files: FileList) {
        this.error = false;
        this.files = files;
        if (!files) {
            this.cancelUpload();
        }
    }

    onUploadClick() {
        if (this.files && this.files[0]) {
            const file = this.files[0];
            const fileExist = this.checkDuplicateFile(file);
            this.uploadedFileExt = file.name.split('.').pop() ?? '';

            if (fileExist) {
                this.askForNewFileName();
            } else {
                this.upload();
            }
        }
    }

    onCancelClick() {
        this.cancelUpload();
        this.dialogRef.close({ success: false });
    }

    onDiscrepancySelected(checked: any, discrepancyId: number) {
        if (checked) {
            this.selectedDiscrepancies = [
                ...this.selectedDiscrepancies,
                discrepancyId,
            ];
        } else {
            this.selectedDiscrepancies = [
                ...this.selectedDiscrepancies.filter(
                    (disc) => disc !== discrepancyId,
                ),
            ];
        }
    }

    private askForNewFileName() {
        const file = this.files![0];
        const fileName = file.name;
        const duplicateDocDialogRef = this.dialog.open(
            DuplicateDocumentsDialogComponent,
            {
                data: {
                    documentName: fileName,
                    revisionList: this.revisionList,
                },
                maxWidth: '450px',
                minWidth: '200px',
                width: '450px',
                disableClose: true,
            },
        );
        duplicateDocDialogRef.afterClosed().subscribe({
            next: (response) => {
                if (response.success) {
                    const { updatedDocumentName } = response;
                    const newFile = this.createFileWithName(
                        file,
                        `${updatedDocumentName}.${this.uploadedFileExt}`,
                    );
                    const fileArray = [newFile];
                    this.files = convertToFileList(fileArray);
                    this.upload();
                }
            },
        });
    }

    private cancelUpload() {
        if (this.progress) {
            this.progress = null;
            this.loading = false;
            this._subscriptions.unsubscribeAllSafe();
        }
    }

    private upload() {
        if (this.files && this.files[0]) {
            this.error = false;
            this.loading = true;

            this._subscriptions['upload-document'] = this.documentService
                .uploadDocument(
                    this.data.id,
                    this.prepareFormdata(this.files[0]),
                )
                .subscribe({
                    next: (response) => {
                        this.progress = response.progress;
                        if (response.status === 'success') {
                            this.dialogRef.close({
                                success: true,
                                fileName: this.files![0].name,
                            });
                        }
                    },
                    error: (err) => this.handleError(err),
                    complete: () => (this.loading = false),
                });
        }
    }

    private prepareFormdata = (file: File) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.selectedDiscrepancies.forEach((item) =>
            formData.append('discrepancy', item.toString()),
        );

        return formData;
    };

    private checkDuplicateFile(file: File): boolean {
        const fileName = file.name;
        const fileExist = this.revisionList.includes(fileName);

        return fileExist;
    }

    private createFileWithName(file: File, newName: string): File {
        const newFile = new File([file], newName, {
            type: file.type,
            lastModified: file.lastModified,
        });

        return newFile;
    }

    private handleError(error: HttpErrorResponse) {
        this.error = true;
        this.loading = false;

        if (error.status === HttpStatusCode.Conflict) {
            this.askForNewFileName();
        }
    }
}
