import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscrepancyDataService } from '@services/data/discrepancy-data.service';

import { RouterService } from '@services/router.service';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { DiscrepancyDetailsComponent } from 'app/components/documents-and-discrepancies/discrepancy-details/discrepancy-details.component';

@Component({
    standalone: true,
    template: `<app-discrepancy-details
        mode="detail"
        [id]="discId"
        (formChange)="onFormChanged($event)" />`,
    imports: [DiscrepancyDetailsComponent],
})
export class DiscrepancyDetailPageComponent implements OnInit, OnDestroy {
    @ViewChild(DiscrepancyDetailsComponent)
    discDetailComp!: DiscrepancyDetailsComponent;
    discId!: number;
    unsavedChanges = false;
    unsavedChangeArea = 'discrepancies';

    private route = inject(ActivatedRoute);
    private routerService = inject(RouterService);
    private unsavedChangesService = inject(UnsavedChangesService);
    private discrepancyDataService = inject(DiscrepancyDataService);

    ngOnInit(): void {
        this.handleDiscIdParam();
    }

    ngOnDestroy(): void {
        this.unsavedChangesService.cleanup();
    }

    onFormChanged(change: boolean) {
        this.discrepancyDataService.unsavedDiscrepancy = change;
        this.unsavedChanges = change;
        this.unsavedChangesService.hasUnsavedChanges = change;
    }

    hasUnsavedChanges(): boolean {
        return this.unsavedChanges;
    }

    save() {
        this.discDetailComp.saveChanges();
    }

    private handleDiscIdParam() {
        this.route.paramMap.subscribe({
            next: (params) => {
                const discrepancyId = parseFloat(params.get('discId')!);
                if (isNaN(discrepancyId)) {
                    this.routerService.goHome();
                } else {
                    this.discId = discrepancyId;
                }
            },
        });
    }
}
