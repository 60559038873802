import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { PDFDocument } from '@constants/file';

export const createPDFDocument = (
    response: HttpResponse<Blob>,
): PDFDocument | null => {
    if (response.body) {
        const name = getFileNameFromHeader(response.headers);
        return new PDFDocument(createBlobPDF(response.body), name);
    }

    return null;
};

export const getFileNameFromHeader = (headers: HttpHeaders): string => {
    let name = '';
    const contentDisposition = headers.get('Content-Disposition') ?? '';

    if (contentDisposition) {
        name = contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '');
    }

    return name;
};

export const createBlobPDF = (blobPart: BlobPart) => {
    return new Blob([blobPart], {
        type: 'application/pdf',
    });
};

export const convertToFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();

    files.forEach((file) => {
        dataTransfer.items.add(file);
    });

    return dataTransfer.files;
};

export const bytesToMB = (bytes: number) => {
    return bytes / (1024 * 1024);
};

export const bytesToGB = (bytes: number) => {
    return bytes / (1024 * 1024 * 1024);
};

export const getFileNameAndExtension = (
    input: string,
): { name: string; ext: string } => {
    const parts = input.split('.');
    const ext = parts.pop() || '';
    const name = parts.join('.');

    return { name, ext };
};

export const removeFileExtensionsFromFileList = (files: FileList): FileList => {
    const dataTransfer = new DataTransfer();
    Array.from(files).forEach((file) => {
        const newFile = removeFileExtension(file);
        dataTransfer.items.add(newFile);
    });

    return dataTransfer.files;
};

export const removeFileExtension = (file: File): File => {
    const nameWithoutExtension =
        file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

    const newFile = new File([file], nameWithoutExtension, {
        type: file.type,
        lastModified: file.lastModified,
    });

    return newFile;
};
