import { Component, inject, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';

import { RevisionDataService } from '@services/data/revision-data-service';
import { SnackbarService } from '@services/snackbar.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { DiscrepancyDataService } from '@services/data/discrepancy-data.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { RecallForEditDialogComponent } from '@dialogs/recall-for-edit-dialog/recall-for-edit-dialog.component';
import { CancelDiscrepancyDialogComponent } from '@dialogs/cancel-discrepancy-dialog/cancel-discrepancy-dialog.component';
import { DiscardDiscrepancyDialogComponent } from '@dialogs/discard-discrepancy-dialog/discard-discrepancy-dialog.component';
import { UnresolveDiscrepancyDialogComponent } from '@dialogs/unresolve-discrepancy-dialog/unresolve-discrepancy-dialog.component';
import { RecallDiscrepancyDialogComponent } from '@dialogs/recall-discrepancy-dialog/recall-discrepancy-dialog.component';
import { ConfirmFinalVersionDialogComponent } from '@dialogs/confirm-final-version-dialog/confirm-final-version-dialog.component';
import { FinalVersionDialogComponent } from '@dialogs/final-version-dialog/final-version-dialog.component';
import { UnsavedChangesDialogComponent } from '@dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component';
import {
    DiscrepancyEditOptions,
    DiscrepancyDetail,
    DiscrepancyActionCode,
    UpdateDiscrepancyRequest,
    DiscrepancyTerminateType,
    TERMINATE_WITHOUT_RESOLUTION,
    TerminateTypeWithoutResolution,
} from '@models/discrepancy';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { DocumentService } from '@services/api/document/document.service';
import { ResolutionDecision } from '@models/document/resolution-decision';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-footer-state-actions',
    standalone: true,
    template: `
        <div class="flex items-center gap-10">
            @if (canRecallForEdit()) {
                <app-text-button
                    (clicked)="onEditClick()"
                    [dataQa]="dataQa.footerRecallForEditDiscrepancy">
                    Recall
                </app-text-button>
            }
            @if (canRecall()) {
                <app-text-button
                    (clicked)="onRecallToAwaitClick()"
                    [dataQa]="dataQa.footerRecallToAwaitDiscrepancy">
                    Recall
                </app-text-button>
            }
            @if (canCancel()) {
                <app-flat-button
                    variant="tertiary"
                    [dataQa]="dataQa.footerCancelDiscrepancy"
                    [matMenuTriggerFor]="cancelDiscrepancyMenu"
                    (menuOpened)="menuOpened = true"
                    (menuClosed)="menuOpened = false">
                    Cancel Discrepancy
                    <mat-icon
                        [svgIcon]="
                            menuOpened ? 'chevron-up' : 'chevron-down'
                        " />
                </app-flat-button>
                <mat-menu #cancelDiscrepancyMenu="matMenu">
                    <div
                        tabindex="0"
                        (click)="$event.stopPropagation()"
                        (keyup.enter)="$event.stopPropagation()">
                        <button
                            mat-menu-item
                            [attr.data-qa]="
                                dataQa.footerCancelDiscrepancyMarkAsCancelled
                            "
                            (click)="onCancelClick()">
                            Mark as Cancelled
                        </button>
                        <button
                            mat-menu-item
                            [attr.data-qa]="
                                dataQa.footerCancelDiscrepancyMarkAsUnresolved
                            "
                            (click)="onUnresolveClick()">
                            Mark as Unresolved
                        </button>
                    </div>
                </mat-menu>
            }
            @if (showCloseResolve()) {
                <app-flat-button
                    variant="cta"
                    [dataQa]="dataQa.footerCloseResolveDiscrepancy"
                    (clicked)="onCloseResolveClick()">
                    Close Resolve
                </app-flat-button>
            }
            @if (discDetail.isDraft) {
                <app-flat-button
                    (clicked)="onDiscard()"
                    [dataQa]="dataQa.footerDiscardDiscrepancy">
                    Discard
                </app-flat-button>
            }
            @if (editOptions.canEdit) {
                <app-flat-button
                    variant="tertiary"
                    [disabled]="!canSave"
                    [dataQa]="dataQa.footerSaveDiscrepancy"
                    (clicked)="onSave()">
                    Save
                </app-flat-button>
            }
            @if (showNotify()) {
                <app-flat-button
                    variant="cta"
                    [disabled]="!canNotify"
                    [dataQa]="dataQa.footerNotifyDiscrepancy"
                    (clicked)="onNotifyClicked()">
                    Notify
                </app-flat-button>
            }
            @if (showRenotify()) {
                <app-flat-button
                    variant="cta"
                    [dataQa]="dataQa.footerRenotifyDiscrepancy"
                    (clicked)="onRenotify()">
                    Re-Notify
                </app-flat-button>
            }
        </div>
        @if (loading) {
            <app-loader type="overlay" size="large" position="fixed" />
        }
    `,
    imports: [
        MatMenuModule,
        MatIconModule,
        TextButtonComponent,
        FlatButtonComponent,
        LoaderComponent,
    ],
})
export class FooterStateActionsComponent implements OnDestroy {
    @Input({ required: true }) discDetail!: DiscrepancyDetail;
    @Input({ required: true }) canSave!: boolean;
    @Input({ required: true }) canNotify!: boolean;
    @Input({ required: true }) editForm!: FormGroup;
    @Input({ required: true }) editOptions!: DiscrepancyEditOptions;

    menuOpened = false;
    loading = false;
    readonly dataQa = DATA_QA;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    resolutionDecision: ResolutionDecision | null = null;

    private documentService = inject(DocumentService);
    private snackbarService = inject(SnackbarService);
    private discrepancyService = inject(DiscrepancyService);
    private documentDataService = inject(DocumentDataService);
    private revisionDataService = inject(RevisionDataService);
    private discrepancyDataService = inject(DiscrepancyDataService);
    private dialog = inject(MatDialog);
    private store = inject(Store);

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }

    canRecallForEdit() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RECALL_FOR_EDIT,
        );
    }

    onEditClick() {
        this.dialog.open(RecallForEditDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });
    }

    canRecall() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RECALL,
        );
    }

    onRecallToAwaitClick() {
        this.dialog.open(RecallDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });
    }

    canCancel() {
        return (
            !this.discDetail.isDraft &&
            this.discDetail.actions.some(
                (item) => item.action === DiscrepancyActionCode.CANCEL,
            )
        );
    }

    onCancelClick() {
        this.getResolutionDecisionAndExecute(() => {
            this.dialog.open(CancelDiscrepancyDialogComponent, {
                data: {
                    id: this.discDetail?.id,
                    discrepancyNo: this.discDetail.discrepancyNo,
                },
                maxWidth: '450px',
                minWidth: '200px',
                width: '450px',
            });
        }, 'Cancel');
    }

    onUnresolveClick() {
        this.getResolutionDecisionAndExecute(() => {
            this.dialog.open(UnresolveDiscrepancyDialogComponent, {
                data: {
                    id: this.discDetail?.id,
                    discrepancyNo: this.discDetail.discrepancyNo,
                },
                maxWidth: '450px',
                minWidth: '200px',
                width: '450px',
                maxHeight: '90vh',
            });
        }, 'Unresolve');
    }

    showCloseResolve() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.CLOSE_RESOLVE,
        );
    }

    onCloseResolveClick() {
        this.getResolutionDecisionAndExecute(() => {
            this.closeResolveDiscrepancy();
        }, 'Close Resolve');
    }

    showNotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.NOTIFY,
        );
    }

    showRenotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RENOTIFY,
        );
    }

    onDiscard() {
        this.getResolutionDecisionAndExecute(() => {
            this.dialog.open(DiscardDiscrepancyDialogComponent, {
                data: {
                    id: this.discDetail?.id,
                    discrepancyNo: this.discDetail.discrepancyNo,
                },
                maxWidth: '450px',
                minWidth: '200px',
                width: '450px',
            });
        }, 'Discard');
    }

    onSave(triggerNotify = false) {
        this.loading = true;
        const { id, discrepancyNo } = this.discDetail;
        const editData = this.getUpdatedDiscrepancyData();

        this._subscriptions['edit-discrepancy'] = this.discrepancyService
            .editDiscrepancy(id, editData)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${discrepancyNo} is Saved`,
                        });
                        this.discrepancyDataService.triggerDiscrepancySaved();
                        if (triggerNotify) {
                            this.notifyDiscrepancy();
                        } else {
                            this.store.dispatch(refreshActionRelatedData());
                        }
                    } else {
                        this.snackbarService.error({
                            variant: 'error',
                            header: `Discrepancy ${discrepancyNo} Could Not be Saved`,
                        });
                        this.loading = false;
                    }
                },
                complete: () => {
                    if (!triggerNotify) this.loading = false;
                },
            });
    }

    onNotifyClicked() {
        const { unsavedDocumentId, originalDocument } =
            this.revisionDataService;
        const isOriginalUnsaved =
            unsavedDocumentId && originalDocument?.id === unsavedDocumentId;

        if (isOriginalUnsaved) {
            this._subscriptions['original-saved'] =
                this.revisionDataService.originalSaved$.subscribe({
                    next: () => this.notify(),
                });

            this.dialog
                .open(UnsavedChangesDialogComponent, {
                    data: {
                        unsavedChangeArea: `${
                            this.revisionDataService.originalDocument
                                ?.revisionName
                        } (Original)`,
                        action: 'notifying station',
                    },
                    maxWidth: '430px',
                    minWidth: '200px',
                    width: '430px',
                })
                .afterClosed()
                .subscribe({
                    next: (result) => {
                        if (result.save) {
                            this.revisionDataService.triggerRevisionSave();
                        } else {
                            if (result.leave) {
                                this.notify();
                            }
                        }
                    },
                });
        } else {
            this.notify();
        }
    }

    onRenotify() {
        const { id, discrepancyNo } = this.discDetail;
        this.loading = true;

        this._subscriptions['renotify-discrepancy'] = this.discrepancyService
            .renotifyDiscrepancy(id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Re-Notified for Dicrepancy ${discrepancyNo}!`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private getUpdatedDiscrepancyData() {
        return {
            ...new UpdateDiscrepancyRequest(
                this.discDetail.priority,
                this.discDetail.reason?.backingField,
                this.discDetail.pageRange,
                this.discDetail.station?.backingField,
                this.discDetail.primaryFixer?.id,
                this.discDetail.directions,
            ),
            ...this.editForm.value,
        };
    }

    private notify() {
        if (this.canSave) {
            this.onSave(true);
        } else {
            this.notifyDiscrepancy();
        }
    }

    private openFinalVersionDialog(triggerType: DiscrepancyTerminateType) {
        this.dialog.open(FinalVersionDialogComponent, {
            data: {
                triggerType,
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '100%',
            minWidth: '200px',
            width: '100%',
            height: '100%',
            panelClass: 'p-4',
        });
    }

    private openConfirmFinalVersionDialog(
        triggerType: TerminateTypeWithoutResolution,
    ) {
        const { id, discrepancyNo } = this.discDetail;
        const documentNameToPublish =
            this.revisionDataService.originalDocument?.revisionName;

        this.dialog.open(ConfirmFinalVersionDialogComponent, {
            data: {
                triggerType,
                id,
                discrepancyNo,
                documentNameToPublish,
            },
            maxWidth: '522px',
            minWidth: '200px',
            width: '522px',
        });
    }

    private closeResolveDiscrepancy() {
        const { id, discrepancyNo } = this.discDetail;
        this.loading = true;

        this._subscriptions['close-resolve-disc'] = this.discrepancyService
            .closeResolveDiscrepancy(id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${discrepancyNo} is Close-Resolved and moved to "Recently Completed"!`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private notifyDiscrepancy() {
        const { id, discrepancyNo } = this.discDetail;
        this.loading = true;

        this._subscriptions['notify-discrepancy'] = this.discrepancyService
            .notifyDiscrepancy(id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Notified for Dicrepancy ${discrepancyNo}!`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.snackbarService.error({
                            variant: 'error',
                            header: `Discrepancy ${discrepancyNo} Could Not be Notified`,
                        });
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private getResolutionDecisionAndExecute(
        action: () => void,
        triggerType: DiscrepancyTerminateType,
    ) {
        const execute = () => {
            if (this.resolutionDecision) {
                const { isReadyForFinal, useOriginalAsFinal } =
                    this.resolutionDecision;

                if (isReadyForFinal) {
                    this.openFinalVersionDialog(triggerType);
                } else {
                    if (
                        TERMINATE_WITHOUT_RESOLUTION.includes(triggerType) &&
                        useOriginalAsFinal
                    ) {
                        this.openConfirmFinalVersionDialog(
                            triggerType as TerminateTypeWithoutResolution,
                        );
                    } else {
                        action();
                    }
                }
            }
        };

        if (this.resolutionDecision) {
            execute();
        } else {
            const documentId = this.documentDataService.currentDocument?.id;

            if (documentId)
                this._subscriptions['resolutio-decision'] = this.documentService
                    .getResolutionDecision(documentId, {
                        discrepancy: this.discDetail.id,
                    })
                    .subscribe({
                        next: (data) => {
                            this.resolutionDecision = data;
                            execute();
                        },
                    });
        }
    }
}
