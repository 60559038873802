import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { SimpleResponse } from '@models/api.model';
import { Roles, UserResponse, UserInboxChoices } from '@models/user';
import { UserDataService } from '@services/data/user-data.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly _path = 'v1';

    private api = inject(ApiService);
    private userDataService = inject(UserDataService);

    isAdminUser() {
        return this.getUser().pipe(
            map((user) => (user ? user.roles.includes(Roles.Admin) : false)),
        );
    }

    getUser(): Observable<UserResponse | null> {
        if (this.userDataService.currentUser === null) {
            return this.fetchUser();
        } else {
            return this.userDataService.user$;
        }
    }

    getInboxChoices(): Observable<UserInboxChoices | null> {
        return this.api.get(this._path + endpoints.userInboxChoices).pipe(
            tap((response: SimpleResponse<UserInboxChoices>) =>
                this.userDataService.updateUserInboxChoices(response.data),
            ),
            map((response: SimpleResponse<UserInboxChoices>) => response.data),
            catchError(() => of(null)),
        );
    }

    private fetchUser() {
        return this.api.get(this._path + endpoints.userInfo).pipe(
            tap((response: SimpleResponse<UserResponse>) =>
                this.userDataService.updateUser(response.data),
            ),
            map((response: SimpleResponse<UserResponse>) => response.data),
        );
    }
}
