import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';

import {
    DropdownItem,
    IdDisplayPair,
    SelectOption,
} from '@models/shared.model';
import { Formats } from '@constants/formats';
import { isNil } from '@helpers/index';
import { Priority } from '@models/shared/priority';

@Injectable({
    providedIn: 'root',
})
export class SharedMapper {
    constructor(private datePipe: DatePipe) {}

    toDropdownItem = (item: DropdownItem) =>
        new DropdownItem(item?.id, item?.name || '-');

    toDate = (date: string | Date): string => {
        return this.datePipe.transform(date, Formats.DATE) ?? '-';
    };

    toIdDisplayPair = (item: IdDisplayPair): IdDisplayPair =>
        new IdDisplayPair(item.backingField, item?.displayName || '-');

    toSelectOption = (item: IdDisplayPair): SelectOption => ({
        value: item.backingField,
        viewValue: item.displayName,
    });

    toPriority = (item: Priority): Priority => {
        const validPriority =
            !isNil(item) &&
            Object.values(Priority).includes(item!.toString() as Priority);

        return validPriority ? item : Priority.Undefined;
    };

    toLocalTime(utcDate: string) {
        const momentDate = moment.utc(utcDate);
        const localDate = momentDate.local();

        return localDate.format(Formats.DATE_WITH_HOUR);
    }
}
