<div>
    <label>
        @if (label) {
            <span class="flex items-start mb-1">
                <span
                    [ngClass]="{
                        'text-xxs color-gray14': true,
                        'color-status-red4': error,
                    }">
                    {{ getLabel() }}
                </span>
                <ng-content />
            </span>
        }
        <input
            [type]="type"
            [value]="value"
            (input)="onInput($event)"
            class="input block w-full"
            [ngClass]="{
                'input--secondary': variant === 'secondary',
                'input--tertiary': variant === 'tertiary',
            }"
            [attr.placeholder]="placeholder"
            [attr.maxlength]="maxLength"
            [attr.min]="min"
            [attr.max]="max" />

        <div *ngIf="maxLength" class="text-xs text-end mt-1 color-gray14">
            {{ value.length }}/{{ maxLength }}
        </div>
    </label>
    <div class="text-xs color-status-red4">
        <ng-content select="[error]" />
    </div>
</div>
