import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { SharedMapper } from '@services/api/shared.mapper';
import { RevisionTableSections } from '@constants/table';
import {
    DocumentRevisions,
    DocumentRevisionsResponse,
    DocumentRevisionTableItem,
} from '@models/document';

@Injectable({
    providedIn: 'root',
})
export class RevisionDataService {
    private documentRevisions = new BehaviorSubject<DocumentRevisions | null>(
        null,
    );
    private selectedRevisionId = new BehaviorSubject<number | null>(null);
    private revisionSaveTrigger = new Subject<void>();
    private originalSaved = new Subject<void>();

    documentRevisions$ = this.documentRevisions.asObservable();
    selectedRevisionId$ = this.selectedRevisionId.asObservable();
    revisionSaveTrigger$ = this.revisionSaveTrigger.asObservable();
    originalSaved$ = this.originalSaved.asObservable();
    unsavedDocumentId: number | null = null;

    private sharedMapper = inject(SharedMapper);

    get selectedRevision() {
        return this.selectedRevisionId.getValue();
    }

    get corrections() {
        return this.documentRevisions.getValue()?.corrections;
    }

    get finalVersion() {
        return this.documentRevisions.getValue()?.finalVersion;
    }

    get originalDocument() {
        return this.documentRevisions.getValue()?.originalRevision;
    }

    get appliedVersions() {
        return this.documentRevisions.getValue()?.appliedVersions;
    }

    get avCount() {
        return this.documentRevisions.getValue()?.appliedVersions.length ?? 0;
    }

    get pickableDiscrepancies() {
        return this.documentRevisions.getValue()?.pickableDiscrepancies;
    }

    get discrepancyNos() {
        return this.documentRevisions.getValue()?.discrepancyNos;
    }

    triggerRevisionSave() {
        this.revisionSaveTrigger.next();
    }

    triggerOriginalSaved() {
        this.originalSaved.next();
    }

    updateSelectedRevisionId(id: number | null) {
        this.unsavedDocumentId = null;
        this.selectedRevisionId.next(id);
    }

    updateDocumentRevisions(response: DocumentRevisionsResponse) {
        this.documentRevisions.next(response);
    }

    createRevisionTableData = (
        data: DocumentRevisions,
    ): DocumentRevisionTableItem[] => {
        const tableData: DocumentRevisionTableItem[] = [];
        const { finalVersion, appliedVersions, corrections, originalRevision } =
            data;

        if (finalVersion) {
            tableData.push(
                ...[
                    new DocumentRevisionTableItem(
                        'fv',
                        true,
                        RevisionTableSections.fv,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        'Will Be Published',
                    ),
                    new DocumentRevisionTableItem(
                        finalVersion.id,
                        false,
                        finalVersion.revisionName,
                        finalVersion.discrepancyRelations,
                        finalVersion.createdBy,
                        finalVersion.createdAt
                            ? this.sharedMapper.toLocalTime(
                                  finalVersion.createdAt,
                              )
                            : '-',
                        finalVersion.documentServiceId,
                        false,
                        finalVersion.id,
                    ),
                ],
            );
        }

        if (appliedVersions.length > 0) {
            tableData.push(
                new DocumentRevisionTableItem(
                    'av',
                    true,
                    RevisionTableSections.av,
                ),
            );
            appliedVersions.forEach((item) => {
                tableData.push(
                    new DocumentRevisionTableItem(
                        item.id,
                        false,
                        item.revisionName,
                        item.discrepancyRelations,
                        item.createdBy,
                        item.createdAt
                            ? this.sharedMapper.toLocalTime(item.createdAt)
                            : '-',
                        item.documentServiceId,
                        false,
                        item.id,
                    ),
                );
            });
        }

        if (corrections.length > 0) {
            tableData.push(
                new DocumentRevisionTableItem(
                    'uc',
                    true,
                    RevisionTableSections.uc,
                ),
            );
            corrections.forEach((item) => {
                tableData.push(
                    new DocumentRevisionTableItem(
                        item.id,
                        false,
                        item.revisionName,
                        item.discrepancyRelations,
                        item.createdBy,
                        item.createdAt
                            ? this.sharedMapper.toLocalTime(item.createdAt)
                            : '-',
                        item.documentServiceId,
                        false,
                        item.id,
                    ),
                );
            });
        }
        if (originalRevision) {
            const {
                id,
                revisionName,
                discrepancyRelations,
                createdBy,
                createdAt,
                documentServiceId,
            } = originalRevision;

            tableData.push(
                ...[
                    new DocumentRevisionTableItem(
                        'od',
                        true,
                        RevisionTableSections.od,
                    ),
                    new DocumentRevisionTableItem(
                        id,
                        false,
                        revisionName,
                        discrepancyRelations,
                        createdBy,
                        createdAt
                            ? this.sharedMapper.toLocalTime(createdAt)
                            : '-',
                        documentServiceId,
                        true,
                        id,
                    ),
                ],
            );
        }

        return tableData;
    };
}
