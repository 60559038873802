<div class="p-5">
    <p class="text-lg mb-3">
        Mark Discrepancy {{ data.discrepancyNo }} as Cancelled
    </p>
    <div class="p-3 text-sm bg-status-red1">
        This action will mark the discrepancy as <b>Cancelled</b> and moved to
        <b>Recently Completed</b> state. Once cancelled a discrepancy cannot be
        re-opened.
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.cancelDiscrepancyDialogReturnToPage">
            Return to Page
        </app-flat-button>
        <app-flat-button
            variant="warning"
            [dataQa]="dataQa.cancelDiscrepancyDialogCancel"
            (clicked)="cancel()">
            Mark as Cancelled
        </app-flat-button>
    </div>
    @if (error) {
        <div class="flex justify-end gap-8">
            <small class="color-status-red4"> Something went wrong </small>
        </div>
    }
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
