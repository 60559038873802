<div class="p-5">
    <p class="text-lg mb-3">Assign Discrepancy {{ data.discrepancyNo }}</p>
    <p class="text-sm mb-2">Select a person to assign to:</p>
    <div style="height: 30px">
        <app-autocomplete
            variant="secondary"
            placeholder="Search by name or e-mail"
            [options]="data.options"
            [inputControl]="analyst" />
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.assignDiscrepancyDialogCancel">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="cta"
            (clicked)="assign()"
            [disabled]="!analyst.value"
            [dataQa]="dataQa.assignDiscrepancyDialogAssign">
            Assign
        </app-flat-button>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
