import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebViewerInstance } from '@pdftron/webviewer';

import { InputComponent } from '@shared/input/input.component';
import { RadioButtonComponent } from '@shared/radio-button/radio-button.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { pageRangeValidator } from '@helpers/validators';
import { preparePages } from './utils';

@Component({
    selector: 'app-insert-menu-content',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        FlatButtonComponent,
        RadioButtonComponent,
        InputComponent,
        TextButtonComponent,
    ],
    styleUrl: 'apply-correction-dialog.component.scss',
    template: ` <div
        tabindex="0"
        class="insert-replace-menu color-white p-4"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <div class="grid gap-10">
            <p class="flex flex-wrap gap-5 items-end text-xs">
                <span class="text-semi"> Insert Page(s): </span>
                <app-input
                    variant="tertiary"
                    class="ml-1"
                    [style.width]="'190px'"
                    [required]="true"
                    [formControl]="pageRange"
                    [control]="pageRange">
                </app-input>
                <span class="text-semi">From</span> {{ sourceDocumentName }}
                <span class="correction-tag"> Uploaded Correction </span>
            </p>
            <div class="mt-2">
                <p class="text-s mb-1">
                    <span class="text-semi">To</span>
                    {{ destinationDocumentName }}
                </p>
                <div class="flex gap-10">
                    <p class="flex items-center text-xs">
                        <app-input
                            variant="tertiary"
                            class="ml-1"
                            type="number"
                            [style.width]="'190px'"
                            [min]="1"
                            [max]="destinationDocumentPageCount"
                            [formControl]="pageLocationToInsert" />
                    </p>
                    <div class="flex gap-8">
                        <app-radio-button
                            name="selectedPagePlacement"
                            size="small"
                            variant="dark"
                            value="above"
                            [(ngModel)]="selectedPagePlacement">
                            Above Page
                        </app-radio-button>
                        <app-radio-button
                            name="selectedPagePlacement"
                            size="small"
                            variant="dark"
                            value="below"
                            [(ngModel)]="selectedPagePlacement">
                            Below Page
                        </app-radio-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end gap-8">
            <app-text-button (clicked)="closeClicked()">
                Cancel
            </app-text-button>
            <app-flat-button
                variant="cta"
                [disabled]="
                    !pageLocationToInsert ||
                    !pageRange.value ||
                    pageRange.hasError('pageRange')
                "
                (clicked)="insert()">
                Insert
            </app-flat-button>
        </div>
    </div>`,
})
export class InsertMenuContentComponent {
    @Input({ required: true }) vwInstance!: WebViewerInstance;
    @Input() sourceDocumentName: string | undefined = '';
    @Input() destinationDocumentName: string | undefined = '';
    @Input() destinationDocumentPageCount: number = 0;
    @Output() closeMenu = new EventEmitter();
    @Output() inserted = new EventEmitter<{ log: string }>();

    selectedPagePlacement: 'above' | 'below' = 'above';
    pageLocationToInsert = new FormControl(1);
    pageRange = new FormControl('', [pageRangeValidator()]);

    insert() {
        if (this.pageRange.value) {
            const [dvLeft, dvRight] = this.vwInstance.Core.getDocumentViewers();
            const docLeft = dvLeft.getDocument();
            const docRight = dvRight.getDocument();

            const pagesToBeInserted = preparePages(this.pageRange.value);
            const locationToInsert = this.getPageLocationToInsert();

            docLeft
                .insertPages(docRight, pagesToBeInserted, locationToInsert)
                .then(() => {
                    dvLeft.setCurrentPage(locationToInsert, true);
                    this.inserted.emit({
                        log: `Page(s) ${pagesToBeInserted.join(', ')} from ${this.sourceDocumentName} (uploaded correction) inserted to ${this.selectedPagePlacement} of page ${locationToInsert} of ${this.destinationDocumentName}`,
                    });
                });
        }
    }

    closeClicked() {
        this.closeMenu.emit();
    }

    private getPageLocationToInsert() {
        let locationToInsert = 1;
        if (this.pageLocationToInsert) {
            const _page = Number(this.pageLocationToInsert.value) ?? 1;
            locationToInsert =
                this.selectedPagePlacement === 'above' ? _page - 1 : _page + 1;
            locationToInsert = locationToInsert === 0 ? 1 : locationToInsert;
        }

        return locationToInsert;
    }
}
