import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { TableComponent } from '@grid-table/table.component';
import { setSortQuery } from '../documents-and-discrepancies.utils';

@Component({
    selector: 'app-activity-log',
    standalone: true,
    templateUrl: 'activity-log.component.html',
    styleUrl: 'activity-log.component.scss',
    imports: [TableComponent, MatIconModule, TextButtonComponent],
})
export class ActivityLogComponent {
    @Input({ required: true }) discrepancyId!: number;

    @ViewChild('activityLogEnd') activityLogEnd!: ElementRef;
    toggled = false;
    columnDefs: ColDef[] = [
        { field: 'user', sortable: false },
        {
            field: 'logMessage',
            headerName: 'activity',
            flex: 2,
            sortable: false,
        },
        {
            field: 'createdAt',
            headerName: 'date',
            resizable: false,
            sort: 'desc',
            sortingOrder: ['asc', 'desc'],
        },
    ];
    private _gridApi!: GridApi;

    constructor(private discrepancyService: DiscrepancyService) {}

    get toggleText() {
        return this.toggled ? 'Hide Activity Log' : 'Show Activity Log';
    }

    onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;
        const datasource = this.getActivityLogs();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    toggleActivityLog() {
        this.toggled = !this.toggled;
    }

    private scrollToArea() {
        this.activityLogEnd.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        return {
            ...setSortQuery(request.sortModel),
        };
    }

    private getActivityLogs(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                this.discrepancyService
                    .getDiscrepancyActivityLogs(
                        this.discrepancyId,
                        this.getQueryParams(params.request),
                    )
                    .subscribe((data) => {
                        if (data) {
                            params.success({
                                rowData: data,
                                rowCount: data.length,
                            });
                            if (data.length === 0) {
                                params.api.showNoRowsOverlay();
                            }
                            this.scrollToArea();
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }
}
