import { Component, forwardRef, Input } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';

@Component({
    selector: 'app-textarea',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true,
        },
    ],
    templateUrl: './textarea.component.html',
    styleUrl: './textarea.component.scss',
})
export class TextareaComponent implements ControlValueAccessor {
    @Input() label: string = '';
    @Input() type: string = '';
    @Input() placeholder = '';
    @Input() maxLength: number | null = null;
    @Input() initialValue: string = '';
    @Input() required: boolean = false;
    @Input() control: FormControl | null = null;
    @Input() textAreaStyle: string = '';
    @Input() dataQa = '';

    value: string = '';

    onChange = (_value: string) => {};
    onTouched = () => {};

    onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.onChange(this.value);
    }

    writeValue(obj: string): void {
        this.value = obj;
    }

    registerOnChange(fn: (_value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    getLabel() {
        return `${this.label} ${this.required ? '*' : ''}`;
    }
}
