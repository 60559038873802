<div class="range-slider text-xs">
    {{ min }}
    <span class="dot"></span>
    <mat-slider [min]="min" [max]="max">
        <input
            matSliderStartThumb
            (input)="onMinChange($event)"
            [value]="formGroup.controls[minControlName].value" />
        <input
            matSliderEndThumb
            (input)="onMaxChange($event)"
            [value]="formGroup.controls[maxControlName].value" />
    </mat-slider>
    <span class="dot"></span>
    {{ max }}
</div>
