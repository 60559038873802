import { Directive, AfterViewInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Directive({
    selector: 'mat-menu[wfeCenter]',
    standalone: true,
})
export class MenuCenterDirective implements AfterViewInit {
    constructor(private menu: MatMenu) {}

    ngAfterViewInit(): void {
        this.menu.overlayPanelClass = `center-${this.menu.xPosition}`;
    }
}
