import { Priority } from '../shared.model';
import { DiscrepancyProgress } from './discrepancy-progress';
import { LatestActivity } from './latest-activity';

export class WorkflowDocument {
    constructor(
        public id: number,
        public name: string,
        public documentType: string,
        public repository: string,
        public priority: Priority,
        public createdAt: string,
        public station: string,
        public documentServiceId: string | null,
        public maximumDiscrepancyCount: number | null,
        public canAddDiscrepancy: boolean,
        public age: number | null,
        public latestActivity: LatestActivity,
        public progress: DiscrepancyProgress,
    ) {}
}
