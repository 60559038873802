<div class="flex flex-column h-full document-detail">
    <div class="header flex flex-wrap items-center color-gray18">
        <button mat-icon-button aria-label="Close" (click)="onCloseClick()">
            <mat-icon svgIcon="close" aria-label="Close" />
        </button>
        <span class="text-lg">{{ documentDetails?.name }}</span>
        @if (documentDetails) {
            <app-header-document-details
                class="ml-12"
                mode="detail"
                [document]="documentDetails" />
        }
    </div>
    <div class="body">
        <app-tabs
            alignment="center"
            class="flex mt-4 h-full"
            [tabs]="tabsData"
            [syncWithRouter]="true"
            [initialSelectedTabIndex]="initialSelectedTabIndex"
            (tabSelected)="onTabSelected($event)">
            <div class="flex h-full bg-gray0 overflow-hidden mt-n4">
                <div class="document-edit pl-4 mt-4">
                    <router-outlet />
                </div>
                <div wfeResizable></div>
                <div class="document-render mt-1 flex-1">
                    @if (documentLoading) {
                        <app-loader
                            class="disp-block h-full flex items-center justify-center" />
                    }
                    @if (revisionDetails) {
                        <app-document-preview
                            [revisionDetails]="revisionDetails"
                            (documentChange)="onDocumentChanged($event)" />
                    }
                </div>
            </div>
        </app-tabs>
    </div>
</div>
