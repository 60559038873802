import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IRowNode,
    IServerSideDatasource,
} from 'ag-grid-community';
import { delay } from 'rxjs/internal/operators/delay';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { DocumentService } from '@services/api/document/document.service';
import { RevisionDataService } from '@services/data/revision-data-service';
import { TableComponent } from '@shared/table/table.component';
import {
    SelectComponent,
    SelectionChangeEvent,
} from '@shared/select/select.component';
import { StatusCellComponent } from './status-cell.component';
import { RevisionNameCellComponent } from './revision-name-cell.component';
import { RevisionKind } from '@models/revision/revision-kind';
import { DocumentRevisionTableItem } from '@models/document';
import { SelectOption } from '@models/shared.model';
import {
    Discrepancy,
    DiscrepancyRevisionItem,
    DiscrepancyRevisionTableItem,
} from '@models/discrepancy';
import { selectSelectedDiscrepancy } from 'app/store/selectors/document.selectors';
import { RevisionKindToSectionMap } from '@constants/table';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { isNumber } from '@helpers/index';
import { reverse } from '@helpers/array';

@Component({
    selector: 'app-document-revisions',
    standalone: true,
    templateUrl: 'document-revisions.component.html',
    styleUrl: 'document-revisions.component.scss',
    imports: [TableComponent, SelectComponent],
})
export class DocumentRevisionsComponent implements OnInit, OnChanges {
    @Input({ required: true }) documentId!: number;
    @Input() canEditDocument = false;
    @Output() revisionClicked = new EventEmitter<number>();

    columnDefs: ColDef[] = [];
    discrepancies: SelectOption[] = [];
    selectedDiscrepancy: string | number = 'all';
    private _gridApi!: GridApi;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private store = inject(Store);
    private documentService = inject(DocumentService);
    private discrepancyService = inject(DiscrepancyService);
    private revisionDataService = inject(RevisionDataService);

    ngOnInit() {
        this.getDiscrepancies(this.documentId);
        this.listenDiscrepancyTabChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];

        if (documentIdChange && !documentIdChange.firstChange) {
            if (!this._gridApi.isDestroyed()) {
                this._gridApi.refreshServerSide({ purge: true });
            }
            this.getDiscrepancies(documentIdChange.currentValue);
        }

        const canEditDocumentChange = changes['canEditDocument'];
        if (canEditDocumentChange) {
            this.columnDefs = this.defaultColDef();
        }
    }

    onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;
        this.showDocumentRevisions();
    }

    getRowStyle = (params: { data: DocumentRevisionTableItem }) => {
        if (params?.data?.isHeader) {
            return { backgroundColor: '#f7f7f7', borderBottom: 'none' };
        }
        return undefined;
    };

    getDiscrepancies(id: number) {
        this.selectedDiscrepancy = 'all';
        this.discrepancyService.getDiscrepanciesByDocumentId(id).subscribe({
            next: (data) => {
                this.setDiscrepancyOptions(data.discrepancies);
            },
        });
    }

    onDiscrepancyChange(event: SelectionChangeEvent) {
        this.selectedDiscrepancy = event.value;
        if (isNumber(event.value)) this.showSelectedDiscRevisions(event.value);
        else {
            this.showDocumentRevisions();
        }
    }

    private showSelectedDiscRevisions(id: number) {
        if (this._gridApi && !this._gridApi.isDestroyed()) {
            const datasource = this.getDiscrepancyRevisions(id);
            this._gridApi.setGridOption('serverSideDatasource', datasource);
            this.setColumnDefsForDiscrepancy();
        }
    }

    private setColumnDefsForDiscrepancy() {
        if (this._gridApi && !this._gridApi.isDestroyed()) {
            this._gridApi.setGridOption('columnDefs', [
                ...this.defaultColDef(),
            ]);
        }
    }

    private setColumnDefsForDocument() {
        if (this._gridApi) {
            this._gridApi.setGridOption('columnDefs', this.defaultColDef());
        }
    }

    private showDocumentRevisions() {
        const datasource = this.getDocumentVersions();
        this._gridApi?.setGridOption('serverSideDatasource', datasource);
        this.setColumnDefsForDocument();
    }

    private setDiscrepancyOptions(discrepancies: Discrepancy[]) {
        const discOptions = discrepancies.map((disc) => ({
            value: disc.id,
            viewValue: `Discrepancy ${disc.discrepancyNo}`,
        }));
        this.discrepancies = [
            { value: 'all', viewValue: 'All Discrepancies' },
            ...discOptions,
        ];
    }

    private getDocumentVersions(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();

                this.documentService
                    .getDocumentRevisions(this.documentId)
                    .subscribe((data) => {
                        if (data) {
                            const rowData =
                                this.revisionDataService.createRevisionTableData(
                                    data,
                                );
                            params.success({
                                rowData: rowData,
                                rowCount: rowData.length,
                            });
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }

    private listenDiscrepancyTabChange() {
        this.selectedDiscrepancy = 'all';
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );

        this._subscriptions['selected-disc'] = selectedDiscrepancy$
            .pipe(delay(100))
            .subscribe({
                next: (id) => {
                    if (id) {
                        this.selectedDiscrepancy = id;
                        this.showSelectedDiscRevisions(id);
                    }
                },
            });
    }

    private getDiscrepancyRevisions(id: number): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();

                this.discrepancyService
                    .getDiscrepancyRevisions(id)
                    .subscribe((data) => {
                        if (data) {
                            const sorted = this.sortRevisions(data.revisions);
                            const rowData =
                                this.createDiscRevisionTableData(sorted);
                            params.success({
                                rowData: rowData,
                                rowCount: rowData.length,
                            });
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }

    private sortRevisions(revisions: DiscrepancyRevisionItem[]) {
        const reversed = reverse(revisions);
        const sorted = [
            ...reversed.filter(
                (item) => item.kind === RevisionKind.FINAL_VERSION,
            ),
            ...reversed.filter(
                (item) => item.kind !== RevisionKind.FINAL_VERSION,
            ),
        ];

        return sorted;
    }

    private createDiscRevisionTableData(
        items: DiscrepancyRevisionItem[],
    ): DiscrepancyRevisionTableItem[] {
        const grouped = new Map<RevisionKind, DiscrepancyRevisionItem[]>();
        items.forEach((item) => {
            if (!grouped.has(item.kind)) {
                grouped.set(item.kind, []);
            }
            grouped.get(item.kind)?.push(item);
        });

        const result: DiscrepancyRevisionTableItem[] = [];
        grouped.forEach((items, kind) => {
            result.push({
                isHeader: true,
                id: RevisionKind[kind],
                additionalInfo:
                    kind === RevisionKind.FINAL_VERSION
                        ? 'Will Be Published'
                        : '',
                revisionName: RevisionKindToSectionMap[kind],
            });
            result.push(
                ...items.map((item) => ({
                    isHeader: false,
                    revisionName: item.name,
                    revisionId: item.id,
                    ...item,
                })),
            );
        });

        return result;
    }

    private defaultColDef() {
        return [
            {
                field: 'revisionName',
                headerName: 'Version Name',
                floatingFilter: false,
                sortable: false,
                cellRenderer: RevisionNameCellComponent,
                cellRendererParams: {
                    clickable: this.canEditDocument,
                    onClick: (node: IRowNode<DocumentRevisionTableItem>) => {
                        if (node.data?.revisionId) {
                            this.revisionClicked.emit(node.data.revisionId);
                        }
                    },
                },
            },
            {
                field: 'relationKind',
                headerName: 'Status',
                cellRenderer: StatusCellComponent,
                cellRendererParams: {
                    selectedDiscrepancy: this.selectedDiscrepancy,
                },
                floatingFilter: false,
                sortable: false,
                hide: this.selectedDiscrepancy === 'all' ? true : false,
            },
        ];
    }
}
