import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: '[wfeResizable]',
    standalone: true,
})
export class ResizableDirective implements OnChanges {
    @Input() wfeResizable!: '';
    @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';

    private _startX = 0;
    private _startY = 0;
    private _startWitdh = 0;
    private _starHeight = 0;
    private _minWidth = 100;
    private _minHeight = 50;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    get isHorizontal() {
        return this.orientation === 'horizontal';
    }

    ngOnChanges(): void {
        const cursor = this.isHorizontal ? 'col-resize' : 'row-resize';
        this.renderer.setStyle(this.el.nativeElement, 'cursor', cursor);
        this.renderer.setStyle(
            this.el.nativeElement,
            'background-color',
            '#d8e0e5',
        );
        this.renderer.setStyle(this.el.nativeElement, 'z-index', '1');

        if (this.isHorizontal) {
            this.renderer.setStyle(this.el.nativeElement, 'min-width', '10px');
            this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
        } else {
            this.renderer.setStyle(this.el.nativeElement, 'min-height', '10px');
            this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
        }
    }

    @HostListener('pointerdown', ['$event'])
    onPointerDown(event: MouseEvent) {
        const leftElement = this.el.nativeElement.previousElementSibling;
        const rightElement = this.el.nativeElement.nextElementSibling;
        this._startX = event.clientX;
        this._startY = event.clientY;
        this._startWitdh = leftElement.getBoundingClientRect().width;
        this._starHeight = leftElement.getBoundingClientRect().height;

        this.renderer.setStyle(rightElement, 'pointer-events', 'none');

        const cursor = this.isHorizontal ? 'col-resize' : 'row-resize';
        document.body.style.cursor = cursor;
        document.body.style.userSelect = 'none';
        document.addEventListener('pointermove', this.onPointerMove);
        document.addEventListener('pointerup', this.onPointerUp);
    }

    onPointerMove = (event: MouseEvent) => {
        const dx = event.clientX - this._startX;
        const dy = event.clientY - this._startY;

        const leftElement = this.el.nativeElement.previousElementSibling;

        if (this.isHorizontal) {
            const newWidth = this._startWitdh + dx;
            if (
                newWidth >= this._minWidth &&
                newWidth <= innerWidth - this._minWidth
            ) {
                this.renderer.setStyle(leftElement, 'flex', 'none');
                this.renderer.setStyle(
                    this.el.nativeElement.previousElementSibling,
                    'width',
                    `${newWidth}px`,
                );
            }
        } else {
            const newHeight = this._starHeight + dy;
            if (
                newHeight >= this._minHeight &&
                newHeight <= innerHeight - this._minHeight
            ) {
                this.renderer.setStyle(leftElement, 'flex', 'none');
                this.renderer.setStyle(
                    this.el.nativeElement.previousElementSibling,
                    'height',
                    `${newHeight}px`,
                );
            }
        }
    };

    onPointerUp = () => {
        document.body.style.cursor = 'default';
        document.body.style.userSelect = 'auto';

        const rightElement = this.el.nativeElement.nextElementSibling;
        this.renderer.setStyle(rightElement, 'pointer-events', 'auto');

        document.removeEventListener('pointermove', this.onPointerMove);
        document.removeEventListener('mouseup', this.onPointerUp);
    };
}
