@if (revisionDetails && document) {
    <app-web-viewer
        [document]="document"
        [elementsToBeDisabled]="elementsToBeDisabled">
        <div
            header
            class="header text-sm flex gap-10 items-center justify-space-between">
            <div class="flex items-center text-semi">
                <mat-icon
                    svgIcon="arrow-right"
                    class="color-primary-default mr-1" />
                {{ revisionDetails.name }}
                @if (statuses) {
                    <app-tooltip class="ml-1">
                        <mat-icon trigger svgIcon="info" />
                        <div content class="grid gap-5 text-s">
                            @for (item of getKeys(statuses); track $index) {
                                @if (statuses[item].show) {
                                    <p
                                        class="flex items-end"
                                        [class]="statuses[item].textClass">
                                        <mat-icon
                                            [svgIcon]="statuses[item].icon" />
                                        <span class="ml-2">
                                            {{ statuses[item].text }}
                                        </span>
                                    </p>
                                }
                            }
                        </div>
                    </app-tooltip>
                }
            </div>
            <div class="flex gap-6">
                @if (canApply()) {
                    <app-flat-button
                        variant="success"
                        type="solid"
                        [dataQa]="dataQa.documentPreviewApplyButton"
                        (clicked)="onApplyClicked()">
                        Apply Correction
                    </app-flat-button>
                }
                @if (canReject()) {
                    <app-flat-button
                        variant="danger"
                        (clicked)="onRejectClick()"
                        [dataQa]="dataQa.documentPreviewRejectButton">
                        Reject
                    </app-flat-button>
                }
                @if (canAccept()) {
                    <app-flat-button
                        variant="success"
                        (clicked)="onAcceptClick()"
                        [dataQa]="dataQa.documentPreviewAcceptButton">
                        Accept
                    </app-flat-button>
                }
            </div>
        </div>
    </app-web-viewer>
}
