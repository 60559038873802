import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { StationService } from '@services/api/station/station.service';
import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { SelectComponent } from '@shared/select/select.component';
import { InputComponent } from '@shared/input/input.component';
import { InfoBoxComponent } from '@shared/info-box/info-box.component';
import { TextareaComponent } from '@shared/textarea/textarea.component';
import { InvalidPageRangeErrorComponent } from '@shared/validation-error/invalid-page-range.component';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { pageRangeValidator } from '@helpers/validators';
import { SelectOption } from '@models/shared.model';
import { DocDiscrepancyCreateOptions } from '@models/document/discrepancy-create-options';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'add-discrepancy-dialog.component.html',
    providers: [StationService],
    imports: [
        ReactiveFormsModule,
        SelectComponent,
        FlatButtonComponent,
        LoaderComponent,
        InputComponent,
        InvalidPageRangeErrorComponent,
        InfoBoxComponent,
        TextareaComponent,
    ],
})
export class AddDiscrepancyDialogComponent implements OnInit, OnDestroy {
    form!: FormGroup;
    formChanged = false;
    loading = false;
    error = false;
    options = DocDiscrepancyCreateOptions.initial();
    fixers: SelectOption[] = [];
    readonly maxDirectionsLength = 300;
    readonly dataQa = DATA_QA;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    readonly data: {
        documentId: number;
        station?: number;
        fixer?: number;
    } = inject(MAT_DIALOG_DATA);
    private formBuilder = inject(FormBuilder);
    private stationService = inject(StationService);
    private snackbarService = inject(SnackbarService);
    private documentService = inject(DocumentService);
    private discrepancyService = inject(DiscrepancyService);
    private dialogRef = inject(MatDialogRef<AddDiscrepancyDialogComponent>);

    get pageRange() {
        return this.form.get('pageRange') as FormControl;
    }

    ngOnInit(): void {
        this.initForm();
        this.getFormOptions();
        this.handleFormChange();
        this.handleStationChange();
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }

    create() {
        this.loading = true;
        this._subscriptions['add-discrepancy'] = this.discrepancyService
            .addDiscrepancyToDocument(this.data.documentId, this.form.value)
            .subscribe({
                next: (newDiscrepancy) => {
                    this.loading = false;
                    if (newDiscrepancy) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: 'Discrepancy Is Created',
                        });
                        this.dialogRef.close({
                            success: true,
                            discId: newDiscrepancy.id,
                        });
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    isCreateDisabled() {
        return !(this.formChanged && this.form.valid) || this.loading;
    }

    onCloseClick() {
        this.dialogRef.close({ success: false });
    }

    private initForm() {
        const { station, fixer } = this.data;

        this.form = this.formBuilder.group({
            reasonId: new FormControl(null),
            stationId: new FormControl(station),
            primaryFixerId: new FormControl(fixer),
            pageRange: new FormControl('', [pageRangeValidator()]),
            directions: new FormControl(
                '',
                Validators.maxLength(this.maxDirectionsLength),
            ),
        });

        if (station) {
            this.getFixers(station);
        }
    }

    private getFormOptions() {
        this._subscriptions['get-create-options'] = this.documentService
            .getDocDiscrepancyCreateOptions(this.data.documentId)
            .subscribe({
                next: (data) => (this.options = data),
            });
    }

    private handleFormChange() {
        const initialValue = this.form.value;
        this._subscriptions['form-change'] = this.form.valueChanges.subscribe(
            () => {
                this.formChanged = Object.keys(initialValue).some(
                    (key) => this.form.value[key] != initialValue[key],
                );
            },
        );
    }

    private getFixers(stationId: number) {
        this._subscriptions['station-fixers'] = this.stationService
            .getStationFixers(stationId)
            .subscribe({
                next: (fixers) => {
                    this.fixers = fixers.map((fixer) => ({
                        value: fixer.id,
                        viewValue: `${fixer.fullName} (${fixer.email})`,
                    }));
                },
            });
    }

    private handleStationChange(): void {
        this._subscriptions['station-change'] = this.form.controls[
            'stationId'
        ].valueChanges.subscribe((change) => {
            this.form.patchValue({ primaryFixerId: null });
            this.getFixers(change);
        });
    }
}
