import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams, ISimpleFilter } from 'ag-grid-community';
import { debounceTime, Subject } from 'rxjs';

export interface CustomParams {
    class: string;
    type: 'text' | 'number';
}

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './text-filter.component.html',
    styleUrl: './text-filter.component.scss',
})
export class TextFilterComponent implements IFloatingFilterAngularComp {
    params!: IFloatingFilterParams<ISimpleFilter> & CustomParams;
    currentValue: null | string = null;
    inputType: 'text' | 'number' = 'text';
    private input$ = new Subject<string | number>();

    agInit(params: IFloatingFilterParams<ISimpleFilter> & CustomParams): void {
        this.params = params;
        this.inputType = params.type || 'text';
        this.input$.pipe(debounceTime(500)).subscribe(() => {
            this.onValueChange();
        });
    }

    inputChange(event: Event) {
        this.input$.next((event.target as HTMLInputElement).value);
    }

    onParentModelChanged(parentModel: any) {
        if (!parentModel) {
            this.currentValue = null;
        } else {
            this.currentValue = parentModel.filter;
        }
    }

    onValueChange() {
        if (!this.currentValue) {
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }

        this.params.parentFilterInstance((instance: any) => {
            instance.onFloatingFilterChanged('equals', this.currentValue);
        });
    }
}
