<div class="flex flex-column ml-n4">
    <app-text-button class="toggle self-center" (click)="toggleActivityLog()">
        <span class="text-s">{{ toggleText }}</span>
        <mat-icon
            svgIcon="chevron-down"
            class="toggle__icon"
            [attr.toggled]="toggled" />
    </app-text-button>
    @if (toggled) {
        <div class="table p-3">
            <p class="title pl-2 mb-2">Activity Log</p>
            <app-table
                variant="secondary"
                noRowsText="No activity logs to show."
                [hideBorder]="true"
                [suppressRowHoverHighlight]="true"
                [suppressCellFocus]="true"
                [pagination]="false"
                [floatingFilter]="false"
                [columnDefs]="columnDefs"
                [pageSize]="-1"
                [gridOptions]="gridOptions"
                (gridReady)="onGridReady($event)" />
        </div>
    }
</div>
<div #activityLogEnd></div>
