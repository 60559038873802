export const DATE_FORMAT = {
    parse: {
        dateInput: 'DD MMM, yyyy',
    },
    display: {
        dateInput: 'DD MMM, yyyy',
        monthYearLabel: 'DD MMM, yyyy',
        dateA11yLabel: '',
        monthYearA11yLabel: '',
    },
};
