<mat-label class="label text-xxs color-gray14">{{ label }} </mat-label>
<form>
    <mat-chip-grid #chipGrid [attr.aria-label]="label">
        @for (selected of selectedOptions(); track $index) {
            <mat-chip-row (removed)="remove(selected)">
                {{ selected.viewValue }}
                <button matChipRemove [attr.aria-label]="'remove ' + selected">
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </mat-chip-row>
        }
    </mat-chip-grid>
    <input
        #input
        name="current"
        class="w-full h-full"
        [placeholder]="placeholder"
        [(ngModel)]="current"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event); input.value = ''">
        @if (isGroupedOptions) {
            @for (option of filteredGroupOptions(); track option.group) {
                @if (option.items.length > 0) {
                    <mat-optgroup [label]="option.group">
                        @for (item of option.items; track item.value) {
                            <mat-option
                                [value]="item"
                                [disabled]="isOptionDisabled(item.value)">
                                {{ item.viewValue }}
                            </mat-option>
                        }
                    </mat-optgroup>
                }
            }
        } @else {
            @for (option of filteredOptions(); track option) {
                <mat-option
                    [value]="option"
                    [disabled]="isOptionDisabled(option.value)">
                    {{ option.viewValue }}
                </mat-option>
            }
        }
    </mat-autocomplete>
</form>
