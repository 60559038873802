import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { DocumentService } from '@services/api/document/document.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { RouterService } from '@services/router.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { DocumentPreviewComponent } from 'app/components/documents-and-discrepancies/detail-dialog/document-preview/document-preview.component';
import { HeaderDocumentDetailsComponent } from 'app/components/documents-and-discrepancies/header-document-details/header-document-details.component';
import {
    DocumentDetailTab,
    DocumentDetailTabs,
} from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import { ResizableDirective } from '@directives/resizable.directive';
import { WorkflowDocument } from '@models/document';
import { RevisionDetails } from '@models/revision/revision-detail';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { DocumentDataService } from '@services/data/document-data.service';

@Component({
    standalone: true,
    templateUrl: 'document-detail.component.html',
    styleUrl: 'document-detail.component.scss',
    imports: [
        MatIconModule,
        MatButtonModule,
        TabsComponent,
        ResizableDirective,
        LoaderComponent,
        DocumentPreviewComponent,
        RouterOutlet,
        HeaderDocumentDetailsComponent,
    ],
})
export class DocumentDetailPageComponent implements OnInit, OnDestroy {
    documentId!: number;
    readonly tabs: typeof DocumentDetailTab = DocumentDetailTab;
    selectedDocumentRevisionId: number | null = null;
    activeTab: DocumentDetailTab = DocumentDetailTab.METADATA;
    tabsData = DocumentDetailTabs;
    documentDetails: WorkflowDocument | null = null;
    documentLoading = false;
    revisionDetails: RevisionDetails | null = null;
    initialSelectedTabIndex: number | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        public dialog: MatDialog,
        private routerService: RouterService,
        private route: ActivatedRoute,
        private store: Store,
        private documentService: DocumentService,
        private documentDataService: DocumentDataService,
        private revisionService: RevisionService,
        private actions$: Actions,
    ) {
        this.setDocumentIdFromRouter();
        this.getDocumentDetails(this.documentId);
    }

    ngOnInit(): void {
        this.setDocumentDetails();
        this.setSelectedTab();
        this.subscribeSelectedRevision();
        this.handleComponentRefresh();
    }

    ngOnDestroy(): void {
        this.documentDataService.updateSelectedRevisionId(null);
        this._subscriptions.unsubscribeAllSafe();
    }

    onCloseClick() {
        this.routerService.goHome();
    }

    onTabSelected(tabIndex: number) {
        this.activeTab = this.tabsData[tabIndex].id;
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: this.documentId,
                selectedTab: this.activeTab,
                selectedDiscrepancy: null,
            }),
        );
    }

    private setDocumentIdFromRouter() {
        this.route.paramMap.subscribe({
            next: (params) => (this.documentId = parseFloat(params.get('id')!)),
        });
    }

    private setDocumentDetails() {
        this._subscriptions['document-details'] =
            this.documentDataService.documentDetail$.subscribe({
                next: (detail) => {
                    this.documentDetails = detail;
                    if (!this.selectedDocumentRevisionId && detail) {
                        this.documentDataService.updateSelectedRevisionId(
                            detail.originalRevisionId!,
                        );
                    }
                },
            });
    }

    private setSelectedTab() {
        this.route.children.forEach((child) => {
            this.activeTab = (child.routeConfig?.path ||
                '') as DocumentDetailTab;
        });
        this.initialSelectedTabIndex = this.tabsData.findIndex(
            (item) => item.id === this.activeTab,
        );
    }

    private subscribeSelectedRevision() {
        this._subscriptions['document-revision'] =
            this.documentDataService.selectedRevisionId$.subscribe({
                next: (id) => {
                    if (id) {
                        this.selectedDocumentRevisionId = id;
                        this.getRevisionDetails(
                            this.selectedDocumentRevisionId!,
                        );
                    }
                },
            });
    }

    private getRevisionDetails(id: number) {
        this.revisionDetails = null;

        this._subscriptions['get-revision-details'] = this.revisionService
            .getRevisionDetails(id)
            .subscribe({
                next: (response) => (this.revisionDetails = response),
            });
    }

    private handleComponentRefresh() {
        this._subscriptions['component-refresh'] = this.actions$
            .pipe(ofType(refreshActionRelatedData))
            .subscribe({
                next: (refresh) => {
                    if (refresh) {
                        this.getDocumentDetails(this.documentId);
                    }
                },
            });
    }

    private getDocumentDetails(id: number) {
        this.documentLoading = true;
        this._subscriptions['get-document-details'] = this.documentService
            .getDocumentDetails(id)
            .subscribe({
                next: () => (this.documentLoading = false),
            });
    }
}
