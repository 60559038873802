import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface SnackbarComponentProps {
    variant?: 'default' | 'success' | 'progress' | 'error';
    header: string;
    content?: string;
}

@Component({
    standalone: true,
    templateUrl: 'snackbar.component.html',
    styleUrl: 'snackbar.component.scss',
    imports: [MatIconModule, MatButtonModule, MatMenuModule],
})
export class SnackbarComponent {
    snackBarRef = inject(MatSnackBarRef);
    data: SnackbarComponentProps = inject(MAT_SNACK_BAR_DATA);

    constructor() {}

    closeSnackbar() {
        this.snackBarRef.dismissWithAction();
    }
}
