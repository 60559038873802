import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

import { DocumentRevisionTableItem } from '@models/document/revision';

interface CustomParams {
    data: DocumentRevisionTableItem;
    onClick: (node: IRowNode) => void;
    clickable: boolean;
}

@Component({
    selector: 'app-revision-name-cell',
    standalone: true,
    imports: [MatIconModule],
    template: `
        @if (params.data.isHeader) {
            {{ revisionName }}
            @if (additionalInfo) {
                <b>({{ additionalInfo }})</b>
            }
        } @else {
            @if (params.clickable) {
                <span
                    tabindex="0"
                    class="color-primary flex items-center gap-4 cursor-pointer"
                    (click)="params.onClick(params.node)"
                    (keyup.enter)="params.onClick(params.node)">
                    @if (params.data.sentForConnection) {
                        <mat-icon
                            svgIcon="send-circle"
                            style="minWidth: 24px" />
                    }
                    {{ revisionName }}
                </span>
            } @else {
                {{ revisionName }}
            }
        }
    `,
})
export class RevisionNameCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams & CustomParams;
    revisionName: string = '';
    additionalInfo: string | null = null;

    agInit(params: ICellRendererParams & CustomParams): void {
        this.params = params;
        this.revisionName = params.data.revisionName;
        this.additionalInfo = params.data.additionalInfo;
    }

    refresh() {
        return false;
    }
}
