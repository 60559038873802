<label>
    <span class="text-xxs color-gray14">
        {{ label }} {{ required ? '*' : '' }}
    </span>
    <div
        class="input"
        tabindex="0"
        (click)="picker.open()"
        (keyup)="picker.open()">
        <input
            matInput
            [value]="value"
            [matDatepicker]="picker"
            readonly="readonly"
            (dateChange)="onDateChange($event)" />
        <mat-icon svgIcon="date-picker" class="icon pos-abs"> </mat-icon>
        <mat-datepicker #picker></mat-datepicker>
    </div>
</label>
