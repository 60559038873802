import { WebViewerInstance } from '@pdftron/webviewer';

export const insertCorrectedDocumentTag = (vwInstance: WebViewerInstance) => {
    const node = document.createElement('span');
    node.style.cssText = `color: #A0EC4C;
    border: 1px solid #4F7B00;
    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap`;
    const text = document.createTextNode('Uploaded Correction');
    node.appendChild(text);
    const div = vwInstance.UI.iframeWindow.document.querySelector(
        '#header2 .file-name',
    );

    div?.insertAdjacentElement('afterend', node);
};

export const hideCloseDocumentButton = (vwInstance: WebViewerInstance) => {
    const matches =
        vwInstance.UI.iframeWindow.document.querySelectorAll(
            '.control-buttons',
        );

    matches.forEach((match) => {
        const buttons = match.querySelectorAll('button');
        buttons[1].style.display = 'none';
    });
};

/**
 * Inserts current page number to document on multiviewer mode.
 * @param {WebViewerInstance} vwInstance - A single instance of webviewer.
 */
export const insertCurrentPageNumber = (vwInstance: WebViewerInstance) => {
    const instanceDocument = vwInstance.UI.iframeWindow.document;
    const node1 = document.createElement('span');
    const node2 = document.createElement('span');

    node1.setAttribute('id', 'vwCurrentPage1');
    node1.textContent = '1';
    node2.setAttribute('id', 'vwCurrentPage2');
    node2.textContent = '1';

    const cssText = `
        position: absolute;
        bottom: 10px;
        background-color: #343A41;
        border-radius: 4px;
        padding: 5px;
        text-align: center;
        min-width: 24px;
        min-height: 24px;
    `;

    node1.style.cssText = cssText;
    node2.style.cssText = cssText;

    const doc1 = instanceDocument.querySelector('#Document1');
    const doc2 = instanceDocument.querySelector('#Document2');

    doc1?.insertAdjacentElement('afterend', node1);
    doc2?.insertAdjacentElement('afterend', node2);

    const [documentViewerLeft, documentViewerRight] =
        vwInstance.Core.getDocumentViewers();

    documentViewerLeft.addEventListener('pageNumberUpdated', (pageNumber) => {
        const el = instanceDocument.getElementById('vwCurrentPage1');
        if (el) el.textContent = pageNumber;
    });

    documentViewerRight.addEventListener('pageNumberUpdated', (pageNumber) => {
        const el = instanceDocument.getElementById('vwCurrentPage2');
        if (el) el.textContent = pageNumber;
    });
};
