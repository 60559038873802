import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { TooltipComponent } from '../../../tooltip/tooltip.component';

interface CustomParams {
    onClick: (node: IRowNode) => void;
    onDoubleClick: (node: IRowNode) => void;
    tooltipGetter?: (node: ICellRendererParams) => string;
}

@Component({
    standalone: true,
    imports: [TooltipComponent],
    template: `
        <div
            tabindex="0"
            class="clickable"
            (dblclick)="params.onDoubleClick(params.node)"
            (click)="params.onClick(params.node)"
            (keyup.enter)="params.onClick(params.node)">
            @if (tooltip) {
                <app-tooltip>
                    <span trigger>
                        {{ params.valueFormatted ?? params.value }}
                    </span>
                    <div content class="grid gap-5 text-s">
                        {{ tooltip }}
                    </div>
                </app-tooltip>
            } @else {
                {{ params.valueFormatted ?? params.value }}
            }
        </div>
    `,
    styleUrl: './clickable-cell.component.scss',
})
export class ClickableCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams & CustomParams;
    public tooltip: string | undefined;

    agInit(params: ICellRendererParams & CustomParams): void {
        this.params = params;
        this.tooltip = this.params.tooltipGetter?.(this.params);
    }

    refresh() {
        return false;
    }
}
