import {
    Component,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    ColDef,
    DetailGridInfo,
    GetDetailRowDataParams,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { Store } from '@ngrx/store';

import { RouterService } from '@services/router.service';
import { UserDataService } from '@services/data/user-data.service';
import { ColumnOptionsService } from '@services/column-options.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { DocumentService } from '@services/api/document/document.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TableComponent } from '@grid-table/table.component';
import { TextFilterComponent } from '@grid-table/filter/text-filter/text-filter.component';
import { PriorityCellComponent } from '@grid-table/cell/priority-cell/priority-cell.component';
import { SelectFilterComponent } from '@grid-table/filter/select-filter/select-filter.component';
import { NoRowsOverlayComponent } from '@grid-table/overlay/no-rows-overlay/no-rows-overlay.component';
import { ClickableCellComponent } from '@shared/table/cell/clickable-cell/clickable-cell.compoent';
import { DateFilterComponent } from '@shared/table/filter/date-filter/date-filter.component';
import { GrayBoxCellComponent } from '@shared/table/cell/gray-box-cell/gray-box-cell.component';
import { RangeFilterComponent } from '@shared/table/filter/range-filter/range-filter.component';
import { CountStatusBarComponent } from './count-status-bar.component';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { DocumentFilterOptions, WorkflowDocument } from '@models/document';
import { Discrepancy, BulkRequestItem } from '@models/discrepancy';
import { Range } from '@models/shared.model';
import { DocumentColumn, DocumentNestedColumn } from '@constants/table';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import {
    DocumentDetailTab,
    getAgeInWFValue,
    getDiscrepancyId,
    highlightSelectedDiscrepancy,
    isFilterApplied,
    setFilterAndSorting,
    getFilterQuery,
    getPageIndex,
    getSortQuery,
} from '../documents-and-discrepancies.utils';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-document-view',
    standalone: true,
    templateUrl: './document-view.component.html',
    imports: [TableComponent],
})
export class DocumentViewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() workflowAndRepo: WorkflowAndRepository | undefined;
    @Input() statuses: number[] = [];
    @Input() showMyItems: boolean = false;

    filterApplied: boolean = false;
    resultCount: number | null = null;
    selectedBulkData: BulkRequestItem[] = [];
    protected error = false;
    protected columnDefs: ColDef[] = [];
    protected gridOptions: GridOptions = {
        onRowSelected: this.onRowSelected.bind(this),
        onPaginationChanged: this.onRowSelected.bind(this),
    };
    protected detailCellRendererParams = (p: ICellRendererParams) =>
        this.getDetailCellRendererParams(p);
    private hiddenColumnOptions: DocumentColumn[] = [];
    private hiddenNestedColumnOptions: DocumentNestedColumn[] = [];
    private _gridApi!: GridApi;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private store = inject(Store);
    private ngZone = inject(NgZone);
    private userDataService = inject(UserDataService);
    private routerService = inject(RouterService);
    private documentService = inject(DocumentService);
    private discrepancyService = inject(DiscrepancyService);
    private documentDataService = inject(DocumentDataService);
    private columnOptionsService = inject(ColumnOptionsService);

    constructor() {
        // this.setSavedFilter();
    }

    get filterModel() {
        return this._gridApi.getFilterModel();
    }

    get columnState() {
        return this._gridApi.getColumnState();
    }

    get hasSelectedRow() {
        return this.selectedBulkData.length > 0;
    }

    get selectedRowsCount() {
        return this.selectedBulkData.length;
    }

    ngOnInit(): void {
        this.columnDefs = [
            ...this.getColumnDefs(DocumentFilterOptions.initial()),
        ];
        this.handleColumnOptions();
        this.getFilterOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const workflowAndRepoChange = changes['workflowAndRepo'];
        if (workflowAndRepoChange && workflowAndRepoChange.previousValue) {
            this.resetFilters();
            this.getFilterOptions(true);
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const datasource = this.getDocuments();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    setBulkData() {
        const rows: BulkRequestItem[] = [];

        if (this._gridApi)
            this._gridApi.forEachNode((rowNode: any) => {
                if (rowNode.isSelected()) {
                    const discList: number[] = [];

                    rowNode.detailNode?.detailGridInfo?.api.forEachNode(
                        (childNode: IRowNode) => {
                            if (childNode.isSelected()) {
                                discList.push(childNode.data.id);
                            }
                        },
                    );
                    rows.push({
                        documentId: rowNode?.data.id,
                        discrepancies: discList,
                    });
                }
            });

        this.selectedBulkData = rows;
    }

    resetFilters() {
        this._gridApi?.refreshHeader();
        this._gridApi?.setFilterModel(null);
        this._gridApi?.onFilterChanged();
    }

    refreshData() {
        if (!this._gridApi.isDestroyed()) {
            this._gridApi.refreshServerSide({ purge: true });
        }
    }

    closePanel() {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: null,
                selectedDiscrepancy: null,
            }),
        );
    }

    private setSavedFilter() {
        this._subscriptions['set-saved-filter'] =
            this.userDataService.userInboxChoices$.subscribe({
                next: (choices) => {
                    if (choices) {
                        setFilterAndSorting(this._gridApi, choices.document);
                    }
                },
            });
    }

    private onRowSelected(event: any) {
        const masterNodeSelected =
            event?.node?.master &&
            event.node.expanded &&
            event.source === 'checkboxSelected';

        if (masterNodeSelected) {
            this.selectAllChildNodes(event);
        } else {
            this.selectParentNode(event);
        }

        this.setBulkData();
    }

    private selectAllChildNodes(event: any) {
        const gridDetailGridInfo: DetailGridInfo = event.api.getDetailGridInfo(
            event.node.detailNode.id,
        );

        if (gridDetailGridInfo?.api) {
            gridDetailGridInfo.api.forEachNode((node: IRowNode) => {
                node.setSelected(event.node.selected);
            });
        }
    }

    private selectParentNode(event: any) {
        const parentNodeId = event?.data?.documentId?.toString() || null;

        if (parentNodeId) {
            const parentNode = this._gridApi.getRowNode(parentNodeId);
            const noChildSelected = event.api.getSelectedNodes().length === 0;

            parentNode?.setSelected(!noChildSelected);
        }
    }

    private handleColumnOptions() {
        this._subscriptions['column-options'] =
            this.columnOptionsService.columnOptions$.subscribe({
                next: (options) => {
                    this.hiddenColumnOptions = options.hiddenColumns;
                    const option = options.lastToggled;
                    if (
                        option &&
                        this._gridApi &&
                        !this._gridApi.isDestroyed()
                    ) {
                        this._gridApi.setColumnsVisible(
                            [option.id],
                            option.checked,
                        );
                        // this._gridApi.destroyFilter(option.id);
                    }
                },
            });

        this._subscriptions['nested-column-options'] =
            this.columnOptionsService.nestedColumnOptions$.subscribe({
                next: (options: { hiddenColumns: DocumentNestedColumn[] }) => {
                    this.hiddenNestedColumnOptions = options.hiddenColumns;
                    this.detailCellRendererParams = (p: ICellRendererParams) =>
                        this.getDetailCellRendererParams(p);
                },
            });
    }

    private getFilterOptions(ignoreUserChoices = false) {
        this._subscriptions.unsubscribeSafe('filter-options');

        this._subscriptions['filter-options'] = this.documentService
            .getDocumentsFilterOptions(this.workflowAndRepo)
            .subscribe({
                next: (response) => {
                    this.columnDefs = this.getColumnDefs(response);
                },
                complete: () => {
                    if (!ignoreUserChoices) this.setSavedFilter();
                },
            });
    }

    private hideHiddenColumns() {
        this._gridApi.setColumnsVisible(this.hiddenColumnOptions, false);
    }

    private getDocuments(): IServerSideDatasource {
        this._subscriptions.unsubscribeSafe('get-documents');

        return {
            getRows: (params) => {
                params.api.hideOverlay();
                params.api.showLoadingOverlay();

                this.filterApplied = isFilterApplied(params.request);
                if (this.filterApplied) this._gridApi.deselectAll();

                this._subscriptions['get-documents'] = this.documentService
                    .getDocuments(this.getQueryParams(params.request))
                    .subscribe({
                        next: (data) => {
                            params.api.hideOverlay();

                            if (data) {
                                params.success({
                                    rowData: data.items,
                                    rowCount: data.totalItems ?? -1,
                                });
                                if (data.items.length === 0) {
                                    params.api.showNoRowsOverlay();
                                }
                                this.hideHiddenColumns();
                                this.resultCount = data.totalItems ?? null;
                            } else {
                                params.fail();
                            }
                        },
                        error: () => {
                            params.api.hideOverlay();
                            this.error = true;
                        },
                    });
            },
        };
    }

    private getDiscrepancies(params: GetDetailRowDataParams<WorkflowDocument>) {
        this._subscriptions['discs-by-doc-id'] = this.discrepancyService
            .getDiscrepanciesByDocumentId(params.data.id, {
                mine: this.showMyItems,
                status: this.statuses,
            })
            .subscribe({
                next: (response) => {
                    this.ngZone.run(() => {
                        this.updateStatusBarDiscCount(
                            params.data.id,
                            response.discrepancies.length,
                            response.totalCount,
                        );
                        params.successCallback(response.discrepancies);
                        this.setSelectedChildRows(params.node);
                    });
                },
            });
    }

    private updateStatusBarDiscCount(
        documentId: number,
        filtered: number,
        total: number,
    ) {
        this.documentDataService.updateDiscDataForStatusPanel({
            documentId,
            filtered,
            total,
        });
    }

    private setSelectedChildRows(node: any) {
        const detailGridApi = node.detailGridInfo?.api;
        if (detailGridApi)
            detailGridApi.forEachNode((childNode: any) => {
                childNode.setSelected(node.parent?.isSelected());
            });
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        const pageSize = this._gridApi.paginationGetPageSize();
        const pageIndex = getPageIndex(request, pageSize);

        // getFilterQuery should be after workflowAndRepo to update repository query of dropdown

        return {
            ...this.workflowAndRepo,
            ...{ status: this.statuses },
            ...{ mine: this.showMyItems },
            ...getFilterQuery(request.filterModel),
            ...getSortQuery(request.sortModel),
            pageIndex,
            pageSize,
        };
    }

    private expandRow(node: IRowNode) {
        this._gridApi.setRowNodeExpanded(node, true);
    }

    private onDocumentNameClick(node: IRowNode) {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: node.data.id,
                selectedDiscrepancy: null,
            }),
        );

        this.expandRow(node);
        this._gridApi.ensureIndexVisible(node.rowIndex ?? 0, 'top');
    }

    private onDocumentNameDoubleClick(node: IRowNode) {
        this.routerService.goDocumentDetails(
            parseInt(node.data.id),
            DocumentDetailTab.METADATA,
        );
    }

    private onDiscrepancyIdClick(node: IRowNode) {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: node.data.documentId,
                selectedDiscrepancy: node.data.id,
                selectedTab: DocumentDetailTab.DISCREPANCIES,
            }),
        );
        highlightSelectedDiscrepancy(node.data.id);
    }

    private onDiscrepancyIdDoubleClick(node: IRowNode) {
        this.routerService.goDocumentDetails(
            parseInt(node.data.documentId),
            DocumentDetailTab.DISCREPANCIES,
            parseInt(node.data.id),
        );
    }

    private getDetailCellRendererParams(p: ICellRendererParams) {
        return {
            detailGridOptions: this.getDetailGridOptions(p),
            getDetailRowData: (params: GetDetailRowDataParams) =>
                this.getDiscrepancies(params),
        };
    }

    private getColumnDefs(filterOptions: DocumentFilterOptions): ColDef[] {
        return [
            {
                field: DocumentColumn.NAME,
                headerName: 'Document Name',
                showRowGroup: true,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true,
                    suppressDoubleClickExpand: true,
                    innerRenderer: ClickableCellComponent,
                    innerRendererParams: {
                        dataQa: this.dataQa.documentViewDocumentNameCell,
                        onClick: (node: IRowNode) =>
                            this.onDocumentNameClick(node),
                        onDoubleClick: (node: IRowNode) =>
                            this.onDocumentNameDoubleClick(node),
                    },
                },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: { class: 'pl-11' },
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 180,
            },
            {
                field: DocumentColumn.DOC_TYPE,
                maxWidth: 250,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.documentType,
                    autocomplete: true,
                },
            },
            {
                field: DocumentColumn.PRIORITY,
                maxWidth: 130,
                cellRenderer: PriorityCellComponent,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.priority,
                    priority: true,
                },
            },
            {
                field: DocumentColumn.REPOSITORY,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: [...filterOptions.repository],
                },
            },
            {
                field: DocumentColumn.AGE_IN_WF,
                headerName: 'Age In WF',
                maxWidth: 120,
                cellRenderer: GrayBoxCellComponent,
                valueFormatter: getAgeInWFValue,
                cellStyle: { textOverflow: 'clip' },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: RangeFilterComponent,
                floatingFilterComponentParams: {
                    availableRange: new Range(0, filterOptions.maxAge),
                },
            },
            {
                field: DocumentColumn.WF_ENTER_DATE,
                headerName: 'WF Enter Date',
                maxWidth: 160,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
            {
                field: DocumentColumn.DISCREPANCY_COUNT,
                maxWidth: 150,
                headerName: '# Discrepancies',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: {
                    type: 'number',
                },
            },
            {
                field: DocumentColumn.STATION,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.station,
                    autocomplete: true,
                },
            },
        ];
    }

    private getDetailGridOptions(params: ICellRendererParams) {
        return {
            rowHeight: 35,
            headerHeight: 32,
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            rowMultiSelectWithClick: true,
            onRowSelected: this.onRowSelected.bind(this),
            columnDefs: this.getDetailColumnDefs(),
            getRowId: (params: GetRowIdParams) => 'nested-' + params.data.id,
            noRowsOverlayComponent: NoRowsOverlayComponent,
            noRowsOverlayComponentParams: {
                text: 'No discrepancies found',
                isDetail: true,
            },
            defaultColDef: {
                flex: 1,
                cellStyle: { display: 'flex', alignItems: 'center' },
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: CountStatusBarComponent,
                        statusPanelParams: { documentId: params.data.id },
                        align: 'left',
                    },
                ],
            },
        };
    }

    private getDetailColumnDefs(): ColDef[] {
        const columnDefs = [
            {
                field: DocumentNestedColumn.ID,
                headerName: 'Discrepancy ID',
                checkboxSelection: true,
                valueFormatter: (p: { data: Discrepancy }) =>
                    getDiscrepancyId(p.data).label,
                cellRenderer: ClickableCellComponent,
                cellRendererParams: {
                    dataQa: this.dataQa.documentViewNestedDiscrepanyIdCell,
                    tooltipGetter: (p: ICellRendererParams) =>
                        getDiscrepancyId(p.data).tooltip,
                    onClick: (node: IRowNode) =>
                        this.onDiscrepancyIdClick(node),
                    onDoubleClick: (node: IRowNode) =>
                        this.onDiscrepancyIdDoubleClick(node),
                },
            },
            { field: DocumentNestedColumn.REASON },
            {
                field: DocumentNestedColumn.PRIORITY,
                cellRenderer: PriorityCellComponent,
            },
            { field: DocumentNestedColumn.STATUS },
            {
                field: DocumentNestedColumn.AGE_IN_WF,
                headerName: 'Age In WF',
                cellRenderer: GrayBoxCellComponent,
                valueFormatter: getAgeInWFValue,
            },
            { field: DocumentNestedColumn.TAKEN },
            { field: DocumentNestedColumn.ANALYST },
            { field: DocumentNestedColumn.STATION },
            {
                field: DocumentNestedColumn.PRIMARY_FIXER,
                resizable: false,
            },
        ];

        return columnDefs.filter(
            ({ field }) =>
                !this.hiddenNestedColumnOptions.includes(
                    field as DocumentNestedColumn,
                ),
        );
    }
}
