import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserInboxChoices, UserResponse } from '@models/user';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    private user = new BehaviorSubject<UserResponse | null>(null);
    private userInboxChoices = new BehaviorSubject<UserInboxChoices | null>(
        null,
    );

    user$ = this.user.asObservable();
    userInboxChoices$ = this.userInboxChoices.asObservable();

    get currentUser() {
        return this.user.getValue();
    }

    updateUser(user: UserResponse) {
        this.user.next(user);
    }

    updateUserInboxChoices(choices: UserInboxChoices) {
        this.userInboxChoices.next(choices);
    }
}
