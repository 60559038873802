import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import 'ag-grid-enterprise';

import { NavbarComponent, Tabs } from '@shared/navbar/navbar.component';
import { BrandingBarComponent } from '@shared/branding-bar/branding-bar.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { WebsocketService } from '@services/websocket.service';
import { SnackbarService } from '@services/snackbar.service';
import { AuthService } from '@services/api/auth/auth.service';
import { UserService } from '@services/api/user/user.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        BrandingBarComponent,
        HttpClientModule,
        NavbarComponent,
        LoaderComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    activeTabId: Tabs = Tabs.DOCUMENT_AND_DISCREPANCIES;
    tabs: typeof Tabs = Tabs;
    loading = true;
    navbarVisible = false;
    isLoggedIn = false;

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private router: Router,
        private authService: AuthService,
        private wsService: WebsocketService,
        private snackbarService: SnackbarService,
        private userService: UserService,
    ) {
        this.isLoggedIn = this.authService.isLoggedIn();
        this.setIconResolver(sanitizer, iconRegistry);
    }

    ngOnInit() {
        this.listenNotification();
        this.setOidcToken();
        this.userService.setTenantId().subscribe();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.loading = false;
                this.setNavbarVisibility(event.url);
            }
        });
    }

    updateActiveTab(tabId: Tabs) {
        this.activeTabId = tabId;
    }

    logout() {
        this.authService.logout();
    }

    private setOidcToken() {
        this.authService.setOidcToken().subscribe();
    }

    private setNavbarVisibility(url: string) {
        this.navbarVisible =
            this.isLoggedIn && !url.startsWith('/documents-and-discrepancies/');
    }

    private listenNotification() {
        this.wsService.notification$.subscribe({
            next: (res) => {
                if (res)
                    this.snackbarService.success({
                        header: 'Notification',
                        content: res.message,
                    });
            },
        });
    }

    private setIconResolver(
        sanitizer: DomSanitizer,
        iconRegistry: MatIconRegistry,
    ) {
        const resolver: IconResolver = (name) =>
            sanitizer.bypassSecurityTrustResourceUrl(
                `/assets/icons/${name}.svg`,
            );
        iconRegistry.addSvgIconResolver(resolver);
    }
}
