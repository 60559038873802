import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-toggle',
    standalone: true,
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
})
export class ToggleComponent implements OnInit, OnChanges {
    @Input() label: string | null = null;
    @Input() initialToggled: boolean = false;
    @Input() dataQa = '';
    @Output() toggle = new EventEmitter<boolean>();

    toggled: boolean = false;

    ngOnInit() {
        this.toggled = this.initialToggled;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const toggleChange = changes['initialToggled'];
        if (toggleChange && !toggleChange.firstChange) {
            this.toggled = toggleChange.currentValue;
        }
    }

    onToggle(e: any) {
        this.toggle.emit(e.target.checked);
    }
}
