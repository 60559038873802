import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';

@Component({
    selector: 'app-input',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    templateUrl: './input.component.html',
    styleUrl: './input.component.scss',
})
export class InputComponent implements ControlValueAccessor {
    @Input() label: string = '';
    @Input() type: string = '';
    @Input() placeholder = '';
    @Input() maxLength: number | null = null;
    @Input() initialValue: string = '';
    @Input() required: boolean = false;
    @Input() control: FormControl | null = null;
    @Input() variant: 'primary' | 'secondary' | 'tertiary' = 'primary';
    @Input() min: number = 0;
    @Input() max: number = 100;
    @Input() dataQa = '';

    value: string = '';

    get error() {
        return this.control
            ? Object.keys(this.control?.errors ?? {}).length > 0
            : this.required && !this.value;
    }

    get class() {
        return {
            'input--secondary': this.variant === 'secondary',
            'input--tertiary': this.variant === 'tertiary',
        };
    }

    onChange = (_value: string) => {};
    onTouched = () => {};

    onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.onChange(this.value);
    }

    writeValue(obj: string): void {
        this.value = obj;
    }

    registerOnChange(fn: (_value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    getLabel() {
        return `${this.label} ${this.required ? '*' : ''}`;
    }
}
