import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
    discrepancyRelations: number[];
}

@Component({
    standalone: true,
    template: `
        <div class="flex flex-column">
            <p class="text-center">DISCREPANCY</p>
            <div class="flex gap-8 justify-space-evenly">
                @for (item of params.discrepancyRelations; track $index) {
                    <span class="text-center">
                        {{ item }}
                    </span>
                }
            </div>
        </div>
    `,
})
export class DiscrepancyHeaderComponent implements IHeaderAngularComp {
    public params!: IHeaderParams & ICustomHeaderParams;

    agInit(params: IHeaderParams & ICustomHeaderParams): void {
        this.params = params;
    }

    refresh(_params: IHeaderParams) {
        return false;
    }
}
