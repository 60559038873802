import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { PriorityFieldComponent } from '@shared/priority-field/priority-field.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';
import { GrayBoxDirective } from '@directives/gray-box.directive';
import { DiscrepancyDetail } from '@models/discrepancy';
import { DashIfEmptyPipe } from '@pipes/dash-if-empty.pipe';

@Component({
    selector: 'app-discrepancy-summary',
    standalone: true,
    template: ` <div class="info-section bordered">
            <app-content-field label="ANALYST">
                {{ discDetail.analyst?.displayName | dashIfEmpty }}
            </app-content-field>
            <app-content-field label="STATUS">
                <span wfeGrayBox>
                    {{ discDetail.status }}
                </span>
            </app-content-field>
            <app-content-field label="PRIORITY">
                <app-priority-field [wfePriorityField]="discDetail.priority" />
            </app-content-field>
            <app-content-field label="CREATE DATE">
                {{ discDetail.createdAt }}
            </app-content-field>
        </div>
        <mat-divider />
        <div class="info-section">
            <app-content-field label="REASON">
                {{ discDetail.reason?.displayName }}
            </app-content-field>
            <app-content-field label="PAGE RANGE">
                {{ discDetail.pageRange | dashIfEmpty }}
            </app-content-field>
        </div>
        <div class="info-section">
            <app-content-field label="RESPONSIBLE STATION">
                {{ discDetail.station?.displayName }}
            </app-content-field>
            <app-content-field label="FIXER">
                {{ discDetail.primaryFixer?.fullName | dashIfEmpty }}
            </app-content-field>
        </div>
        <div class="info-section">
            <app-content-field label="DIRECTIONS">
                {{ discDetail.directions | dashIfEmpty }}
            </app-content-field>
        </div>
        <app-activity-log [discrepancyId]="discDetail.id" />`,
    imports: [
        MatDividerModule,
        ContentFieldComponent,
        ActivityLogComponent,
        GrayBoxDirective,
        PriorityFieldComponent,
        DashIfEmptyPipe,
    ],
})
export class DiscrepancySummaryComponent {
    @Input() discDetail!: DiscrepancyDetail;
}
