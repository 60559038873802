import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { forkJoin, of, Subscription } from 'rxjs';

import { DiscrepancyDataService } from '@services/data/discrepancy-data.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { UserService } from '@services/api/user/user.service';
import { WorkflowDataService } from '@services/data/workflow-data.service';
import { InputComponent } from '@shared/input/input.component';
import { SelectComponent } from '@shared/select/select.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { TextareaComponent } from '@shared/textarea/textarea.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { PrioritySelectComponent } from '@shared/select/priority-select/priority-select.component';
import { InvalidPageRangeErrorComponent } from '@shared/validation-error/invalid-page-range.component';
import { PriorityFieldComponent } from '@shared/priority-field/priority-field.component';
import { StepperComponent } from '@shared/stepper/stepper.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';
import { FooterStateActionsComponent } from './footer-state.actions.component';
import { PageRangeErrorComponent } from './page-range-error.component';
import { FooterAssignActionsComponent } from './footer-assign-actions.component';
import { FixerFieldComponent } from './fixer-field.component';
import { DashIfEmptyPipe } from '@pipes/dash-if-empty.pipe';
import { UserInfo } from '@models/user';
import { WorkflowState } from '@models/workflow/workflow-state';
import {
    DiscrepancyDetail,
    DiscrepancyEditOptions,
    DiscrepancyFixers,
} from '@models/discrepancy';
import { DiscrepancyResolutionStateToLabel } from '../documents-and-discrepancies.utils';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { pageRangeValidator } from '@helpers/validators';
import { isNil } from '@helpers/index';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-discrepancy-details',
    standalone: true,
    imports: [
        PrioritySelectComponent,
        SelectComponent,
        InputComponent,
        ContentFieldComponent,
        CheckboxComponent,
        PageRangeErrorComponent,
        InvalidPageRangeErrorComponent,
        MatDividerModule,
        ReactiveFormsModule,
        ActivityLogComponent,
        FooterAssignActionsComponent,
        FooterStateActionsComponent,
        LoaderComponent,
        PriorityFieldComponent,
        TextareaComponent,
        StepperComponent,
        DashIfEmptyPipe,
        FixerFieldComponent,
    ],
    styleUrl: 'discrepancy-details.component.scss',
    templateUrl: 'discrepancy-details.component.html',
})
export class DiscrepancyDetailsComponent
    implements OnInit, OnChanges, OnDestroy
{
    @ViewChild(FooterStateActionsComponent)
    stateActionsComp!: FooterStateActionsComponent;
    @Input() id!: number;
    @Input() mode: 'summary' | 'detail' = 'summary';
    @Output() formChange = new EventEmitter();

    form!: FormGroup;
    discDetail = DiscrepancyDetail.initial();
    detailsLoading = false;
    editFormChanged = false;
    editOptions = DiscrepancyEditOptions.initial();
    isAdmin = false;
    workflowStates: WorkflowState[] = [];
    discrepancyFixers = DiscrepancyFixers.initial();
    readonly maxDirectionsLength = 300;
    private editFormSubscription: Subscription | undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private formBuilder = inject(FormBuilder);
    private discrepancyService = inject(DiscrepancyService);
    private discrepancyDataService = inject(DiscrepancyDataService);
    private workflowDataService = inject(WorkflowDataService);
    private userService = inject(UserService);

    get pageRange() {
        return this.form.get('pageRange') as FormControl;
    }

    get readOnly() {
        return !this.editOptions.canEdit;
    }

    get canSave() {
        return !this.form.invalid && this.editFormChanged;
    }

    get completion() {
        const { resolutionState } = this.discDetail;
        return DiscrepancyResolutionStateToLabel[resolutionState!];
    }

    ngOnInit() {
        this.listenDiscrepancySave();
        this.listenDiscrepancyReset();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const idChange = changes['id'];

        if (idChange) {
            this.resetDiscrepancyDetails();
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    docSizeExceedMaxLimit() {
        return (
            !isNil(this.discDetail.revision) &&
            this.discDetail.revision!.size >
                this.discDetail.revision!.fullFileInclusionLimit
        );
    }

    saveChanges() {
        this.stateActionsComp.onSave();
    }

    updateFixer(fixer: UserInfo | null) {
        this.discDetail.primaryFixer = structuredClone(fixer);
    }

    private listenDiscrepancySave() {
        this._subscriptions['discrepancy-save-trigger'] =
            this.discrepancyDataService.discrepancySaveTrigger$.subscribe({
                next: () => this.saveChanges(),
            });
    }

    private listenDiscrepancyReset() {
        this._subscriptions['discrepancy-reset-trigger'] =
            this.discrepancyDataService.discrepancyResetTrigger$.subscribe({
                next: () => this.resetDiscrepancyDetails(),
            });
    }

    private resetDiscrepancyDetails() {
        this.initForm();
        this.getDiscrepancyDetails();
        this.setUserPermission();
        this.getWorkflowStates();
        this.formChange.emit(false);
    }

    private initForm() {
        this.form = this.formBuilder.group({
            priority: new FormControl(null),
            reason: new FormControl(null),
            station: new FormControl(null),
            pageRange: new FormControl('', [
                Validators.required,
                pageRangeValidator(),
            ]),
            directions: new FormControl(
                '',
                Validators.maxLength(this.maxDirectionsLength),
            ),
            revision: new FormControl(null),
            notifyWithOnlySelectedPages: new FormControl(null),
        });
    }

    private getDiscrepancyDetails() {
        this.editFormSubscription?.unsubscribe();
        this.detailsLoading = true;
        this.getFixers();

        this._subscriptions['disc-details'] = forkJoin([
            this.getEditOptions(this.id),
            this.discrepancyService.getDiscrepancyDetails(this.id),
        ]).subscribe({
            next: ([editOptionsData, discDetailsData]) => {
                this.editOptions = editOptionsData;
                this.discDetail = discDetailsData;
                this.setStationAndFixer(discDetailsData);

                if (this.mode === 'detail' && !this.readOnly) {
                    this.fillEditForm(discDetailsData);
                    this.onEditFormValueChange();
                }
            },
            complete: () => (this.detailsLoading = false),
        });
    }

    private setStationAndFixer(disc: DiscrepancyDetail) {
        this.discrepancyDataService.updateSelectedDiscStationAndFixer({
            station: disc.station?.backingField ?? null,
            fixer: disc.primaryFixer?.id ?? null,
        });
    }

    private getEditOptions(id: number) {
        if (this.mode === 'detail') {
            return this.discrepancyService.getDiscrepancyEditOptions(id);
        }
        return of(DiscrepancyEditOptions.initial());
    }

    private fillEditForm({
        priority,
        pageRange,
        reason,
        station,
        directions,
        revision,
        notifyWithOnlySelectedPages,
    }: DiscrepancyDetail) {
        this.form.patchValue({
            priority,
            pageRange,
            reason: reason?.backingField,
            station: station?.backingField,
            directions,
            revision: revision?.id,
            notifyWithOnlySelectedPages,
        });
    }

    private onEditFormValueChange() {
        this.editFormChanged = false;
        const initialValue = this.form.value;
        this.editFormSubscription = this.form.valueChanges.subscribe(() => {
            this.editFormChanged = Object.keys(initialValue).some(
                (key) => this.form.value[key] != initialValue[key],
            );
            this.formChange.emit(this.editFormChanged);
        });
    }

    private setUserPermission() {
        this._subscriptions['is-admin-user'] = this.userService
            .isAdminUser()
            .subscribe({
                next: (isAdmin) => (this.isAdmin = isAdmin),
            });
    }

    private getWorkflowStates() {
        this._subscriptions['workflow-states'] =
            this.workflowDataService.states$.subscribe({
                next: (states) => (this.workflowStates = states),
            });
    }

    private getFixers() {
        this._subscriptions['get-fixers'] = this.discrepancyService
            .getDiscrepancyFixers(this.id)
            .subscribe({
                next: (fixers) => (this.discrepancyFixers = fixers),
            });
    }
}
