import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { DATE_FORMAT } from '@constants/date';

@Component({
    selector: 'app-datepicker',
    standalone: true,
    templateUrl: 'datepicker.component.html',
    styleUrl: 'datepicker.component.scss',
    providers: [
        provideMomentDateAdapter(DATE_FORMAT),
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
    imports: [
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor {
    @Input() label: string = '-';
    @Input() required: boolean = false;

    value: Date | null = null;

    onChange = (_value: Date | null) => {};
    onTouched = () => {};

    onDateChange(event: { value: Date | null }) {
        this.value = event.value!;
        this.onChange(event.value!);
    }

    writeValue(obj: Date | null): void {
        this.value = obj;
    }

    registerOnChange(fn: (_value: Date | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
