import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { DocumentService } from '@services/api/document/document.service';
import { RouterService } from '@services/router.service';
import { ResizableDirective } from '@directives/resizable.directive';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { PanelComponent } from '@shared/panel/panel.component';
import { DiscrepanciesTabComponent } from '../discrepancies-tab/discrepancies-tab.component';
import { DocumentMetadataTabComponent } from './document-metadata-tab/document-metadata-tab.component';
import { InternalsTabComponent } from '../internals-tab/internals-tab.component';
import { HeaderDocumentDetailsComponent } from '../header-document-details/header-document-details.component';
import { WorkflowDocument } from '@models/document';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    DocumentDetailTab,
    DocumentDetailTabs,
} from '../documents-and-discrepancies.utils';
import {
    selectSelectedDiscrepancy,
    selectSelectedTab,
} from 'app/store/selectors/document.selectors';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';

@Component({
    selector: 'app-documents-summary-panel',
    standalone: true,
    templateUrl: './summary-panel.component.html',
    styleUrl: './summary-panel.component.scss',
    imports: [
        TabsComponent,
        DiscrepanciesTabComponent,
        DocumentMetadataTabComponent,
        InternalsTabComponent,
        ResizableDirective,
        PanelComponent,
        HeaderDocumentDetailsComponent,
    ],
})
export class SummaryPanelComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) documentId!: number;
    @Output() panelClose = new EventEmitter();

    readonly tabs: typeof DocumentDetailTab = DocumentDetailTab;
    activeTab = DocumentDetailTab.METADATA;
    document: WorkflowDocument | null = null;
    initialSelectedTabIndex: number | null = null;
    tabsData = DocumentDetailTabs;
    canEditDocument: boolean = false;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private store: Store,
        private routerService: RouterService,
        private documentService: DocumentService,
    ) {}

    ngOnInit() {
        this.fetchDocumentDetails(this.documentId);
        this.isDocumentEditable(this.documentId);
        this.setSelectedTab();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];
        if (documentIdChange && !documentIdChange.firstChange) {
            this.fetchDocumentDetails(documentIdChange.currentValue);
            this.isDocumentEditable(documentIdChange.currentValue);
            this.setSelectedTab();
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    goToDetails() {
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );

        this._subscriptions['selected-disc'] = selectedDiscrepancy$.subscribe({
            next: (disc) => {
                this.routerService.goDocumentDetails(
                    this.documentId,
                    this.activeTab,
                    disc ?? undefined,
                );
            },
        });
    }

    closePanel() {
        this.panelClose.emit();
    }

    onTabSelected(index: number) {
        this.activeTab = this.tabsData[index].id;
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: this.documentId,
                selectedTab: this.activeTab,
                selectedDiscrepancy: null,
            }),
        );
    }

    private fetchDocumentDetails(documentId: number) {
        this._subscriptions['doc-details'] = this.documentService
            .getDocumentDetails(documentId)
            .subscribe({
                next: (res) => (this.document = res),
            });
    }

    private setSelectedTab() {
        const selectedTab$ = this.store.select(selectSelectedTab);

        this._subscriptions['selected-tab'] = selectedTab$.subscribe({
            next: (tab) => {
                if (tab) {
                    this.activeTab = tab;
                    this.initialSelectedTabIndex = this.tabsData.findIndex(
                        (item) => item.id === tab,
                    );
                }
            },
        });
    }

    private isDocumentEditable(documentId: number) {
        this.canEditDocument = false;

        this._subscriptions['can-edit-document'] = this.documentService
            .canEditDocument(documentId)
            .subscribe({
                next: (canEdit) => (this.canEditDocument = canEdit),
            });
    }
}
