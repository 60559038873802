import {
    Component,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { LoaderComponent } from '@shared/loader/loader.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { DocumentService } from '@services/api/document/document.service';
import { MetadataItem } from '@models/document/metadata';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    selector: 'app-document-metadata-tab',
    standalone: true,
    templateUrl: './document-metadata-tab.component.html',
    styleUrl: './document-metadata-tab.component.scss',
    imports: [LoaderComponent, ContentFieldComponent],
})
export class DocumentMetadataTabComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) documentId: number | null = null;

    metadata: MetadataItem[] = [];
    loading = false;
    readonly columnCount = 3;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private documentService = inject(DocumentService);

    ngOnInit(): void {
        if (this.documentId) {
            this.getMetadata(this.documentId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];

        if (documentIdChange && !documentIdChange.firstChange) {
            this.getMetadata(documentIdChange.currentValue);
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    placeholderCells(): string[] {
        const count = this.metadata.length % this.columnCount;
        const cells = [];
        for (let i = 0; i <= count; i++) {
            cells.push('');
        }
        return cells;
    }

    private getMetadata(id: number) {
        this.loading = true;
        this._subscriptions['get-metadata'] = this.documentService
            .getMetadata(id)
            .subscribe({
                next: (data) => (this.metadata = data),
                complete: () => (this.loading = false),
            });
    }
}
