import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';
import { GrayBoxDirective } from '@directives/gray-box.directive';
import { PriorityColorDirective } from '@directives/priority-color.directive';
import { DiscrepancyDetail } from 'app/models/discrepancy';

@Component({
    selector: 'app-discrepancy-summary',
    standalone: true,
    styleUrls: ['../discrepancies-tab/discrepancies-tab.component.scss'],
    template: ` <div class="info-section bordered">
            <app-content-field label="ANALYST">
                {{ discDetail.analyst?.displayName ?? '-' }}
            </app-content-field>
            <app-content-field label="STATUS">
                <span wfeGrayBox>
                    {{ discDetail.status }}
                </span>
            </app-content-field>
            <app-content-field label="PRIORITY">
                <span [wfePriorityField]="discDetail.priority"></span>
            </app-content-field>
            <app-content-field label="CREATE DATE">
                {{ discDetail.createdAt }}
            </app-content-field>
        </div>
        <mat-divider />
        <div class="info-section">
            <app-content-field label="REASON">
                {{ discDetail.reason?.displayName }}
            </app-content-field>
            <app-content-field label="PAGE RANGE">
                {{ discDetail.pageRange || '-' }}
            </app-content-field>
        </div>
        <div class="info-section">
            <app-content-field label="RESPONSIBLE STATION">
                {{ discDetail.station?.displayName }}
            </app-content-field>
            <app-content-field label="RESPONSIBLE FIXER">
                {{ discDetail.responsibleFixer?.displayName ?? '-' }}
            </app-content-field>
        </div>
        <div class="info-section">
            <app-content-field label="DIRECTIONS">
                {{ discDetail.directions }}
            </app-content-field>
        </div>
        <app-activity-log [discrepancyId]="discDetail.id" />`,
    imports: [
        MatDividerModule,
        ContentFieldComponent,
        ActivityLogComponent,
        PriorityColorDirective,
        GrayBoxDirective,
    ],
})
export class DiscrepancySummaryComponent {
    @Input() discDetail!: DiscrepancyDetail;
}
