import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { WorkflowState } from '@models/workflow/workflow-state';

@Injectable({
    providedIn: 'root',
})
export class WorkflowDataService {
    private states = new BehaviorSubject<WorkflowState[]>([]);

    states$ = this.states.asObservable();

    updateWorkflowStates(_states: WorkflowState[]) {
        this.states.next(_states);
    }
}
