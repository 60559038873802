import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-stepper',
    standalone: true,
    templateUrl: 'stepper.component.html',
    styleUrl: 'stepper.component.scss',
    imports: [MatIconModule],
})
export class StepperComponent {
    @Input() steps: { id: number; label: string }[] = [];
    @Input() activeStep: number | null = null;

    get activeStepIndex() {
        return this.steps.findIndex((step) => step.id === this.activeStep);
    }

    isStepCompleted(index: number) {
        return (
            index < this.activeStepIndex ||
            this.activeStepIndex === this.steps.length - 1
        );
    }
}
