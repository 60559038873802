import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-range-slider',
    standalone: true,
    templateUrl: 'range-slider.component.html',
    styleUrl: 'range-slider.component.scss',
    imports: [MatSliderModule, FormsModule],
})
export class SliderComponent implements OnChanges {
    @Input() min: number | null = null;
    @Input() max: number | null = null;
    @Input() formGroup!: FormGroup;
    @Input() minControlName: string = '';
    @Input() maxControlName: string = '';

    minValue: string | number = 0;
    maxValue: string | number = 0;

    ngOnChanges(changes: SimpleChanges) {
        const formChange = changes['formGroup'];
        if (formChange) {
            this.minValue = formChange.currentValue.value.min;
            this.maxValue = formChange.currentValue.value.max;
        }
    }

    onMinChange(event: Event) {
        const target = event.target as HTMLInputElement;

        if (target.value !== null) {
            this.formGroup.controls[this.minControlName].setValue(target.value);
        }
    }

    onMaxChange(event: Event) {
        const target = event.target as HTMLInputElement;

        if (target.value !== null) {
            this.formGroup.controls[this.maxControlName].setValue(target.value);
        }
    }
}
