import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { GrayBoxDirective } from '@directives/gray-box.directive';

@Component({
    standalone: true,
    template: `
        @if (value) {
            <span wfeGrayBox>
                {{ value }}
            </span>
        } @else {
            <span>-</span>
        }
    `,
    imports: [GrayBoxDirective],
})
export class GrayBoxCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    get value() {
        if (this.params.valueFormatted) {
            return this.params.valueFormatted !== ''
                ? this.params.valueFormatted
                : null;
        }
        return this.params.value;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
