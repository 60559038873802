import { Component, inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { AutocompleteComponent } from '@shared/autocomplete/autocomplete.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { SelectOption } from 'app/models/shared.model';
import { DiscrepancyIdNo } from 'app/models/discrepancy/item';
import DATA_QA from '@automation/data-qa.json';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';

@Component({
    standalone: true,
    templateUrl: 'assign-discrepancy-dialog.component.html',
    imports: [
        FlatButtonComponent,
        AutocompleteComponent,
        MatDialogClose,
        LoaderComponent,
    ],
})
export class AssignDiscrepancyDialogComponent implements OnInit {
    analyst!: FormControl;
    loading = false;
    error = false;
    readonly dataQa = DATA_QA;

    readonly data: { options: SelectOption[] } & DiscrepancyIdNo =
        inject(MAT_DIALOG_DATA);
    private store = inject(Store);
    private discrepancyService = inject(DiscrepancyService);
    private snackbarService = inject(SnackbarService);
    private dialogRef = inject(MatDialogRef<AssignDiscrepancyDialogComponent>);

    ngOnInit(): void {
        this.analyst = new FormControl(null);
    }

    assign() {
        const userId = this.analyst.value.value;
        const discId = this.data.id;
        this.loading = true;
        this.error = false;

        this.discrepancyService
            .assignDiscrepancy(discId, { userId })
            .subscribe({
                next: (success) => {
                    this.loading = false;

                    if (success) {
                        const assignee = this.getAssignee(userId);

                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.data.discrepancyNo} Assigned to ${assignee}`,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    private getAssignee(userId: number) {
        return this.data.options.filter(
            (option: SelectOption) => option.value === userId,
        )[0].viewValue;
    }
}
