<div class="inline-flex items-center w-full">
    <input
        #trigger="matAutocompleteTrigger"
        matInput
        class="w-full h-full"
        [class.secondary]="variant === 'secondary'"
        [placeholder]="placeholder"
        [formControl]="inputControl"
        [matAutocomplete]="auto" />
</div>
<mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    [displayWith]="displayFn"
    (optionSelected)="onSelectionChange($event.option)">
    @for (option of filteredOptions | async; track option) {
        <mat-option [value]="option">{{ option.viewValue }}</mat-option>
    }
</mat-autocomplete>
