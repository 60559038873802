import { Component } from '@angular/core';

@Component({
    selector: 'app-info-box',
    standalone: true,
    template: ` <div class="info-box text-s bg-gray1 p-2 pb-3 ">
        <ng-content />
    </div>`,
    styleUrl: 'info-box.component.scss',
})
export class InfoBoxComponent {}
