<div class="p-5" [class.disabled]="loading">
    <p class="text-lg mb-3">Publish Document</p>
    Publishing this document;
    <ul>
        <li>Will make the document accessible in RMS.</li>
        <li>Will remove the document from Workflow Inbox.</li>
    </ul>
    <div class="flex justify-end items-center gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4"> Something went wrong </small>
        }
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.publishDocumentDialogCancel">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="cta"
            [dataQa]="dataQa.publishDocumentDialogPublish"
            (clicked)="publish()">
            Publish
        </app-flat-button>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
