import { Component, inject } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { RevisionService } from '@services/api/revision/revision.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TextareaComponent } from '@shared/textarea/textarea.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { RejectRevisionRequest, RevisionDetails } from '@models/revision';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'reject-correction-dialog.component.html',
    imports: [
        ReactiveFormsModule,
        FlatButtonComponent,
        TextareaComponent,
        LoaderComponent,
    ],
})
export class RejectCorrectionDialogComponent {
    form!: FormGroup;
    error: boolean = false;
    loading: boolean = false;
    discrepancy!: number;
    readonly dataQa = DATA_QA;

    readonly data: { revisionDetails: RevisionDetails; discrepancy: number } =
        inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<RejectCorrectionDialogComponent>);
    private store = inject(Store);
    private formBuilder = inject(FormBuilder);
    private snackbarService = inject(SnackbarService);
    private revisionService = inject(RevisionService);

    constructor() {
        this.initForm();
        this.discrepancy = this.data.discrepancy;
    }

    get textPlaceholder() {
        return 'I.e. Image quality between 15 - 19 pages is bad. Re-scan is needed.';
    }

    get reason() {
        return this.form.get('reason') as FormControl;
    }

    get discrepancyNo() {
        return this.data.revisionDetails.discrepancies.find(
            (disc) => disc.id === this.discrepancy,
        )?.discrepancyNo;
    }

    reject() {
        this.error = false;
        this.loading = true;

        this.revisionService
            .rejectRevision(
                this.data.revisionDetails.id,
                new RejectRevisionRequest(this.reason.value, this.discrepancy),
            )
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            header: `Correction is rejected for ${this.discrepancyNo}! `,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    private initForm() {
        this.form = this.formBuilder.group({
            reason: new FormControl(''),
        });
    }
}
