import {
    HttpErrorResponse,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

export const blobErrorInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn,
): Observable<any> => {
    return next(req).pipe(
        catchError((err) => {
            if (
                err instanceof HttpErrorResponse &&
                err.error instanceof Blob &&
                err.error.type === 'application/json'
            ) {
                // When request of type Blob, the error is also in Blob instead of object of the json data
                return new Promise<any>((_, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e: Event) => {
                        try {
                            const errmsg = JSON.parse((<any>e.target).result);
                            reject(
                                new HttpErrorResponse({
                                    error: errmsg,
                                    headers: err.headers,
                                    status: err.status,
                                    statusText: err.statusText,
                                    url: err.url || undefined,
                                }),
                            );
                        } catch (e) {
                            reject(err);
                        }
                    };
                    reader.onerror = (e) => {
                        reject(err);
                    };
                    reader.readAsText(err.error);
                });
            }
            throw err;
        }),
    );
};
