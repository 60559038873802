import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { UserService } from '@services/api/user/user.service';
import { SnackbarService } from '@services/snackbar.service';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { AssignDiscrepancyDialogComponent } from '../assign-discrepancy-dialog/assign-discrepancy-dialog.component';
import { DiscrepancyEditOptions } from '@models/discrepancy/edit-options';
import { DiscrepancyDetail } from '@models/discrepancy/detail';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    selector: 'app-footer-assign-actions',
    standalone: true,
    template: ` <div>
        <app-text-button (clicked)="takeDiscrepancy()" [disabled]="!canTake">
            Take
        </app-text-button>
        <app-text-button
            (clicked)="putbackDiscrepancy()"
            [disabled]="!isAssignedToMe">
            Put back
        </app-text-button>
        @if (isAdmin) {
            <app-text-button
                [disabled]="!isAdmin"
                (clicked)="assignDiscrepancy()">
                Assign
            </app-text-button>
            <app-text-button
                [disabled]="!canUnassign"
                (clicked)="unassignDiscrepancy()">
                Unassign
            </app-text-button>
        }
    </div>`,
    styles: ``,
    imports: [TextButtonComponent],
})
export class FooterAssignActionsComponent implements OnInit, OnDestroy {
    @Input({ required: true }) discDetail!: DiscrepancyDetail;
    @Input({ required: true }) editOptions!: DiscrepancyEditOptions;
    @Input({ required: true }) isAdmin!: boolean;

    isAssignedToMe = false;
    userId: number | null = null;
    canTake = false;
    canUnassign = false;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private discrepancyService: DiscrepancyService,
        private userService: UserService,
        private snackbarService: SnackbarService,
        private dialog: MatDialog,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this._subscriptions['get-user-id'] = this.userService
            .getUserId()
            .subscribe({
                next: (userId) => (this.userId = userId),
            });

        this.setActionPermissions();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    takeDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId) {
            this.discrepancyService.takeDiscrepancy(discId).subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.discDetail.discrepancyNo} Has Been Taken`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                    }
                },
            });
        }
    }

    putbackDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId && this.isAssignedToMe) {
            this.discrepancyService.putbackDiscrepancy(discId).subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.discDetail.discrepancyNo} Has Been Put Back`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                    }
                },
            });
        }
    }

    assignDiscrepancy() {
        const discId = this.discDetail?.id;
        this.dialog.open(AssignDiscrepancyDialogComponent, {
            data: {
                id: discId,
                discrepancyNo: this.discDetail.discrepancyNo,
                options: this.editOptions.analyst,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });
    }

    unassignDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId) {
            this.discrepancyService.unassignDiscrepancy(discId).subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.discDetail.discrepancyNo} Has Been Unassigned`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                    }
                },
            });
        }
    }

    private setActionPermissions() {
        this.isAssignedToMe =
            this.discDetail.analyst?.backingField == this.userId;
        this.canTake = this.canTakeDiscrepancy();
        this.canUnassign = this.canUassignDiscrepancy();
    }

    private canTakeDiscrepancy() {
        if (this.isAdmin) return !this.isAssignedToMe;
        const noAnalyst = this.discDetail.analyst === null;
        if (noAnalyst || !this.isAssignedToMe) return true;
        return false;
    }

    private canUassignDiscrepancy() {
        const noAnalyst = this.discDetail.analyst === null;
        if (this.isAdmin) return !this.isAssignedToMe && !noAnalyst;
        return false;
    }
}
