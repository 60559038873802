import { Component } from '@angular/core';
import { FlatButtonComponent } from '../../shared/button/flat-button/flat-button.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    standalone: true,
    templateUrl: 'unsaved-changes-dialog.component.html',
    imports: [FlatButtonComponent],
})
export class UnsavedChangesDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
    ) {}

    onReturnToPage() {
        this.dialogRef.close({ leave: false });
    }

    onDiscardChanges() {
        this.dialogRef.close({ leave: true });
    }

    onSaveChanges() {
        this.dialogRef.close({ leave: false, save: true });
    }
}
