<form class="p-5" [formGroup]="form">
    <p class="text-lg mb-3">Create New Discrepancy</p>
    <div class="flex flex-column gap-15">
        <app-select
            label="REASON"
            [options]="options.reason"
            formControlName="reasonId" />
        <app-input
            label="PAGE RANGE"
            formControlName="pageRange"
            [control]="pageRange">
            @if (pageRange.hasError('pageRange')) {
                <app-input-invalid-page-range error />
            }
        </app-input>
        <app-select
            label="STATION"
            [options]="options.station"
            [value]="data.station"
            formControlName="stationId" />
        <app-select
            label="FIXER"
            [options]="fixers"
            [value]="data.fixer"
            formControlName="primaryFixerId" />
        <app-textarea
            label="DIRECTIONS"
            formControlName="directions"
            [maxLength]="maxDirectionsLength" />

        <app-info-box>
            Any change in data on the fields shown will create a discrepancy
            that can be treated as a draft until mandatory fields are populated
            and 'notify' action is taken by the user.
        </app-info-box>
    </div>

    <div class="flex justify-end gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4"> Something went wrong </small>
        }
        <app-flat-button
            [disabled]="loading"
            [dataQa]="dataQa.addDiscrepancyDialogCancel"
            (clicked)="onCloseClick()">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="cta"
            [disabled]="isCreateDisabled()"
            [dataQa]="dataQa.addDiscrepancyDialogCreate"
            (clicked)="create()">
            Create
        </app-flat-button>
    </div>
</form>

@if (loading) {
    <app-loader type="overlay" size="medium" />
}
