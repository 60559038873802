import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { LoaderComponent } from '@shared/loader/loader.component';

export interface SnackbarPosition {
    horizontalPosition: MatSnackBarHorizontalPosition;
    verticalPosition: MatSnackBarVerticalPosition;
}
export interface SnackbarComponentProps<T = undefined> {
    variant?: 'default' | 'success' | 'progress' | 'error';
    header: string;
    content?: string;
    showContent?: boolean;
    position?: SnackbarPosition;
    notification?: T;
    dataQa?: string;
}

@Component({
    selector: 'app-snackbar',
    standalone: true,
    templateUrl: 'snackbar.component.html',
    styleUrl: 'snackbar.component.scss',
    imports: [MatIconModule, MatButtonModule, LoaderComponent],
})
export class SnackbarComponent {
    snackBarRef = inject(MatSnackBarRef);
    data: SnackbarComponentProps = inject(MAT_SNACK_BAR_DATA);

    close() {
        this.snackBarRef.dismissWithAction();
    }
}
