import { inject, Injectable } from '@angular/core';

import { AuthService } from './api/auth/auth.service';
import { SnackbarService } from './snackbar.service';
import { WebsocketService } from './websocket.service';
import { UserDataService } from './data/user-data.service';
import { ConfigurationsService } from './api/configurations/configurations.service';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Injectable({
    providedIn: 'root',
})
export class PostLoginService {
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private configurationsService = inject(ConfigurationsService);
    private authService = inject(AuthService);
    private wsService = inject(WebsocketService);
    private snackbarService = inject(SnackbarService);
    private userDataService = inject(UserDataService);

    init() {
        this.listenNotification();
        this.setOidcToken();
        this.setTenantId();
        this.initializeFeedbackWidget();
    }

    private listenNotification() {
        this._subscriptions['listen-notification'] =
            this.wsService.notification$.subscribe({
                next: (res) => {
                    if (res)
                        this.snackbarService.success({
                            header: 'Notification',
                            content: res.message,
                        });
                },
            });
    }

    private setOidcToken() {
        this._subscriptions['set-oidc-token'] = this.authService
            .setOidcToken()
            .subscribe();
    }

    private setTenantId() {
        this._subscriptions['set-tenant-id'] = this.configurationsService
            .getTenantId()
            .subscribe();
    }

    private initializeFeedbackWidget() {
        this._subscriptions['user'] = this.userDataService.user$.subscribe({
            next: (user) => {
                if (user) {
                    this.loadScript({
                        id: user.rmsUserId.toString(),
                        name: user.userName,
                        email: user.email,
                    });
                }
            },
        });
    }

    private loadScript(user: { id: string; name: string; email: string }) {
        if (window.aha) {
            window.aha('initialize', {
                account: 'geaero',
                applicationId: '7323280983708364845',
                user: {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                },
            });
        }
    }
}
