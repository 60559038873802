import { Component, inject, OnDestroy } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@services/snackbar.service';
import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { RouterService } from '@services/router.service';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'publish-document-dialog.component.html',
    imports: [FlatButtonComponent, LoaderComponent, MatDialogClose],
})
export class PublishDocumentDialogComponent implements OnDestroy {
    loading: boolean = false;
    error: boolean = false;
    readonly dataQa = DATA_QA;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private store = inject(Store);
    private routerService = inject(RouterService);
    private snackbarService = inject(SnackbarService);
    private documentService = inject(DocumentService);
    readonly data: { id: number; name: string } = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<PublishDocumentDialogComponent>);

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    protected publish() {
        this.loading = true;
        const { id, name } = this.data;
        this._subscriptions['publish-document'] = this.documentService
            .publishDocument(id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.showSuccessMessage();
                        this.routerService.goHome();
                        this.clearSummaryPanelData();
                        this.dialogRef.close();
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private showSuccessMessage() {
        this.snackbarService.success({
            variant: 'success',
            header: `${name} has been published and it is accessible in RMS!`,
        });
    }

    private clearSummaryPanelData() {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: null,
                selectedDiscrepancy: null,
            }),
        );
    }
}
