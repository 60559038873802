import { Order } from '@models/api.model';

export class IdDisplayPair {
    constructor(
        public backingField: number,
        public displayName: string,
    ) {}
}

export class DropdownItem {
    constructor(
        public id: number,
        public name: string,
    ) {}
}

export class Range<D> {
    constructor(
        public min: D,
        public max: D,
    ) {}
}

export class ExportDocumentExcelQuery {
    constructor(
        public order: Order,
        public selectedDocumentColumns: string[],
        public selectedDiscrepancyColumns: string[],
        public excludeFilters: boolean,
        public workflow?: number,
        public repository?: number[],
        public status?: number,
        public mine?: boolean,
        public age?: { min: number; max: number },
        public createdAt?: { start: string; end: string },
        public orderBy?: string,
    ) {}
}

export interface NotificationMessage {
    message: string;
}
export class ColumnOption<D> {
    constructor(
        public id: D,
        public checked: boolean,
        public label: string,
        public disabled: boolean,
        public group?: string,
    ) {}
}

export interface SelectOption {
    value: number | string;
    viewValue: string;
}

export enum Priority {
    Low = '0',
    Normal = '1',
    High = '2',
    Critical = '3',
}
