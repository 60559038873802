import { createAction, props } from '@ngrx/store';

import { DocumentDetailTab } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import { DocumentRevisionItem } from '@models/document';

export const updateSelectedRevision = createAction(
    '[Document] Update Selected revision',
    props<{ selectedRevision: DocumentRevisionItem | null }>(),
);

export const saveRevisionList = createAction(
    '[Document] Save Revision List',
    props<{ revisionList: string[] }>(),
);

export const updateDiscrepancyCount = createAction(
    '[Document] Update Discrepancy Count',
    props<{ discrepancyCount: { filtered: number; total: number } }>(),
);

export const saveOriginalDocumentId = createAction(
    '[Document] Original DocumentId',
    props<{ originalDocumentId: number | null }>(),
);

export const saveSummaryPanelData = createAction(
    '[Document] Save Summary Panel Data',
    props<{
        selectedDocument: number | null;
        selectedDiscrepancy: number | null;
        selectedTab?: DocumentDetailTab | null;
    }>(),
);

export const saveSelectedDiscrepancy = createAction(
    '[Document] Save Selected Discrepancy',
    props<{
        selectedDiscrepancy: number | null;
    }>(),
);

export const saveSelectedTab = createAction(
    '[Document] Save Selected Tab',
    props<{
        selectedTab: DocumentDetailTab | null;
    }>(),
);
