import { Order } from '@models/api.model';

export class IdDisplayPair {
    constructor(
        public backingField: number,
        public displayName: string,
    ) {}
}

export class DropdownItem {
    constructor(
        public id: number,
        public name: string,
    ) {}
}

export class Range<D> {
    constructor(
        public min: D,
        public max: D,
    ) {}
}

export class ExportDocumentExcelQuery {
    constructor(
        public order: Order,
        public selectedDocumentColumns: string[],
        public selectedDiscrepancyColumns: string[],
        public excludeFilters: boolean,
        public workflow?: number,
        public repository?: number[],
        public status?: number | number[],
        public mine?: boolean,
        public age?: { min: number; max: number },
        public createdAt?: { start: string; end: string },
        public orderBy?: string,
    ) {}
}

export class ColumnOption<D> {
    constructor(
        public id: D,
        public checked: boolean,
        public label: string,
        public disabled: boolean,
        public group?: string,
    ) {}
}

export class ColumnToggleOptions<D> {
    constructor(
        public lastToggled: Pick<ColumnOption<D>, 'id' | 'checked'> | null,
        public hiddenColumns: D[],
    ) {}
}

export interface SelectOption {
    value: number | string;
    viewValue: string;
}

export interface NotificationMessage {
    message: string;
}
