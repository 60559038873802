import { Component, Input, NgZone, OnInit } from '@angular/core';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TextFilterComponent } from '@grid-table/filter/text-filter/text-filter.component';
import { PriorityCellComponent } from '@grid-table/cell/priority-cell/priority-cell.component';
import { TableComponent } from '@grid-table/table.component';
import { ClickableCellComponent } from '@grid-table/cell/clickable-cell/clickable-cell.compoent';
import { GrayBoxCellComponent } from '@grid-table/cell/gray-box-cell/gray-box-cell.component';
import { SelectFilterComponent } from '@grid-table/filter/select-filter/select-filter.component';
import { DateFilterComponent } from '@grid-table/filter/date-filter/date-filter.component';
import { RangeFilterComponent } from '@grid-table/filter/range-filter/range-filter.component';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { DiscrepancyFilterOptions } from '@models/discrepancy/filter-options';
import { Range } from '@models/shared.model';
import { DiscrepancyColumn } from '@constants/table';
import {
    DocumentDetailTab,
    getAgeInWFValue,
    getDiscrepancyIdLabelValue,
    getDiscrepancyIdTooltipValue,
    isFilterApplied,
    setFilterQuery,
    setPageIndex,
    setSortQuery,
} from '../documents-and-discrepancies.utils';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';

@Component({
    selector: 'app-discrepancy-view',
    standalone: true,
    templateUrl: './discrepancy-view.component.html',
    imports: [TableComponent],
})
export class DiscrepencyViewComponent implements OnInit {
    @Input() workflowAndRepo: WorkflowAndRepository | undefined;
    @Input() statuses: number[] = [];
    @Input() showMyItems: boolean = false;

    error = false;
    filterApplied: boolean = false;
    columnDefs: ColDef[] = [];
    hiddenColumnOptions: DiscrepancyColumn[] = [];

    private _gridApi!: GridApi;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private store: Store,
        private discrepancyService: DiscrepancyService,
        private ngZone: NgZone,
    ) {}

    get filterModel() {
        return this._gridApi.getFilterModel();
    }

    get columnState() {
        return this._gridApi.getColumnState();
    }

    ngOnInit(): void {
        this.getFilterOptions();
        this.handleColumnOptions();
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const datasource = this.getAllDiscrepancies();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    resetFilters() {
        this._gridApi.refreshHeader();
        this._gridApi.setFilterModel(null);
    }

    refreshData() {
        if (!this._gridApi.isDestroyed()) {
            this._gridApi.refreshServerSide({ purge: true });
        }
    }

    private getFilterOptions() {
        this.discrepancyService
            .getDiscrepanciesFilterOptions(this.workflowAndRepo)
            .subscribe({
                next: (response: DiscrepancyFilterOptions) => {
                    this.columnDefs = this.getColumnDefs(response);
                },
            });
    }

    private handleColumnOptions() {
        this._subscriptions['column-options'] =
            this.discrepancyService.discColumnOptions$.subscribe({
                next: (options) => {
                    this.hiddenColumnOptions = options.hiddenColumns;
                    const option = options.lastToggled;
                    if (
                        option &&
                        this._gridApi &&
                        !this._gridApi.isDestroyed()
                    ) {
                        this._gridApi.setColumnsVisible(
                            [option.id],
                            option.checked,
                        );
                        this._gridApi.destroyFilter(option.id);
                    }
                },
            });
    }

    private getAllDiscrepancies(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                this.filterApplied = isFilterApplied(params.request);

                this.discrepancyService
                    .getAllDiscrepancies(this.getQueryParams(params.request))
                    .subscribe({
                        next: (data) => {
                            if (data) {
                                params.success({
                                    rowData: data.items,
                                    rowCount: data.totalItems ?? -1,
                                });
                                if (data.items.length === 0) {
                                    params.api.showNoRowsOverlay();
                                }
                                this.hideHiddenColumns();
                            } else {
                                params.fail();
                            }
                        },
                        error: () =>
                            this.ngZone.run(() => {
                                this.error = true;
                            }),
                    });
            },
        };
    }

    private hideHiddenColumns() {
        this._gridApi.setColumnsVisible(this.hiddenColumnOptions, false);
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        const pageSize = this._gridApi.paginationGetPageSize();
        const pageIndex = setPageIndex(request, pageSize);

        // setFilterQuery should be after workflowAndRepo to update repository query of dropdown

        return {
            ...this.workflowAndRepo,
            ...{ status: this.statuses },
            ...{ mine: this.showMyItems },
            ...setFilterQuery(request.filterModel),
            ...setSortQuery(request.sortModel),
            pageIndex,
            pageSize,
        };
    }

    private getColumnDefs(filterOptions: DiscrepancyFilterOptions): ColDef[] {
        return [
            {
                field: DiscrepancyColumn.ID,
                headerName: 'Discrepancy ID',
                filter: 'agTextColumnFilter',
                minWidth: 145,
                valueFormatter: (params) =>
                    getDiscrepancyIdLabelValue(params.data),
                cellRenderer: ClickableCellComponent,
                cellRendererParams: {
                    tooltipGetter: (params: ICellRendererParams) =>
                        getDiscrepancyIdTooltipValue(params.data),
                    onClick: (node: IRowNode) => {
                        this.store.dispatch(
                            saveSummaryPanelData({
                                selectedDocument: node.data.documentId,
                                selectedDiscrepancy: node.data.id,
                                selectedTab: DocumentDetailTab.DISCREPANCIES,
                            }),
                        );
                    },
                },
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: {
                    class: 'pl-11',
                },
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                field: DiscrepancyColumn.DOC_NAME,
                minWidth: 180,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: DiscrepancyColumn.REASON,
                filter: 'agTextColumnFilter',
                minWidth: 130,
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.reason,
                },
            },
            {
                field: DiscrepancyColumn.PRIORITY,
                maxWidth: 130,
                cellRenderer: PriorityCellComponent,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.priority,
                    priority: true,
                },
            },
            {
                field: DiscrepancyColumn.STATUS,
                minWidth: 180,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.status,
                },
            },
            {
                field: DiscrepancyColumn.AGE_IN_WF,
                headerName: 'Age In WF',
                cellRenderer: GrayBoxCellComponent,
                valueFormatter: getAgeInWFValue,
                cellStyle: { textOverflow: 'clip' },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: RangeFilterComponent,
                floatingFilterComponentParams: {
                    availableRange: new Range(0, filterOptions.maxAge),
                },
            },
            {
                field: DiscrepancyColumn.TAKEN,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
            {
                field: DiscrepancyColumn.WF_ENTER_DATE,
                headerName: 'WF Enter Date',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
            {
                field: DiscrepancyColumn.ANALYST,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: DiscrepancyColumn.STATION,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.station,
                    autocomplete: true,
                },
            },
            {
                field: DiscrepancyColumn.RESPONSIBLE_FIXER,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.responsibleFixer,
                    autocomplete: true,
                },
            },
        ];
    }
}
