import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';

import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { InputComponent } from '@shared/input/input.component';
import { duplicateDocNameValidator } from 'app/utils/helpers/validators';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'duplicate-documents-dialog.component.html',
    imports: [ReactiveFormsModule, FlatButtonComponent, InputComponent],
})
export class DuplicateDocumentsDialogComponent implements OnInit {
    form!: FormGroup;
    name: string = '';
    readonly dataQa = DATA_QA;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { documentName: string; revisionList: string[] },
        public dialogRef: MatDialogRef<DuplicateDocumentsDialogComponent>,
        private formBuilder: FormBuilder,
    ) {
        this.name = this.data.documentName;
    }

    get updatedDocumentName() {
        return this.form.get('updatedDocumentName') as FormControl;
    }

    get formInvalid() {
        return this.form.status === 'INVALID';
    }

    ngOnInit() {
        this.initForm();
    }

    onCloseClick() {
        this.dialogRef.close({ success: false });
    }

    onUploadClick() {
        this.dialogRef.close({
            success: true,
            updatedDocumentName: this.updatedDocumentName.value,
        });
    }

    private initForm() {
        this.form = this.formBuilder.group({
            updatedDocumentName: new FormControl('', [
                Validators.required,
                duplicateDocNameValidator(this.data.revisionList),
            ]),
        });
    }
}
