import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { WebViewerInstance } from '@pdftron/webviewer';

import { SnackbarService } from '@services/snackbar.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { DocumentService } from '@services/api/document/document.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { RadioButtonComponent } from '@shared/radio-button/radio-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import {
    WebViewerComponent,
    WebViewerDocument,
} from '@shared/webviewer/webviewer.component';
import { InsertMenuContentComponent } from './insert-menu-content.component';
import { ReplaceMenuContentComponent } from './replace-menu-content.component';
import { DiscrepancySummaryComponent } from '../accept-correction-dialog/discrepancy-summary.component';
import { RevisionDetails } from '@models/revision/revision-detail';
import { DiscrepancyDetail } from '@models/discrepancy';
import {
    DocumentRevisionsResponse,
    DocumentRevisionStatus,
} from '@models/document';
import {
    hideCloseDocumentButton,
    insertCurrentPageNumber,
} from '@helpers/webviewer';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { selectOriginalDocumentId } from 'app/store/selectors/document.selectors';
import { ResizableDirective } from '@directives/resizable.directive';
import { MenuCenterDirective } from '@directives/center-mat-menu.directive';
import { FileType, fileTypesMap } from '@constants/file';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    standalone: true,
    templateUrl: 'apply-correction-dialog.component.html',
    styleUrl: 'apply-correction-dialog.component.scss',
    imports: [
        MatMenuModule,
        MatRadioModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        FlatButtonComponent,
        LoaderComponent,
        WebViewerComponent,
        DiscrepancySummaryComponent,
        ResizableDirective,
        InsertMenuContentComponent,
        ReplaceMenuContentComponent,
        RadioButtonComponent,
        MenuCenterDirective,
    ],
})
export class ApplyCorrectionDialogComponent implements OnInit, OnDestroy {
    discDetail = DiscrepancyDetail.initial();
    discDetailLoading: boolean = false;
    documentLoading: boolean = false;
    saveLoading: boolean = false;
    sourceDocument: WebViewerDocument | null = null;
    destinationDocument: WebViewerDocument | null = null;
    originalDocumentId: number | null = null;
    vwInstance!: WebViewerInstance;
    selectedDiscrepancy!: number;
    documentChanged = false;
    destinationDocumentPageCount: number = 0;
    showInsertAndReplace = false;
    private logs: string[] = [];
    private documentId: number | null = null;
    private documentRevisions: DocumentRevisionsResponse | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    @ViewChild('insertTrigger') insertTrigger: MatMenuTrigger | undefined;
    @ViewChild('replaceTrigger') replaceTrigger: MatMenuTrigger | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { selectedRevision: RevisionDetails },
        private dialogRef: MatDialogRef<ApplyCorrectionDialogComponent>,
        private revisionService: RevisionService,
        private documentService: DocumentService,
        private documentDataService: DocumentDataService,
        private discrepancyService: DiscrepancyService,
        private snackbarService: SnackbarService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        const { selectedRevision } = this.data;
        this.selectedDiscrepancy = selectedRevision.discrepancies[0].id;

        this.getDiscrepancyDetails();
        this.getDocumentId();
        this.setSourceAndDestinationDocuments();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    get title() {
        return this.acceptedDiscrepancies.length > 1
            ? 'Apply Correction'
            : `Apply Correction for Discrepancy ${this.data.selectedRevision.discrepancies[0].discrepancyNo}`;
    }

    get acceptedDiscrepancies() {
        return this.data.selectedRevision.discrepancies.filter(
            (item) => (item.relation = DocumentRevisionStatus.ACCEPTED),
        );
    }

    get selectedDiscrepancyNo() {
        const { discrepancies } = this.data.selectedRevision!;
        const discNo = discrepancies.find(
            (disc) => disc.id === this.selectedDiscrepancy,
        )?.discrepancyNo;

        return discNo;
    }

    protected discard() {
        this.dialogRef.close();
    }

    protected closeResolve() {}

    protected async save() {
        this.saveLoading = true;
        this.insertTrigger?.closeMenu();
        this.replaceTrigger?.closeMenu();
        this.isAppliedVersion() ? this.updateAV() : this.createAV();
    }

    protected onDocumentChanged(event: { log: string }) {
        this.logs.push(event.log);
        this.documentChanged = true;
        this.setDestinationDocumentPageCount();
    }

    protected onDiscrepancyChanged() {
        this.getDiscrepancyDetails();
    }

    protected onDocumentLoaded(vwInstance: WebViewerInstance) {
        this.vwInstance = vwInstance;
        this.showInsertAndReplace = true;

        this.setDestinationDocumentPageCount();
        hideCloseDocumentButton(vwInstance);
        insertCurrentPageNumber(vwInstance);
        this.listenAndLogPagesUpdates();
    }

    private listenAndLogPagesUpdates() {
        const [dvLeft] = this.vwInstance.Core.getDocumentViewers();
        dvLeft.addEventListener('pagesUpdated', (e: PagesUpdatedEvent) => {
            this.documentChanged = true;
            const name = this.destinationDocument?.name ?? '';

            if (e.removed.length > 0) {
                this.logs.push(
                    `Page ${e.removed.join(', ')} removed from ${name}. (Page(s) ${this.getMovedPages(e.moved)} moved)`,
                );
            }
            if (e.rotationChanged.length > 0) {
                this.logs.push(
                    `Page: ${e.rotationChanged.join(', ')} rotated on ${name}`,
                );
            }
        });
    }

    private getMovedPages(pages: { [key: number]: number }) {
        return Object.entries(pages)
            .map(([key, value]) => `${key} -> ${value}`)
            .join(', ');
    }

    private setSourceAndDestinationDocuments() {
        this._subscriptions['doc-revisions'] =
            this.documentDataService.documentRevisions$.subscribe({
                next: (response) => {
                    if (response) {
                        this.documentRevisions = response;

                        if (this.isAppliedVersion())
                            this.setAppliedVersionAsDestinationDocument();
                        else this.setOriginalAsDestinationDocument();
                    }
                },
            });
    }

    private getDocumentId() {
        this.documentDataService.documentDetail$.subscribe({
            next: (detail) => {
                if (detail) {
                    this.documentId = detail.id;
                }
            },
        });
    }

    private async updateAV() {
        const { selectedRevision } = this.data;
        const { id } = selectedRevision;
        const document = await this.getUpdatedDocument();
        const formData = new FormData();
        formData.append('discrepancy', this.selectedDiscrepancy.toString());
        formData.append('file', document.blob, document.name);
        this.logs.forEach((i) => formData.append('logs', i));

        this._subscriptions['update-av'] = this.documentService
            .updateAppliedVersion(this.documentId!, formData, id)
            .subscribe({
                next: (success) => {
                    this.saveLoading = false;
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Applied version of Discrepancy ${this.selectedDiscrepancyNo} is updated!`,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                        this.documentDataService.updateSelectedRevisionId(id);
                    }
                },
            });
    }

    private async createAV() {
        const document = await this.getUpdatedDocument();
        const { blob, name } = document;
        const formData = new FormData();
        const newDocumentName = `${name}_AV_${this.selectedDiscrepancyNo}${fileTypesMap[FileType.PDF]}`;
        formData.append('discrepancy', this.selectedDiscrepancy.toString());
        formData.append('file', blob, newDocumentName);
        this.logs.forEach((i) => formData.append('logs', i));

        this._subscriptions['create-av'] = this.documentService
            .createAppliedVersion(this.documentId!, formData)
            .subscribe({
                next: (success) => {
                    this.saveLoading = false;
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Applied version is created for Discrepancy ${this.selectedDiscrepancyNo}!`,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    }
                },
            });
    }

    private isAppliedVersion() {
        const { selectedRevision } = this.data;

        return this.documentRevisions
            ? this.documentRevisions.appliedVersions.some(
                  (i) => i.id === selectedRevision.id,
              )
            : false;
    }

    private setAppliedVersionAsDestinationDocument() {
        const { selectedRevision } = this.data;
        const { documentServiceId, name } = selectedRevision!;
        const acceptedDocument = this.getAcceptedDocumentOfDiscrepancy();

        this.destinationDocument = {
            id: `${documentServiceId}`,
            name: name ?? '',
        };

        this.sourceDocument = {
            id: `${acceptedDocument!.documentServiceId}`,
            name: acceptedDocument?.revisionName ?? '',
        };
    }

    private getAcceptedDocumentOfDiscrepancy() {
        const { discrepancyNos, corrections } = this.documentRevisions!;
        const index = discrepancyNos.findIndex(
            (i) => i === this.selectedDiscrepancyNo,
        );
        const document = corrections.find(
            (revision) =>
                revision.discrepancyRelations[index] ===
                DocumentRevisionStatus.ACCEPTED,
        );

        return document;
    }

    private setOriginalAsDestinationDocument() {
        const { selectedRevision } = this.data;

        this.sourceDocument = {
            id: `${selectedRevision!.documentServiceId}`,
            name: selectedRevision?.name ?? '',
        };
        this.getOriginalDocumentId();
        this.getOriginalDocumentDetails();
    }

    private async getUpdatedDocument() {
        const { Core } = this.vwInstance;
        const { annotationManager } = Core;
        const [documentViewerLeft] = Core.getDocumentViewers();
        const documentLeft = documentViewerLeft.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        const data = await documentLeft.getFileData({ xfdfString });
        const blob = new Blob([data], { type: FileType.PDF });

        return { blob, name: documentLeft.getFilename() };
    }

    private setDestinationDocumentPageCount() {
        const [documentViewerLeft] = this.vwInstance.Core.getDocumentViewers();
        this.destinationDocumentPageCount = documentViewerLeft.getPageCount();
    }

    private getOriginalDocumentId() {
        const originalDocumentId$ = this.store.select(selectOriginalDocumentId);
        this._subscriptions['original-doc-id'] = originalDocumentId$.subscribe({
            next: (id) => (this.originalDocumentId = id),
        });
    }

    private getDiscrepancyDetails() {
        this.discDetailLoading = true;

        this._subscriptions['get-disc-details'] = this.discrepancyService
            .getDiscrepancyDetails(this.selectedDiscrepancy)
            .subscribe({
                next: (response) => (this.discDetail = response),
                complete: () => (this.discDetailLoading = false),
            });
    }

    private getOriginalDocumentDetails() {
        if (this.originalDocumentId) {
            this.documentLoading = true;

            this._subscriptions['get-original-doc'] = this.revisionService
                .getRevisionDetails(this.originalDocumentId)
                .subscribe({
                    next: (originalDocument) => {
                        this.destinationDocument = {
                            id: `${originalDocument!.documentServiceId}`,
                            name: originalDocument?.name ?? '',
                        };
                    },
                    complete: () => (this.documentLoading = false),
                });
        }
    }
}

interface PagesUpdatedEvent {
    removed: number[];
    moved: { [key: number]: number };
    rotationChanged: number[];
}
