<div class="p-5">
    <p class="text-lg mb-3">Upload Correction</p>
    <app-dropzone
        [acceptedFiles]="acceptedFiles"
        [preselectedFiles]="files"
        [progress]="progress"
        [inputQa]="dataQa.uploadCorrectionDialogDropzoneInput"
        (fileReady)="onFileReady($event)" />
    <div class="text-sm mt-5">
        <p>Which discrepanc(ies) are you uploading this correction for? *</p>
        <p class="text-s flex items-center mb-2 gap-4">
            <mat-icon svgIcon="info" />You can upload correction for the
            discrepancies that are in “Await Correction” state.
        </p>
        <div class="grid gap-8">
            @for (item of data.discrepancies; track $index) {
                <app-checkbox
                    label="Discrepancy {{ item.discrepancyNo }}"
                    [dataQa]="dataQa.uploadCorrectionDialogDiscrepancyCheckbox"
                    (checkedChange)="onDiscrepancySelected($event, item.id)" />
            }
        </div>
    </div>
    <div class="flex justify-end gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4"> Something went wrong </small>
        }
        <app-flat-button
            [dataQa]="dataQa.uploadCorrectionDialogCancel"
            (clicked)="onCancelClick()">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="tertiary"
            [dataQa]="dataQa.uploadCorrectionDialogUpload"
            [disabled]="selectedDiscrepancies.length === 0 || !files || loading"
            (clicked)="onUploadClick()">
            Upload
        </app-flat-button>
    </div>
</div>
