import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { DocumentService } from '@services/api/document/document.service';
import { RevisionDataService } from '@services/data/revision-data-service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { DropzoneComponent } from '@shared/dropzone/dropzone.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { DuplicateDocumentsDialogComponent } from '../duplicate-documents-dialog/duplicate-documents-dialog.component';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    convertToFileList,
    removeFileExtensionsFromFileList,
} from '@helpers/file';
import { DiscrepancyIdNo } from '@models/discrepancy/item';
import { FileType } from '@constants/file';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'upload-correction-dialog.component.html',
    imports: [
        MatIconModule,
        FlatButtonComponent,
        DropzoneComponent,
        CheckboxComponent,
    ],
})
export class UploadCorrectionDialogComponent implements OnInit, OnDestroy {
    acceptedFiles: FileType[] = [FileType.PDF];
    files: FileList | null = null;
    selectedDiscrepancies: number[] = [];
    progress: number | null = null;
    error: boolean = false;
    loading: boolean = false;
    revisionList: string[] = [];
    uploadedFileExt: string = '';
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    readonly data: UploadCorrectionDialogData = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<UploadCorrectionDialogComponent>);
    private dialog = inject(MatDialog);
    private documentService = inject(DocumentService);
    private revisionDataService = inject(RevisionDataService);

    ngOnInit(): void {
        if (this.data.file) {
            this.files = convertToFileList([this.data.file]);
        }

        this.setCorrectionNameList();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    onFileReady(files: FileList) {
        this.error = false;
        if (files) {
            const filesWithoutExtension =
                removeFileExtensionsFromFileList(files);
            this.files = filesWithoutExtension;
        } else {
            this.cancelUpload();
        }
    }

    onUploadClick() {
        if (this.files && this.files[0]) {
            const file = this.files[0];
            const fileExist = this.checkDuplicateFile(file);
            this.uploadedFileExt = file.name.split('.').pop() ?? '';

            if (fileExist) {
                this.askForNewFileName();
            } else {
                this.upload();
            }
        }
    }

    onCancelClick() {
        this.cancelUpload();
        this.dialogRef.close({ success: false });
    }

    onDiscrepancySelected(checked: boolean, discrepancyId: number) {
        if (checked) {
            this.selectedDiscrepancies = [
                ...this.selectedDiscrepancies,
                discrepancyId,
            ];
        } else {
            this.selectedDiscrepancies = [
                ...this.selectedDiscrepancies.filter(
                    (disc) => disc !== discrepancyId,
                ),
            ];
        }
    }

    private setCorrectionNameList() {
        this.revisionDataService.documentRevisions$.subscribe({
            next: (revisions) =>
                (this.revisionList = revisions!.corrections.map(
                    (item) => item.revisionName,
                )),
        });
    }

    private askForNewFileName() {
        const file = this.files![0];
        const fileName = file.name;
        const duplicateDocDialogRef = this.dialog.open(
            DuplicateDocumentsDialogComponent,
            {
                data: {
                    documentName: fileName,
                    revisionList: this.revisionList,
                },
                maxWidth: '450px',
                minWidth: '200px',
                width: '450px',
                disableClose: true,
            },
        );
        duplicateDocDialogRef.afterClosed().subscribe({
            next: (response) => {
                if (response.success) {
                    const { updatedDocumentName } = response;
                    const newFile = this.createFileWithName(
                        file,
                        updatedDocumentName,
                    );
                    const fileArray = [newFile];
                    this.files = convertToFileList(fileArray);
                    this.upload();
                }
            },
        });
    }

    private cancelUpload() {
        if (this.progress) {
            this.progress = null;
            this.loading = false;
            this._subscriptions.unsubscribeAllSafe();
        }
    }

    private upload() {
        if (this.files && this.files[0]) {
            this.error = false;
            this.loading = true;

            this._subscriptions['upload-document'] = this.documentService
                .uploadDocument(
                    this.data.id,
                    this.prepareFormdata(this.files[0]),
                )
                .subscribe({
                    next: (response) => {
                        this.progress = response.progress;
                        if (response.status === 'success') {
                            this.dialogRef.close({
                                success: true,
                                fileName: this.files![0].name,
                            });
                        }
                    },
                    error: (err) => this.handleError(err),
                    complete: () => (this.loading = false),
                });
        }
    }

    private prepareFormdata = (file: File) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.selectedDiscrepancies.forEach((item) =>
            formData.append('discrepancy', item.toString()),
        );

        return formData;
    };

    private checkDuplicateFile(file: File): boolean {
        const fileName = file.name;
        const fileExist = this.revisionList.includes(fileName);

        return fileExist;
    }

    private createFileWithName(file: File, newName: string): File {
        const newFile = new File([file], newName, {
            type: file.type,
            lastModified: file.lastModified,
        });

        return newFile;
    }

    private handleError(error: HttpErrorResponse) {
        this.error = true;
        this.loading = false;

        if (error.status === HttpStatusCode.Conflict) {
            this.askForNewFileName();
        }
    }
}

interface UploadCorrectionDialogData {
    id: number;
    discrepancies: DiscrepancyIdNo[];
    file: File | null;
}
