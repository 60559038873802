import { IdDisplayPair, SelectOption } from '../shared.model';

export class DocumentFilterOptionsResponse {
    constructor(
        public documentType: IdDisplayPair[],
        public priority: IdDisplayPair[],
        public repository: IdDisplayPair[],
        public station: IdDisplayPair[],
        public maxAge: number | null,
    ) {}

    static initial(): DocumentFilterOptionsResponse {
        return new DocumentFilterOptionsResponse([], [], [], [], null);
    }
}

export class DocumentFilterOptions {
    constructor(
        public documentType: SelectOption[],
        public priority: SelectOption[],
        public repository: SelectOption[],
        public station: SelectOption[],
        public maxAge: number | null,
    ) {}

    static initial(): DocumentFilterOptions {
        return new DocumentFilterOptions([], [], [], [], null);
    }
}
