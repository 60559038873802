import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { TextButtonComponent } from '@shared/button/text-button/text-button.component';

@Component({
    selector: 'app-pagination',
    standalone: true,
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.scss',
    imports: [
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        TextButtonComponent,
    ],
})
export class PaginationComponent {
    @Input() pageSize: number = 10;
    @Output() pageSizeChanged = new EventEmitter<number>();

    paginationPageSizeSelector = [10, 25, 50];
    menuOpened = false;

    onPageChange(size: number) {
        this.pageSizeChanged.emit(size);
    }
}
