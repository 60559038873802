import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, map } from 'rxjs';

import { ApiService } from '@services/api/api.service';
import { SimpleResponse } from '@models/api.model';
import { environment } from '@environments/environment';
import { Cookies } from '@constants/cookies';
import endpoints from '@endpoints';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private apiUrl = environment.apiUrl;
    private oidcToken = new BehaviorSubject<string | null>(null);

    oidcToken$ = this.oidcToken.asObservable();

    constructor(
        private api: ApiService,
        private cookieService: CookieService,
        @Inject(DOCUMENT) private window: Window,
    ) {}

    login() {
        this.window.location.href = this.apiUrl + endpoints.login;
    }

    isLoggedIn(): boolean {
        return this.cookieService.check(Cookies.UAA_ACCESS_TOKEN);
    }

    getUaaToken(): string {
        return this.cookieService.get(Cookies.UAA_ACCESS_TOKEN);
    }

    setOidcToken() {
        return this.api.get(endpoints.oidcToken).pipe(
            map((response: SimpleResponse<{ token: string }>) => {
                this.oidcToken.next(response.data.token);
            }),
        );
    }

    logout() {
        const domain = this.window.location.host;
        this.cookieService.delete(Cookies.UAA_ACCESS_TOKEN, '/', domain);
        this.cookieService.delete(Cookies.OIDC_ACCESS_TOKEN, '/', domain);
        this.cookieService.deleteAll();
        this.window.location.href = this.apiUrl + endpoints.logout;
    }
}
