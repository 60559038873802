<div class="flex flex-wrap gap-7">
    @for (chip of statusChips; track $index) {
        <app-tooltip>
            <app-chip
                trigger
                [dataQa]="dataQa.statusChipFilter"
                [defaultSelected]="
                    selectedFilterItems.includes(chip.backingField)
                "
                (clicked)="handleFilterSelect(chip.backingField)">
                {{ chip.name }}({{ chip.assigned }}/{{ chip.unassigned }}/{{
                    chip.total
                }})
            </app-chip>
            <div content>
                <strong>{{ chip.assigned }}</strong> assigned to me /
                <strong>{{ chip.unassigned }}</strong> unassigned /
                <strong>{{ chip.total }}</strong> total
            </div>
        </app-tooltip>
    }
</div>
