<div class="p-5" [class.disabled]="loading || revisionLoading">
    <p class="text-lg mb-3">
        Recall Discrepancy {{ data.discrepancyNo }} to “Await Correction”
    </p>
    @if (revisionLoading) {
        <app-loader />
    } @else {
        This action will:
        <ul>
            @if (appliedVersions.length > 0) {
                <li>Delete {{ appliedVersionList }}.</li>
            }
            @if (corrections.length > 0) {
                <li>
                    Clear accept and reject statuses of
                    {{ correctionList }} for Discrepancy
                    {{ data.discrepancyNo }}.
                </li>
            }
        </ul>
    }
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.recallDiscrepancyDialogCancel"
            [disabled]="revisionLoading">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="tertiary"
            [disabled]="revisionLoading"
            [dataQa]="dataQa.recallDiscrepancyDialogRecall"
            (clicked)="recall()">
            Recall to Await Correction
        </app-flat-button>
    </div>
    @if (error) {
        <div class="flex justify-end gap-8">
            <small class="color-status-red4"> Something went wrong </small>
        </div>
    }
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
