@if (autocomplete) {
    <app-autocomplete
        [options]="list"
        (selectionChange)="onValueChange($event)" />
} @else {
    @if (priority) {
        <app-priority-select
            [options]="list"
            [noneOption]="true"
            (selectionChange)="onValueChange($event)"
            placeholder="Filter" />
    } @else {
        <app-select
            [options]="list"
            [noneOption]="true"
            (selectionChange)="onValueChange($event)"
            placeholder="Filter" />
    }
}
