import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { DocumentAndDiscrepenciesComponent } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.component';

@Component({
    standalone: true,
    templateUrl: './documents-and-discrepancies.html',
    styleUrl: './documents-and-discrepancies.scss',
    imports: [RouterOutlet, DocumentAndDiscrepenciesComponent],
})
export class DocumentsAndDiscrepanciesPageComponent {}
