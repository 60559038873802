import {
    AdvancedFilterModel,
    FilterModel,
    IServerSideGetRowsRequest,
    SortModelItem,
} from 'ag-grid-community';

import { Discrepancy, DiscrepancyResolutionState } from '@models/discrepancy';

export enum DocumentDetailTab {
    METADATA = 'metadata',
    DISCREPANCIES = 'discrepancies',
    WF_INTERNALS = 'wf-internals',
}

export const DocumentDetailTabs = [
    {
        id: DocumentDetailTab.METADATA,
        label: 'Document Metadata',
        path: 'metadata',
    },
    {
        id: DocumentDetailTab.DISCREPANCIES,
        label: 'Discrepancies',
        path: 'discrepancies',
    },
    {
        id: DocumentDetailTab.WF_INTERNALS,
        label: 'WF Internals',
        path: 'wf-internals',
    },
];

export const DiscrepancyResolutionStateToLabel = {
    [DiscrepancyResolutionState.CANCELLED]: 'Cancelled',
    [DiscrepancyResolutionState.DISCARDED]: 'Discarded ',
    [DiscrepancyResolutionState.FIXED]: 'Resolved',
    [DiscrepancyResolutionState.FORCE_CLOSED]: 'Unresolved',
};

// Helpers

export const setFilterQuery = (
    filterModel: FilterModel | AdvancedFilterModel | null,
) => {
    const queryParams = {};

    if (filterModel && Object.keys(filterModel).length) {
        Object.keys(filterModel).forEach((key) => {
            const filterValue = (filterModel as any)[key]?.filter
                .toString()
                .trim();

            Object.assign(queryParams, {
                [key]: filterValue,
            });
        });
    }
    return queryParams;
};

export const setSortQuery = (sortModel: SortModelItem[]) => {
    const queryParams = {};

    if (sortModel) {
        sortModel.forEach((item) => {
            Object.assign(queryParams, {
                orderBy: item.colId,
                order: item.sort,
            });
        });
    }
    return queryParams;
};

export const setPageIndex = (
    request: IServerSideGetRowsRequest,
    pageSize: number,
): number => {
    const startRow = request?.startRow ?? null;
    const index = startRow ? Math.round(startRow / pageSize) + 1 : 1;
    return index;
};

export const isFilterApplied = (
    request: IServerSideGetRowsRequest,
): boolean => {
    return Object.keys(request.filterModel || {}).length !== 0;
};

export const getAgeInWFValue = (p: { value: string }) => {
    return p.value ? `${p.value} Days` : '';
};

export const getDiscrepancyId = (disc: Discrepancy) => {
    return {
        label: disc ? `${disc.discrepancyNo}${disc.isDraft ? '*' : ''}` : '',
        tooltip: disc?.isDraft ? 'This discrepancy is in draft state.' : '',
    };
};

export const highlightSelectedDiscrepancy = (id: number) => {
    const rows = document.querySelectorAll(`[row-id^="nested"]`);

    rows.forEach((el) => {
        const rowId = el.getAttribute('row-id');
        (el as HTMLElement).style.backgroundColor =
            rowId === `nested-${id}` ? '#e6f5ff' : '';
    });
};
