<div class="snackbar">
    <div class="snackbar__header">
        @if (data.variant === 'success') {
            <mat-icon
                svgIcon="check2"
                style="height: 20px"
                class="color-yellow-green7" />
        }
        @if (data.variant === 'error') {
            <mat-icon svgIcon="status-cancel" class="color-status-red4" />
        }
        <span class="header text-sm text-bold color-gray15">
            {{ data.header }}
        </span>
        <mat-icon
            svgIcon="close"
            (click)="closeSnackbar()"
            class="cursor-pointer" />
    </div>
    @if (data.content) {
        <div class="snackbar__content text-xs mt-2">
            <div></div>
            <div>{{ data.content }}</div>
            <div></div>
        </div>
    }
</div>
