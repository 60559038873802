<div class="p-5">
    <p class="text-lg">Save Changes</p>
    <p class="mt-2">
        Would you like to save changes you made to
        <b>{{ unsavedChangeArea }}</b> before {{ action }}?
    </p>
    <div class="flex flex-wrap justify-end items-center mt-4 gap-10">
        <app-flat-button
            [dataQa]="dataQa.unsavedChangesDialogReturnToPage"
            (clicked)="onReturnToPage()">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="danger"
            [dataQa]="dataQa.unsavedChangesDialogDiscard"
            (clicked)="onDiscardChanges()">
            Discard Changes
        </app-flat-button>
        <app-flat-button
            variant="tertiary"
            [dataQa]="dataQa.unsavedChangesDialogSaveChanges"
            (clicked)="onSaveChanges()">
            Save Changes
        </app-flat-button>
    </div>
</div>
