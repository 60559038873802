<label class="custom-radio" [class.disabled]="disabled">
    <input
        type="radio"
        [(value)]="value"
        [name]="name"
        [checked]="checked"
        [disabled]="disabled"
        (change)="select()" />
    <span
        class="radio-indicator"
        [ngClass]="{
            small: size === 'small',
            medium: size === 'medium',
            dark: variant === 'dark',
            light: variant === 'light',
        }"></span>
    <ng-content />
</label>
