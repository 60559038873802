import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsavedChangesService } from '@services/unsaved-changes.service';

import { DiscrepancyDetailsComponent } from 'app/components/documents-and-discrepancies/discrepancy-details/discrepancy-details.component';

@Component({
    standalone: true,
    template: `<app-discrepancy-details
        mode="detail"
        [id]="discId"
        (formChange)="onFormChanged($event)" />`,
    imports: [DiscrepancyDetailsComponent],
})
export class DiscrepancyDetailPageComponent implements OnInit, OnDestroy {
    @ViewChild(DiscrepancyDetailsComponent)
    discDetailComp!: DiscrepancyDetailsComponent;
    discId!: number;
    unsavedChanges = false;

    constructor(
        private route: ActivatedRoute,
        private unsavedChangesService: UnsavedChangesService,
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe({
            next: (params) => (this.discId = parseFloat(params.get('discId')!)),
        });
    }

    ngOnDestroy(): void {
        this.unsavedChangesService.cleanup();
    }

    onFormChanged(change: boolean) {
        this.unsavedChanges = change;
        this.unsavedChangesService.hasUnsavedChanges = change;
    }

    hasUnsavedChanges(): boolean {
        return this.unsavedChanges;
    }

    save() {
        this.discDetailComp.saveChanges();
    }
}
