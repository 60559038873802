<div class="range-slider text-xs">
    {{ min }}
    <span class="dot"></span>
    <mat-slider [min]="min" [max]="max">
        <input
            matSliderStartThumb
            [ngModel]="minValue"
            (input)="onMinChange($event)" />
        <input
            matSliderEndThumb
            [ngModel]="maxValue"
            (input)="onMaxChange($event)" />
    </mat-slider>
    <span class="dot"></span>
    {{ max }}
</div>
