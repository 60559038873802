import { Component, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatCalendar,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import {
    IFloatingFilterParams,
    ISimpleFilter,
    TextFilterModel,
} from 'ag-grid-community';
import moment, { Moment } from 'moment';

import { SharedMapper } from '@services/api/shared.mapper';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { DATE_FORMAT } from '@constants/date';

@Component({
    standalone: true,
    templateUrl: './date-filter.component.html',
    styleUrl: './date-filter.component.scss',
    imports: [
        MatIconModule,
        MatMenuModule,
        MatDatepickerModule,
        TextButtonComponent,
        FlatButtonComponent,
        ContentFieldComponent,
    ],
    providers: [
        provideMomentDateAdapter(DATE_FORMAT),
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
    ],
})
export class DateFilterComponent implements IFloatingFilterAngularComp {
    params!: IFloatingFilterParams<ISimpleFilter>;
    selectedRangeValue: DateRange<any> | undefined;
    currentValue: DateRange<any> | undefined;
    selectedOption: RangeOption | null = null;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
    @ViewChild('calendar', { static: false }) calendar!: MatCalendar<Date>;

    constructor(private sharedMapper: SharedMapper) {}

    get selectedStartDate() {
        return this.currentValue?.start
            ? this.sharedMapper.toDate(this.currentValue!.start)
            : null;
    }

    get startDate() {
        return this.selectedRangeValue?.start
            ? this.sharedMapper.toDate(this.selectedRangeValue!.start)
            : '';
    }

    get endDate() {
        return this.selectedRangeValue?.end
            ? this.sharedMapper.toDate(this.selectedRangeValue!.end)
            : '';
    }

    agInit(params: IFloatingFilterParams<ISimpleFilter>): void {
        this.params = params;
        this.selectedRangeValue = new DateRange<Moment>(null, null);
    }

    onParentModelChanged(_parentModel: TextFilterModel) {
        if (_parentModel?.filter) {
            const filter = JSON.parse(_parentModel.filter);
            const start = filter.start
                ? moment.utc(filter.start).local()
                : null;
            const end = filter.end ? moment.utc(filter.end).local() : null;

            this.selectedRangeValue = new DateRange<Moment>(start, end);
            this.currentValue = this.selectedRangeValue;
        } else {
            this.selectedOption = null;
            this.selectedRangeValue = new DateRange<Moment>(null, null);
            this.currentValue = undefined;
        }
    }

    refresh(): boolean {
        return false;
    }

    selectedChange(m: Moment) {
        if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
            this.selectedRangeValue = new DateRange<Moment>(m, null);
        } else {
            const start = this.selectedRangeValue.start;
            const end = m;
            if (end < start) {
                this.selectedRangeValue = new DateRange<Moment>(end, start);
            } else {
                this.selectedRangeValue = new DateRange<Moment>(start, end);
            }
        }

        this.selectedOption = 'custom';
    }

    apply() {
        this.currentValue = this.selectedRangeValue;
        this.onFilterChanged();
        if (this.trigger) this.trigger.closeMenu();
    }

    close() {
        if (this.currentValue) this.selectedRangeValue = this.currentValue;
        if (this.trigger) this.trigger.closeMenu();
    }

    reset() {
        this.params.api?.refreshHeader();
        const coldId = this.params.column.getColId();
        const newFilterModel = this.params.api.getFilterModel();
        newFilterModel[coldId] = null;
        this.params.api.setFilterModel(newFilterModel);
        this.params.api?.onFilterChanged();
    }

    selectToday() {
        const today = moment();
        const startOfDay = today.clone().startOf('day');
        const endOfDay = today.clone().endOf('day');

        this.selectedRangeValue = new DateRange(startOfDay, endOfDay);
        this.selectedOption = 'today';
        this.goToDate(today);
    }

    selectLastWeekend() {
        const today = moment();
        const lastSaturday = today.clone().day(-1);
        const lastSunday = today.clone().day(0);
        const start = lastSaturday;
        const end = lastSunday;

        this.selectedRangeValue = new DateRange(start, end);
        this.selectedOption = 'lastWeekend';
        this.goToDate(today);
    }

    selectWeekToDate() {
        const today = moment();
        const startOfWeek = moment().startOf('isoWeek');
        const endOfDay = today.clone().endOf('day');

        this.selectedRangeValue = new DateRange(startOfWeek, endOfDay);
        this.selectedOption = 'weekToDate';
        this.goToDate(startOfWeek);
    }

    selectMonthToDate() {
        const startOfMonth = moment().startOf('month');
        const today = moment();
        const endOfDay = today.clone().endOf('day');

        this.selectedRangeValue = new DateRange(startOfMonth, endOfDay);
        this.selectedOption = 'monthToDate';
        this.goToDate(startOfMonth);
    }

    selectLastMonth() {
        const startOfLastMonth = moment()
            .subtract(1, 'months')
            .startOf('month');
        const endOfLastMonth = moment().subtract(1, 'months').endOf('month');

        this.selectedRangeValue = new DateRange(
            startOfLastMonth,
            endOfLastMonth,
        );
        this.selectedOption = 'lastMonth';
        this.goToDate(startOfLastMonth);
    }

    selectLastYear() {
        const startOfLastYear = moment().subtract(1, 'years').startOf('year');
        const endOfLastYear = moment().subtract(1, 'years').endOf('year');

        this.selectedRangeValue = new DateRange(startOfLastYear, endOfLastYear);
        this.selectedOption = 'lastYear';
        this.goToDate(startOfLastYear);
    }

    goToDate(date: any) {
        this.calendar._goToDateInView(date, 'month');
    }

    onFilterChanged() {
        this.params.parentFilterInstance((instance: ISimpleFilter) => {
            const startAndEndDate = this.currentValue?.start
                ? JSON.stringify({
                      start: this.formatDate(this.currentValue?.start, 'start'),
                      end: this.formatDate(this.currentValue?.end),
                  })
                : undefined;
            instance.onFloatingFilterChanged('contains', startAndEndDate);
        });
    }

    private formatDate(date: Moment, type: 'start' | 'end' = 'end') {
        const formatted = moment(date).format('yyyy-MM-DD');

        if (date) {
            //  return `${formatted}${type === 'start' ? 'T00:00:00.000Z' : 'T23:59:59.000Z'}`;
            return type === 'end' && this.selectedOption === 'custom'
                ? date.add(1, 'd')
                : date;
        }
        return null;
    }
}

type RangeOption =
    | 'custom'
    | 'today'
    | 'lastWeekend'
    | 'weekToDate'
    | 'monthToDate'
    | 'lastMonth'
    | 'lastYear';
