<div class="detail-dialog">
    <div class="detail-dialog__header">
        <div class="detail-dialog__name flex items-center color-gray18">
            <button mat-icon-button aria-label="Close" (click)="onCloseClick()">
                <mat-icon svgIcon="close" aria-label="Close" />
            </button>
            <span class="text-lg">{{ documentDetails?.name }}</span>
            @if (documentDetails) {
                <app-header-document-details
                    class="ml-12"
                    [document]="documentDetails" />
            }
        </div>
    </div>
    <div class="detail-dialog__body">
        <app-tabs
            [tabs]="tabsData"
            alignment="center"
            [initialSelectedTabIndex]="initialSelectedTabIndex"
            class="flex mt-4 h-full"
            (tabSelected)="onTabSelected($event)">
            <div class="flex h-full bg-gray0 overflow-hidden mt-n4">
                <div class="detail-dialog__document-edit pl-4 mt-4">
                    <router-outlet />
                </div>
                <div wfeResizable></div>
                <div class="detail-dialog__document-render mt-1 flex-1">
                    @if (documentLoading) {
                        <app-loader
                            class="disp-block h-full flex items-center justify-center" />
                    }
                    @if (documentFile) {
                        <app-document-preview
                            [document]="documentFile"
                            [revisionDetails]="revisionDetails" />
                    }
                </div>
            </div>
        </app-tabs>
    </div>
</div>
