import { CommonModule } from '@angular/common';
import {
    AfterContentChecked,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import {
    MatSelect,
    MatSelectChange,
    MatSelectModule,
} from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import { SelectOption } from 'app/models/shared.model';

export interface SelectionChangeEvent {
    value: any;
    isDefaultValue?: boolean;
}

@Component({
    selector: 'app-select',
    standalone: true,
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDividerModule,
        MatCheckboxModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent
    implements ControlValueAccessor, OnInit, AfterContentChecked
{
    @Input() variant: 'primary' | 'secondary' = 'primary';
    @Input() label: string = '';
    @Input() dataQa = '';
    @Input({ required: true }) options: SelectOption[] = [];
    @Input() value: any | any[];
    @Input() placeholder: string = '';
    @Input() multiple: boolean = false;
    @Input() noneOption: boolean = false;
    @Input() required: boolean = false;
    @Output() selectionChange = new EventEmitter<SelectionChangeEvent>();
    @ViewChild('select') select!: MatSelect;

    allSelected = false;

    ngOnInit(): void {
        if (this.value) {
            this.selectionChange.emit({
                value: this.value,
                isDefaultValue: true,
            });
        }
    }

    ngAfterContentChecked() {
        this.allSelected = this.checkAllSelected();
    }

    get disabled() {
        return this.options.length === 0;
    }

    onChange = (_value: string) => {};
    onTouched = () => {};

    onSelectionChange(event: MatSelectChange) {
        this.selectionChange.emit({ value: event.value });
        this.onChange(event.value);
    }

    onSelectAllClick() {
        const selectedValues = this.checkAllSelected()
            ? []
            : this.options.map((option) => option.value);
        this.value = selectedValues;
        this.selectionChange.emit({
            value: selectedValues,
        });
    }

    displayText() {
        return this.value?.length === 1
            ? this.options.filter((option) => option.value === this.value[0])[0]
                  ?.viewValue
            : `${this.value?.length} Selected`;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private checkAllSelected() {
        return (
            this.select?.options.toArray().every((option) => option.selected) ??
            false
        );
    }
}
