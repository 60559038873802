<div class="p-5">
    <p class="text-lg">Save Changes</p>
    <p class="mt-2">
        Would you like to save changes you made before leaving this page?
    </p>
    <div class="flex flex-wrap items-center mt-4 gap-10">
        <app-flat-button (clicked)="onReturnToPage()">
            Return to Page
        </app-flat-button>
        <app-flat-button variant="danger" (clicked)="onDiscardChanges()">
            Discard Changes
        </app-flat-button>
        <app-flat-button variant="tertiary" (clicked)="onSaveChanges()">
            Save Changes
        </app-flat-button>
    </div>
</div>
