export enum UserKind {
    Person = 0,
    DistributionList = 1,
}

export const UserKindLabel: UserKindLabelType = {
    [UserKind.DistributionList]: 'Distribution Lists',
    [UserKind.Person]: 'People',
};

type UserKindLabelType = {
    [key: string]: string;
};
