<div
    tabindex="0"
    matRipple
    class="chip inline-flex items-center text-xxs"
    [class.chip--selected]="selected"
    [class.chip--clickable]="clickable"
    [attr.data-qa]="dataQa"
    (click)="onChipClick()"
    (keyup.enter)="onChipClick()">
    @if (selected) {
        <mat-icon svgIcon="check" style="width: 16px" class="mr-1" />
    }
    <ng-content />
</div>
