import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    standalone: true,
    selector: 'app-flat-button',
    imports: [CommonModule, MatButtonModule, MatIconModule],
    templateUrl: './flat-button.component.html',
    styleUrl: './flat-button.component.scss',
})
export class FlatButtonComponent {
    @Input() disabled = false;
    @Input() dataQa = '';
    @Input() variant:
        | 'default'
        | 'primary'
        | 'tertiary'
        | 'cta'
        | 'danger'
        | 'success' = 'default';
    @Input() type: 'outline' | 'solid' = 'outline';
    @Input() iconVariant: string | null = null;
    @Output() clicked = new EventEmitter();

    onClick() {
        this.clicked.emit();
    }

    onKeyup() {
        this.clicked.emit();
    }
}
