import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SnackbarService } from '@services/snackbar.service';
import { DocumentService } from '@services/api/document/document.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { RecallDiscrepancyDialogComponent } from '../recall-discrepancy-dialog/recall-discrepancy-dialog.component';
import { CancelDiscrepancyDialogComponent } from '../cancel-discrepancy-dialog/cancel-discrepancy-dialog.component';
import { DiscardDiscrepancyDialogComponent } from '../discard-discrepancy-dialog/discard-discrepancy-dialog.component';
import { DiscrepancyEditOptions } from 'app/models/discrepancy/edit-options';
import { DiscrepancyDetail } from 'app/models/discrepancy/detail';
import { DiscrepancyActionCode } from 'app/models/discrepancy/state-action';
import { UpdateDiscrepancyRequest } from 'app/models/discrepancy/update-discrepancy-request';
import DATA_QA from '@automation/data-qa.json';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';

@Component({
    selector: 'app-footer-state-actions',
    standalone: true,
    template: `
        <div class="flex items-center gap-10">
            @if (canRecall()) {
                <app-text-button
                    (clicked)="onRecallClick()"
                    [dataQa]="dataQa.footerRecallDiscrepancy">
                    Recall
                </app-text-button>
            }
            @if (canCancel()) {
                <app-flat-button
                    variant="tertiary"
                    (clicked)="onCancelClick()"
                    [dataQa]="dataQa.footerCancelDiscrepancy">
                    Cancel Discrepancy
                </app-flat-button>
            }
            @if (discDetail.isDraft) {
                <app-flat-button
                    (clicked)="onDiscard()"
                    [dataQa]="dataQa.footerDiscardDiscrepancy">
                    Discard
                </app-flat-button>
            }
            @if (editOptions.canEdit) {
                <app-flat-button
                    variant="tertiary"
                    (clicked)="onSave()"
                    [disabled]="!canSave"
                    [dataQa]="dataQa.footerSaveDiscrepancy">
                    Save
                </app-flat-button>
            }
            @if (canNotify()) {
                <app-flat-button
                    [disabled]="!editForm.valid"
                    variant="cta"
                    (clicked)="onNotify()"
                    [dataQa]="dataQa.footerNotifyDiscrepancy">
                    Notify
                </app-flat-button>
            }
            @if (canRenotify()) {
                <app-flat-button
                    variant="cta"
                    (clicked)="onRenotify()"
                    [dataQa]="dataQa.footerRenotifyDiscrepancy">
                    Re-Notify
                </app-flat-button>
            }
        </div>
        @if (loading) {
            <app-loader type="overlay" size="large" />
        }
    `,
    styles: ``,
    imports: [TextButtonComponent, FlatButtonComponent, LoaderComponent],
})
export class FooterStateActionsComponent implements OnDestroy {
    @Input({ required: true }) documentId!: number;
    @Input({ required: true }) discDetail!: DiscrepancyDetail;
    @Input({ required: true }) canSave!: boolean;
    @Input({ required: true }) editForm!: FormGroup;
    @Input({ required: true }) editOptions!: DiscrepancyEditOptions;
    @Output() getDiscrepancies = new EventEmitter<number>();
    @Output() getDiscrepancyDetails = new EventEmitter<number>();

    loading = false;
    readonly dataQa = DATA_QA;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private snackbarService: SnackbarService,
        private discrepancyService: DiscrepancyService,
        private documentService: DocumentService,
        private dialog: MatDialog,
    ) {}

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }

    canRecall() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RECALL,
        );
    }

    onRecallClick() {
        const dialogRef = this.dialog.open(RecallDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.getDiscrepancies.emit(this.documentId);
            }
        });
    }

    canCancel() {
        return (
            !this.discDetail.isDraft &&
            this.discDetail.actions.some(
                (item) => item.action === DiscrepancyActionCode.CANCEL,
            )
        );
    }

    onCancelClick() {
        const dialogRef = this.dialog.open(CancelDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.fetchDocumentDetails();
            }
        });
    }

    canNotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.NOTIFY,
        );
    }

    canRenotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RENOTIFY,
        );
    }

    onDiscard() {
        const dialogRef = this.dialog.open(DiscardDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.fetchDocumentDetails();
            }
        });
    }

    onSave(triggerNotify = false) {
        const discId = this.discDetail?.id;
        const editData = {
            ...new UpdateDiscrepancyRequest(
                this.discDetail.priority,
                this.discDetail.reason?.backingField,
                this.discDetail.pageRange,
                this.discDetail.station?.backingField,
                this.discDetail.responsibleFixer?.backingField,
                this.discDetail.directions,
            ),
            ...this.editForm.value,
        };
        this._subscriptions['edit-discrepancy'] = this.discrepancyService
            .editDiscrepancy(discId, editData)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.discDetail.discrepancyNo} is Saved`,
                        });
                        if (triggerNotify) {
                            this.notifyDiscrepancy();
                        } else {
                            this.getDiscrepancyDetails.emit(discId);
                        }
                    } else {
                        this.snackbarService.error({
                            variant: 'error',
                            header: `Discrepancy ${this.discDetail.discrepancyNo} Could Not be Saved`,
                        });
                    }
                },
            });
    }

    onNotify() {
        if (this.canSave) {
            this.onSave(true);
        } else {
            this.notifyDiscrepancy();
        }
    }

    onRenotify() {
        const discId = this.discDetail?.id;
        this.loading = true;
        this._subscriptions['renotify-discrepancy'] = this.discrepancyService
            .renotifyDiscrepancy(discId)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Re-Notified for Dicrepancy ${this.discDetail.discrepancyNo}!`,
                        });
                        this.getDiscrepancyDetails.emit(discId);
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private notifyDiscrepancy() {
        const discId = this.discDetail?.id;
        this.loading = true;
        this._subscriptions['notify-discrepancy'] = this.discrepancyService
            .notifyDiscrepancy(discId)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Notified for Dicrepancy ${this.discDetail.discrepancyNo}!`,
                        });
                        this.getDiscrepancyDetails.emit(discId);
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private fetchDocumentDetails() {
        this._subscriptions['doc-details'] = this.documentService
            .getDocumentDetails(this.documentId)
            .subscribe({
                next: () => this.getDiscrepancies.emit(this.documentId),
            });
    }
}
