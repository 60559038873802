import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { RouterService } from '@services/router.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { DiscrepancyDataService } from '@services/data/discrepancy-data.service';
import { DocumentService } from '@services/api/document/document.service';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DocumentUploadComponent } from '../detail-dialog/document-upload/document-upload.component';
import { AddDiscrepancyDialogComponent } from '../add-discrepancy-dialog/add-discrepancy-dialog.component';
import { AddDiscrepancyButtonComponent } from './add-discrepancy-button.component';
import { DiscrepancyDetailsComponent } from '../discrepancy-details/discrepancy-details.component';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { WorkflowDocument } from '@models/document/item';
import { Discrepancy, StationAndFixer } from '@models/discrepancy';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { saveSelectedDiscrepancy } from 'app/store/actions/document.actions';
import { selectSelectedDiscrepancy } from 'app/store/selectors/document.selectors';
import {
    DocumentDetailTab,
    highlightSelectedDiscrepancy,
    getDiscrepancyId,
} from '../documents-and-discrepancies.utils';

@Component({
    selector: 'app-discrepancies-tab',
    standalone: true,
    templateUrl: './discrepancies-tab.component.html',
    imports: [
        TabsComponent,
        LoaderComponent,
        DocumentUploadComponent,
        AddDiscrepancyButtonComponent,
        DiscrepancyDetailsComponent,
        RouterOutlet,
    ],
})
export class DiscrepanciesTabComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) documentId!: number;
    @Input() mode: 'summary' | 'detail' = 'summary';
    @ViewChild(DocumentUploadComponent) documentUploadComponent:
        | DocumentUploadComponent
        | undefined;

    readonly maxDirectionsLength = 300;
    loading = false;
    tabsData: { label: string; path: string; tooltip?: string }[] = [];
    selectedTabIndex: number = 0;
    discrepancies: Discrepancy[] = [];
    documentDetails: WorkflowDocument | null = null;
    selectedDiscrepancy: number | null = null;
    selectedDiscStationAndFixer: StationAndFixer | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        public dialog: MatDialog,
        private documentService: DocumentService,
        private documentDataService: DocumentDataService,
        private discrepancyService: DiscrepancyService,
        private discrepancyDataService: DiscrepancyDataService,
        private actions$: Actions,
        private store: Store,
        private routerService: RouterService,
        private route: ActivatedRoute,
    ) {
        this.handleComponentRefresh();
    }

    get maxDiscrepancyCount() {
        return this.documentDetails?.maximumDiscrepancyCount ?? null;
    }

    get canAddDiscrepancy() {
        return this.documentDetails?.canAddDiscrepancy ?? false;
    }

    get discrepancyIds() {
        return this.discrepancies.map((item) => item.id);
    }

    ngOnInit(): void {
        this.setDocumentDetails();
        this.setStoredDiscrepancy();
        this.getDiscrepancies(this.documentId);
        this.subscribeStationAndFixer();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];

        if (documentIdChange && !documentIdChange.firstChange) {
            this._subscriptions.unsubscribeSafe('selected-discrepancy');
            this.getDiscrepancies(documentIdChange.currentValue);
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    handleTabChange(index: number) {
        this.store.dispatch(
            saveSelectedDiscrepancy({
                selectedDiscrepancy: this.discrepancies[index].id,
            }),
        );
        highlightSelectedDiscrepancy(this.discrepancies[index].id);
    }

    handleSelectedDiscrepancyChange() {
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );
        this._subscriptions['selected-discrepancy'] =
            selectedDiscrepancy$.subscribe({
                next: (id) => {
                    if (id) {
                        this.selectedDiscrepancy = id;
                        this.selectedTabIndex = this.discrepancyIds.findIndex(
                            (item) => item === this.selectedDiscrepancy,
                        );
                    }
                },
            });
    }

    openAddDiscModal() {
        const dialogRef = this.dialog.open(AddDiscrepancyDialogComponent, {
            data: {
                documentId: this.documentId,
                ...this.selectedDiscStationAndFixer,
            },
            maxWidth: '545px',
            minWidth: '200px',
            width: '545px',
        });

        dialogRef
            .afterClosed()
            .subscribe((res: { success: boolean; discId: number }) => {
                if (this.documentId && res?.success) {
                    this.selectedDiscrepancy = res.discId;
                    this.store.dispatch(
                        saveSelectedDiscrepancy({
                            selectedDiscrepancy: res.discId,
                        }),
                    );
                    this.getDiscrepancies(this.documentId);
                    this.documentService
                        .getDocumentDetails(this.documentId)
                        .subscribe();
                    this.routerService.goDocumentDetails(
                        this.documentId,
                        DocumentDetailTab.DISCREPANCIES,
                        res.discId,
                    );
                }
            });
    }

    getDiscrepancies(id: number) {
        this.loading = true;
        this.selectedTabIndex = 0;
        this._subscriptions.unsubscribeSafe('selected-discrepancy');

        this._subscriptions['get-disc-by-doc-id'] = this.discrepancyService
            .getDiscrepanciesByDocumentId(id)
            .subscribe({
                next: (data) => {
                    this.discrepancies = data.discrepancies;

                    if (!this.selectedDiscrepancy) {
                        this.setDiscrepancyAndNavigate();
                    } else {
                        highlightSelectedDiscrepancy(this.selectedDiscrepancy);
                    }
                    this.setsTabData();
                },
                complete: () => {
                    this.loading = false;
                    this.handleSelectedDiscrepancyChange();
                },
            });
    }

    private subscribeStationAndFixer() {
        this._subscriptions['selected-disc-station-fixer'] =
            this.discrepancyDataService.selectedDiscStationAndFixer$.subscribe({
                next: (data) => {
                    this.selectedDiscStationAndFixer = data;
                },
            });
    }

    private setDiscrepancyAndNavigate() {
        const discIdParam =
            this.route.children[0]?.snapshot.paramMap.get('discId');
        const discrepancyId = discIdParam
            ? parseInt(discIdParam)
            : this.discrepancyIds[this.selectedTabIndex];

        this.store.dispatch(
            saveSelectedDiscrepancy({
                selectedDiscrepancy: discrepancyId,
            }),
        );
        highlightSelectedDiscrepancy(discrepancyId);

        if (this.mode === 'detail')
            this.routerService.goDocumentDetails(
                this.documentId,
                DocumentDetailTab.DISCREPANCIES,
                discrepancyId,
            );
    }

    private setDocumentDetails() {
        this._subscriptions['document-details'] =
            this.documentDataService.documentDetail$.subscribe({
                next: (detail) => (this.documentDetails = detail),
            });
    }

    private setStoredDiscrepancy() {
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );
        this._subscriptions['selected-discrepancy'] = selectedDiscrepancy$
            .pipe(take(1))
            .subscribe({
                next: (id) => (this.selectedDiscrepancy = id),
            });
    }

    private handleComponentRefresh() {
        this._subscriptions['component-refresh'] = this.actions$
            .pipe(ofType(refreshActionRelatedData))
            .subscribe({
                next: (refresh) => {
                    if (refresh) {
                        this.documentService.getDocumentDetails(
                            this.documentId,
                        );
                        this.getDiscrepancies(this.documentId);
                    }
                },
            });
    }

    private setsTabData() {
        this.tabsData = [
            ...this.discrepancies.map((item) => {
                const { label, tooltip } = getDiscrepancyId(item);

                return {
                    path: `${item.id}`,
                    label,
                    tooltip,
                };
            }),
        ];
    }
}
