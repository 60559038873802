<div class="tabs">
    <div class="scroll-container flex text-md" [ngClass]="labelClass">
        @if (canScroll()) {
            <div
                matRipple
                tabindex="0"
                class="scroll-button left"
                [matRippleDisabled]="!canScrollLeft()"
                [class.disabled]="!canScrollLeft()"
                (click)="scrollLeft()"
                (keyup)="scrollLeft()">
                &#10094;
            </div>
        }
        <div class="scroll-content" #scrollContent [style]="scrollContentStyle">
            @for (tab of tabs; track tab; let i = $index) {
                <div
                    tabindex="0"
                    class="tab"
                    [class.active]="i === selectedTabIndex"
                    (click)="selectTab(i)"
                    (keyup.enter)="selectTab(i)">
                    @if (tab.tooltip) {
                        <app-tooltip>
                            <span trigger>
                                {{ tab.label }}
                            </span>
                            <div content>
                                {{ tab.tooltip }}
                            </div>
                        </app-tooltip>
                    } @else {
                        {{ tab.label }}
                    }
                </div>
            }
        </div>
        @if (canScroll()) {
            <div
                matRipple
                tabindex="0"
                class="scroll-button right"
                [matRippleDisabled]="!canScrollRight()"
                [class.disabled]="!canScrollRight()"
                (click)="scrollRight()"
                (keyup)="scrollRight()">
                &#10095;
            </div>
        }
        <ng-content select="[headerContent]" />
    </div>
    <div class="tab-content" [ngClass]="tabContentClass">
        <ng-content />
    </div>
</div>
