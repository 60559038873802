import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { TooltipComponent } from '@shared/tooltip/tooltip.component';

@Component({
    selector: 'app-input-invalid-page-range',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        TooltipComponent,
    ],
    template: ` <p class="flex items-center">
        Invalid page range.
        <app-tooltip>
            <mat-icon svgIcon="info" class="flex" trigger></mat-icon>
            <div content>
                <p class="flex items-center gap-5">
                    <mat-icon svgIcon="check" class="color-yellow-green8" />
                    Valid page range formats:
                </p>
                <ul class="ml-2">
                    <li>
                        Start page - end page
                        <span class="text-bold">(i.e: 1 - 3)</span>
                    </li>
                    <li>Single page <span class="text-bold">(i.e: 1)</span></li>
                </ul>
                <p class="flex items-center gap-5">
                    <mat-icon svgIcon="close" class="color-status-red4" />
                    Invalid page range format
                </p>
                <ul class="ml-2">
                    <li>Comma list (i.e: 1,3,5)</li>
                </ul>
            </div>
        </app-tooltip>
    </p>`,
})
export class InvalidPageRangeErrorComponent {}
