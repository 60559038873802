import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RevisionKind } from '@models/revision/revision-kind';

import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';

@Component({
    standalone: true,
    templateUrl: 'document-change-confirmation-dialog.component.html',
    imports: [FlatButtonComponent, TextButtonComponent],
})
export class DocumentChangeConfirmationDialogComponent {
    documentName: string = '';
    documentType: string = '';

    readonly data: {
        documentName: string;
        documentType: RevisionKind;
    } = inject(MAT_DIALOG_DATA);
    readonly dialogRef = inject(
        MatDialogRef<DocumentChangeConfirmationDialogComponent>,
    );

    constructor() {
        this.documentName = this.data.documentName;
        this.documentType = this.getDocumentTypeLabel(this.data.documentType);
    }

    close(confirmed: boolean) {
        this.dialogRef.close(confirmed);
    }

    private getDocumentTypeLabel(type: RevisionKind) {
        if (type === RevisionKind.APPLIED_VERSION) {
            return 'applied';
        }
        if (type === RevisionKind.FINAL_VERSION) {
            return 'final';
        }
        return '';
    }
}
