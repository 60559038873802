import { Component, Input, OnInit } from '@angular/core';

import { DiscrepancyDetail } from '@models/discrepancy/detail';
import { bytesToMB } from '@helpers/file';

@Component({
    selector: 'app-page-range-error',
    standalone: true,
    template: `
        @if (pageSizeExceedMaxLimit()) {
            <p class="text-xs color-status-red4 whitespace-pre-line">
                The max document size limit:
                <span class="text-semi">
                    {{ maxDocSizeLimit }}
                </span>
                Selected pages:
                <span class="text-semi"> {{ pageRangeFileSize }}. </span>
                Please remove some pages from page range.
            </p>
        } @else {
            @if (maxDocSizeLimit && documentSize) {
                <p class="text-xs whitespace-pre-line">
                    You can send only selected pages because the whole document
                    {{ documentSize }} exceeds the max limit ({{
                        maxDocSizeLimit
                    }}).
                </p>
            }
        }
    `,
})
export class PageRangeErrorComponent implements OnInit {
    @Input({ required: true }) discDetail!: DiscrepancyDetail;

    fullFileInclusionLimit: number | null = null;
    size: number | null = null;
    pageRangeSize: number | null = null;

    ngOnInit(): void {
        this.fullFileInclusionLimit =
            this.discDetail.revision?.fullFileInclusionLimit ?? null;
        this.size = this.discDetail.revision?.size ?? null;
        this.pageRangeSize = this.discDetail.pageRangeFileSize;
    }

    get maxDocSizeLimit(): string | null {
        if (this.fullFileInclusionLimit) {
            return `${bytesToMB(this.fullFileInclusionLimit).toFixed(0)} MB`;
        }
        return null;
    }

    get documentSize(): string | null {
        if (this.size) {
            return `${bytesToMB(this.size).toFixed(0)} MB`;
        }
        return null;
    }

    get pageRangeFileSize(): string | null {
        if (this.pageRangeSize) {
            return `${bytesToMB(this.pageRangeSize).toFixed(0)} MB`;
        }
        return null;
    }

    pageSizeExceedMaxLimit() {
        if (this.pageRangeSize && this.fullFileInclusionLimit) {
            return this.pageRangeSize > this.fullFileInclusionLimit;
        } else {
            return false;
        }
    }
}
