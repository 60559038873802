<div id="resizable" class="panel" @slideInOut>
    <div id="drag-handle"></div>
    <div class="panel__header">
        <div class="flex items-center name">
            <button mat-icon-button aria-label="Close" (click)="onCloseClick()">
                <mat-icon class="color-gray16" svgIcon="close" />
            </button>
            <span class="whitespace-nowrap text-lg">{{ headerTitle }}</span>
        </div>
        <div class="content ml-4">
            <ng-content select="[headerContent]" />
        </div>
        <app-flat-button
            variant="cta"
            class="button"
            [disabled]="headerPrimaryButtonDisabled"
            (clicked)="onPrimaryButtonClick()">
            {{ headerPrimaryButtonLabel }}
        </app-flat-button>
    </div>
    <div id="content"><ng-content /></div>
</div>
