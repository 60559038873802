export enum RevisionKind {
    ORIGINAL,
    CORRECTION,
    APPLIED_VERSION,
    FINAL_VERSION,
}

export const EditableRevisionKindsOnPreview = [
    RevisionKind.ORIGINAL,
    RevisionKind.CORRECTION,
];
