import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WebViewerInstance } from '@pdftron/webviewer';

import { SnackbarService } from '@services/snackbar.service';
import { DocumentService } from '@services/api/document/document.service';
import { RevisionDataService } from '@services/data/revision-data-service';
import { RevisionService } from '@services/api/revision/revision.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import {
    WebViewerComponent,
    WebViewerDocument,
} from '@shared/webviewer/webviewer.component';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { RejectCorrectionDialogComponent } from '@dialogs/reject-correction-dialog/reject-correction-dialog.component';
import { AcceptCorrectionDialogComponent } from '@dialogs/accept-correction-dialog/accept-correction-dialog.component';
import { ApplyCorrectionDialogComponent } from '@dialogs/apply-correction-dialog/apply-correction-dialog.component';
import { DocumentRevisionStatus } from '@models/document';
import {
    EditableRevisionKindsOnPreview,
    RevisionDetails,
    RevisionKind,
} from '@models/revision';
import { DiscrepancyActionCode } from '@models/discrepancy';
import { selectSelectedDiscrepancy } from 'app/store/selectors/document.selectors';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { getUpdatedDocument, WebViewerCustomButtons } from '@helpers/webviewer';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-document-preview',
    standalone: true,
    templateUrl: 'document-preview.component.html',
    styleUrl: 'document-preview.component.scss',
    imports: [
        MatIconModule,
        WebViewerComponent,
        FlatButtonComponent,
        TooltipComponent,
        LoaderComponent,
    ],
})
export class DocumentPreviewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() revisionDetails!: RevisionDetails;
    @Output() documentChange = new EventEmitter();

    statuses: Record<string, ToolipContentItem> | undefined;
    selectedDiscrepancy: number | null = null;
    document: WebViewerDocument | null = null;
    loading = false;
    private vwInstance!: WebViewerInstance;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;
    readonly shownStatuses = [
        DocumentRevisionStatus.ACCEPTED,
        DocumentRevisionStatus.REJECTED,
        DocumentRevisionStatus.PENDING,
    ];
    readonly elementsToBeDisabled = [
        'viewControlsButton',
        'documentControl',
        'moreButton',
        'leftPanelTabs',
        'pageManipulationOverlayButton',
    ];
    readonly saveButton = WebViewerCustomButtons.SAVE;
    readonly fullScreenButton = WebViewerCustomButtons.FULL_SCREEN;

    private store = inject(Store);
    private dialog = inject(MatDialog);
    private revisionService = inject(RevisionService);
    private revisionDataService = inject(RevisionDataService);
    private documentService = inject(DocumentService);
    private snackbarService = inject(SnackbarService);

    constructor() {
        this._subscriptions['save-trigger'] =
            this.revisionDataService.revisionSaveTrigger$.subscribe({
                next: () => this.saveDocument(),
            });
    }

    get selectedDiscNo() {
        return this.revisionDetails.discrepancies.find(
            (disc) => disc.id === this.selectedDiscrepancy,
        )?.discrepancyNo;
    }

    get appliedDiscrepancies(): string {
        return this.revisionDetails.discrepancies
            .filter((r) => r.relation === DocumentRevisionStatus.APPLIED)
            .map((r) => r.discrepancyNo)
            .join(', ');
    }

    get editable() {
        return (
            this.revisionDetails.isEditable &&
            EditableRevisionKindsOnPreview.includes(this.revisionDetails.kind)
        );
    }

    ngOnInit(): void {
        this.setStatuses();
        this.setStoredDiscrepancy();
        this.setDocument();
    }

    ngOnChanges(changes: SimpleChanges) {
        const revisionDetailsChange = changes['revisionDetails'];

        if (revisionDetailsChange && !revisionDetailsChange.firstChange) {
            this.setStatuses();
            this.setDocument();
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    canReject() {
        const discrepancy = this.revisionDetails.discrepancies.find(
            (disc) => disc.id === this.selectedDiscrepancy,
        );
        return discrepancy?.actions.some(
            (item) => item.action === DiscrepancyActionCode.REJECT,
        );
    }

    canAccept() {
        const discrepancy = this.revisionDetails.discrepancies.find(
            (disc) => disc.id === this.selectedDiscrepancy,
        );
        return discrepancy
            ? discrepancy?.actions.some(
                  (item) => item.action === DiscrepancyActionCode.ACCEPT,
              )
            : false;
    }

    canApply() {
        const discrepancy = this.revisionDetails.discrepancies.find(
            (disc) => disc.id === this.selectedDiscrepancy,
        );

        return discrepancy
            ? discrepancy?.actions.some(
                  (item) => item.action === DiscrepancyActionCode.APPLY,
              )
            : false;
    }

    onRejectClick() {
        this.dialog.open(RejectCorrectionDialogComponent, {
            data: {
                revisionDetails: this.revisionDetails,
                discrepancy: this.selectedDiscrepancy,
            },
            maxWidth: '1000px',
            minWidth: '200px',
            width: '1000px',
            maxHeight: '90vh',
        });
    }

    onAcceptClick() {
        this.dialog.open(AcceptCorrectionDialogComponent, {
            data: {
                revisionDetails: this.revisionDetails,
                discrepancy: this.selectedDiscrepancy,
            },
            maxWidth: '100%',
            minWidth: '200px',
            width: '100%',
            height: '100%',
            panelClass: 'p-4',
        });
    }

    onApplyClicked() {
        this.dialog.open(ApplyCorrectionDialogComponent, {
            data: {
                selectedRevision: structuredClone(this.revisionDetails),
                discrepancy: this.selectedDiscrepancy,
            },
            maxWidth: '100%',
            minWidth: '200px',
            width: '100%',
            height: '100%',
            panelClass: 'p-4',
        });
    }

    getKeys(obj: any) {
        return Object.keys(obj);
    }

    onDocumentLoaded(vwInstance: WebViewerInstance) {
        this.vwInstance = vwInstance;
        this.cleanupButtons();
        this.revisionDataService.unsavedDocumentId = null;

        if (this.editable) {
            this.insertSaveButton();
            this.listenPagesUpdates(vwInstance);
        }

        this.insertFullScreenButton();
    }

    private insertSaveButton() {
        const { UI } = this.vwInstance;
        let saveBtn = this.getSaveButtonElement();

        if (!saveBtn) {
            UI.setHeaderItems((header) => {
                header.update([
                    ...header.getItems(),
                    {
                        type: 'actionButton',
                        dataElement: this.saveButton,
                        label: 'Save',
                    },
                ]);
            });

            saveBtn = this.getSaveButtonElement();
            saveBtn.setAttribute('data-qa', this.saveButton);
            saveBtn.style.cssText = `
                padding: 5px 10px;
                border: 1px solid #677E8C;
                width: fit-content;
                height: 28px;
                border-radius: 0;
            }`;
        }
        saveBtn.disabled = true;
        saveBtn?.addEventListener('click', () => {
            this.saveDocument();
        });
    }

    private cleanupButtons() {
        const { UI } = this.vwInstance;
        const buttons = [this.saveButton, this.fullScreenButton];

        UI.setHeaderItems((header) => {
            const items = [...header.getItems()] as {
                dataElement: WebViewerCustomButtons;
                label: string;
                type: string;
            }[];

            const index = items.findIndex((i) =>
                buttons.includes(i.dataElement),
            );

            if (index !== -1) {
                const newHeader = items.slice(0, index);
                header.update([...newHeader]);
            }
        });

        const saveButton = this.getSaveButtonElement();

        if (saveButton) {
            saveButton?.removeEventListener('click', () => this.saveDocument);
            saveButton.remove();
        }
    }

    private insertFullScreenButton() {
        const { UI } = this.vwInstance;
        UI.setHeaderItems((header) => {
            header.update([
                ...header.getItems(),
                {
                    type: 'actionButton',
                    img: 'icon-header-full-screen',
                    onClick: () => UI.toggleFullScreen(),
                    dataElement: this.fullScreenButton,
                },
            ]);
        });
    }

    private getSaveButtonElement() {
        const { UI } = this.vwInstance;
        const instanceDocument = UI.iframeWindow.document;

        return instanceDocument.querySelector(
            `[data-element="${this.saveButton}"]`,
        ) as HTMLButtonElement;
    }

    async saveDocument() {
        if (this.revisionDetails.kind === RevisionKind.ORIGINAL) {
            this.updateOriginalDocument();
        } else {
            this.updateRevisionDocument();
        }
    }

    private async updateRevisionDocument() {
        this.loading = true;
        const formData = new FormData();
        const document = await getUpdatedDocument(this.vwInstance);
        formData.append('file', document.blob, document.name);

        this._subscriptions['update-revision'] = this.revisionService
            .updateRevision(this.revisionDetails.id, formData)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.documentChange.emit(false);
                        this.showSuccessMessage(document.name);
                        this.revisionDataService.updateSelectedRevisionId(
                            this.revisionDetails.id,
                        );
                    } else {
                        this.showErrorMessage(document.name);
                    }
                },
                complete: () => {
                    this._subscriptions.unsubscribeSafe('update-revision');
                    this.loading = false;
                },
            });
    }

    private async updateOriginalDocument() {
        this.loading = true;
        const formData = new FormData();
        const document = await getUpdatedDocument(this.vwInstance);
        formData.append('file', document.blob, document.name);

        this._subscriptions['update-original'] = this.documentService
            .updateOriginalDocument(this.revisionDetails.documentId, formData)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.documentChange.emit(false);
                        this.showSuccessMessage(document.name);
                        this.revisionDataService.updateSelectedRevisionId(
                            this.revisionDetails.id,
                        );
                        this.revisionDataService.triggerOriginalSaved();
                    } else {
                        this.showErrorMessage(document.name);
                    }
                },
                complete: () => {
                    this.loading = false;
                    this._subscriptions.unsubscribeSafe('update-original');
                },
            });
    }

    private showSuccessMessage(documentName: string) {
        this.snackbarService.success({
            header: `${documentName} is saved!`,
        });
    }

    private showErrorMessage(documentName: string) {
        this.snackbarService.error({
            header: `Failed Saving ${documentName}`,
            content: 'Not able to save the document. Please try in some time.',
        });
    }

    private listenPagesUpdates(vwInstance: WebViewerInstance) {
        const [dvLeft] = vwInstance.Core.getDocumentViewers();
        dvLeft.addEventListener('pagesUpdated', () => {
            this.revisionDataService.unsavedDocumentId =
                this.revisionDetails.id;
            this.documentChange.emit(true);
            const saveBtn = this.getSaveButtonElement();
            saveBtn.disabled = false;
        });
    }

    private setDocument() {
        this.document = {
            id: `${this.revisionDetails.documentServiceId}`,
            name: this.revisionDetails.name,
        };
    }

    private setStoredDiscrepancy() {
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );
        this._subscriptions['selected-discrepancy'] =
            selectedDiscrepancy$.subscribe({
                next: (id) => (this.selectedDiscrepancy = id),
            });
    }

    private setStatuses() {
        const discrepancies = this.getDiscrepanciesByRelation();

        if (discrepancies && this.getKeys(discrepancies).length > 0) {
            const { ACCEPTED, REJECTED, PENDING } = DocumentRevisionStatus;
            const groups = {
                [ACCEPTED]: {
                    show: discrepancies[ACCEPTED],
                    textClass: 'color-yellow-green7',
                    icon: 'check-circle',
                    text: `Discrepancy ${discrepancies[ACCEPTED]?.join(', ')} accepted`,
                },
                [REJECTED]: {
                    show: discrepancies[REJECTED],
                    textClass: 'color-status-red4',
                    icon: 'cancel-circle',
                    text: `Discrepancy ${discrepancies[REJECTED]?.join(', ')} rejected`,
                },
                [PENDING]: {
                    show: discrepancies[PENDING],
                    textClass: '',
                    icon: 'upload-circle',
                    text: `Discrepancy ${discrepancies[PENDING]?.join(', ')} pending action`,
                },
            };

            this.statuses = groups;
        }
    }

    private getDiscrepanciesByRelation = () => {
        const filtered = this.revisionDetails?.discrepancies.filter((item) =>
            this.shownStatuses.includes(item.relation),
        );
        const grouped = filtered?.reduce(
            (acc: Record<number, number[]>, item) => {
                if (!acc[item.relation]) {
                    acc[item.relation] = [];
                }
                acc[item.relation].push(item.discrepancyNo);
                return acc;
            },
            {},
        );

        return grouped;
    };
}

interface ToolipContentItem {
    show: number[] | undefined;
    textClass: string;
    icon: string;
    text: string;
}
