<form class="p-5" [formGroup]="form">
    <p class="text-lg mb-3">
        Mark Discrepancy {{ data.discrepancyNo }} as Unresolved
    </p>
    <div class="p-3 text-sm bg-status-red1">
        This action will mark the discrepancy as <b>Unresolved</b> and moved to
        <b>Recently Completed</b> state. Once marked as unresolved, discrepancy
        cannot be re-opened.
    </div>
    <app-textarea
        class="mt-2"
        textAreaStyle="height: 160px"
        label="REASON (OPTIONAL)"
        formControlName="reason"
        [placeholder]="reasonPlaceholder"
        [maxLength]="500" />
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.cancelDiscrepancyDialogReturnToPage">
            Return to Page
        </app-flat-button>
        <app-flat-button
            variant="warning"
            [dataQa]="dataQa.cancelDiscrepancyDialogCancel"
            (clicked)="unresolve()">
            Mark as Unresolved
        </app-flat-button>
    </div>
    @if (error) {
        <div class="flex justify-end gap-8">
            <small class="color-status-red4"> Something went wrong </small>
        </div>
    }
</form>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
