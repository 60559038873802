import { Injectable } from '@angular/core';

import { ActivityLogItem } from 'app/models/discrepancy/activity-log';
import { SharedMapper } from '../shared.mapper';
import {
    DiscrepancyFilterOptionsResponse,
    DiscrepancyFilterOptions,
    DiscrepancyEditOptionsResponse,
    DiscrepancyEditOptions,
    StatusChipItem,
    DiscrepanciesWithTotal,
    DiscrepanciesWithTotalResponse,
    Discrepancy,
    DiscrepancyResponse,
    DiscrepancyDetail,
} from 'app/models/discrepancy';

@Injectable({
    providedIn: 'root',
})
export class DiscrepancyMapper {
    constructor(private sharedMapper: SharedMapper) {}

    toDiscrepancy = (item: DiscrepancyResponse) => {
        return new Discrepancy(
            item.id,
            item.reason?.displayName ?? '-',
            item.priority,
            item.status,
            this.sharedMapper.toDate(item.createdAt, true),
            this.sharedMapper.toDate(item.updatedAt, true),
            this.sharedMapper.toDate(item.taken, true),
            item.analyst?.displayName ?? '-',
            item.station?.displayName ?? '-',
            item.responsibleFixer?.displayName ?? '-',
            item.isDraft,
            item.documentName ?? '-',
            item.documentId,
            item.discrepancyNo,
            item.age ?? null,
        );
    };

    toDiscrepancyDetail = (item: DiscrepancyDetail): DiscrepancyDetail =>
        new DiscrepancyDetail(
            item.id,
            item?.pageRange || '',
            item?.directions || '',
            this.sharedMapper.toDate(item.createdAt, true),
            item.reason ? this.sharedMapper.toIdDisplayPair(item.reason) : null,
            item?.priority ?? '-',
            item?.status || '-',
            item.analyst
                ? this.sharedMapper.toIdDisplayPair(item.analyst)
                : null,
            item.station
                ? this.sharedMapper.toIdDisplayPair(item.station)
                : null,
            item.responsibleFixer
                ? this.sharedMapper.toIdDisplayPair(item.responsibleFixer)
                : null,
            item.documentServiceId,
            item?.canCancel ?? false,
            item?.actions ?? [],
            item?.revision ?? null,
            item.discrepancyNo,
            item?.isDraft ?? false,
            item?.pageRangeFileSize ?? null,
            item?.notifyWithOnlySelectedPages ?? false,
        );

    toDiscrepanciesWithTotal = (
        item: DiscrepanciesWithTotalResponse,
    ): DiscrepanciesWithTotal =>
        new DiscrepanciesWithTotal(
            item.discrepancies?.map(this.toDiscrepancy) ?? [],
            item.totalCount,
        );

    toActivityLogItem = (item: ActivityLogItem): ActivityLogItem =>
        new ActivityLogItem(
            item?.id,
            item?.user || '-',
            item?.logMessage || '-',
            item?.createdAt || '-',
        );

    toDiscrepancyFilterOptions = (
        item: DiscrepancyFilterOptionsResponse,
    ): DiscrepancyFilterOptions =>
        new DiscrepancyFilterOptions(
            (item?.priority || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.reason || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.status || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.station || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.responsibleFixer || [])?.map(
                this.sharedMapper.toSelectOption,
            ) ?? [],
            item?.maxAge ?? null,
        );

    toDiscrepancyEditOptions = (item: DiscrepancyEditOptionsResponse) =>
        new DiscrepancyEditOptions(
            item.priority.map(this.sharedMapper.toSelectOption),
            item.reason.map(this.sharedMapper.toSelectOption),
            item.station.map(this.sharedMapper.toSelectOption),
            item.fixer.map(this.sharedMapper.toSelectOption),
            item.canEdit,
            item.analyst?.map(this.sharedMapper.toSelectOption) ?? [],
            item.revision.map(this.sharedMapper.toSelectOption) ?? [],
        );

    toStatusChipItem = (item: StatusChipItem): StatusChipItem =>
        new StatusChipItem(
            item?.assigned ?? 0,
            item?.backingField ?? -1,
            item?.name ?? '',
            item?.total ?? 0,
            item?.unassigned ?? 0,
        );
}
