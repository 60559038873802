import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Patterns } from '@constants/patterns';

export function pageRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value as string;
        const pageRangePattern = new RegExp(Patterns.PAGE_RANGE);

        if (!value) {
            return null;
        } else {
            if (pageRangePattern.test(value)) {
                const parts = value.split('-');
                const start = parseInt(parts[0], 10);
                const end = parts[1] ? parseInt(parts[1], 10) : start;

                return start <= end ? null : { pageRange: true };
            }
        }

        return { pageRange: true };
    };
}

export function duplicateDocNameValidator(
    docNames: string[],
    extension: string,
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const inputValue = control.value;

        return docNames.includes(`${inputValue}.${extension}`)
            ? { duplicateDocName: { value: inputValue } }
            : null;
    };
}
