import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { ColumnOptionsService } from '@services/column-options.service';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { ColumnOption } from '@models/shared.model';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    DocumentColumn,
    DocumentNestedColumn,
    DocumentViewColumnOptions,
    DocumentViewNestedColumnOptions,
} from '@constants/table';

@Component({
    selector: 'app-document-view-column-options',
    standalone: true,
    imports: [CheckboxComponent],
    template: `
        <p class="mb-3">Column Options</p>
        <div class="column-options">
            @for (item of docViewColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="updateColumnDisplay($event, item.id)" />
            }
        </div>
        <div class="column-options nested ml-n3 mr-n3 mt-1 p-3">
            @for (item of docViewNestedColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="
                        updateNestedColumnDisplay($event, item.id)
                    " />
            }
        </div>
    `,
    styles: `
        :host {
            width: 100%;

            .column-options {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                &.nested {
                    background-color: #f5f6f7;
                }
            }
        }
    `,
})
export class DocumentViewColumnOptionsComponent implements OnInit, OnDestroy {
    docViewColumnOptions: ColumnOption<DocumentColumn>[] = [];
    docViewNestedColumnOptions: ColumnOption<DocumentNestedColumn>[] = [];
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private columnOptionsService = inject(ColumnOptionsService);

    constructor() {}

    ngOnInit(): void {
        this.setColumnOptions();
        this.setNestedColumnOptions();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    private setColumnOptions() {
        this._subscriptions['column-options'] =
            this.columnOptionsService.columnOptions$.subscribe({
                next: (options) => {
                    this.docViewColumnOptions = DocumentViewColumnOptions.map(
                        (option) => {
                            const isHidden = options.hiddenColumns.some(
                                (hiddenOption) => hiddenOption === option.id,
                            );

                            if (isHidden) {
                                return { ...option, checked: false };
                            }
                            return option;
                        },
                    );
                },
            });
    }

    private setNestedColumnOptions() {
        this._subscriptions['nested-column-options'] =
            this.columnOptionsService.nestedColumnOptions$.subscribe({
                next: (options) => {
                    this.docViewNestedColumnOptions =
                        DocumentViewNestedColumnOptions.map((option) => {
                            const isHidden = options.hiddenColumns.some(
                                (hiddenOption) => hiddenOption === option.id,
                            );

                            if (isHidden) {
                                return { ...option, checked: false };
                            }
                            return option;
                        });
                },
            });
    }

    updateColumnDisplay(checked: boolean, id: DocumentColumn) {
        this.columnOptionsService.updateColumnOptions({ checked, id });
    }

    updateNestedColumnDisplay(checked: boolean, id: DocumentNestedColumn) {
        this.columnOptionsService.updateNestedColumnOptions({ checked, id });
    }
}
