import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { forkJoin, of, Subscription, take } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { StationService } from '@services/api/station/station.service';
import { UserService } from '@services/api/user/user.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { DocumentService } from '@services/api/document/document.service';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { InputComponent } from '@shared/input/input.component';
import { SelectComponent } from '@shared/select/select.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { PrioritySelectComponent } from '@shared/select/priority-select/priority-select.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { InvalidPageRangeErrorComponent } from '@shared/validation-error/invalid-page-range.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';
import { DocumentUploadComponent } from '../detail-dialog/document-upload/document-upload.component';
import { AddDiscrepancyDialogComponent } from '../add-discrepancy-dialog/add-discrepancy-dialog.component';
import { FooterAssignActionsComponent } from './footer-assign-actions.component';
import { FooterStateActionsComponent } from './footer-state.actions.component';
import { PageRangeErrorComponent } from './page-range-error.component';
import { AddDiscrepancyButtonComponent } from './add-discrepancy-button.component';
import { PriorityColorDirective } from '@directives/priority-color.directive';
import { GrayBoxDirective } from '@directives/gray-box.directive';
import { pageRangeValidator } from 'app/utils/helpers/validators';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';
import { isNil } from 'app/utils/helpers';
import { SelectOption } from 'app/models/shared.model';
import { WorkflowDocument } from 'app/models/document/item';
import {
    Discrepancy,
    DiscrepancyDetail,
    DiscrepancyEditOptions,
} from 'app/models/discrepancy';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { saveSelectedDiscrepancy } from 'app/store/actions/document.actions';
import { selectSelectedDiscrepancy } from 'app/store/selectors/document.selectors';
import { getDiscrepancyIdTooltipValue } from '../documents-and-discrepancies.utils';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';

@Component({
    selector: 'app-discrepancies-tab',
    standalone: true,
    templateUrl: './discrepancies-tab.component.html',
    styleUrl: './discrepancies-tab.component.scss',
    imports: [
        MatDividerModule,
        ReactiveFormsModule,
        PriorityColorDirective,
        GrayBoxDirective,
        TabsComponent,
        LoaderComponent,
        ContentFieldComponent,
        ActivityLogComponent,
        SelectComponent,
        InputComponent,
        DocumentUploadComponent,
        PrioritySelectComponent,
        FooterAssignActionsComponent,
        FooterStateActionsComponent,
        CheckboxComponent,
        PageRangeErrorComponent,
        AddDiscrepancyButtonComponent,
        InvalidPageRangeErrorComponent,
        TooltipComponent,
    ],
})
export class DiscrepanciesTabComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) documentId!: number;
    @Input() mode: 'summary' | 'detail' = 'summary';
    @ViewChild(DocumentUploadComponent) documentUploadComponent:
        | DocumentUploadComponent
        | undefined;

    readonly maxDirectionsLength = 300;
    loading = false;
    detailsLoading = false;
    tabsData: { label: string; tooltip?: string }[] = [];
    selectedTabIndex: number = 0;
    discrepancies: Discrepancy[] = [];
    discDetail = DiscrepancyDetail.initial();
    editOptions = DiscrepancyEditOptions.initial();
    form!: FormGroup;
    editFormChanged = false;
    formChanged = false;
    isAdmin = false;
    readOnly = true;
    fixerOptions: SelectOption[] = [];
    documentDetails: WorkflowDocument | null = null;
    selectedDiscrepancy: number | null = null;
    private editFormSubscription: Subscription | undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        public dialog: MatDialog,
        private documentService: DocumentService,
        private discrepancyService: DiscrepancyService,
        private userService: UserService,
        private stationService: StationService,
        private formBuilder: FormBuilder,
        private actions$: Actions,
        private store: Store,
    ) {
        this.initForm();
        this.handleComponentRefresh();
    }

    get canSave() {
        return !this.form.invalid && this.editFormChanged;
    }

    get maxDiscrepancyCount() {
        return this.documentDetails?.maximumDiscrepancyCount ?? null;
    }

    get canAddDiscrepancy() {
        return this.documentDetails?.canAddDiscrepancy ?? false;
    }

    get pageRange() {
        return this.form.get('pageRange') as FormControl;
    }

    get discrepancyIds() {
        return this.discrepancies.map((item) => item.id);
    }

    ngOnInit(): void {
        this.setUserPermission();
        this.setDocumentDetails();
        this.setSelectedDiscrepancy();
        this.getDiscrepancies(this.documentId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];

        if (documentIdChange && !documentIdChange.firstChange) {
            this._subscriptions.unsubscribeSafe('selected-discrepancy');
            this.selectedDiscrepancy = null;
            this.getDiscrepancies(documentIdChange.currentValue);
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    handleTabChange(index: number) {
        this.store.dispatch(
            saveSelectedDiscrepancy({
                selectedDiscrepancy: this.discrepancies[index].id,
            }),
        );
    }

    handleSelectedDiscrepancyChange() {
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );
        this._subscriptions['selected-discrepancy'] =
            selectedDiscrepancy$.subscribe({
                next: (data) => {
                    if (data) {
                        this.selectedDiscrepancy = data;
                        this.selectedTabIndex = this.discrepancyIds.findIndex(
                            (item) => item === this.selectedDiscrepancy,
                        );
                        this.getDiscrepancyDetails(this.selectedDiscrepancy);
                    }
                },
            });
    }

    openAddDiscModal() {
        const dialogRef = this.dialog.open(AddDiscrepancyDialogComponent, {
            data: {
                documentId: this.documentId,
                station: this.discDetail.station?.backingField,
                fixer: this.discDetail.responsibleFixer?.backingField,
            },
            maxWidth: '545px',
            minWidth: '200px',
            width: '545px',
        });

        dialogRef
            .afterClosed()
            .subscribe((res: { success: boolean; discId: number }) => {
                if (this.documentId && res?.success) {
                    this.selectedDiscrepancy = res.discId;
                    this.store.dispatch(
                        saveSelectedDiscrepancy({
                            selectedDiscrepancy: res.discId,
                        }),
                    );
                    this.getDiscrepancies(this.documentId);
                    this.documentService
                        .getDocumentDetails(this.documentId)
                        .subscribe();
                }
            });
    }

    docSizeExceedMaxLimit() {
        return (
            !isNil(this.discDetail.revision) &&
            this.discDetail.revision!.size >
                this.discDetail.revision!.fullFileInclusionLimit
        );
    }

    getDiscrepancies(id: number) {
        this.loading = true;
        this.selectedTabIndex = 0;
        this._subscriptions.unsubscribeSafe('selected-discrepancy');

        this._subscriptions['get-disc-by-doc-id'] = this.discrepancyService
            .getDiscrepanciesByDocumentId(id)
            .subscribe({
                next: (data) => {
                    this.discrepancies = data.discrepancies;

                    if (!this.selectedDiscrepancy) {
                        this.store.dispatch(
                            saveSelectedDiscrepancy({
                                selectedDiscrepancy:
                                    this.discrepancyIds[this.selectedTabIndex],
                            }),
                        );
                    }

                    this.setsTabData();
                },
                complete: () => {
                    this.loading = false;
                    this.handleSelectedDiscrepancyChange();
                },
            });
    }

    protected getDiscrepancyDetails(id: number) {
        this.editFormSubscription?.unsubscribe();

        if (this.discrepancies.length > 0) {
            this.detailsLoading = true;

            forkJoin([
                this.getEditOptions(id),
                this.discrepancyService.getDiscrepancyDetails(id),
            ]).subscribe({
                next: ([editOptionsData, discDetailsData]) => {
                    this.editOptions = editOptionsData;
                    this.discDetail = discDetailsData;
                    this.readOnly = !editOptionsData.canEdit;
                    this.handleStationChange();
                    this.fillEditForm(discDetailsData);
                    this.onEditFormValueChange();
                },
                complete: () => (this.detailsLoading = false),
            });
        }

        if (this.documentUploadComponent) {
            this.documentUploadComponent.refreshData();
        }
    }

    private setUserPermission() {
        this._subscriptions['is-admin-user'] = this.userService
            .isAdminUser()
            .subscribe({
                next: (isAdmin) => (this.isAdmin = isAdmin),
            });
    }

    private setDocumentDetails() {
        this._subscriptions['document-details'] =
            this.documentService.documentDetail$.subscribe({
                next: (detail) => (this.documentDetails = detail),
            });
    }

    private setSelectedDiscrepancy() {
        const selectedDiscrepancy$ = this.store.select(
            selectSelectedDiscrepancy,
        );
        this._subscriptions['selected-discrepancy'] = selectedDiscrepancy$
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    if (data) {
                        this.selectedDiscrepancy = data;
                    }
                },
            });
    }

    private initForm() {
        this.form = this.formBuilder.group({
            priority: new FormControl(null),
            reason: new FormControl(null),
            station: new FormControl(null),
            responsibleFixer: new FormControl(null),
            pageRange: new FormControl('', [pageRangeValidator()]),
            directions: new FormControl(
                '',
                Validators.maxLength(this.maxDirectionsLength),
            ),
            revision: new FormControl(null),
            notifyWithOnlySelectedPages: new FormControl(null),
        });
    }

    private handleComponentRefresh() {
        this.actions$.pipe(ofType(refreshActionRelatedData)).subscribe({
            next: (refresh) => {
                if (refresh) this.getDiscrepancies(this.documentId);
            },
        });
    }

    private onEditFormValueChange() {
        this.editFormChanged = false;
        const initialValue = this.form.value;
        this.editFormSubscription = this.form.valueChanges.subscribe(() => {
            this.editFormChanged = Object.keys(initialValue).some(
                (key) => this.form.value[key] != initialValue[key],
            );
        });
    }

    private fillEditForm({
        priority,
        pageRange,
        reason,
        station,
        directions,
        responsibleFixer,
        revision,
        notifyWithOnlySelectedPages,
    }: DiscrepancyDetail) {
        this.form.patchValue({
            priority,
            pageRange,
            reason: reason?.backingField,
            station: station?.backingField,
            responsibleFixer: responsibleFixer?.backingField,
            directions,
            revision: revision?.id,
            notifyWithOnlySelectedPages,
        });
    }

    private getEditOptions(id: number) {
        if (this.mode === 'detail') {
            return this.discrepancyService.getDiscrepancyEditOptions(id);
        }
        return of(DiscrepancyEditOptions.initial());
    }

    private setsTabData() {
        this.tabsData = [
            ...this.discrepancies.map((item) => ({
                label: item.isDraft
                    ? `${item.discrepancyNo}*`
                    : `${item.discrepancyNo}`,
                tooltip: getDiscrepancyIdTooltipValue(item),
            })),
        ];
    }

    private getFixers(stationId: number) {
        this._subscriptions['get-fixers'] = this.stationService
            .getStationFixers(stationId)
            .subscribe({
                next: (fixers) => {
                    this.fixerOptions = fixers;
                    if (fixers.length === 1) {
                        this.form.patchValue({
                            responsibleFixer: fixers[0].value,
                        });
                    }
                },
            });
    }

    private handleStationChange(): void {
        if (this.mode === 'detail' && !this.readOnly) {
            this._subscriptions['station-change'] = this.form.controls[
                'station'
            ].valueChanges.subscribe((change) => {
                this.form.patchValue({ responsibleFixer: null });
                this.getFixers(change);
            });
        } else {
            this._subscriptions.unsubscribeSafe('station-change');
        }
    }
}
