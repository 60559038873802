<div class="dialog-content">
    <div class="half pt-5 pr-5 pl-5 overflow-hidden">
        <div class="flex items-start justify-space-between mb-3">
            <p class="text-lg">{{ title }}</p>
            <div class="flex gap-10">
                @if (error) {
                    <small class="color-status-red4">
                        Something went wrong
                    </small>
                }
                <app-flat-button
                    (clicked)="cancel()"
                    [dataQa]="dataQa.acceptCorrectionDialogCancel">
                    Cancel
                </app-flat-button>
                <app-flat-button
                    (clicked)="accept()"
                    variant="success"
                    [dataQa]="dataQa.acceptCorrectionDialogAccept">
                    Accept
                </app-flat-button>
            </div>
        </div>
        @if (!onlyOneDiscrepancy) {
            <div class="p-3 text-sm mb-4 summary">
                You will be accepting this correction for:
                <span class="text-semi">{{ discrepanciesText }}</span>
            </div>
        }
        @if (onlyOneDiscrepancy) {
            <p class="text-sm mb-2">Discrepancy Details</p>
            <mat-divider />
            @if (discDetailLoading) {
                <app-loader class="mt-2" />
            } @else {
                <app-discrepancy-summary
                    class="overflow-auto"
                    [discDetail]="discDetail" />
            }
        } @else {
            <app-tabs
                class="h-full"
                [tabs]="tabsData"
                (tabSelected)="onTabChange($event)"
                tabLabelClass="text-s">
                @if (discDetailLoading) {
                    <app-loader />
                } @else {
                    <app-discrepancy-summary [discDetail]="discDetail" />
                }
            </app-tabs>
        }
    </div>
    <div wfeResizable orientation="vertical"></div>
    <div class="half bg-gray18 overflow-auto flex-1">
        @if (documentLoading) {
            <div class="h-full flex items-center justify-center">
                <app-loader />
            </div>
        } @else {
            @if (revisionDocument && originalDocument) {
                <app-web-viewer
                    class="h-full"
                    [document]="originalDocument"
                    [documentToCompare]="revisionDocument"
                    [elementsToBeDisabled]="elementsToBeDisabled"
                    (documentLoaded)="onDocumentLoaded($event)" />
            }
        }
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" />
}
