<div [class.overlay]="type === 'overlay'">
    <span
        [class.loader]="true"
        [class.pos-abs]="position === 'absolute'"
        [class.loader--small]="size === 'small'"
        [class.loader--medium]="size === 'medium'"
        [class.loader--large]="size === 'large'"
        [class.loader--xlarge]="size === 'xlarge'">
    </span>
</div>
