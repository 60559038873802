import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    standalone: true,
    selector: 'app-radio-button',
    templateUrl: 'radio-button.component.html',
    styleUrl: 'radio-button.component.scss',
    imports: [CommonModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonComponent),
            multi: true,
        },
    ],
})
export class RadioButtonComponent implements ControlValueAccessor {
    @Input() value!: string | number;
    @Input() name!: string;
    @Input() disabled: boolean = false;
    @Input() variant: 'light' | 'dark' = 'light';
    @Input() size: 'small' | 'medium' = 'medium';

    private _selectedValue!: string | number;

    private onChange = (_value: string | number) => {};
    private onTouched = () => {};

    get checked(): boolean {
        return this._selectedValue === this.value;
    }

    select(): void {
        if (!this.disabled) {
            this._selectedValue = this.value;
            this.onChange(this.value);
            this.onTouched();
        }
    }

    writeValue(value: string): void {
        if (value !== undefined) {
            this._selectedValue = value;
        }
    }

    registerOnChange(fn: (value: string | number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
