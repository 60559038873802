import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { DocumentViewColumnOptionsComponent } from './document-view-column-options.component';
import { DiscrepancyViewColumnOptionsComponent } from './discrepancy-view-column-options.component';
import DATA_QA from '@automation/data-qa.json';
import { TableView } from '@constants/table';

@Component({
    selector: 'app-change-view-menu',
    standalone: true,
    templateUrl: './change-table-view-menu.component.html',
    styleUrl: './change-table-view-menu.component.scss',
    imports: [
        MatMenuModule,
        MatButtonToggleModule,
        MatIconModule,
        MatDividerModule,
        FlatButtonComponent,
        TextButtonComponent,
        DocumentViewColumnOptionsComponent,
        DiscrepancyViewColumnOptionsComponent,
    ],
})
export class ChangeTableViewMenuComponent {
    @Input() selected: TableView = TableView.Document;
    @Output() tableViewChanged = new EventEmitter<TableView>();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

    menuOpened = false;
    readonly dataQa = DATA_QA;
    readonly tableView = TableView;

    get description() {
        return this.selected === TableView.Document
            ? `Document view shows both document’s and discrepancy information in
            the same table by grouping discrepancies under documents.`
            : 'Discrepancy view lists each discrepancy separately in the table.';
    }

    changeTableView(tableView: TableView) {
        this.tableViewChanged.emit(tableView);
    }

    closeMenu() {
        if (this.trigger) this.trigger.closeMenu();
    }
}
