import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, of, switchMap } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { UserService } from '@services/api/user/user.service';
import { PagedResponse, SimpleResponse } from '@models/api.model';
import {
    WorkflowDocument,
    DocumentFilterOptionsResponse,
    DocumentFilterOptions,
    DocumentRevisionsResponse,
    MetadataItem,
    UpdateDocumentMetadataRequest,
    MetadataOptionResponse,
    DocumentInternals,
    DocDiscrepancyCreateOptions,
    DocDiscrepancyCreateOptionsResponse,
    DocumentPermissionResponse,
} from '@models/document';
import { ExportDocumentExcelQuery } from '@models/shared.model';
import { QuestionBase } from '@shared/question/question-base';
import { interpolate } from '@helpers/index';
import { DocumentMapper } from './document.mapper';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { DocumentDataService } from '@services/data/document-data.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    private readonly _path = 'workflow/v1';

    constructor(
        private api: ApiService,
        private userService: UserService,
        private documentDataService: DocumentDataService,
        private mapper: DocumentMapper,
    ) {}

    getDocuments(params = {}): Observable<PagedResponse<WorkflowDocument>> {
        return this.api
            .get<WorkflowDocument>(
                `${this._path}${endpoints.documents}`,
                params,
                true,
            )
            .pipe(
                map((response) => {
                    const pagedResponse =
                        response as PagedResponse<WorkflowDocument>;
                    return new PagedResponse<WorkflowDocument>(
                        pagedResponse.pageIndex,
                        pagedResponse.pageSize,
                        pagedResponse.totalItems,
                        pagedResponse.items.map(this.mapper.toWorkflowDocument),
                    );
                }),
            );
    }

    getDocumentDetails(id: number): Observable<WorkflowDocument | null> {
        this.documentDataService.updateDocumentDetail(null);

        return this.api
            .get(this._path + interpolate(endpoints.documentById, { id }))
            .pipe(
                map((response: SimpleResponse<WorkflowDocument>) => {
                    const document = this.mapper.toWorkflowDocument(
                        response.data,
                    );

                    this.documentDataService.updateDocumentDetail(document);
                    return document;
                }),
                catchError(() => of(null)),
            );
    }

    updateDocument(
        id: number,
        data: Partial<WorkflowDocument>,
    ): Observable<boolean> {
        return this.api
            .patch<WorkflowDocument>(
                this._path + interpolate(endpoints.documentById, { id }),
                data,
            )
            .pipe(
                map((document: WorkflowDocument) => {
                    this.documentDataService.updateDocumentDetail(document);
                    return true;
                }),
                catchError(() => of(false)),
            );
    }

    getDocumentsFilterOptions(params: WorkflowAndRepository | undefined) {
        return this.api
            .get(this._path + endpoints.documentsFilterOptions, params)
            .pipe(
                map((response: SimpleResponse<DocumentFilterOptionsResponse>) =>
                    this.mapper.toDocumentFilterOptions(response.data),
                ),
                catchError(() => of(DocumentFilterOptions.initial())),
            );
    }

    getDocumentFile(
        id: number,
        serviceId: string,
    ): Observable<HttpResponse<Blob>> {
        return this.api.getBlob(
            this._path +
                interpolate(endpoints.documentFilesContent, { id, serviceId }),
        );
    }

    getDocumentRevisions(
        id: number,
        params = {},
    ): Observable<DocumentRevisionsResponse | null> {
        return this.api
            .get(
                this._path + interpolate(endpoints.documentRevisions, { id }),
                params,
            )
            .pipe(
                map((response: SimpleResponse<DocumentRevisionsResponse>) =>
                    this.mapper.toDocumentRevisions(response.data),
                ),
                catchError(() => of(null)),
            );
    }

    getMetadata(id: number): Observable<MetadataItem[]> {
        return this.api
            .get(this._path + interpolate(endpoints.documentsMetadata, { id }))
            .pipe(
                map((response: SimpleResponse<MetadataItem[]>) =>
                    response.data.map(this.mapper.toMetadata),
                ),
                catchError(() => of([])),
            );
    }

    updateMetadata(
        id: number,
        data: UpdateDocumentMetadataRequest,
    ): Observable<boolean> {
        return this.api
            .put(
                this._path + interpolate(endpoints.documentsMetadata, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    getMetadataOptions(id: number): Observable<QuestionBase<any>[]> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.documentsMetadataOptions, { id }),
            )
            .pipe(
                map((response: SimpleResponse<MetadataOptionResponse[]>) => {
                    return response.data
                        .map(this.mapper.toMetadataQuestion)
                        .filter((item) => item !== null) as QuestionBase<any>[];
                }),
                catchError(() => of([])),
            );
    }

    getInternals(id: number): Observable<DocumentInternals | null> {
        return this.api
            .get(this._path + interpolate(endpoints.documentsInternals, { id }))
            .pipe(
                map((response: SimpleResponse<DocumentInternals>) =>
                    this.mapper.toInternals(response.data),
                ),
                catchError(() => of(null)),
            );
    }

    canEditDocument(id: number): Observable<boolean> {
        return this.userService.isAdminUser().pipe(
            switchMap((isAdminUser) => {
                if (isAdminUser) return of(true);
                else {
                    return this.isMyDocument(id);
                }
            }),
        );
    }

    getDocDiscrepancyCreateOptions(
        documentId: number,
    ): Observable<DocDiscrepancyCreateOptions> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.docDiscrepancyCreateOptions, {
                        id: documentId,
                    }),
            )
            .pipe(
                map(
                    (
                        response: SimpleResponse<DocDiscrepancyCreateOptionsResponse>,
                    ) =>
                        this.mapper.toDocDiscrepancyCreateOptions(
                            response.data,
                        ),
                ),
                catchError(() => of(DocDiscrepancyCreateOptions.initial())),
            );
    }

    uploadDocument(
        documentId: number,
        formData: FormData,
    ): Observable<{ progress: number; status: string }> {
        return this.api.postProgress(
            this._path +
                interpolate(endpoints.documentFiles, { id: documentId }),
            formData,
        );
    }

    export(query: ExportDocumentExcelQuery) {
        return this.api.getBlob(this._path + endpoints.documentExport, query);
    }

    createAppliedVersion(documentId: number, formData: FormData) {
        return this.api
            .post(
                this._path +
                    interpolate(endpoints.documentAppliedVersion, {
                        id: documentId,
                    }),
                formData,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    updateAppliedVersion(
        documentId: number,
        formData: FormData,
        revisionId: number,
    ) {
        return this.api
            .put(
                this._path +
                    interpolate(endpoints.documentAppliedVersionById, {
                        id: documentId,
                        revisionId,
                    }),
                formData,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    private isMyDocument(id: number): Observable<boolean> {
        return this.api
            .get(this._path + interpolate(endpoints.documentIsMine, { id }))
            .pipe(
                map(
                    (response: SimpleResponse<DocumentPermissionResponse>) =>
                        response.data.isMine,
                ),
                catchError(() => of(false)),
            );
    }
}
