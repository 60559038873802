import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { StatusBarDiscrepancyData } from '@constants/table';
import { WorkflowDocument } from '@models/document';

@Injectable({
    providedIn: 'root',
})
export class DocumentDataService {
    private statusBarDiscrepancyCount = new BehaviorSubject<
        StatusBarDiscrepancyData[]
    >([]);
    private documentDetail = new BehaviorSubject<WorkflowDocument | null>(null);
    private statusBarData: StatusBarDiscrepancyData[] = [];

    statusBarDiscrepancyCount$ = this.statusBarDiscrepancyCount.asObservable();
    documentDetail$ = this.documentDetail.asObservable();

    get currentDocument() {
        return this.documentDetail.getValue();
    }

    updateDocumentDetail(document: WorkflowDocument | null) {
        this.documentDetail.next(document);
    }

    updateDiscDataForStatusPanel(newData: StatusBarDiscrepancyData) {
        const index = this.statusBarData.findIndex(
            (item) => item.documentId === newData.documentId,
        );
        if (index !== -1) {
            this.statusBarData[index] = newData;
        } else {
            this.statusBarData.push(newData);
        }

        this.statusBarDiscrepancyCount.next([...this.statusBarData]);
    }
}
