import { IdDisplayPair, SelectOption } from '../shared.model';

export class DiscrepancyFilterOptionsResponse {
    constructor(
        public priority: IdDisplayPair[] = [],
        public reason: IdDisplayPair[] = [],
        public status: IdDisplayPair[] = [],
        public station: IdDisplayPair[] = [],
        public primaryFixer: IdDisplayPair[] = [],
        public maxAge: number | null = null,
    ) {}

    static initial(): DiscrepancyFilterOptionsResponse {
        return new DiscrepancyFilterOptionsResponse();
    }
}

export class DiscrepancyFilterOptions {
    constructor(
        public priority: SelectOption[] = [],
        public reason: SelectOption[] = [],
        public status: SelectOption[] = [],
        public station: SelectOption[] = [],
        public primaryFixer: SelectOption[] = [],
        public maxAge: number | null = null,
    ) {}

    static initial(): DiscrepancyFilterOptions {
        return new DiscrepancyFilterOptions();
    }
}
