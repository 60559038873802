@if (label) {
    <mat-label class="label text-xxs color-gray14">
        {{ label.toLocaleUpperCase() }} {{ required ? '*' : '' }}
        @if (description) {
            <span>{{ description }}</span>
        }
    </mat-label>
}
<mat-select
    #select
    [attr.data-qa]="dataQa"
    [disabled]="disabled"
    [multiple]="multiple"
    [placeholder]="placeholder"
    [ngClass]="{
        select: true,
        'select--secondary': variant === 'secondary',
        'select--disabled': disabled,
    }"
    [(value)]="value"
    (selectionChange)="onSelectionChange($event)">
    <mat-select-trigger *ngIf="multiple">
        {{ displayText() }}
    </mat-select-trigger>
    <mat-checkbox
        *ngIf="multiple"
        class="select-all-checkbox color-gray15 fs-13"
        [indeterminate]="!allSelected"
        [checked]="allSelected"
        (change)="onSelectAllClick()">
        Select all ({{ options.length }})
    </mat-checkbox>
    <mat-divider *ngIf="multiple" />
    <mat-option *ngIf="noneOption" class="color-gray15" [value]="null">
        None
    </mat-option>
    @if (isGroupedOptions) {
        @for (option of groupedOptions; track option.group) {
            <mat-optgroup [label]="option.group">
                @for (item of option.items; track item.value) {
                    <mat-option class="option" [value]="item.value">
                        {{ item.viewValue }}
                    </mat-option>
                }
            </mat-optgroup>
        }
    } @else {
        @for (option of flatOptions; track option.value) {
            <mat-option [value]="option.value">
                {{ option.viewValue }}
            </mat-option>
        }
    }
</mat-select>
