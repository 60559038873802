<ag-grid-angular
    [getRowId]="getRowId"
    [class]="class"
    [pagination]="pagination"
    [paginationPageSize]="pageSize"
    [cacheBlockSize]="pageSize"
    [columnDefs]="columnDefs"
    [getRowStyle]="getRowStyle"
    [masterDetail]="masterDetail"
    [detailCellRendererParams]="detailCellRendererParams"
    [detailRowAutoHeight]="detailRowAutoHeight"
    [defaultColDef]="defaultColDef"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (rowGroupOpened)="onRowGroupOpened($event)"
    [gridOptions]="_gridOptions"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>

@if (pagination) {
    <app-pagination
        [pageSize]="pageSize"
        (pageSizeChanged)="pageSize = $event" />
}
