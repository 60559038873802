<div class="p-5">
    <p class="text-lg mb-3">
        Recall Discrepancy {{ data.discrepancyNo }} to “Initial Review”
    </p>
    Recalling the discrepancy to Initial Review will make it editable.
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button mat-dialog-close>Cancel</app-flat-button>
        <app-flat-button variant="cta" (clicked)="recall()">
            Recall
        </app-flat-button>
    </div>
    @if (error) {
        <div class="flex justify-end gap-8">
            <small class="color-status-red4"> Something went wrong </small>
        </div>
    }
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
