import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridAngular } from 'ag-grid-angular';
import {
    ColDef,
    GetRowIdParams,
    GridOptions,
    GridReadyEvent,
    RowStyle,
    RowClassParams,
    RowGroupOpenedEvent,
} from 'ag-grid-community';

import { environment } from '@environments/environment';
import { NoRowsOverlayComponent } from '@grid-table/overlay/no-rows-overlay/no-rows-overlay.component';
import { LoadingOverlayComponent } from '@grid-table/overlay/loading-overlay/loading-overlay.component';
import { PaginationComponent } from './pagination/pagination.component';

LicenseManager.setLicenseKey(environment.aggridLicenseKey);

@Component({
    selector: 'app-table',
    standalone: true,
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss',
    imports: [CommonModule, AgGridAngular, PaginationComponent],
})
export class TableComponent implements OnInit {
    @Input({ required: true }) columnDefs: ColDef[] = [];
    @Input() masterDetail: boolean = false;
    @Input() pagination = true;
    @Input() floatingFilter = true;
    @Input() suppressMenu = false;
    @Input() suppressRowHoverHighlight = false;
    @Input() suppressCellFocus = false;
    @Input() suppressMovableColumns = false;
    @Input() detailCellRendererParams: any;
    @Input() detailRowAutoHeight = true;
    @Input() pageSize = 10;
    @Input() noRowsText: string = 'No data available';
    @Input() variant: 'primary' | 'secondary' = 'primary';
    @Input() gridOptions: GridOptions = {};
    @Input() getRowStyle:
        | ((params: RowClassParams<any, any>) => RowStyle | undefined)
        | undefined;

    @Output() gridReady = new EventEmitter<GridReadyEvent>();
    @Output() rowGroupOpened = new EventEmitter<RowGroupOpenedEvent>();

    noRowsOverlayComponent = NoRowsOverlayComponent;
    loadingOverlayComponent = LoadingOverlayComponent;
    noRowsOverlayComponentParams = {};
    defaultColDef: ColDef = {};

    get class() {
        return `ag-theme-alpine w-full ${this.variant}`;
    }

    get _gridOptions(): GridOptions {
        return {
            ...this.gridOptions,
            suppressAggFuncInHeader: true,
            suppressRowClickSelection: true,
            suppressDragLeaveHidesColumns: true,
            suppressServerSideFullWidthLoadingRow: true,
            enableRangeSelection: false,
            rowMultiSelectWithClick: true,
            paginationPageSizeSelector: false,
            floatingFiltersHeight: 30,
            domLayout: 'autoHeight',
            rowModelType: 'serverSide',
            rowSelection: 'multiple',
            rowHeight: this.variant === 'secondary' ? 28 : 46,
            headerHeight: this.variant === 'secondary' ? 20 : 32,
            suppressRowHoverHighlight: this.suppressRowHoverHighlight,
            suppressMovableColumns: this.suppressMovableColumns,
            suppressCellFocus: this.suppressCellFocus,
        };
    }

    ngOnInit(): void {
        this.defaultColDef = {
            flex: 1,
            minWidth: 80,
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: this.floatingFilter,
            suppressFloatingFilterButton: true,
            suppressHeaderMenuButton: this.suppressMenu,
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        };

        this.noRowsOverlayComponentParams = {
            text: this.noRowsText,
        };
    }

    getRowId = (params: GetRowIdParams) => params.data.id;

    onGridReady(event: GridReadyEvent): void {
        this.gridReady.emit(event);
    }

    onRowGroupOpened(event: RowGroupOpenedEvent): void {
        this.rowGroupOpened.emit(event);
    }
}
