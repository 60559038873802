import { Component, Inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import {
    SnackbarComponent,
    SnackbarComponentProps,
} from '@shared/snackbar/snackbar.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { BulkActionResultNotification } from '@models/discrepancy';

@Component({
    standalone: true,
    template: `<app-snackbar>
        <div class="grid gap-10">
            @if (succeededDiscrepancies) {
                <div class="flex items-center gap-5">
                    <mat-icon svgIcon="check" style="color: #4F7B00" />
                    <p>{{ succeededDiscrepancies }}</p>
                </div>
            }
            @if (failedDiscrepanciesCount > 0) {
                <div>
                    <div class="flex items-center gap-5">
                        <mat-icon svgIcon="close" style="color: #c11212" />
                        <p style="line-height: 24px;">
                            Selected
                            {{ failedDiscrepanciesCount }} discrepancies cannot
                            be renotified.
                        </p>
                    </div>
                    <div class="pl-7 grid gap-5">
                        @if (invalidStateDiscrepancies) {
                            <p>&#x2022; {{ invalidStateDiscrepancies }}</p>
                        }
                        @if (unauthorizedDiscrepancies) {
                            <p>&#x2022; {{ unauthorizedDiscrepancies }}</p>
                        }
                        @if (failedSystemDiscrepancies) {
                            <p>&#x2022; {{ failedSystemDiscrepancies }}</p>
                        }
                    </div>
                </div>
            }
        </div>
        <app-text-button class="ml-auto mt-2" (clicked)="copyMessage()">
            <mat-icon svgIcon="copy" />
            Copy Message
        </app-text-button>
    </app-snackbar>`,
    imports: [
        SnackbarComponent,
        MatIconModule,
        MatDividerModule,
        TextButtonComponent,
    ],
})
export class SummaryBulkRenotifyComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: SnackbarComponentProps<BulkActionResultNotification>,
        private clipboard: Clipboard,
    ) {}

    get succeededDiscrepancies() {
        const discrepancies =
            this.data.notification?.succeededDiscrepancies ?? [];
        return discrepancies.length > 0
            ? `${discrepancies.join(', ')} renotified succesfully.`
            : undefined;
    }

    get invalidStateDiscrepancies() {
        const discrepancies =
            this.data.notification?.invalidStateDiscrepancies ?? [];
        return discrepancies.length > 0
            ? `${discrepancies.join(', ')} (not in Await Correction state)`
            : undefined;
    }

    get unauthorizedDiscrepancies() {
        const discrepancies =
            this.data.notification?.unauthorizedDiscrepancies ?? [];

        return discrepancies.length > 0
            ? `${discrepancies.join(', ')} (no permission)`
            : undefined;
    }

    get failedSystemDiscrepancies() {
        const discrepancies =
            this.data.notification?.failedLogicAppDiscrepancies ?? [];

        return discrepancies.length > 0
            ? `${discrepancies.join(', ')} (system failure)`
            : undefined;
    }

    get failedDiscrepanciesCount() {
        if (this.data.notification) {
            const {
                invalidStateDiscrepancies,
                unauthorizedDiscrepancies,
                failedLogicAppDiscrepancies,
            } = this.data.notification;

            return (
                invalidStateDiscrepancies.length +
                unauthorizedDiscrepancies.length +
                failedLogicAppDiscrepancies.length
            );
        }
        return 0;
    }

    copyMessage() {
        const message = [
            this.succeededDiscrepancies,
            this.invalidStateDiscrepancies,
            this.unauthorizedDiscrepancies,
            this.failedSystemDiscrepancies,
        ]
            .filter((i) => i)
            .join(' ');

        this.clipboard.copy(message);
    }
}
