<div class="w-full">
    @if (label) {
        <mat-label class="label text-xxs color-gray14">
            {{ label.toLocaleUpperCase() }} {{ required ? '*' : '' }}
            @if (description) {
                <span>{{ description }}</span>
            }
        </mat-label>
    }
    <div class="inline-flex items-center w-full">
        <input
            #trigger="matAutocompleteTrigger"
            matInput
            class="w-full h-full"
            [class.secondary]="variant === 'secondary'"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            [matAutocomplete]="auto"
            (keyup.enter)="onInputEnter($event)" />
    </div>
</div>

<mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="autoActiveFirstOption"
    [displayWith]="displayFn"
    (optionSelected)="onSelectionChange($event.option)">
    @if (isGroupedOptions) {
        @for (option of filteredGroupOptions | async; track option.group) {
            @if (option.items.length > 0) {
                <mat-optgroup [label]="option.group">
                    @for (item of option.items; track item.value) {
                        <mat-option [value]="item">
                            {{ item.viewValue }}
                        </mat-option>
                    }
                </mat-optgroup>
            }
        }
    } @else {
        @for (option of filteredOptions | async; track option.value) {
            <mat-option [value]="option">{{ option.viewValue }}</mat-option>
        }
    }
</mat-autocomplete>
