import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@services/snackbar.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DiscrepancyIdNo } from 'app/models/discrepancy/item';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'discard-discrepancy-dialog.component.html',
    imports: [FlatButtonComponent, MatDialogClose, LoaderComponent],
})
export class DiscardDiscrepancyDialogComponent {
    loading: boolean = false;
    error: boolean = false;
    readonly dataQa = DATA_QA;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DiscrepancyIdNo,
        public dialogRef: MatDialogRef<DiscardDiscrepancyDialogComponent>,
        private discrepancyService: DiscrepancyService,
        private snackbarService: SnackbarService,
        private store: Store,
    ) {}

    discard() {
        this.loading = true;
        this.error = false;

        this.discrepancyService.discardDiscrepancy(this.data.id).subscribe({
            next: (success) => {
                this.loading = false;
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Discrepancy ${this.data.discrepancyNo} Discarded`,
                    });
                    this.dialogRef.close();
                    this.store.dispatch(refreshActionRelatedData());
                } else {
                    this.error = true;
                }
            },
        });
    }
}
