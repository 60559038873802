export enum Priority {
    Undefined = -1,
    Low = '0',
    Normal = '1',
    High = '2',
    Critical = '3',
}

export const priorityOptions = [
    {
        value: 0,
        viewValue: 'Low',
    },
    {
        value: 1,
        viewValue: 'Normal',
    },
    {
        value: 2,
        viewValue: 'High',
    },
    {
        value: 3,
        viewValue: 'Critical',
    },
];

export const priorityMap = {
    [Priority.Low]: {
        text: 'Low',
        icon: 'keyboard-arrow-down',
    },
    [Priority.Normal]: {
        text: 'Normal',
        icon: 'density',
    },
    [Priority.High]: {
        text: 'High',
        icon: 'keyboard-arrow-up',
    },
    [Priority.Critical]: {
        text: 'Critical',
        icon: 'double-arrow-up',
    },
    [Priority.Undefined]: {
        text: '-',
        icon: '',
    },
};
