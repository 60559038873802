import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectDiscrepancyCount } from 'app/store/selectors/document.selectors';

@Component({
    standalone: true,
    imports: [MatIconModule],
    template: `
        @if (count) {
            <div class="flex items-center text-xxs whitespace-pre">
                Showing <span class="text-semi">{{ count.filtered }}</span>
                of
                <span class="text-semi">{{ count.total }}</span>
                discrepancies
            </div>
        }
    `,
})
export class CountStatusBarComponent implements IStatusPanelAngularComp {
    count: { filtered: number; total: number } | null = null;
    count$: Observable<{ filtered: number; total: number }> | undefined;

    constructor(private store: Store) {}

    agInit(_params: IStatusPanelParams): void {
        this.count$ = this.store.select(selectDiscrepancyCount);
        this.count$.subscribe({
            next: (value) => (this.count = value),
        });
    }
}
