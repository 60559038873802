import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { documentRevisionStatusMap } from '@constants/table';

@Component({
    selector: 'app-document-status-legend',
    standalone: true,
    imports: [MatIconModule],
    template: `
        <div
            class="status-legend flex items-center gap-10 ml-n4 pl-4 flex-wrap p-3 text-xxs">
            @for (item of documentRevisionLegend; track $index) {
                <span class="flex items-center gap-2">
                    <mat-icon
                        [svgIcon]="item.icon"
                        class="color-yellow-green7" />
                    {{ item.label }}
                </span>
            }
        </div>
    `,
    styles: `
        .status-legend {
            border-top: 1px solid #c5d1d8;
        }
    `,
})
export class DocStatusLegendComponent {
    readonly documentRevisionLegend = Object.entries(documentRevisionStatusMap)
        .map(([key, value]) => ({
            ...value,
            order: parseInt(key),
        }))
        .sort((a, b) => b.order - a.order);
}
