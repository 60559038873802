import { Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { DocDetailGuard } from '@guards/document-edit.guard';
import { LoginPageComponent } from './pages/login/login.component';
import { DocumentsAndDiscrepanciesPageComponent } from './pages/documents-and-discrepancies/documents-and-discrepancies';
import { DocumentDetailPageComponent } from './pages/document-detail/document-detail.component';
import { InternalsPageComponent } from './pages/document-detail/internals.component';
import { MetadataPageComponent } from './pages/document-detail/metadata.component';
import { DiscrepanciesPageComponent } from './pages/document-detail/discrepancies.component';

export const routes: Routes = [
    { path: '', redirectTo: '/documents-and-discrepancies', pathMatch: 'full' },
    {
        path: 'documents-and-discrepancies',
        component: DocumentsAndDiscrepanciesPageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'documents-and-discrepancies/:id',
        component: DocumentDetailPageComponent,
        canActivate: [DocDetailGuard],
        children: [
            {
                path: 'metadata',
                component: MetadataPageComponent,
            },
            {
                path: 'discrepancies',
                component: DiscrepanciesPageComponent,
            },
            {
                path: 'wf-internals',
                component: InternalsPageComponent,
            },
        ],
    },
    { path: 'login', component: LoginPageComponent },
    { path: '**', redirectTo: '/documents-and-discrepancies' },
];
