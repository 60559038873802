type Descripted<T> = {
    [K in keyof T]: {
        readonly id: T[K];
        readonly description: string;
    };
}[keyof T];

export function enumToDescriptedArray<T>(
    enumeration: any,
    separatorRegex: RegExp = /_/g,
): Descripted<T>[] {
    return (Object.keys(enumeration) as Array<keyof T>)
        .filter((key) => isNaN(Number(key)))
        .filter(
            (key) =>
                typeof enumeration[key] === 'number' ||
                typeof enumeration[key] === 'string',
        )
        .map((key) => ({
            id: enumeration[key],
            description: String(key).replace(separatorRegex, ' '),
        }));
}

export function toggleItem<T>(array: T[], item: T): T[] {
    const index = array.indexOf(item);

    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }

    return array;
}
