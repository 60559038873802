{
    "acceptCorrectionDialogCancel": "acceptCorrectionDialogCancel",
    "acceptCorrectionDialogAccept": "acceptCorrectionDialogAccept",
    "addNewDiscrepancyButton": "addNewDiscrepancyButton",
    "addDiscrepancyDialogCancel": "addDiscrepancyDialogCancel",
    "addDiscrepancyDialogCreate": "addDiscrepancyDialogCreate",
    "addEditFixerButton": "addEditFixerButton",
    "applyCorrectionDialog": "applyCorrectionDialog",
    "applyCorrectionDialogChangeLogItem": "applyCorrectionDialogChangeLogItem",
    "applyCorrectionDialogInsertMenuTrigger": "applyCorrectionDialogInsertMenuTrigger",
    "applyCorrectionDialogReplaceMenuTrigger": "applyCorrectionDialogReplaceMenuTrigger",
    "applyCorrectionDialogInsertButton": "applyCorrectionDialogInsertButton",
    "applyCorrectionDialogReplaceButton": "applyCorrectionDialogReplaceButton",
    "applyCorrectionDialogDiscard": "applyCorrectionDialogDiscard",
    "applyCorrectionDialogSave": "applyCorrectionDialogSave",
    "applyCorrectionDialogCloseResolve": "applyCorrectionDialogCloseResolve",
    "assignDiscrepancyDialogCancel": "assignDiscrepancyDialogCancel",
    "assignDiscrepancyDialogAssign": "assignDiscrepancyDialogAssign",
    "bulkRenotifyButton": "bulkRenotifyButton",
    "cancelDiscrepancyDialogReturnToPage": "cancelDiscrepancyDialogReturnToPage",
    "cancelDiscrepancyDialogCancel": "cancelDiscrepancyDialogCancel",
    "discardDiscrepancyDialogCancel": "discardDiscrepancyDialogCancel",
    "discardDiscrepancyDialogDiscard": "discardDiscrepancyDialogDiscard",
    "discrepancyViewDiscrepancyIdCell": "discrepancyViewDiscrepancyIdCell",
    "discrepancyViewTable": "discrepancyViewTable",
    "discrepancyViewToggle": "discrepancyViewToggle",
    "documentViewDocumentNameCell": "documentViewDocumentNameCell",
    "documentViewNestedDiscrepanyIdCell": "documentViewNestedDiscrepanyIdCell",
    "documentViewTable": "documentViewTable",
    "documentViewToggle": "documentViewToggle",
    "documentPreviewAcceptButton": "documentPreviewAcceptButton",
    "documentPreviewRejectButton": "documentPreviewRejectButton",
    "documentPreviewApplyButton": "documentPreviewApplyButton",
    "documentUploadButton": "documentUploadButton",
    "duplicateDocumentsDialogCancel": "duplicateDocumentsDialogCancel",
    "duplicateDocumentsDialogCorrectionNameInput": "duplicateDocumentsDialogCorrectionNameInput",
    "duplicateDocumentsDialogUpload": "duplicateDocumentsDialogUpload",
    "excelExportDialogCancel": "excelExportDialogCancel",
    "excelExportDialogExcludeFilters": "excelExportDialogExcludeFilters",
    "excelExportDialogExport": "excelExportDialogExport",
    "excelExportDialogShowMaxLimitReduceInfo": "excelExportDialogShowMaxLimitReduceInfo",
    "notifyWithOnlySelectedPagesCheckbox": "notifyWithOnlySelectedPagesCheckbox",
    "repositorySelect": "repositorySelect",
    "resetFiltersButton": "resetFiltersButton",
    "showOnlyMyItemsToggle": "showOnlyMyItemsToggle",
    "statusChipFilter": "statusChipFilter",
    "summaryPanelGoToDetailsButton": "summaryPanelGoToDetailsButton",
    "triggerPublishDocumentModalButton": "triggerPublishDocumentModalButton",
    "finalVersionDialog": "finalVersionDialog",
    "finalVersionDialogCancelButton": "finalVersionDialogCancelButton",
    "finalVersionDialogChangeLogItem": "finalVersionDialogChangeLogItem",
    "finalVersionDialogCreateButton": "finalVersionDialogCreateButton",
    "finalVersionDialogInsertMenuTrigger": "finalVersionDialogInsertMenuTrigger",
    "finalVersionDialogReplaceMenuTrigger": "finalVersionDialogReplaceMenuTrigger",
    "footerAssignDiscrepancyButton": "footerAssignDiscrepancyButton",
    "footerRecallForEditDiscrepancy": "footerRecallForEditDiscrepancy",
    "footerRecallToAwaitDiscrepancy": "footerRecallToAwaitDiscrepancy",
    "footerCancelDiscrepancy": "footerCancelDiscrepancy",
    "footerCancelDiscrepancyMarkAsCancelled": "footerCancelDiscrepancyMarkAsCancelled",
    "footerCancelDiscrepancyMarkAsUnresolved": "footerCancelDiscrepancyMarkAsUnresolved",
    "footerCloseResolveDiscrepancy": "footerCloseResolveDiscrepancy",
    "footerDiscardDiscrepancy": "footerDiscardDiscrepancy",
    "footerPutbackDiscrepancyButton": "footerPutbackDiscrepancyButton",
    "footerSaveDiscrepancy": "footerSaveDiscrepancy",
    "footerTakeDiscrepancyButton": "footerTakeDiscrepancyButton",
    "footerNotifyDiscrepancy": "footerNotifyDiscrepancy",
    "footerRenotifyDiscrepancy": "footerRenotifyDiscrepancy",
    "footerUnassignDiscrepancyButton": "footerUnassignDiscrepancyButton",
    "metadataEditSaveButton": "metadataEditSaveButton",
    "openExcelDialogButton": "openExcelDialogButton",
    "publishDocumentDialogCancel": "publishDocumentDialogCancel",
    "publishDocumentDialogPublish": "publishDocumentDialogPublish",
    "recallDiscrepancyDialogCancel": "recallDiscrepancyDialogCancel",
    "recallDiscrepancyDialogRecall": "recallDiscrepancyDialogRecall",
    "recallForEditDialogCancel": "recallForEditDialogCancel",
    "recallForEditDialogRecall": "recallForEditDialogRecall",
    "rejectCorrectionDialogCancel": "rejectCorrectionDialogCancel",
    "rejectCorrectionDialogReasonInput": "rejectCorrectionDialogReasonInput",
    "rejectCorrectionDialogReject": "rejectCorrectionDialogReject",
    "tableViewMenuClose": "tableViewMenuClose",
    "tableViewButton": "tableViewButton",
    "unsavedChangesDialogDiscard": "unsavedChangesDialogDiscard",
    "unsavedChangesDialogReturnToPage": "unsavedChangesDialogReturnToPage",
    "unsavedChangesDialogSaveChanges": "unsavedChangesDialogSaveChanges",
    "uploadCorrectionDialogCancel": "uploadCorrectionDialogCancel",
    "uploadCorrectionDialogDiscrepancyCheckbox": "uploadCorrectionDialogDiscrepancyCheckbox",
    "uploadCorrectionDialogDropzoneInput": "uploadCorrectionDialogDropzoneInput",
    "uploadCorrectionDialogUpload": "uploadCorrectionDialogUpload",
    "workflowInstanceSelect": "workflowInstanceSelect"
}
