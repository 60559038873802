<div class="h-full flex flex-column">
    <div class="flex flex-column pt-5 pr-5 pl-5 overflow-hidden">
        <div class="flex items-start justify-space-between mb-3">
            <p class="text-lg">{{ title }}</p>
            <div class="flex gap-10">
                @if (error) {
                    <small class="color-status-red4">
                        Something went wrong
                    </small>
                }
                <app-flat-button
                    [dataQa]="dataQa.acceptCorrectionDialogCancel"
                    (clicked)="cancel()">
                    Cancel
                </app-flat-button>
                <app-flat-button
                    variant="success"
                    [dataQa]="dataQa.acceptCorrectionDialogAccept"
                    (clicked)="accept()">
                    Accept
                </app-flat-button>
            </div>
        </div>
        @if (discDetailLoading) {
            <app-loader class="mt-2" />
        } @else {
            <app-discrepancy-summary
                class="overflow-auto"
                [discDetail]="discDetail" />
        }
    </div>
    <div wfeResizable orientation="vertical"></div>
    <div class="flex flex-column bg-gray18 overflow-auto flex-1">
        @if (documentLoading) {
            <div class="h-full flex items-center justify-center">
                <app-loader />
            </div>
        } @else {
            @if (revisionDocument && originalDocument) {
                <app-web-viewer
                    class="h-full"
                    [document]="originalDocument"
                    [documentToCompare]="revisionDocument"
                    [elementsToBeDisabled]="elementsToBeDisabled"
                    (documentLoaded)="onDocumentLoaded($event)" />
            }
        }
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" />
}
