<span
    class="flex items-center"
    [ngClass]="{
        'medium textsm': size === 'medium',
        'small text-s': size === 'small',
    }">
    <mat-checkbox
        (change)="toggle()"
        [checked]="checked"
        [disabled]="disabled"
        [indeterminate]="indeterminate">
        {{ label }}
    </mat-checkbox>
</span>
