<div class="p-5">
    <p class="text-lg">
        Change
        <span style="text-transform: capitalize">{{ documentType }}</span>
        Version Selection?
    </p>
    <div class="p-3 bg-status-red1 mt-4">
        If you change {{ documentType }} version selection, edits you made on
        {{ documentName }} <b>will be lost</b>.
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button (clicked)="close(false)"> Cancel </app-flat-button>
        <app-text-button (clicked)="close(true)">
            Change Anyway
        </app-text-button>
    </div>
</div>
