import { Component, OnInit } from '@angular/core';

import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import {
    DocumentColumn,
    DocumentNestedColumn,
    DocumentViewColumnOptions,
    DocumentViewNestedColumnOptions,
} from '@constants/table';
import { ColumnOption } from '@models/shared.model';
import { DocumentDataService } from '@services/data/document-data.service';

@Component({
    selector: 'app-document-view-column-options',
    standalone: true,
    imports: [CheckboxComponent],
    template: `
        <p class="mb-3">Column Options</p>
        <div class="column-options">
            @for (item of docViewColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="updateColumnDisplay($event, item.id)" />
            }
        </div>
        <div class="column-options nested ml-n3 mr-n3 mt-1 p-3">
            @for (item of docViewNestedColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="
                        updateNestedColumnDisplay($event, item.id)
                    " />
            }
        </div>
    `,
    styles: `
        :host {
            width: 100%;

            .column-options {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                &.nested {
                    background-color: #f5f6f7;
                }
            }
        }
    `,
})
export class DocumentViewColumnOptionsComponent implements OnInit {
    docViewColumnOptions: ColumnOption<DocumentColumn>[] = [];
    docViewNestedColumnOptions: ColumnOption<DocumentNestedColumn>[] = [];
    hiddenColumnOptions: DocumentColumn[] = [];
    hiddenNestedColumnOptions: DocumentNestedColumn[] = [];

    constructor(private documentDataService: DocumentDataService) {}

    ngOnInit(): void {
        this.handleColumnOptions();
        this.handleNestedColumnOptions();
    }

    private handleColumnOptions() {
        this.documentDataService.columnOptions$.subscribe({
            next: (options) =>
                (this.hiddenColumnOptions = options.hiddenColumns),
        });

        this.docViewColumnOptions = DocumentViewColumnOptions.map((option) => {
            const isHidden = this.hiddenColumnOptions.some(
                (hiddenOption) => hiddenOption === option.id,
            );

            if (isHidden) {
                return { ...option, checked: false };
            }
            return option;
        });
    }

    private handleNestedColumnOptions() {
        this.documentDataService.nestedColumnOptions$.subscribe({
            next: (options) =>
                (this.hiddenNestedColumnOptions = options.hiddenColumns),
        });

        this.docViewNestedColumnOptions = DocumentViewNestedColumnOptions.map(
            (option) => {
                const isHidden = this.hiddenNestedColumnOptions.some(
                    (hiddenOption) => hiddenOption === option.id,
                );

                if (isHidden) {
                    return { ...option, checked: false };
                }
                return option;
            },
        );
    }

    updateColumnDisplay(checked: boolean, id: DocumentColumn) {
        this.documentDataService.updateColumnOptions({ checked, id });
    }

    updateNestedColumnDisplay(checked: boolean, id: DocumentNestedColumn) {
        this.documentDataService.updateNestedColumnOptions({ checked, id });
    }
}
