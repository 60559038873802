import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
    ColDef,
    GetDetailRowDataParams,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
    RowGroupOpenedEvent,
} from 'ag-grid-community';
import { Store } from '@ngrx/store';

import { DocumentService } from '@services/api/document/document.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TableComponent } from '@grid-table/table.component';
import { TextFilterComponent } from '@grid-table/filter/text-filter/text-filter.component';
import { PriorityCellComponent } from '@grid-table/cell/priority-cell/priority-cell.component';
import { SelectFilterComponent } from '@grid-table/filter/select-filter/select-filter.component';
import { NoRowsOverlayComponent } from '@grid-table/overlay/no-rows-overlay/no-rows-overlay.component';
import { ClickableCellComponent } from '@shared/table/cell/clickable-cell/clickable-cell.compoent';
import { DateFilterComponent } from '@shared/table/filter/date-filter/date-filter.component';
import { GrayBoxCellComponent } from '@shared/table/cell/gray-box-cell/gray-box-cell.component';
import { RangeFilterComponent } from '@shared/table/filter/range-filter/range-filter.component';
import { CountStatusBarComponent } from './count-status-bar.component';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { DocumentFilterOptions } from '@models/document/filter-options';
import { WorkflowDocument } from '@models/document/item';
import { Range } from '@models/shared.model';
import { DocumentColumn, DocumentNestedColumn } from '@constants/table';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    saveSummaryPanelData,
    updateDiscrepancyCount,
} from 'app/store/actions/document.actions';
import {
    getAgeInWFValue,
    getDiscrepancyIdLabelValue,
    getDiscrepancyIdTooltipValue,
    isFilterApplied,
    setFilterQuery,
    setPageIndex,
    setSortQuery,
} from '../documents-and-discrepancies.utils';

@Component({
    selector: 'app-document-view',
    standalone: true,
    templateUrl: './document-view.component.html',
    imports: [TableComponent],
})
export class DocumentViewComponent implements OnInit, OnDestroy {
    @Input() workflowAndRepo: WorkflowAndRepository | undefined;
    @Input() statuses: number[] = [];
    @Input() showMyItems: boolean = false;

    error = false;
    discrepancies: number[] = [];
    expandedRow: WorkflowDocument | null = null;
    filterApplied: boolean = false;
    columnDefs: ColDef[] = [];
    hiddenColumnOptions: DocumentColumn[] = [];
    hiddenNestedColumnOptions: DocumentNestedColumn[] = [];
    detailCellRendererParams = this.getDetailCellRendererParams();

    private _gridApi!: GridApi;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private documentService: DocumentService,
        private discrepancyService: DiscrepancyService,
        private ngZone: NgZone,
        private store: Store,
    ) {}

    get filterModel() {
        return this._gridApi.getFilterModel();
    }

    get columnState() {
        return this._gridApi.getColumnState();
    }

    ngOnInit(): void {
        this.getFilterOptions();
        this.handleColumnOptions();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const datasource = this.getDocuments();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    onRowGroupOpened(params: RowGroupOpenedEvent) {
        if (params.node.expanded) {
            this._gridApi.forEachNode((node) => {
                if (node.rowIndex !== params.node.rowIndex) {
                    this._gridApi.setRowNodeExpanded(node, false);
                }
            });
        }
    }

    resetFilters() {
        this._gridApi.refreshHeader();
        this._gridApi.setFilterModel(null);
    }

    refreshData() {
        if (!this._gridApi.isDestroyed()) {
            this._gridApi.refreshServerSide({ purge: true });
        }
    }

    closePanel() {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: null,
                selectedDiscrepancy: null,
            }),
        );
    }

    private handleColumnOptions() {
        this._subscriptions['column-options'] =
            this.documentService.columnOptions$.subscribe({
                next: (options) => {
                    this.hiddenColumnOptions = options.hiddenColumns;
                    const option = options.lastToggled;
                    if (
                        option &&
                        this._gridApi &&
                        !this._gridApi.isDestroyed()
                    ) {
                        this._gridApi.setColumnsVisible(
                            [option.id],
                            option.checked,
                        );
                        this._gridApi.destroyFilter(option.id);
                    }
                },
            });

        this._subscriptions['nested-column-options'] =
            this.documentService.nestedColumnOptions$.subscribe({
                next: (options) => {
                    this.hiddenNestedColumnOptions = options.hiddenColumns;
                    this.detailCellRendererParams = {
                        detailGridOptions: this.getDetailGridOptions(),
                        getDetailRowData: (params: GetDetailRowDataParams) =>
                            this.getDiscrepancies(params),
                    };
                },
            });
    }

    private getFilterOptions() {
        this.documentService
            .getDocumentsFilterOptions(this.workflowAndRepo)
            .subscribe({
                next: (response: DocumentFilterOptions) => {
                    this.columnDefs = this.getColumnDefs(response);
                },
            });
    }

    private getDiscrepancies(params: GetDetailRowDataParams) {
        this.discrepancyService
            .getDiscrepanciesByDocumentId(params.data.id, {
                mine: this.showMyItems,
                status: this.statuses,
            })
            .subscribe({
                next: (response) => {
                    this.ngZone.run(() => {
                        this.expandedRow = params.data;
                        this.discrepancies = [
                            ...response.discrepancies.map((item) => item.id),
                        ];

                        this.store.dispatch(
                            updateDiscrepancyCount({
                                discrepancyCount: {
                                    filtered: response.discrepancies.length,
                                    total: response.totalCount,
                                },
                            }),
                        );
                        params.successCallback(response.discrepancies);
                    });
                },
            });
    }

    private getDocuments(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                params.api.showLoadingOverlay();
                this.filterApplied = isFilterApplied(params.request);

                this.documentService
                    .getDocuments(this.getQueryParams(params.request))
                    .subscribe({
                        next: (data) => {
                            params.api.hideOverlay();
                            if (data) {
                                params.success({
                                    rowData: data.items,
                                    rowCount: data.totalItems ?? -1,
                                });
                                if (data.items.length === 0) {
                                    params.api.showNoRowsOverlay();
                                }
                                this.hideHiddenColumns();
                            } else {
                                params.fail();
                            }
                        },
                        error: () =>
                            this.ngZone.run(() => {
                                this.error = true;
                            }),
                    });
            },
        };
    }

    private hideHiddenColumns() {
        this._gridApi.setColumnsVisible(this.hiddenColumnOptions, false);
    }

    private expandRow(node: IRowNode) {
        this._gridApi.setRowNodeExpanded(node, true);
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        const pageSize = this._gridApi.paginationGetPageSize();
        const pageIndex = setPageIndex(request, pageSize);

        // setFilterQuery should be after workflowAndRepo to update repository query of dropdown

        return {
            ...this.workflowAndRepo,
            ...{ status: this.statuses },
            ...{ mine: this.showMyItems },
            ...setFilterQuery(request.filterModel),
            ...setSortQuery(request.sortModel),
            pageIndex,
            pageSize,
        };
    }

    private getColumnDefs(filterOptions: DocumentFilterOptions): ColDef[] {
        return [
            {
                field: DocumentColumn.NAME,
                headerName: 'Document Name',
                showRowGroup: true,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true,
                    suppressDoubleClickExpand: true,
                    innerRenderer: ClickableCellComponent,
                    innerRendererParams: {
                        onClick: (node: IRowNode) =>
                            this.onDocumentNameClick(node),
                    },
                },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: { class: 'pl-11' },
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 150,
            },
            {
                field: DocumentColumn.DOC_TYPE,
                maxWidth: 250,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.documentType,
                    autocomplete: true,
                },
            },
            {
                field: DocumentColumn.PRIORITY,
                maxWidth: 130,
                cellRenderer: PriorityCellComponent,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.priority,
                    priority: true,
                },
            },
            {
                field: DocumentColumn.REPOSITORY,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.repository,
                },
            },
            {
                field: DocumentColumn.AGE_IN_WF,
                headerName: 'Age In WF',
                cellRenderer: GrayBoxCellComponent,
                valueFormatter: getAgeInWFValue,
                cellStyle: { textOverflow: 'clip' },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: RangeFilterComponent,
                floatingFilterComponentParams: {
                    availableRange: new Range(0, filterOptions.maxAge),
                },
            },
            {
                field: DocumentColumn.WF_ENTER_DATE,
                headerName: 'WF Enter Date',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
            {
                field: DocumentColumn.STATION,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.station,
                    autocomplete: true,
                },
            },
        ];
    }

    private getDetailGridOptions() {
        return {
            rowHeight: 35,
            headerHeight: 32,
            noRowsOverlayComponent: NoRowsOverlayComponent,
            noRowsOverlayComponentParams: {
                text: 'No discrepancies found',
                isDetail: true,
            },
            columnDefs: this.getDetailColumnDefs().filter(
                (column) =>
                    !this.hiddenNestedColumnOptions.includes(
                        column.field as DocumentNestedColumn,
                    ),
            ),
            defaultColDef: {
                flex: 1,
                cellStyle: { display: 'flex', alignItems: 'center' },
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: CountStatusBarComponent,
                        align: 'left',
                    },
                ],
            },
        };
    }

    private onDocumentNameClick(node: IRowNode) {
        this.expandedRow = node.data;

        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: node.data.id,
                selectedDiscrepancy: null,
            }),
        );

        this.expandRow(node);
        this._gridApi.ensureIndexVisible(node.rowIndex ?? 0, 'top');
    }

    private getDetailCellRendererParams() {
        return {
            detailGridOptions: this.getDetailGridOptions(),
            getDetailRowData: (params: GetDetailRowDataParams) =>
                this.getDiscrepancies(params),
        };
    }

    private getDetailColumnDefs(): ColDef[] {
        return [
            {
                field: DocumentNestedColumn.ID,
                headerName: 'Discrepancy ID',
                valueFormatter: (params) =>
                    getDiscrepancyIdLabelValue(params.data),
                cellRenderer: ClickableCellComponent,
                cellRendererParams: {
                    tooltipGetter: (params: ICellRendererParams) =>
                        getDiscrepancyIdTooltipValue(params.data),
                    onClick: (node: IRowNode) => {
                        this.store.dispatch(
                            saveSummaryPanelData({
                                selectedDocument: this.expandedRow!.id,
                                selectedDiscrepancy: node.data.id,
                            }),
                        );
                    },
                },
            },
            { field: DocumentNestedColumn.REASON },
            {
                field: DocumentNestedColumn.PRIORITY,
                cellRenderer: PriorityCellComponent,
            },
            { field: DocumentNestedColumn.STATUS },
            {
                field: DocumentNestedColumn.AGE_IN_WF,
                headerName: 'Age In WF',
                cellRenderer: GrayBoxCellComponent,
                valueFormatter: getAgeInWFValue,
            },
            { field: DocumentNestedColumn.TAKEN },
            { field: DocumentNestedColumn.ANALYST },
            { field: DocumentNestedColumn.STATION },
            {
                field: DocumentNestedColumn.RESPONSIBLE_FIXER,
                resizable: false,
            },
        ];
    }
}
