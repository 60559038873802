import {
    Component,
    inject,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { WebViewerInstance } from '@pdftron/webviewer';

import { RevisionDataService } from '@services/data/revision-data-service';
import { DocumentService } from '@services/api/document/document.service';
import { SnackbarService } from '@services/snackbar.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabItem, TabsComponent } from '@shared/tabs/tabs.component';
import {
    WebViewerDocument,
    WebViewerComponent,
} from '@shared/webviewer/webviewer.component';
import { DocumentChangeConfirmationDialogComponent } from '@dialogs/document-change-confirmation-dialog/document-change-confirmation-dialog.component';
import { InsertMenuContentComponent } from '../apply-correction-dialog/document-manipulation-menu/insert-menu-content.component';
import { ReplaceMenuContentComponent } from '../apply-correction-dialog/document-manipulation-menu/replace-menu-content.component';
import { DiscrepancySummaryComponent } from '../../discrepancy-summary/discrepancy-summary.component';
import { getDiscrepancyId } from '../../documents-and-discrepancies.utils';
import { ResizableDirective } from '@directives/resizable.directive';
import { MenuCenterDirective } from '@directives/center-mat-menu.directive';
import {
    Discrepancy,
    DiscrepancyDetail,
    DiscrepancyIdNo,
    DiscrepancyTerminateType,
} from '@models/discrepancy';
import { DocumentRevisionVersion } from '@models/document';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    createDropdown,
    createVersionTag,
    getUpdatedDocument,
    hideCloseDocumentButton,
    insertCurrentPageNumber,
    PagesUpdatedEvent,
    removeZoomOverlayButton,
    toggleLeftPanel,
} from '@helpers/webviewer';
import { RevisionKind } from '@models/revision/revision-kind';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'final-version-dialog.component.html',
    styleUrl: 'final-version-dialog.component.scss',
    imports: [
        MatMenuModule,
        FlatButtonComponent,
        LoaderComponent,
        WebViewerComponent,
        MenuCenterDirective,
        ResizableDirective,
        InsertMenuContentComponent,
        ReplaceMenuContentComponent,
        TabsComponent,
        DiscrepancySummaryComponent,
        AsyncPipe,
    ],
})
export class FinalVersionDialogComponent implements OnInit, OnDestroy {
    @ViewChild('insertTrigger') insertTrigger: MatMenuTrigger | undefined;
    @ViewChild('replaceTrigger') replaceTrigger: MatMenuTrigger | undefined;
    sourceDocument: WebViewerDocument | null = null;
    destinationDocument: WebViewerDocument | null = null;
    vwInstance!: WebViewerInstance;
    showInsertAndReplace = false;
    documentChanged = false;
    tabsData: TabItem[] = [];
    triggeredDiscrepancy!: DiscrepancyIdNo;
    selectedDiscrepancy = DiscrepancyDetail.initial();
    selectedDiscrepancyLoading: boolean = true;
    loading: boolean = false;
    combinedLogs = new BehaviorSubject<string[]>([]);
    combinedLogs$ = this.combinedLogs.asObservable();
    destinationDocumentPageCount: number = 0;
    private discrepancies: Discrepancy[] = [];
    private sourceDropdownItems: DocumentRevisionVersion[] = [];
    private destinationDropdownItems: DocumentRevisionVersion[] = [];
    private destinationDropdownNode: HTMLSelectElement | null = null;
    private sourceDropdownNode: HTMLSelectElement | null = null;
    private savedLogs: string[] = [];
    private logs: string[] = [];
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;
    readonly elementsToBeDisabled = [
        'insertPage',
        'replacePage',
        'leftPanelTabs',
        'fullScreenButton',
    ];

    private store = inject(Store);
    private ngZone = inject(NgZone);
    private dialog = inject(MatDialog);
    private snackbarService = inject(SnackbarService);
    private documentService = inject(DocumentService);
    private documentDataService = inject(DocumentDataService);
    private discrepancyService = inject(DiscrepancyService);
    private revisionDataService = inject(RevisionDataService);
    readonly data: {
        triggerType: DiscrepancyTerminateType;
    } & DiscrepancyIdNo = inject(MAT_DIALOG_DATA);
    readonly dialogRef = inject(MatDialogRef<FinalVersionDialogComponent>);

    constructor() {
        this.triggeredDiscrepancy = {
            discrepancyNo: this.data.discrepancyNo,
            id: this.data.id,
        };
    }

    get logsTitle() {
        return `Changes made on document ${this.destinationDocument?.name}`;
    }

    ngOnInit(): void {
        this.getDiscrepancies();
        this.setDestinationDropdownNode();
        this.setSourceDropdownNode();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    protected cancel() {
        this.dialogRef.close();
    }

    protected triggerAction() {
        this.loading = true;
        const { id } = this.triggeredDiscrepancy;
        const actions: Record<
            DiscrepancyTerminateType,
            () => Observable<boolean>
        > = {
            Cancel: () => this.discrepancyService.cancelDiscrepancy(id),
            Unresolve: () => this.discrepancyService.unresolveDiscrepancy(id),
            Discard: () => this.discrepancyService.discardDiscrepancy(id),
            'Close Resolve': () =>
                this.discrepancyService.closeResolveDiscrepancy(id),
        };
        const action = actions[this.data.triggerType];

        if (action) {
            this._subscriptions['trigger'] = action().subscribe({
                next: (success) => {
                    if (success) {
                        this.updateOrCreateFinalVersion();
                    } else {
                        this.loading = false;
                    }
                },
            });
        }
    }

    protected async updateOrCreateFinalVersion() {
        const documentId = this.documentDataService.currentDocument?.id;
        const discrepancyId = this.triggeredDiscrepancy.id;
        const finalVersionExist = this.revisionDataService.finalVersion;
        const document = await getUpdatedDocument(this.vwInstance);
        const fvName = finalVersionExist
            ? document.name
            : this.revisionDataService.originalDocument?.revisionName +
              '_final';

        if (documentId) {
            const formData = new FormData();
            formData.append('discrepancy', discrepancyId.toString());
            formData.append('file', document.blob, fvName);
            this.logs.forEach((i) => formData.append('logs', i));

            if (finalVersionExist) {
                this._subscriptions['update-fv'] = this.documentService
                    .updateFinalVersion(documentId, formData)
                    .subscribe({
                        next: (success) => {
                            if (success) this.onSuccess();
                        },
                        complete: () => (this.loading = false),
                    });
            } else {
                this._subscriptions['create-fv'] = this.documentService
                    .createFinalVersion(documentId, formData)
                    .subscribe({
                        next: (success) => {
                            if (success) this.onSuccess();
                        },
                        complete: () => (this.loading = false),
                    });
            }
        }
    }

    private onSuccess() {
        const discrepancyId = this.triggeredDiscrepancy.id;
        this.snackbarService.success({
            header: `Discrepancy ${discrepancyId} is moved to “Recently Completed” and the WF document is ready to publish!`,
        });

        this.dialogRef.close();
        this.store.dispatch(refreshActionRelatedData());
    }

    protected onDocumentLoaded(vwInstance: WebViewerInstance) {
        this.vwInstance = vwInstance;
        this.showInsertAndReplace = true;
        this.listenAndLogPagesUpdates();
        this.setDestinationDocumentPageCount();
        this.insertDestinationDropdown();
        this.insertSourceDropdown();
        this.insertFVTag();

        toggleLeftPanel(vwInstance);
        removeZoomOverlayButton(vwInstance);
        hideCloseDocumentButton(vwInstance);
        insertCurrentPageNumber(vwInstance);
    }

    protected onDocumentChanged(event: { log: string }) {
        this.documentChanged = true;

        this.addLog(event.log);
        this.closeMenu();
        this.setDestinationDocumentPageCount();
    }

    protected handleTabChange(index: number) {
        const { id } = this.discrepancies[index];
        this.setDiscrepancyDetails(id);
    }

    private getDiscrepancies() {
        const documentId = this.documentDataService.currentDocument?.id;
        if (documentId) {
            this.discrepancyService
                .getDiscrepanciesByDocumentId(documentId)
                .subscribe({
                    next: (data) => {
                        this.discrepancies = data.discrepancies;
                        this.setsTabData(data.discrepancies);
                        this.setDiscrepancyDetails(data.discrepancies[0].id);
                    },
                });
        }
    }

    private setDestinationDocumentPageCount() {
        const [documentViewerLeft] = this.vwInstance.Core.getDocumentViewers();
        this.destinationDocumentPageCount = documentViewerLeft.getPageCount();
    }

    private setsTabData(discrepancies: Discrepancy[]) {
        this.tabsData = [
            ...discrepancies.map((item) => {
                const { label } = getDiscrepancyId(item);

                return { label };
            }),
        ];
    }

    private setDestinationDropdownNode() {
        const node = createDropdown();
        node.id = 'destination-dropdown';
        const { appliedVersions, finalVersion } = this.revisionDataService;

        if (finalVersion) {
            this.destinationDropdownItems = [finalVersion];
            this.destinationDropdownItems.forEach((i) => {
                node.add(new Option(i.revisionName, i.documentServiceId));
            });
            this.destinationDropdownNode = node;
            this.destinationDocument = structuredClone({
                id: `${finalVersion.documentServiceId}`,
                name: finalVersion.revisionName,
            });
        } else {
            if (appliedVersions) {
                const [first] = appliedVersions.map(
                    ({ documentServiceId, revisionName }) => ({
                        id: `${documentServiceId}`,
                        name: revisionName,
                    }),
                );

                this.destinationDropdownItems = [...(appliedVersions ?? [])];
                this.destinationDropdownItems.forEach((i) => {
                    node.add(
                        new Option(
                            i.revisionName,
                            i.documentServiceId,
                            first.id === i.documentServiceId,
                        ),
                    );
                });

                this.destinationDropdownNode = node;
                this.destinationDocument = structuredClone(first);
            }
        }
    }

    private setSourceDropdownNode() {
        const { appliedVersions, finalVersion } = this.revisionDataService;
        if (appliedVersions) {
            const node = createDropdown();
            node.id = 'source-dropdown';

            const [first, second] = appliedVersions.map(
                ({ documentServiceId, revisionName }) => ({
                    id: `${documentServiceId}`,
                    name: revisionName,
                }),
            );

            if (finalVersion || appliedVersions.length === 1) {
                this.sourceDropdownItems = [...appliedVersions];
                this.sourceDropdownItems.forEach((i) => {
                    node.add(
                        new Option(
                            i.revisionName,
                            i.documentServiceId,
                            first.id === i.documentServiceId,
                            first.id === i.documentServiceId,
                        ),
                    );
                });
                this.sourceDropdownNode = node;
                this.sourceDocument = structuredClone(first);
            } else {
                if (second) {
                    this.sourceDropdownItems = [...appliedVersions];
                    this.sourceDropdownItems.forEach((i) => {
                        node.add(
                            new Option(
                                i.revisionName,
                                i.documentServiceId,
                                second.id === i.documentServiceId,
                                second.id === i.documentServiceId,
                            ),
                        );
                    });
                    this.sourceDropdownNode = node;
                    this.sourceDocument = structuredClone(second);
                }
            }
        }
    }

    private setDiscrepancyDetails(id: number) {
        this.selectedDiscrepancyLoading = true;

        this._subscriptions['get-disc-details'] = this.discrepancyService
            .getDiscrepancyDetails(id)
            .subscribe({
                next: (response) => (this.selectedDiscrepancy = response),
                complete: () => (this.selectedDiscrepancyLoading = false),
            });
    }

    private listenAndLogPagesUpdates() {
        const [dvLeft] = this.vwInstance.Core.getDocumentViewers();
        dvLeft.addEventListener('pagesUpdated', (e: PagesUpdatedEvent) => {
            this.documentChanged = true;

            if (e.removed.length > 0) {
                this.addLog(`Page ${e.removed.join(', ')} deleted.`);
            }
            if (e.rotationChanged.length > 0) {
                this.addLog(`Page ${e.rotationChanged.join(', ')} rotated.`);
            }
        });
    }

    private insertDestinationDropdown() {
        if (this.destinationDropdownNode) {
            const instanceDocument = this.vwInstance.UI.iframeWindow.document;
            const header1 = instanceDocument.querySelector(
                '#header1 .file-name',
            );

            if (header1) {
                header1.parentNode?.replaceChild(
                    this.destinationDropdownNode,
                    header1,
                );
            }

            this.destinationDropdownNode.addEventListener(
                'change',
                (event) => {
                    this.ngZone.run(() => {
                        const target = event.target as HTMLSelectElement;
                        const data = {
                            documentName: this.destinationDocument?.name,
                            documentType: RevisionKind.FINAL_VERSION,
                        };

                        if (this.documentChanged) {
                            this.dialog
                                .open(
                                    DocumentChangeConfirmationDialogComponent,
                                    {
                                        data,
                                        maxWidth: '448px',
                                        minWidth: '200px',
                                        width: '448px',
                                    },
                                )
                                .afterClosed()
                                .subscribe({
                                    next: (confirmed) => {
                                        if (confirmed) {
                                            this.setLogs([]);
                                            this.changeAV(target.value);
                                        } else {
                                            target.value =
                                                this.destinationDocument?.id ??
                                                '';
                                        }
                                    },
                                });
                        } else {
                            this.changeAV(target.value);
                        }
                    });
                },
                { once: true },
            );
        }
    }

    private insertSourceDropdown() {
        if (this.sourceDropdownNode) {
            const instanceDocument = this.vwInstance.UI.iframeWindow.document;
            const header2 = instanceDocument.querySelector(
                '#header2 .file-name',
            );
            if (header2) {
                header2?.parentNode?.replaceChild(
                    this.sourceDropdownNode,
                    header2,
                );
            }

            this.sourceDropdownNode.addEventListener(
                'change',
                (event) => {
                    this.ngZone.run(() => {
                        this.closeMenu();
                        const id = (event.target as HTMLInputElement).value;
                        const name =
                            this.destinationDropdownItems.find(
                                (i) => i.documentServiceId === id,
                            )?.revisionName ?? '';

                        this.sourceDocument = { id, name };
                    });
                },
                { once: true },
            );
        }
    }

    private insertFVTag() {
        const instanceDocument = this.vwInstance.UI.iframeWindow.document;
        const tagId = 'av-indicator';
        const srcDocEl: HTMLElement | null =
            instanceDocument.querySelector('#container1');
        const avIndicatorEl: HTMLElement | null =
            instanceDocument.querySelector(`#${tagId}`);

        if (srcDocEl && !avIndicatorEl) {
            srcDocEl.style.cssText = `
                border-color: #88C545; 
            `;
            const node = createVersionTag(tagId, 'Final Version');
            const div = instanceDocument.querySelector(
                '#header1 .control-buttons',
            );
            div?.insertAdjacentElement('beforebegin', node);
        }
    }

    private changeAV(id: string) {
        this.closeMenu();
        this.documentChanged = false;

        const name =
            this.destinationDropdownItems.find(
                (i) => i.documentServiceId === id,
            )?.revisionName ?? '';

        this.destinationDocument = { id, name };
    }

    private closeMenu() {
        this.insertTrigger?.closeMenu();
        this.replaceTrigger?.closeMenu();
    }

    private addLog(log: string) {
        this.setLogs([...this.logs, log]);
    }

    private setLogs(logs: string[]) {
        this.logs = [...logs];
        this.combinedLogs.next([...this.savedLogs, ...this.logs]);
    }
}
