<div class="pagination flex items-center pos-rel color-gray9 w-fit">
    <span class="text-sm">Rows per page</span>
    <app-text-button
        #tableViewToggle
        [matMenuTriggerFor]="pageSizeMenu"
        (menuOpened)="menuOpened = true"
        (menuClosed)="menuOpened = false">
        <span class="color-primary">{{ pageSize }}</span>
        <mat-icon
            [svgIcon]="menuOpened ? 'chevron-up' : 'chevron-down'"
            class="color-gray9" />
    </app-text-button>
    <mat-menu #pageSizeMenu="matMenu">
        @for (size of paginationPageSizeSelector; track $index) {
            <div
                tabindex="0"
                class="mat-mdc-option"
                (click)="onPageChange(size)"
                (keyup.enter)="onPageChange(size)">
                <span [class.color-primary]="pageSize === size">
                    {{ size }}
                </span>
            </div>
        }
    </mat-menu>
</div>
