import {
    Component,
    inject,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { WebViewerInstance } from '@pdftron/webviewer';

import { RevisionDataService } from '@services/data/revision-data-service';
import { SnackbarService } from '@services/snackbar.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { DocumentService } from '@services/api/document/document.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import {
    WebViewerComponent,
    WebViewerDocument,
} from '@shared/webviewer/webviewer.component';
import { DocumentChangeConfirmationDialogComponent } from '@dialogs/document-change-confirmation-dialog/document-change-confirmation-dialog.component';
import { InsertMenuContentComponent } from './document-manipulation-menu/insert-menu-content.component';
import { ReplaceMenuContentComponent } from './document-manipulation-menu/replace-menu-content.component';
import { DiscrepancySummaryComponent } from '../../discrepancy-summary/discrepancy-summary.component';
import { RevisionDetails } from '@models/revision/revision-detail';
import {
    DiscrepancyDetail,
    DiscrepancyRevisionItem,
} from '@models/discrepancy';
import {
    DocumentRevisionStatus,
    DocumentRevisionVersion,
} from '@models/document';
import { RevisionKind } from '@models/revision/revision-kind';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { ResizableDirective } from '@directives/resizable.directive';
import { MenuCenterDirective } from '@directives/center-mat-menu.directive';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    createDropdown,
    createVersionTag,
    getUpdatedDocument,
    hideCloseDocumentButton,
    insertCurrentPageNumber,
    PagesUpdatedEvent,
    removeZoomOverlayButton,
    toggleLeftPanel,
} from '@helpers/webviewer';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'apply-correction-dialog.component.html',
    styleUrl: 'apply-correction-dialog.component.scss',
    imports: [
        MatMenuModule,
        MatRadioModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        FlatButtonComponent,
        LoaderComponent,
        WebViewerComponent,
        DiscrepancySummaryComponent,
        ResizableDirective,
        InsertMenuContentComponent,
        ReplaceMenuContentComponent,
        MenuCenterDirective,
        AsyncPipe,
    ],
})
export class ApplyCorrectionDialogComponent implements OnInit, OnDestroy {
    @ViewChild('insertTrigger') insertTrigger: MatMenuTrigger | undefined;
    @ViewChild('replaceTrigger') replaceTrigger: MatMenuTrigger | undefined;
    discrepancy!: number;
    discDetail = DiscrepancyDetail.initial();
    discDetailLoading: boolean = false;
    saveLoading: boolean = false;
    sourceDocument: WebViewerDocument | null = null;
    destinationDocument: WebViewerDocument | null = null;
    vwInstance!: WebViewerInstance;
    documentChanged = false;
    destinationDocumentPageCount: number = 0;
    showInsertAndReplace = false;
    private logs: string[] = [];
    savedLogs: string[] = [];
    originalDocument: DocumentRevisionVersion | null = null;
    discrepancyRevisions: DiscrepancyRevisionItem[] = [];
    private sourceDropdownItems: DiscrepancyRevisionItem[] = [];
    private destinationDropdownItems: DocumentRevisionVersion[] = [];
    private destinationDropdownNode: HTMLSelectElement | null = null;
    private sourceDropdownNode: HTMLSelectElement | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;
    readonly elementsToBeDisabled = [
        'insertPage',
        'replacePage',
        'leftPanelTabs',
        'fullScreenButton',
    ];

    combinedLogs = new BehaviorSubject<string[]>([]);
    combinedLogs$ = this.combinedLogs.asObservable();
    closeResolveVisible: boolean = false;

    readonly data: {
        selectedRevision: RevisionDetails;
        discrepancy: number;
    } = inject(MAT_DIALOG_DATA);
    private store = inject(Store);
    private ngZone = inject(NgZone);
    private dialog = inject(MatDialog);
    private revisionService = inject(RevisionService);
    private documentService = inject(DocumentService);
    private snackbarService = inject(SnackbarService);
    private discrepancyService = inject(DiscrepancyService);
    private documentDataService = inject(DocumentDataService);
    private revisionDataService = inject(RevisionDataService);
    private dialogRef = inject(MatDialogRef<ApplyCorrectionDialogComponent>);

    constructor() {
        this.discrepancy = this.data.discrepancy;
    }

    ngOnInit(): void {
        this.setDiscrepancyDetails();
        this.setDestinationDocument();
        this.setSavedLogs();
        this.getDiscrepancyRevisions();
        this.setCloseResolveVisibility();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    get discrepancyNo() {
        const { discrepancies } = this.data.selectedRevision!;
        return discrepancies.find((disc) => disc.id === this.discrepancy)
            ?.discrepancyNo;
    }

    get logsTitle() {
        return `Changes made on document ${this.originalDocument?.revisionName}`;
    }

    isAppliedVersion() {
        const { selectedRevision } = this.data;
        const { appliedVersions = [] } = this.revisionDataService;

        return appliedVersions.some((i) => i.id === selectedRevision.id);
    }

    protected discard() {
        this.dialogRef.close();
    }

    protected closeResolveClicked() {
        this.save(true);
    }

    protected closeResolve() {
        const { selectedRevision } = this.data;
        const { id } = selectedRevision;
        this.showLoading();

        this._subscriptions['close-resolve-disc'] = this.discrepancyService
            .closeResolveDiscrepancy(this.discrepancy)
            .subscribe({
                next: (success) => {
                    this.saveLoading = false;
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.discrepancyNo} is Close-Resolved and moved to "Recently Completed"!`,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                        this.revisionDataService.updateSelectedRevisionId(id);
                    }
                },
            });
    }

    protected async save(closeResolve: boolean = false) {
        this.showLoading();

        if (this.isAppliedVersion()) this.updateAV(closeResolve);
        else this.createAV(closeResolve);
    }

    protected onDocumentChanged(event: { log: string }) {
        this.documentChanged = true;

        this.addLog(event.log);
        this.closeMenu();
        this.setDestinationDocumentPageCount();
    }

    protected onDocumentLoaded(vwInstance: WebViewerInstance) {
        this.vwInstance = vwInstance;
        this.showInsertAndReplace = true;
        this.listenAndLogPagesUpdates();
        this.setDestinationDocumentPageCount();
        this.insertDestinationDropdown();
        this.insertSourceDropdown();
        this.insertAVTag();

        toggleLeftPanel(vwInstance);
        removeZoomOverlayButton(vwInstance);
        hideCloseDocumentButton(vwInstance);
        insertCurrentPageNumber(vwInstance);
    }

    private insertAVTag() {
        const instanceDocument = this.vwInstance.UI.iframeWindow.document;

        const tagId = 'av-indicator';
        const srcDocEl: HTMLElement | null =
            instanceDocument.querySelector('#container1');
        const avIndicatorEl: HTMLElement | null =
            instanceDocument.querySelector(`#${tagId}`);

        if (srcDocEl && !avIndicatorEl) {
            srcDocEl.style.cssText = `
                border-color: #88C545; 
            `;
            const node = createVersionTag(
                tagId,
                `Applied Version ${this.discrepancyNo}`,
            );
            const div = instanceDocument.querySelector(
                '#header1 .control-buttons',
            );
            div?.insertAdjacentElement('beforebegin', node);
        }
    }

    private insertDestinationDropdown() {
        if (this.destinationDropdownNode) {
            const instanceDocument = this.vwInstance.UI.iframeWindow.document;
            const header1 = instanceDocument.querySelector(
                '#header1 .file-name',
            );

            if (header1) {
                header1.parentNode?.replaceChild(
                    this.destinationDropdownNode,
                    header1,
                );
            }

            this.destinationDropdownNode.addEventListener(
                'change',
                (event) => {
                    this.ngZone.run(() => {
                        const target = event.target as HTMLSelectElement;

                        if (this.documentChanged) {
                            this.dialog
                                .open(
                                    DocumentChangeConfirmationDialogComponent,
                                    {
                                        data: {
                                            documentName:
                                                this.destinationDocument?.name,
                                            documentType:
                                                RevisionKind.APPLIED_VERSION,
                                        },
                                        maxWidth: '448px',
                                        minWidth: '200px',
                                        width: '448px',
                                    },
                                )
                                .afterClosed()
                                .subscribe({
                                    next: (confirmed) => {
                                        if (confirmed) {
                                            this.setLogs([]);
                                            this.changeAV(target.value);
                                        } else {
                                            target.value =
                                                this.destinationDocument?.id ??
                                                '';
                                        }
                                    },
                                });
                        } else {
                            this.changeAV(target.value);
                        }
                    });
                },
                { once: true },
            );
        }
    }

    private changeAV(id: string) {
        this.closeMenu();
        this.documentChanged = false;

        const name =
            this.destinationDropdownItems.find(
                (i) => i.documentServiceId === id,
            )?.revisionName ?? '';

        this.destinationDocument = { id, name };
    }

    private insertSourceDropdown() {
        if (this.sourceDropdownNode) {
            const instanceDocument = this.vwInstance.UI.iframeWindow.document;
            const header2 = instanceDocument.querySelector(
                '#header2 .file-name',
            );
            if (header2) {
                header2?.parentNode?.replaceChild(
                    this.sourceDropdownNode,
                    header2,
                );
            }

            this.sourceDropdownNode.addEventListener(
                'change',
                (event) => {
                    this.ngZone.run(() => {
                        this.closeMenu();
                        const id = (event.target as HTMLInputElement).value;
                        const name =
                            this.sourceDropdownItems.find(
                                (i) => i.documentServiceId === id,
                            )?.name ?? '';

                        this.sourceDocument = { id, name };
                    });
                },
                { once: true },
            );
        }
    }

    private listenAndLogPagesUpdates() {
        const [dvLeft] = this.vwInstance.Core.getDocumentViewers();
        dvLeft.addEventListener('pagesUpdated', (e: PagesUpdatedEvent) => {
            this.documentChanged = true;

            if (e.removed.length > 0) {
                this.addLog(`Page ${e.removed.join(', ')} deleted.`);
            }
            if (e.rotationChanged.length > 0) {
                this.addLog(`Page ${e.rotationChanged.join(', ')} rotated.`);
            }
        });
    }

    private setLogs(logs: string[]) {
        this.logs = [...logs];
        this.combinedLogs.next([...this.savedLogs, ...this.logs]);
    }

    private addLog(log: string) {
        this.setLogs([...this.logs, log]);
    }

    private showLoading() {
        this.saveLoading = true;
        this.closeMenu();
    }

    private closeMenu() {
        this.insertTrigger?.closeMenu();
        this.replaceTrigger?.closeMenu();
    }

    private setDestinationDocument() {
        const { appliedVersions = [], originalDocument } =
            this.revisionDataService;
        this.originalDocument = originalDocument ?? null;

        if (appliedVersions.length > 0) {
            this.setDestinationDropdownNode();
            this.setLatestAVAsDestinationDocument();
        } else {
            this.setOriginalAsDestinationDocument();
        }
    }

    private setDestinationDropdownNode() {
        const { appliedVersions } = this.revisionDataService;
        const node = createDropdown();
        node.id = 'destination-dropdown';

        this.destinationDropdownItems = [
            ...(appliedVersions ?? []),
            this.originalDocument!,
        ];

        this.destinationDropdownItems.forEach((i) => {
            node.add(new Option(i.revisionName, i.documentServiceId));
        });
        this.destinationDropdownNode = node;
    }

    private setLatestAVAsDestinationDocument() {
        const { appliedVersions = [] } = this.revisionDataService;

        if (appliedVersions.length > 0) {
            const latestAV = appliedVersions[0];
            this.destinationDocument = {
                id: `${latestAV.documentServiceId}`,
                name: latestAV.revisionName ?? '',
            };
        }
    }

    private setSourceDropdownNode() {
        const correctedVersions = this.discrepancyRevisions.filter(
            (i) =>
                i.kind === RevisionKind.CORRECTION &&
                i.relationKind === DocumentRevisionStatus.ACCEPTED,
        );
        const node = createDropdown();
        node.id = 'source-dropdown';

        this.sourceDropdownItems = [...correctedVersions];
        this.sourceDropdownItems.forEach((i) => {
            node.add(new Option(i.name, i.documentServiceId));
        });
        this.sourceDropdownNode = node;
    }

    private setSavedLogs() {
        this._subscriptions['revision-logs'] = this.revisionService
            .getRevisionLogs(this.data.selectedRevision.id)
            .subscribe({
                next: (logs) => {
                    this.savedLogs = logs;
                    this.combinedLogs.next([...logs]);
                },
            });
    }

    private async updateAV(closeResolve: boolean) {
        const documentId = this.documentDataService.currentDocument?.id;

        if (documentId) {
            const { selectedRevision } = this.data;
            const { id } = selectedRevision;
            const document = await getUpdatedDocument(this.vwInstance);
            const formData = new FormData();
            formData.append('discrepancy', this.discrepancy.toString());
            formData.append('file', document.blob, document.name);
            this.logs.forEach((i) => formData.append('logs', i));

            this._subscriptions['update-av'] = this.documentService
                .updateAppliedVersion(documentId, formData, id)
                .subscribe({
                    next: (success) => {
                        this.saveLoading = false;
                        if (success) {
                            this.snackbarService.success({
                                variant: 'success',
                                header: `Applied version of Discrepancy ${this.discrepancyNo} is updated!`,
                            });
                            if (closeResolve) {
                                this.closeResolve();
                            } else {
                                this.dialogRef.close();
                                this.store.dispatch(refreshActionRelatedData());
                                this.revisionDataService.updateSelectedRevisionId(
                                    id,
                                );
                            }
                        }
                    },
                });
        }
    }

    private async createAV(closeResolve: boolean) {
        const documentId = this.documentDataService.currentDocument?.id;

        if (documentId) {
            const document = await getUpdatedDocument(this.vwInstance);
            const { blob } = document;
            const formData = new FormData();
            const newDocumentName = `${this.originalDocument!.revisionName}_AV_${this.discrepancyNo}`;
            formData.append('discrepancy', this.discrepancy.toString());
            formData.append('file', blob, newDocumentName);
            this.logs.forEach((i) => formData.append('logs', i));

            this._subscriptions['create-av'] = this.documentService
                .createAppliedVersion(documentId, formData)
                .subscribe({
                    next: (success) => {
                        this.saveLoading = false;
                        if (success) {
                            this.snackbarService.success({
                                variant: 'success',
                                header: `Applied version is created for Discrepancy ${this.discrepancyNo}!`,
                            });
                            if (closeResolve) {
                                this.closeResolve();
                            } else {
                                this.dialogRef.close();
                                this.store.dispatch(refreshActionRelatedData());
                            }
                        }
                    },
                });
        }
    }

    private setDestinationDocumentPageCount() {
        const [documentViewerLeft] = this.vwInstance.Core.getDocumentViewers();
        this.destinationDocumentPageCount = documentViewerLeft.getPageCount();
    }

    private setDiscrepancyDetails() {
        this.discDetailLoading = true;

        this._subscriptions['get-disc-details'] = this.discrepancyService
            .getDiscrepancyDetails(this.discrepancy)
            .subscribe({
                next: (response) => (this.discDetail = response),
                complete: () => (this.discDetailLoading = false),
            });
    }

    private setOriginalAsDestinationDocument() {
        if (this.originalDocument) {
            this.destinationDocument = {
                id: `${this.originalDocument.documentServiceId}`,
                name: this.originalDocument.revisionName ?? '',
            };
        }
    }

    private getDiscrepancyRevisions() {
        this._subscriptions['get-discrepancy-revisions'] =
            this.discrepancyService
                .getDiscrepancyRevisions(this.discrepancy)
                .subscribe({
                    next: (response) => {
                        if (response) {
                            this.discrepancyRevisions = response.revisions;
                            const correctedVersions = response.revisions.filter(
                                (i) =>
                                    i.kind === RevisionKind.CORRECTION &&
                                    i.relationKind ===
                                        DocumentRevisionStatus.ACCEPTED,
                            );

                            if (correctedVersions.length > 1) {
                                this.setSourceDropdownNode();
                            }
                            this.setSourceDocument(correctedVersions[0]);
                        }
                    },
                });
    }

    private setCloseResolveVisibility() {
        const documentId = this.documentDataService.currentDocument?.id;

        if (documentId) {
            this._subscriptions['get-resolution-decision'] =
                this.documentService
                    .getResolutionDecision(documentId, {
                        discrepancy: this.discrepancy,
                    })
                    .subscribe({
                        next: (data) => {
                            if (data) {
                                this.closeResolveVisible =
                                    !data.isLastOpenDiscrepancy;
                            }
                        },
                    });
        }
    }

    private setSourceDocument(cv: DiscrepancyRevisionItem) {
        this.sourceDocument = {
            id: `${cv.documentServiceId}`,
            name: cv.name ?? '',
        };
    }
}
