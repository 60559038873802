import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { InternalsTabComponent } from 'app/components/documents-and-discrepancies/internals-tab/internals-tab.component';

@Component({
    selector: 'app-internals-page',
    standalone: true,
    imports: [InternalsTabComponent],
    template: `<app-internals-tab [documentId]="documentId" />`,
})
export class InternalsPageComponent implements OnInit {
    documentId!: number;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        const id = this.route.parent?.snapshot.paramMap.get('id');
        if (id) {
            this.documentId = parseInt(id);
        }
    }
}
