import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'unsaved-changes-dialog.component.html',
    imports: [FlatButtonComponent],
})
export class UnsavedChangesDialogComponent {
    readonly dataQa = DATA_QA;
    unsavedChangeArea: string | null = null;
    action = 'leaving';

    private readonly data: {
        unsavedChangeArea: string;
        action: string | undefined;
    } = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<UnsavedChangesDialogComponent>);

    constructor() {
        this.unsavedChangeArea = this.data.unsavedChangeArea;
        this.action = this.data.action ?? this.action;
    }

    onReturnToPage() {
        this.dialogRef.close({ leave: false });
    }

    onDiscardChanges() {
        this.dialogRef.close({ leave: true });
    }

    onSaveChanges() {
        this.dialogRef.close({ leave: false, save: true });
    }
}
