import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { UpdateDiscrepancyRequest } from '@models/discrepancy/update-discrepancy-request';
import {
    ActivityLogItem,
    DiscrepanciesWithTotal,
    DiscrepanciesWithTotalResponse,
    Discrepancy,
    DiscrepancyDetail,
    DiscrepancyEditOptions,
    DiscrepancyEditOptionsResponse,
    DiscrepancyFilterOptions,
    DiscrepancyFilterOptionsResponse,
    DiscrepancyResponse,
    StatusChipItem,
} from '@models/discrepancy';
import { PagedResponse, SimpleResponse } from '@models/api.model';
import { DiscrepancyMapper } from './discrepancy.mapper';
import { DiscrepancyColumn } from '@constants/table';
import { ColumnOption, ExportDocumentExcelQuery } from '@models/shared.model';
import { interpolate } from '@helpers/index';
import { toggleItem } from '@helpers/array';

@Injectable({
    providedIn: 'root',
})
export class DiscrepancyService {
    private readonly _path = 'workflow/v1';
    private discColumnOptions = new BehaviorSubject<{
        lastToggled: ColumnOption<DiscrepancyColumn> | null;
        hiddenColumns: DiscrepancyColumn[];
    }>({ lastToggled: null, hiddenColumns: [] });

    discColumnOptions$ = this.discColumnOptions.asObservable();

    constructor(
        private api: ApiService,
        private mapper: DiscrepancyMapper,
    ) {}

    updateDiscColumnOptions(option: any) {
        const currentOptions = this.discColumnOptions.value;

        this.discColumnOptions.next({
            lastToggled: option,
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }

    getAllDiscrepancies(params = {}) {
        return this.api
            .get<
                Discrepancy[]
            >(this._path + endpoints.discrepancies, params, true)
            .pipe(
                map((response) => {
                    const pagedResponse =
                        response as PagedResponse<DiscrepancyResponse>;
                    return new PagedResponse<Discrepancy>(
                        pagedResponse.pageIndex,
                        pagedResponse.pageSize,
                        pagedResponse.totalItems,
                        pagedResponse.items.map(this.mapper.toDiscrepancy),
                    );
                }),
            );
    }

    getDiscrepanciesByDocumentId(
        id: number,
        params = {},
    ): Observable<DiscrepanciesWithTotal> {
        const url =
            this._path + interpolate(endpoints.documentsDiscrepancies, { id });

        return this.api.get<Discrepancy[]>(url, params).pipe(
            map((response: SimpleResponse<DiscrepanciesWithTotalResponse>) => {
                return this.mapper.toDiscrepanciesWithTotal(response.data);
            }),
            catchError(() => of(new DiscrepanciesWithTotal([], 0))),
        );
    }

    getDiscrepancyDetails(id: number): Observable<DiscrepancyDetail> {
        return this.api
            .get(this._path + interpolate(endpoints.discrepancyDetails, { id }))
            .pipe(
                map((response: SimpleResponse<DiscrepancyDetail>) =>
                    this.mapper.toDiscrepancyDetail(response.data),
                ),
                catchError(() => of(DiscrepancyDetail.initial())),
            );
    }

    addDiscrepancyToDocument(
        id: number,
        data: UpdateDiscrepancyRequest,
    ): Observable<DiscrepancyDetail | null> {
        return this.api
            .post(
                this._path +
                    interpolate(endpoints.documentsDiscrepancies, { id }),
                data,
            )

            .pipe(
                map((response: SimpleResponse<DiscrepancyDetail>) =>
                    this.mapper.toDiscrepancyDetail(response.data),
                ),
                catchError(() => of(null)),
            );
    }

    editDiscrepancy(
        id: number,
        data: UpdateDiscrepancyRequest,
    ): Observable<boolean> {
        return this.api
            .put(
                this._path + interpolate(endpoints.discrepancyById, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    notifyDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.notifyDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    renotifyDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.renotifyDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    cancelDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.cancelDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    recallDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.recallDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    discardDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.discardDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    takeDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.takeDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    putbackDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.putbackDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    assignDiscrepancy(
        id: number,
        data: { userId: number },
    ): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.assignDiscrepancy, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    unassignDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.unassignDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    getDiscrepancyActivityLogs(
        id: number,
        params = {},
    ): Observable<ActivityLogItem[]> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.discrepancyActivityLogs, { id }),
                params,
            )
            .pipe(
                map((response: SimpleResponse<ActivityLogItem[]>) =>
                    (response.data ?? []).map(this.mapper.toActivityLogItem),
                ),
                catchError(() => of([])),
            );
    }

    getDiscrepanciesFilterOptions(
        params:
            | {
                  workflow: number;
                  repository: number[];
              }
            | undefined,
    ) {
        return this.api
            .get(this._path + endpoints.discrepanciesFilterOptions, params)
            .pipe(
                map(
                    (
                        response: SimpleResponse<DiscrepancyFilterOptionsResponse>,
                    ) => this.mapper.toDiscrepancyFilterOptions(response.data),
                ),
                catchError(() => of(DiscrepancyFilterOptions.initial())),
            );
    }

    getDiscrepancyEditOptions(id: number): Observable<DiscrepancyEditOptions> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.discrepancyEditOptions, { id }),
            )
            .pipe(
                map(
                    (
                        response: SimpleResponse<DiscrepancyEditOptionsResponse>,
                    ) => this.mapper.toDiscrepancyEditOptions(response.data),
                ),
                catchError(() => of(DiscrepancyEditOptions.initial())),
            );
    }

    getDiscrepanciesStatusChips(
        params: { workflow: number; repository: any } | undefined,
    ): Observable<StatusChipItem[]> {
        return this.api
            .get(this._path + endpoints.discrepancyStatusChips, params)
            .pipe(
                map((response: SimpleResponse<StatusChipItem[]>) =>
                    (response.data ?? []).map(this.mapper.toStatusChipItem),
                ),
                catchError(() => of([])),
            );
    }

    export(query: ExportDocumentExcelQuery) {
        return this.api.getBlob(
            this._path + endpoints.discrepancyExport,
            query,
        );
    }
}
