import { Component, Input } from '@angular/core';
import {
    MatProgressBarModule,
    ProgressBarMode,
} from '@angular/material/progress-bar';

@Component({
    selector: 'app-progress-bar',
    standalone: true,
    templateUrl: 'progress-bar.component.html',
    styleUrl: 'progress-bar.component.scss',
    imports: [MatProgressBarModule],
})
export class ProgressBarComponent {
    @Input() progress = 0;
    @Input() mode: ProgressBarMode = 'determinate';
    @Input() variant: 'primary' | 'secondary' = 'primary';
}
