import { inject, Injectable } from '@angular/core';

import {
    ActivityLogItem,
    DiscrepancyFilterOptionsResponse,
    DiscrepancyFilterOptions,
    DiscrepancyEditOptionsResponse,
    DiscrepancyEditOptions,
    StatusChipItem,
    DiscrepanciesWithTotal,
    DiscrepanciesWithTotalResponse,
    Discrepancy,
    DiscrepancyResponse,
    DiscrepancyDetail,
    DiscrepancyRevisionItem,
    DiscrepancyRevisions,
    DiscrepancyFixers,
    DiscrepancyFixersResponse,
} from '@models/discrepancy';
import { SharedMapper } from '../shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class DiscrepancyMapper {
    private sharedMapper = inject(SharedMapper);

    constructor() {}

    toDiscrepancy = (item: DiscrepancyResponse) => {
        return new Discrepancy(
            item.id,
            item.reason?.displayName ?? '-',
            item.priority,
            item.status,
            this.sharedMapper.toLocalTime(item.createdAt),
            this.sharedMapper.toLocalTime(item.updatedAt),
            item.taken ? this.sharedMapper.toLocalTime(item.taken) : '-',
            item.analyst?.displayName ?? '-',
            item.station?.displayName ?? '-',
            item.primaryFixer ?? '-',
            item.isDraft,
            item.documentName ?? '-',
            item.documentId,
            item.discrepancyNo,
            item.age ?? null,
        );
    };

    toDiscrepancyDetail = (item: DiscrepancyDetail): DiscrepancyDetail =>
        new DiscrepancyDetail(
            item.id,
            item?.pageRange || '',
            item?.directions || '',
            this.sharedMapper.toLocalTime(item.createdAt),
            item.reason ? this.sharedMapper.toIdDisplayPair(item.reason) : null,
            this.sharedMapper.toPriority(item.priority),
            item?.status || '-',
            item.analyst
                ? this.sharedMapper.toIdDisplayPair(item.analyst)
                : null,
            item.station
                ? this.sharedMapper.toIdDisplayPair(item.station)
                : null,
            item.primaryFixer,
            item.documentServiceId,
            item?.actions ?? [],
            item?.revision ?? null,
            item.discrepancyNo,
            item?.isDraft ?? false,
            item?.pageRangeFileSize ?? null,
            item?.notifyWithOnlySelectedPages ?? false,
            item?.resolutionState ?? null,
            item?.currentState ?? null,
        );

    toDiscrepanciesWithTotal = (
        item: DiscrepanciesWithTotalResponse,
    ): DiscrepanciesWithTotal =>
        new DiscrepanciesWithTotal(
            item.discrepancies?.map(this.toDiscrepancy) ?? [],
            item.totalCount,
        );

    toActivityLogItem = (item: ActivityLogItem): ActivityLogItem =>
        new ActivityLogItem(
            item?.id,
            item?.user || '-',
            item?.logMessage || '-',
            item?.createdAt || '-',
        );

    toDiscrepancyFilterOptions = (
        item: DiscrepancyFilterOptionsResponse,
    ): DiscrepancyFilterOptions =>
        new DiscrepancyFilterOptions(
            (item?.priority || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.reason || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.status || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.station || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.primaryFixer || [])?.map(this.sharedMapper.toSelectOption) ??
                [],
            item?.maxAge ?? null,
        );

    toDiscrepancyEditOptions = (item: DiscrepancyEditOptionsResponse) =>
        new DiscrepancyEditOptions(
            item.priority.map(this.sharedMapper.toSelectOption),
            item.reason.map(this.sharedMapper.toSelectOption),
            item.station.map(this.sharedMapper.toSelectOption),
            item.fixer.map(this.sharedMapper.toSelectOption),
            item.canEdit,
            item.analyst?.map(this.sharedMapper.toSelectOption) ?? [],
            item.revision.map(this.sharedMapper.toSelectOption) ?? [],
        );

    toStatusChipItem = (item: StatusChipItem): StatusChipItem =>
        new StatusChipItem(
            item?.assigned ?? 0,
            item?.backingField ?? -1,
            item?.name ?? '',
            item?.total ?? 0,
            item?.unassigned ?? 0,
        );

    toDiscrepancyRevisions = (
        item: DiscrepancyRevisions,
    ): DiscrepancyRevisions =>
        new DiscrepancyRevisions(
            item?.id ?? -1,
            item.revisions.map(this.toDiscrepancyRevisionItem),
        );

    toDiscrepancyRevisionItem = (
        item: DiscrepancyRevisionItem,
    ): DiscrepancyRevisionItem =>
        new DiscrepancyRevisionItem(
            item?.id,
            item?.kind,
            item?.relationKind,
            item?.name ?? '',
            item?.documentServiceId ?? '',
        );

    toDiscrepancyFixers = (
        item: DiscrepancyFixersResponse,
    ): DiscrepancyFixers =>
        new DiscrepancyFixers(
            item.primaryFixer ?? null,
            item.secondaryFixers ?? [],
        );
}
