<button
    mat-flat-button
    [disabled]="disabled"
    [attr.data-qa]="dataQa"
    [ngClass]="{
        btn: true,
        'btn--default': variant === 'default',
        'btn--primary': variant === 'primary',
        'btn--tertiary': variant === 'tertiary',
        'btn--cta': variant === 'cta',
        'btn--danger': variant === 'danger',
        'btn--success': variant === 'success',
        'btn--neon-blue': variant === 'neon-blue',
        'btn--solid': type === 'solid',
        disabled: disabled,
    }"
    (click)="onClick()"
    (keyup.enter)="onKeyup()">
    <mat-icon *ngIf="iconVariant" [svgIcon]="iconVariant" />
    <ng-content />
</button>
