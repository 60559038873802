@if (document) {
    <app-web-viewer
        [document]="document"
        [elementsToBeDisabled]="elementsToBeDisabled"
        (documentLoaded)="onDocumentLoaded($event)">
        <div
            header
            class="header text-sm flex gap-10 items-center justify-space-between">
            <div>
                <p class="flex items-center text-semi">
                    <mat-icon
                        svgIcon="arrow-right"
                        class="color-primary-default mr-1" />
                    {{ revisionDetails.name }}
                    @if (statuses) {
                        <app-tooltip class="ml-1">
                            <mat-icon trigger svgIcon="info" />
                            <div content class="grid gap-5 text-s">
                                @for (item of getKeys(statuses); track $index) {
                                    @if (statuses[item].show) {
                                        <p
                                            class="flex items-end"
                                            [class]="statuses[item].textClass">
                                            <mat-icon
                                                [svgIcon]="
                                                    statuses[item].icon
                                                " />
                                            <span class="ml-2">
                                                {{ statuses[item].text }}
                                            </span>
                                        </p>
                                    }
                                }
                            </div>
                        </app-tooltip>
                    }
                </p>
                @if (appliedDiscrepancies.length > 0) {
                    <p class="flex items-center color-yellow-green8 ml-6">
                        <mat-icon svgIcon="done-all" [style.width]="'20px'" />
                        <span class="text-s ml-1">
                            Discrepancy {{ appliedDiscrepancies }} Accepted and
                            Applied
                        </span>
                    </p>
                }
            </div>
            <div class="flex gap-6">
                @if (canApply()) {
                    <app-flat-button
                        variant="success"
                        type="solid"
                        [dataQa]="dataQa.documentPreviewApplyButton"
                        (clicked)="onApplyClicked()">
                        Apply for {{ selectedDiscNo }}
                    </app-flat-button>
                }
                @if (canReject()) {
                    <app-flat-button
                        variant="danger"
                        [dataQa]="dataQa.documentPreviewRejectButton"
                        (clicked)="onRejectClick()">
                        Reject for {{ selectedDiscNo }}
                    </app-flat-button>
                }
                @if (canAccept()) {
                    <app-flat-button
                        variant="success"
                        [dataQa]="dataQa.documentPreviewAcceptButton"
                        (clicked)="onAcceptClick()">
                        Accept for {{ selectedDiscNo }}
                    </app-flat-button>
                }
            </div>
        </div>
    </app-web-viewer>
}

@if (loading) {
    <app-loader type="overlay" />
}
