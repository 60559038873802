import { DocumentRevisionStatus } from '@models/document';

export enum TableView {
    Document = 'document',
    Discrepancy = 'discrepancy',
}

export enum DocumentColumn {
    NAME = 'name',
    DOC_TYPE = 'documentType',
    PRIORITY = 'priority',
    REPOSITORY = 'repository',
    AGE_IN_WF = 'age',
    WF_ENTER_DATE = 'createdAt',
    DISCREPANCY_COUNT = 'discrepanciesCount',
    STATION = 'station',
}

export const DocumentViewColumnOptions = [
    {
        id: DocumentColumn.NAME,
        label: 'WF Document Name',
        disabled: true,
        checked: true,
    },
    {
        id: DocumentColumn.DOC_TYPE,
        label: 'Document Type',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentColumn.PRIORITY,
        label: 'Doc Priority',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentColumn.REPOSITORY,
        label: 'Repository',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentColumn.AGE_IN_WF,
        label: 'Doc Age in WF',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentColumn.WF_ENTER_DATE,
        label: 'WF Enter Date',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentColumn.DISCREPANCY_COUNT,
        label: '# Discrepancies',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentColumn.STATION,
        label: 'Station',
        disabled: false,
        checked: true,
    },
];

export enum DocumentNestedColumn {
    ID = 'discrepancyNo',
    REASON = 'reason',
    PRIORITY = 'priority',
    STATUS = 'status',
    AGE_IN_WF = 'age',
    TAKEN = 'taken',
    ANALYST = 'analyst',
    STATION = 'station',
    RESPONSIBLE_FIXER = 'responsibleFixer',
}

export const DocumentViewNestedColumnOptions = [
    {
        id: DocumentNestedColumn.ID,
        label: 'Discrepancy ID',
        disabled: true,
        checked: true,
    },
    {
        id: DocumentNestedColumn.REASON,
        label: 'Reason',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.PRIORITY,
        label: 'Discrepancy Priority',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.STATUS,
        label: 'Status',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.AGE_IN_WF,
        label: 'Discrepancy Age in WF',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.TAKEN,
        label: 'Taken',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.ANALYST,
        label: 'Analyst',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.STATION,
        label: 'Station',
        disabled: false,
        checked: true,
    },
    {
        id: DocumentNestedColumn.RESPONSIBLE_FIXER,
        label: 'Responsible Fixer',
        disabled: false,
        checked: true,
    },
];

export enum DiscrepancyColumn {
    ID = 'discrepancyNo',
    DOC_NAME = 'documentName',
    REASON = 'reason',
    PRIORITY = 'priority',
    STATUS = 'status',
    AGE_IN_WF = 'age',
    TAKEN = 'taken',
    ANALYST = 'analyst',
    STATION = 'station',
    RESPONSIBLE_FIXER = 'responsibleFixer',
}

export const DiscrepancyViewColumnOptions = [
    {
        id: DiscrepancyColumn.DOC_NAME,
        label: 'WF Document Name',
        disabled: true,
        checked: true,
        group: 'doc',
    },
    {
        id: DiscrepancyColumn.ID,
        label: 'Discrepancy ID',
        disabled: true,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.REASON,
        label: 'Reason',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.PRIORITY,
        label: 'Discrepancy Priority',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.STATUS,
        label: 'Status',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.AGE_IN_WF,
        label: 'Discrepancy Age in WF',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.TAKEN,
        label: 'Taken',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.ANALYST,
        label: 'Analyst',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.STATION,
        label: 'Station',
        disabled: false,
        checked: true,
        group: 'disc',
    },
    {
        id: DiscrepancyColumn.RESPONSIBLE_FIXER,
        label: 'Responsible Fixer',
        disabled: false,
        checked: true,
        group: 'disc',
    },
];

export interface StatusBarDiscrepancyData {
    documentId: number;
    filtered: number;
    total: number;
}

export const documentRevisinStatusMap = {
    [DocumentRevisionStatus.ORIGIN]: { icon: 'flag-circle', label: 'Created' },
    [DocumentRevisionStatus.NO_ACTION]: {
        icon: 'upload-circle',
        label: 'Uploaded',
    },
    [DocumentRevisionStatus.REJECTED]: {
        icon: 'cancel-circle',
        label: 'Rejected',
    },
    [DocumentRevisionStatus.ACCEPTED]: {
        icon: 'check-circle',
        label: 'Accepted',
    },
    [DocumentRevisionStatus.APPLIED]: { icon: 'done-all', label: 'Applied' },
};
