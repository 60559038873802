<app-web-viewer *ngIf="document.blob" [document]="document">
    <div
        header
        class="header text-sm flex gap-10 items-center justify-space-between">
        <div class="flex items-center text-semi">
            <mat-icon
                svgIcon="arrow-right"
                class="color-primary-default mr-1" />
            {{ document.name }}
            <app-tooltip *ngIf="revisionDetails && statuses" class="ml-1">
                <mat-icon trigger svgIcon="info" />
                <div content class="grid gap-5 text-s">
                    @for (item of getKeys(statuses); track $index) {
                        @if (statuses[item].show) {
                            <p
                                class="flex items-end"
                                [ngClass]="statuses[item].textClass">
                                <mat-icon [svgIcon]="statuses[item].icon" />
                                <span class="ml-2">
                                    {{ statuses[item].text }}
                                </span>
                            </p>
                        }
                    }
                </div>
            </app-tooltip>
        </div>
        <div class="flex gap-6">
            @if (canApply()) {
                <app-flat-button
                    variant="success"
                    type="solid"
                    [dataQa]="dataQa.documentPreviewApplyButton">
                    Apply Correction
                </app-flat-button>
            }
            <app-flat-button
                *ngIf="canReject()"
                variant="danger"
                (clicked)="onRejectClick()"
                [dataQa]="dataQa.documentPreviewRejectButton">
                Reject
            </app-flat-button>
            <app-flat-button
                *ngIf="canAccept()"
                variant="success"
                (clicked)="onAcceptClick()"
                [dataQa]="dataQa.documentPreviewAcceptButton">
                Accept
            </app-flat-button>
        </div>
    </div>
</app-web-viewer>
