import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DocumentService } from '@services/api/document/document.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { UserService } from '@services/api/user/user.service';
import { RouterService } from '@services/router.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { WebViewerComponent } from '@shared/webviewer/webviewer.component';
import { DocumentPreviewComponent } from 'app/components/documents-and-discrepancies/detail-dialog/document-preview/document-preview.component';
import { HeaderDocumentDetailsComponent } from 'app/components/documents-and-discrepancies/header-document-details/header-document-details.component';
import {
    DocumentDetailTab,
    DocumentDetailTabs,
} from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import { ResizableDirective } from '@directives/resizable.directive';
import { DocumentRevisionItem, WorkflowDocument } from '@models/document';
import { RevisionDetails } from '@models/revision/revision-detail';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import {
    saveSelectedTab,
    updateSelectedRevision,
} from 'app/store/actions/document.actions';
import { selectSelectedRevision } from 'app/store/selectors/document.selectors';
import { createPDFDocument } from '@helpers/file';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { PDFDocument } from '@constants/file';

@Component({
    standalone: true,
    templateUrl: 'document-detail.component.html',
    styleUrl: 'document-detail.component.scss',
    imports: [
        MatIconModule,
        MatButtonModule,
        TabsComponent,
        ResizableDirective,
        WebViewerComponent,
        LoaderComponent,
        DocumentPreviewComponent,
        RouterOutlet,
        HeaderDocumentDetailsComponent,
    ],
})
export class DocumentDetailPageComponent implements OnInit, OnDestroy {
    documentId!: number;
    readonly tabs: typeof DocumentDetailTab = DocumentDetailTab;
    documentRevision$: Observable<DocumentRevisionItem | null> | undefined;
    documentRevision: DocumentRevisionItem | null = null;
    activeTab: DocumentDetailTab = DocumentDetailTab.METADATA;
    tabsData = DocumentDetailTabs;
    isAdminUser = false;
    documentDetails: WorkflowDocument | null = null;
    documentFile: PDFDocument | null = null;
    documentLoading = false;
    revisionDetails: RevisionDetails | null = null;
    initialSelectedTabIndex: number | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        public dialog: MatDialog,
        private routerService: RouterService,
        private route: ActivatedRoute,
        private store: Store,
        private documentService: DocumentService,
        private userService: UserService,
        private revisionService: RevisionService,
        private actions$: Actions,
    ) {
        route.paramMap.subscribe({
            next: (params) => (this.documentId = parseFloat(params.get('id')!)),
        });
        this.getDocumentDetails(this.documentId);
    }

    ngOnInit(): void {
        this._subscriptions['is-admin-user'] = this.userService
            .isAdminUser()
            .subscribe((isAdmin) => (this.isAdminUser = isAdmin));

        this.setSelectedTab();
        this.onDocumentRevisionChange();
        this.handleComponentRefresh();
    }

    ngOnDestroy(): void {
        this.store.dispatch(updateSelectedRevision({ selectedRevision: null }));
        this._subscriptions.unsubscribeAllSafe();
    }

    onCloseClick() {
        this.routerService.goHome();
    }

    onTabSelected(tabIndex: number) {
        this.activeTab = this.tabsData[tabIndex].id;
        this.store.dispatch(saveSelectedTab({ selectedTab: this.activeTab }));

        this.routerService.goDocumentDetails(this.documentId, this.activeTab);
    }

    private setSelectedTab() {
        this.route.children.forEach((child) => {
            this.activeTab = (child.routeConfig?.path ||
                '') as DocumentDetailTab;
        });
        this.initialSelectedTabIndex = this.tabsData.findIndex(
            (item) => item.id === this.activeTab,
        );
    }

    private onDocumentRevisionChange() {
        this.documentRevision$ = this.store.select(selectSelectedRevision);
        this._subscriptions['document-revision'] =
            this.documentRevision$.subscribe({
                next: (revision) => {
                    if (revision) {
                        this.documentRevision = revision;
                        this.getDocumentFile(revision.documentServiceId!);
                        this.getRevisionDetails();
                    }
                },
            });
    }

    private getRevisionDetails() {
        if (this.documentRevision) {
            this._subscriptions['get-revision-details'] = this.revisionService
                .getRevisionDetails(this.documentRevision.revisionId!)
                .subscribe({
                    next: (response) => (this.revisionDetails = response),
                });
        }
    }

    private handleComponentRefresh() {
        this.actions$.pipe(ofType(refreshActionRelatedData)).subscribe({
            next: (refresh) => {
                if (refresh) this.getRevisionDetails();
            },
        });
    }

    private getDocumentDetails(id: number) {
        this.documentService.getDocumentDetails(id).subscribe({
            next: (data) => {
                if (data) {
                    this.documentDetails = data;
                    if (this.documentDetails) {
                        this.getDocumentFile(
                            this.documentDetails?.documentServiceId,
                        );
                    }
                }
            },
        });
    }

    private getDocumentFile(documentServiceId: string | null) {
        if (documentServiceId) {
            this.documentLoading = true;
            this.documentFile = null;
            this._subscriptions['get-document-file'] = this.documentService
                .getDocumentFile(this.documentId, documentServiceId)
                .subscribe({
                    next: (response) =>
                        (this.documentFile = createPDFDocument(response)),
                    complete: () => (this.documentLoading = false),
                });
        }
    }
}
