<div class="stepper">
    @for (step of steps; track $index; let i = $index) {
        <div
            class="step"
            [class.first]="i === 0"
            [class.last]="i === steps.length - 1"
            [class.active]="activeStep === step.id"
            [class.completed]="isStepCompleted(i)">
            <div class="circle">
                @if (isStepCompleted(i)) {
                    <mat-icon svgIcon="check" />
                }
            </div>
            <div class="label text-xxs pos-abs whitespace-normal">
                {{ step.label }}
            </div>
            @if (i < steps.length - 1) {
                <div class="line"></div>
            }
        </div>
    }
</div>
