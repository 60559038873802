import { Priority } from '@models/shared/priority';
import { DiscrepancyProgress, LatestActivity } from '@models/document/index';

export class WorkflowDocument {
    constructor(
        public id: number,
        public name: string,
        public documentType: string,
        public repository: string,
        public priority: Priority,
        public createdAt: string,
        public station: string,
        public documentServiceId: string | null,
        public maximumDiscrepancyCount: number | null,
        public canAddDiscrepancy: boolean,
        public age: number | null,
        public latestActivity: LatestActivity,
        public progress: DiscrepancyProgress,
        public originalRevisionId: number | null,
        public discrepanciesCount: number | null,
        public daysToPublish: number | null,
        public workflowId: number | null,
    ) {}
}
