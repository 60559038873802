import { Component, OnDestroy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

import { DocumentDataService } from '@services/data/document-data.service';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    standalone: true,
    imports: [MatIconModule],
    template: `
        @if (count) {
            <div class="flex items-center text-xxs whitespace-pre">
                Showing <span class="text-semi">{{ count.filtered }}</span>
                of
                <span class="text-semi">{{ count.total }}</span>
                discrepancies
            </div>
        }
    `,
})
export class CountStatusBarComponent
    implements IStatusPanelAngularComp, OnDestroy
{
    count: { filtered: number; total: number } | undefined = undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(private documentDataService: DocumentDataService) {}

    agInit(_params: IStatusPanelParams & { documentId: number }): void {
        const documentId = _params.documentId;

        this._subscriptions['disc-count-status'] =
            this.documentDataService.statusBarDiscrepancyCount$.subscribe({
                next: (data) => {
                    if (data) {
                        this.count = (data ?? []).find(
                            (item) => item.documentId === documentId,
                        );
                    }
                },
            });
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }
}
