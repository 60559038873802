@if (error) {
    <small class="color-status-red4"> Discrepancies can't be loaded</small>
} @else {
    <app-table
        noRowsText="No workflow items to show."
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [suppressMenu]="true"
        (gridReady)="onGridReady($event)" />
}
