<div class="w-full p-5" [class.disabled]="loading">
    <p class="text-md mb-3">Columns to be exported - Excel (XLSX):</p>
    <div>
        <div class="column-options">
            <p class="whitespace-nowrap">
                Document Columns ({{ docColumnOptions.length }})
            </p>
            <div></div>
            <app-checkbox
                size="small"
                label="Select All Document Columns"
                [indeterminate]="!allDocSelected"
                [checked]="allDocSelected"
                (checkedChange)="toggleAllDocColumns($event)" />
            @for (item of docColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="
                        updateSelectedDocColumns($event, item.id)
                    " />
            }
        </div>
    </div>
    <div class="mt-4 mb-4">
        <div class="column-options">
            <p class="whitespace-nowrap">
                Discrepancy Columns ({{ discOptions.length }})
            </p>
            <div></div>
            <app-checkbox
                size="small"
                label="Select All Discrepancy Columns"
                [indeterminate]="!allDiscSelected"
                [checked]="allDiscSelected"
                (checkedChange)="toggleAllDiscColumns($event)" />
            @for (item of discOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="
                        updateSelectedDiscColumns($event, item.id)
                    " />
            }
        </div>
    </div>
    <div class="filter-sort-option p-5 pt-2 pb-2 ml-n5 mr-n5">
        <app-checkbox
            size="small"
            label="Export data without any filters and sorting."
            [checked]="excludeFilters"
            (checkedChange)="excludeFilters = !excludeFilters" />
        <p class="text-xs ml-8">
            This option excludes applied filters and sorting from excel export.
        </p>
    </div>
    @if (maxLimitError) {
        <div class="text-s mt-3">
            <p class="color-status-red4">
                The maximum excel export limit is
                {{ maxLimit.toLocaleString() }} discrepancies at a time. Please
                reduce the selected number of discrepancies to export or raise a
                support ticket <a>here</a> to export all data.
            </p>
            <app-text-button
                class="ml-n2"
                (clicked)="showMaxLimitReduceInfo = !showMaxLimitReduceInfo">
                How to reduce discrepancy count?
                <mat-icon
                    [svgIcon]="
                        showMaxLimitReduceInfo ? 'chevron-up' : 'chevron-down'
                    " />
            </app-text-button>
            @if (showMaxLimitReduceInfo) {
                <div class="bg-gray0 p-5 pt-3 pb-3 ml-n5 mr-n5">
                    <p>
                        &#x2022; Apply additional table filters to bring the
                        selected discrepancy count under
                        {{ maxLimit.toLocaleString() }}
                    </p>
                    <p>
                        &#x2022; Use table filters to select up to
                        {{ maxLimit.toLocaleString() }}
                        discrepancies at a time and export in multiple batches.
                         
                    </p>
                </div>
            }
        </div>
    }
    <div class="flex justify-end items-center gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4"> Something went wrong </small>
        }
        <app-flat-button mat-dialog-close> Cancel </app-flat-button>
        <app-flat-button variant="cta" (clicked)="export()">
            <mat-icon svgIcon="download" />
            Export
        </app-flat-button>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
