import { AsyncPipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatOption,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs/internal/Observable';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';

import { SelectOption, SelectOptionWithGroup } from '@models/shared.model';

@Component({
    selector: 'app-autocomplete',
    templateUrl: 'autocomplete.component.html',
    styleUrl: 'autocomplete.component.scss',
    standalone: true,
    imports: [
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        ReactiveFormsModule,
        AsyncPipe,
    ],
})
export class AutocompleteComponent implements OnChanges {
    @Input() variant: 'primary' | 'secondary' = 'primary';
    @Input() label: string = '';
    @Input() description: string = '';
    @Input() value: string | number | null = null;
    @Input() required: boolean = false;
    @Input() placeholder: string = 'Filter';
    @Input() options: SelectOption[] | SelectOptionWithGroup[] = [];
    @Input() autoActiveFirstOption: boolean = false;
    @Input() inputControl = new FormControl<string | SelectOption>('');
    @Output() selectionChange = new EventEmitter<{ value: string | number }>();

    isGroupedOptions: boolean = false;
    filteredOptions: Observable<SelectOption[]> | undefined;
    filteredGroupOptions: Observable<SelectOptionWithGroup[]> | undefined;

    get groupedOptions(): SelectOptionWithGroup[] {
        return this.options.filter(
            (opt): opt is SelectOptionWithGroup => 'items' in opt,
        );
    }

    get flatOptions(): SelectOption[] {
        return this.options.filter(
            (opt): opt is SelectOption => !('items' in opt),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isGroupedOptions =
            this.options.length > 0 && 'items' in this.options[0];
        this.setFilteredOptions();
        this.setFilteredGroupOptions();

        const valueChange = changes['value'];
        const optionsChange = changes['options'];

        if (optionsChange || valueChange) {
            const newValue = (this.options as SelectOption[]).find(
                (option) => option.value === this.value,
            );

            if (newValue) this.inputControl.setValue(newValue);
        }
    }

    onSelectionChange(option: MatOption) {
        const selection: SelectOption = option.value;
        this.selectionChange.emit({ value: selection.value });
    }

    displayFn(option: SelectOption): string {
        return option && option.viewValue ? option.viewValue : '';
    }

    onInputEnter(event: Event) {
        if ((event.target as HTMLInputElement).value === '') {
            this.selectionChange.emit({ value: '' });
        }
    }

    private filter(options: SelectOption[], name: string): SelectOption[] {
        const filterValue = name.toLowerCase();

        return options.filter((option) =>
            option.viewValue.toLowerCase().includes(filterValue),
        );
    }

    private filterGroup(value: string): SelectOptionWithGroup[] {
        if (value) {
            return this.groupedOptions.map((opt) => ({
                group: opt.group,
                items: this.filter(opt.items, value),
            }));
        }

        return this.groupedOptions;
    }

    private setFilteredOptions() {
        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map((value) => {
                const name =
                    typeof value === 'string' ? value : value?.viewValue;

                return name
                    ? this.filter(this.flatOptions, name as string)
                    : this.flatOptions.slice();
            }),
        );
    }

    private setFilteredGroupOptions() {
        this.filteredGroupOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map((value) => {
                const name =
                    typeof value === 'string' ? value : value?.viewValue;

                return name
                    ? this.filterGroup(name)
                    : this.groupedOptions.slice();
            }),
        );
    }
}
