import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { WebViewerInstance } from '@pdftron/webviewer';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';

import { DocumentDataService } from '@services/data/document-data.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabsComponent } from '@shared/tabs/tabs.component';
import {
    WebViewerComponent,
    WebViewerDocument,
} from '@shared/webviewer/webviewer.component';
import { DiscrepancySummaryComponent } from './discrepancy-summary.component';
import { ResizableDirective } from '@directives/resizable.directive';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { RevisionDetails } from '@models/revision/revision-detail';
import { DiscrepancyDetail } from '@models/discrepancy';
import { selectOriginalDocumentId } from 'app/store/selectors/document.selectors';
import DATA_QA from '@automation/data-qa.json';
import {
    insertCorrectedDocumentTag,
    hideCloseDocumentButton,
} from '@helpers/webviewer';

@Component({
    standalone: true,
    templateUrl: 'accept-correction-dialog.component.html',
    styleUrls: ['accept-correction-dialog.component.scss'],
    imports: [
        MatDividerModule,
        ReactiveFormsModule,
        MatDialogModule,
        FlatButtonComponent,
        LoaderComponent,
        TabsComponent,
        WebViewerComponent,
        DiscrepancySummaryComponent,
        ResizableDirective,
    ],
})
export class AcceptCorrectionDialogComponent implements OnInit {
    error: boolean = false;
    loading: boolean = false;
    tabsData: { label: string }[] = [];
    selectedTabIndex: number = 0;
    discDetail = DiscrepancyDetail.initial();
    discDetailLoading: boolean = false;
    revisionDocumentDetails: WebViewerDocument | null = null;
    originalDocumentDetails: WebViewerDocument | null = null;
    originalDocumentId: number | null = null;
    documentLoading: boolean = false;
    readonly elementsToBeDisabled = [
        'viewControlsButton',
        'thumbnailControl',
        'documentControl',
    ];
    readonly dataQa = DATA_QA;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { revisionDetails: RevisionDetails },
        public dialogRef: MatDialogRef<AcceptCorrectionDialogComponent>,
        private revisionService: RevisionService,
        private snackbarService: SnackbarService,
        private discrepancyService: DiscrepancyService,
        private documentDataService: DocumentDataService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.getOriginalDocumentId();
        this.setsTabData();
        this.onTabChange();
        this.getDocuments();
    }

    get onlyOneDiscrepancy(): boolean {
        return this.data.revisionDetails.discrepancies.length === 1;
    }

    get title() {
        return this.onlyOneDiscrepancy
            ? `Accept Correction for Discrepancy ${this.data.revisionDetails.discrepancies[0].discrepancyNo}`
            : 'Accept Correction';
    }

    get discrepanciesText() {
        return this.data.revisionDetails.discrepancies
            .map((item) => `Discrepancy ${item.discrepancyNo}`)
            .join(', ');
    }

    accept() {
        this.error = false;
        this.loading = true;

        this.revisionService
            .acceptRevision(this.data.revisionDetails.id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            header: `Correction is accepted for ${this.discrepanciesText}! `,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                        this.documentDataService.updateSelectedRevisionId(
                            this.data.revisionDetails.id,
                        );
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    onTabChange(index: number | null = null) {
        this.discDetailLoading = true;
        const discrepancyId =
            this.data.revisionDetails.discrepancies[
                index ?? this.selectedTabIndex
            ].id;

        this.discrepancyService.getDiscrepancyDetails(discrepancyId).subscribe({
            next: (response) => (this.discDetail = response),
            complete: () => (this.discDetailLoading = false),
        });
    }

    onDocumentLoaded(vwInstance: WebViewerInstance) {
        insertCorrectedDocumentTag(vwInstance);
        hideCloseDocumentButton(vwInstance);
    }

    private getOriginalDocumentId() {
        const originalDocumentId$ = this.store.select(selectOriginalDocumentId);
        originalDocumentId$.subscribe({
            next: (id) => (this.originalDocumentId = id),
        });
    }

    private setsTabData() {
        this.tabsData = [
            ...this.data.revisionDetails.discrepancies.map((item) => ({
                label: `${item.discrepancyNo}`,
            })),
        ];
    }

    private getDocuments() {
        this.documentLoading = true;

        forkJoin([
            this.getRevisionDetails(this.data.revisionDetails.id),
            this.getOriginalDetails(),
        ]).subscribe({
            next: ([revisionDetails, originalDocDetails]) => {
                this.revisionDocumentDetails = {
                    id: `${revisionDetails!.documentServiceId}`,
                    name: revisionDetails?.name ?? '',
                };
                this.originalDocumentDetails = {
                    id: `${originalDocDetails!.documentServiceId}`,
                    name: originalDocDetails?.name ?? '',
                };
            },
            complete: () => (this.documentLoading = false),
        });
    }

    private getRevisionDetails(id: number) {
        return this.revisionService.getRevisionDetails(id);
    }

    private getOriginalDetails() {
        if (this.originalDocumentId)
            return this.getRevisionDetails(this.originalDocumentId);

        return of(null);
    }
}
