<div class="flex flex-column ml-n4">
    <app-text-button class="activity-log-toggle" (click)="toggleActivityLog()">
        <span class="text-s">{{ toggleText }}</span>
        <mat-icon
            svgIcon="chevron-down"
            [attr.toggled]="toggled"
            class="activity-log-toggle-icon" />
    </app-text-button>
    @if (toggled) {
        <div class="activity-log__table p-3">
            <p class="activity-log__title pl-2 mb-2">Activity Log</p>
            <app-table
                variant="secondary"
                [suppressRowHoverHighlight]="true"
                [suppressCellFocus]="true"
                [pagination]="false"
                [floatingFilter]="false"
                [columnDefs]="columnDefs"
                [pageSize]="-1"
                noRowsText="No activity logs  to show."
                (gridReady)="onGridReady($event)" />
        </div>
    }
</div>
<div #activityLogEnd></div>
