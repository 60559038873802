import { WebViewerInstance } from '@pdftron/webviewer';

import { FileType } from '@constants/file';

export interface PagesUpdatedEvent {
    removed: number[];
    moved: { [key: number]: number };
    rotationChanged: number[];
}

export enum WebViewerCustomButtons {
    SAVE = 'wfeSaveButton',
    FULL_SCREEN = 'wfeFullScreenButton',
}

export const insertCorrectedDocumentTag = (vwInstance: WebViewerInstance) => {
    const node = document.createElement('span');
    node.style.cssText = `color: #A0EC4C;
    border: 1px solid #4F7B00;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap`;
    const text = document.createTextNode('Uploaded Correction');
    node.appendChild(text);
    const div = vwInstance.UI.iframeWindow.document.querySelector(
        '#header2 .file-name',
    );

    div?.insertAdjacentElement('afterend', node);
};

export const hideCloseDocumentButton = (vwInstance: WebViewerInstance) => {
    const matches =
        vwInstance.UI.iframeWindow.document.querySelectorAll(
            '.control-buttons',
        );

    matches.forEach((match) => {
        const buttons = match.querySelectorAll('button');
        buttons[1].style.display = 'none';
    });
};

/**
 * Inserts current page number to document on multiviewer mode.
 * @param {WebViewerInstance} vwInstance - A single instance of webviewer.
 */
export const insertCurrentPageNumber = (vwInstance: WebViewerInstance) => {
    const instanceDocument = vwInstance.UI.iframeWindow.document;
    const insertedPageNumber: HTMLElement | null =
        instanceDocument.querySelector('#vwCurrentPage1');

    if (!insertedPageNumber) {
        const node1 = document.createElement('span');
        const node2 = document.createElement('span');

        node1.setAttribute('id', 'vwCurrentPage1');
        node1.textContent = '1';
        node2.setAttribute('id', 'vwCurrentPage2');
        node2.textContent = '1';

        const cssText = `
                position: absolute;
                bottom: 10px;
                background-color: #343A41;
                border-radius: 4px;
                padding: 5px;
                text-align: center;
                min-width: 24px;
                min-height: 24px;
            `;

        node1.style.cssText = cssText;
        node2.style.cssText = cssText;

        const doc1 = instanceDocument.querySelector('#Document1');
        const doc2 = instanceDocument.querySelector('#Document2');

        doc1?.insertAdjacentElement('afterend', node1);
        doc2?.insertAdjacentElement('afterend', node2);

        const [documentViewerLeft, documentViewerRight] =
            vwInstance.Core.getDocumentViewers();

        documentViewerLeft.addEventListener(
            'pageNumberUpdated',
            (pageNumber) => {
                const el = instanceDocument.getElementById('vwCurrentPage1');
                if (el) el.textContent = pageNumber;
            },
        );

        documentViewerRight.addEventListener(
            'pageNumberUpdated',
            (pageNumber) => {
                const el = instanceDocument.getElementById('vwCurrentPage2');
                if (el) el.textContent = pageNumber;
            },
        );
    }
};

export const createDropdown = (): HTMLSelectElement => {
    const node = document.createElement('select');
    node.style.cssText = `background-color: #364C59; 
        color: white;
        border: 1px solid #5ABFF8;
        height: 40px;
        margin: 0 10px;
        font-size: 14px;
        min-width: 150px;
        max-width: 500px;
        width: max-content;
    `;

    return node;
};

export const createVersionTag = (id: string, text: string) => {
    const node = document.createElement('span');
    node.id = id;
    node.style.cssText = `  
        min-width: 140px;              
        color: #A4D76B;
        background: #343A40;
        border: 1px solid #679F00;
        padding: 5px;
        overflow: hidden;
        white-space: nowrap`;

    node.appendChild(document.createTextNode(text));

    return node;
};

export const toggleLeftPanel = (vwInstance: WebViewerInstance) => {
    // Temporary Solution to fix layout
    // Layout shifted to left when border added to doc.
    vwInstance.UI.closeElements(['leftPanel']);
    setTimeout(() => {
        vwInstance.UI.openElements(['leftPanel']);
    }, 1);
};

export const removeDropArea = (vwInstance: WebViewerInstance) => {
    const instanceDocument = vwInstance.UI.iframeWindow.document;
    const dropAreaElements = instanceDocument.querySelectorAll('.DropArea');
    if (dropAreaElements.length > 0)
        dropAreaElements.forEach((el) => {
            (el as HTMLElement).style.display = 'none';
        });
};

export const removeZoomOverlayButton = (vwInstance: WebViewerInstance) => {
    // CHECKPOINT
    // splice(5, 2) removes the button and following divider.
    // should be checked if library update happens

    vwInstance.UI.setHeaderItems((header) => {
        const updatedHeader = header;
        updatedHeader.getItems().splice(5, 2);
        header = updatedHeader;
    });
};

export const getUpdatedDocument = async (vwInstance: WebViewerInstance) => {
    const { Core } = vwInstance;
    const { annotationManager } = Core;
    const [documentViewerLeft] = Core.getDocumentViewers();
    const documentLeft = documentViewerLeft.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    const data = await documentLeft.getFileData({ xfdfString });
    const blob = new Blob([data], { type: FileType.PDF });

    return { blob, name: documentLeft.getFilename() };
};
