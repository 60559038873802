import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
    SnackbarComponent,
    SnackbarComponentProps,
    SnackbarPosition,
} from '@shared/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    readonly defaultPosition: SnackbarPosition = {
        horizontalPosition: 'end',
        verticalPosition: 'top',
    };

    private snackBar = inject(MatSnackBar);

    error(data: SnackbarComponentProps) {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            data: { ...data, variant: 'error' },
            ...this.defaultPosition,
            ...data.position,
            duration: 10000,
        });
    }

    success(data: SnackbarComponentProps) {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            data: { ...data, variant: 'success' },
            ...this.defaultPosition,
            ...data.position,
            duration: 5000,
        });
    }

    progress(data: SnackbarComponentProps) {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            data: { ...data, variant: 'progress' },
            ...this.defaultPosition,
            ...data.position,
            duration: 5000,
        });
    }

    custom<T, D>(component: ComponentType<T>, data: SnackbarComponentProps<D>) {
        return this.snackBar.openFromComponent(component, {
            data: { ...data },
            ...this.defaultPosition,
            ...data.position,
        });
    }
}
