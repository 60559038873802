import { Component, inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TextareaComponent } from '@shared/textarea/textarea.component';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import {
    DiscrepancyIdNo,
    UnresolveDiscrepancyRequest,
} from '@models/discrepancy';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'unresolve-discrepancy-dialog.component.html',
    imports: [
        ReactiveFormsModule,
        FlatButtonComponent,
        MatDialogClose,
        LoaderComponent,
        TextareaComponent,
    ],
})
export class UnresolveDiscrepancyDialogComponent {
    form!: FormGroup;
    loading = false;
    error = false;
    readonly dataQa = DATA_QA;

    readonly data: DiscrepancyIdNo = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(
        MatDialogRef<UnresolveDiscrepancyDialogComponent>,
    );
    private store = inject(Store);
    private formBuilder = inject(FormBuilder);
    private snackbarService = inject(SnackbarService);
    private discrepancyService = inject(DiscrepancyService);

    constructor() {
        this.initForm();
    }

    get reasonPlaceholder() {
        return 'I.e. The station is not able to locate the paperwork, hence we are not able to resolve this discrepancy.';
    }

    get reason() {
        return this.form.get('reason') as FormControl;
    }

    unresolve() {
        this.loading = true;
        this.error = false;

        this.discrepancyService
            .unresolveDiscrepancy(
                this.data.id,
                new UnresolveDiscrepancyRequest(this.reason.value),
            )
            .subscribe({
                next: (success) => {
                    this.loading = false;
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.data.discrepancyNo} is marked as unresolved and moved to “Recently Completed”!`,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    private initForm() {
        this.form = this.formBuilder.group({
            reason: new FormControl(''),
        });
    }
}
