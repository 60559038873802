@if (loading) {
    <app-loader />
} @else {
    @if (internals === null) {
        <div>No internals found.</div>
    } @else {
        <div class="grid gap-10">
            <app-content-field label="REPOSITORY ID">
                {{ internals.repositoryId }}
            </app-content-field>
            <app-content-field label="TENANT ID">
                {{ internals.tenantId }}
            </app-content-field>
            <app-content-field label="WORKFLOW DOCUMENT ID">
                {{ internals.workflowDocumentId }}
            </app-content-field>
            <app-content-field label="WORKFLOW ID">
                {{ internals.workflowId }}
            </app-content-field>
        </div>
    }
}
