<div class="p-5">
    <p class="text-lg mb-3">
        Recall Discrepancy {{ data.discrepancyNo }} to “Initial Review”
    </p>
    This action will:
    <ul>
        <li>Make the discrepancy editable</li>
        <li>Remove uploaded correction(s) from this discrepancy</li>
    </ul>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.recallForEditDialogCancel">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="tertiary"
            [dataQa]="dataQa.recallForEditDialogRecall"
            (clicked)="recall()">
            Recall and Edit Discrepancy
        </app-flat-button>
    </div>
    @if (error) {
        <div class="flex justify-end gap-8">
            <small class="color-status-red4"> Something went wrong </small>
        </div>
    }
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
