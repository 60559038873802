@if (loading) {
    <app-loader class="ml-4" />
} @else {
    @for (data of metadata; track $index) {
        <app-content-field
            class="metadata"
            [required]="data.isRequired"
            [label]="data.fieldLabel.toUpperCase()">
            {{ data.fieldValue }}
        </app-content-field>
    }

    @if (metadata.length > columnCount && metadata.length % columnCount > 0) {
        @for (cell of placeholderCells(); track $index) {
            <div class="metadata"></div>
        }
    }
    @if (metadata.length === 0) {
        <div class="ml-5">No metadata found.</div>
    }
}
