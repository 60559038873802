import { DiscrepancyResponse, Discrepancy } from './item';

export class DiscrepanciesWithTotalResponse {
    constructor(
        public discrepancies: DiscrepancyResponse[],
        public totalCount: number,
    ) {}
}

export class DiscrepanciesWithTotal {
    constructor(
        public discrepancies: Discrepancy[],
        public totalCount: number,
    ) {}
}
