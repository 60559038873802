<div class="h-full flex flex-column" [attr.data-qa]="dataQa.finalVersionDialog">
    <div class="flex flex-column pt-5 pr-5 pl-5 overflow-hidden">
        <div class="flex items-start justify-space-between mb-3">
            <div>
                <p>
                    {{ this.data.triggerType }} Discrepancy
                    {{ this.triggeredDiscrepancy.discrepancyNo }}
                </p>
                <p class="text-lg">
                    Please Create Final Version Before Close Resolving
                    Discrepancy
                </p>
            </div>
            <div class="flex gap-10">
                <app-flat-button
                    [dataQa]="dataQa.finalVersionDialogCancelButton"
                    (clicked)="cancel()">
                    Cancel
                </app-flat-button>
                <app-flat-button
                    variant="cta"
                    type="solid"
                    [disabled]="!vwInstance"
                    [dataQa]="dataQa.finalVersionDialogCreateButton"
                    (click)="triggerAction()">
                    Create Final Version &
                    {{ this.data.triggerType }} Discrepancy
                    {{ this.triggeredDiscrepancy.discrepancyNo }}
                </app-flat-button>
            </div>
        </div>
        <div class="flex overflow-hidden flex-1">
            <app-tabs
                style="width: 50%; min-height: 100px"
                tabLabelClass="text-s"
                tabContentClass="ml-n4 pl-4 flex-1"
                [tabs]="tabsData"
                (tabSelected)="handleTabChange($event)">
                @if (selectedDiscrepancyLoading) {
                    <app-loader class="mt-2" />
                } @else {
                    <app-discrepancy-summary
                        class="overflow-auto flex-1"
                        [discDetail]="selectedDiscrepancy" />
                }
            </app-tabs>
            <div class="logs flex-1 overflow-auto">
                <p class="color-gray13">
                    {{ logsTitle.toLocaleUpperCase() }}
                </p>
                @if ((combinedLogs | async)?.length === 0) {
                    <p class="text-s color-gray9 ml-2 mt-2">
                        No changes to show
                    </p>
                }
                <ul class="text-s mt-2">
                    @for (log of combinedLogs | async; track $index) {
                        <li
                            [attr.data-qa]="
                                dataQa.finalVersionDialogChangeLogItem
                            ">
                            {{ log }}
                        </li>
                    }
                </ul>
            </div>
        </div>
    </div>
    <div wfeResizable orientation="vertical"></div>
    <div class="flex flex-column document bg-gray18 overflow-auto flex-1">
        @if (destinationDocument) {
            @if (showInsertAndReplace) {
                <div
                    class="page-actions flex justify-center items-center gap-10">
                    <app-flat-button
                        #insertTrigger="matMenuTrigger"
                        variant="neon-blue"
                        [dataQa]="dataQa.finalVersionDialogInsertMenuTrigger"
                        [matMenuTriggerFor]="insertMenu"
                        (menuOpened)="replaceTrigger.closeMenu()">
                        Insert
                    </app-flat-button>
                    <app-flat-button
                        #replaceTrigger="matMenuTrigger"
                        variant="neon-blue"
                        [dataQa]="dataQa.finalVersionDialogReplaceMenuTrigger"
                        [matMenuTriggerFor]="replaceMenu"
                        (menuOpened)="insertTrigger.closeMenu()">
                        Replace
                    </app-flat-button>
                </div>
            }
            <app-web-viewer
                class="h-full"
                [document]="destinationDocument"
                [documentToCompare]="sourceDocument"
                [elementsToBeDisabled]="elementsToBeDisabled"
                (documentLoaded)="onDocumentLoaded($event)" />
        }
    </div>
</div>

<mat-menu
    #insertMenu="matMenu"
    yPosition="above"
    wfeCenter
    [hasBackdrop]="false"
    [overlapTrigger]="true">
    <app-insert-menu-content
        [vwInstance]="vwInstance"
        [sourceDocumentName]="sourceDocument?.name"
        [destinationDocumentName]="destinationDocument?.name"
        [destinationDocumentPageCount]="destinationDocumentPageCount"
        (inserted)="onDocumentChanged($event)"
        (closeMenu)="insertTrigger?.closeMenu()" />
</mat-menu>

<mat-menu
    #replaceMenu="matMenu"
    yPosition="above"
    wfeCenter
    [hasBackdrop]="false"
    [overlapTrigger]="true">
    <app-replace-menu-content
        [vwInstance]="vwInstance"
        [sourceDocumentName]="sourceDocument?.name"
        [destinationDocumentName]="destinationDocument?.name"
        (replaced)="onDocumentChanged($event)"
        (closeMenu)="replaceTrigger?.closeMenu()" />
</mat-menu>

@if (loading) {
    <app-loader type="overlay" />
}
