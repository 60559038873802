import { Component, inject, OnInit, OnDestroy } from '@angular/core';

import { ColumnOptionsService } from '@services/column-options.service';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { ColumnOption } from '@models/shared.model';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    DiscrepancyColumn,
    DiscrepancyViewColumnOptions,
} from '@constants/table';

@Component({
    selector: 'app-dicrepancy-view-column-options',
    standalone: true,
    imports: [CheckboxComponent],
    template: ` <p class="mb-3">Column Options</p>
        <div class="column-options">
            @for (item of docColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="updateColumnDisplay($event, item.id)" />
            }
        </div>
        <div class="column-options nested ml-n3 mr-n3 mt-1 p-3">
            @for (item of discColumnOptions; track $index) {
                <app-checkbox
                    size="small"
                    [disabled]="item.disabled"
                    [checked]="item.checked"
                    [label]="item.label"
                    (checkedChange)="updateColumnDisplay($event, item.id)" />
            }
        </div>`,
    styles: `
        :host {
            width: 100%;

            .column-options {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                &.nested {
                    background-color: #f5f6f7;
                }
            }
        }
    `,
})
export class DiscrepancyViewColumnOptionsComponent
    implements OnInit, OnDestroy
{
    discViewColumnOptions: ColumnOption<DiscrepancyColumn>[] = [];
    hiddenColumnOptions: DiscrepancyColumn[] = [];
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private columnOptionsService = inject(ColumnOptionsService);

    constructor() {}

    get docColumnOptions() {
        return this.discViewColumnOptions.filter(
            (item) => item.group === 'doc',
        );
    }

    get discColumnOptions() {
        return this.discViewColumnOptions.filter(
            (item) => item.group === 'disc',
        );
    }

    ngOnInit(): void {
        this.setColumnOptions();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    updateColumnDisplay(checked: boolean, id: DiscrepancyColumn) {
        this.columnOptionsService.updateDiscColumnOptions({ checked, id });
    }

    private setColumnOptions() {
        this._subscriptions['column-options'] =
            this.columnOptionsService.discColumnOptions$.subscribe({
                next: (options) => {
                    this.discViewColumnOptions =
                        DiscrepancyViewColumnOptions.map((option) => {
                            const isHidden = options.hiddenColumns.some(
                                (hiddenOption) => hiddenOption === option.id,
                            );

                            if (isHidden) {
                                return { ...option, checked: false };
                            }
                            return option;
                        });
                },
            });
    }
}
