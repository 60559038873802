{
    "login": "/authorization/account/login",
    "logout": "/authorization/account/logout",
    "oidcToken": "account/oidc-token",
    "tenants": "/tenants/mine",
    "documentServiceTenantId": "/configurations/document-service/tenant-id",
    "documents": "/documents",
    "documentById": "/documents/{id}",
    "documentFiles": "/documents/{id}/files",
    "documentFilesContent": "/documents/{id}/files/{serviceId}",
    "documentsDiscrepancies": "/documents/{id}/discrepancies",
    "documentsMetadata": "/documents/{id}/metadata",
    "documentsMetadataOptions": "/documents/{id}/metadata/options",
    "documentsFilterOptions": "/documents/filter-options",
    "documentsInternals": "/documents/{id}/internals",
    "documentIsMine": "/documents/{id}/mine",
    "docDiscrepancyCreateOptions": "/documents/{id}/discrepancies/create-options",
    "documentRevisions": "/documents/{id}/revisions",
    "documentExport": "/documents/excel",
    "documentAppliedVersion": "/documents/{id}/revisions/applied-version",
    "documentAppliedVersionById": "/documents/{id}/revisions/{revisionId}/applied-version",
    "discrepancies": "/discrepancies",
    "discrepancyById": "/discrepancies/{id}",
    "discrepancyDetails": "/discrepancies/{id}/details",
    "discrepancyActivityLogs": "/discrepancies/{id}/activity-logs",
    "discrepanciesFilterOptions": "/discrepancies/filter-options",
    "discrepancyEditOptions": "/discrepancies/{id}/edit-options",
    "discrepancyStatusChips": "/discrepancies/status-chips",
    "takeDiscrepancy": "/discrepancies/{id}/take",
    "putbackDiscrepancy": "/discrepancies/{id}/putback",
    "assignDiscrepancy": "/discrepancies/{id}/assign",
    "unassignDiscrepancy": "/discrepancies/{id}/unassign",
    "notifyDiscrepancy": "/discrepancies/{id}/notify",
    "renotifyDiscrepancy": "/discrepancies/{id}/renotify",
    "cancelDiscrepancy": "/discrepancies/{id}/cancel",
    "recallDiscrepancy": "/discrepancies/{id}/recall",
    "discardDiscrepancy": "/discrepancies/{id}/discard",
    "discrepancyExport": "/discrepancies/excel",
    "bulkRenotify": "/discrepancies/renotify",
    "repositoryOfWorkflow": "/repositories/{workflow}",
    "stationFixers": "/stations/{id}/fixers",
    "userInfo": "/users/me",
    "workflows": "/workflows",
    "revisionById": "/revisions/{id}",
    "rejectRevision": "/revisions/{id}/reject",
    "acceptRevision": "/revisions/{id}/accept",
    "revisionFile": "/revisions/{id}/file",
    "getDocument": "/document-service/documents/{id}"
}
