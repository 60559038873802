import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DiscrepancyIdNo } from '@models/discrepancy/item';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'recall-for-edit-dialog.component.html',
    imports: [FlatButtonComponent, MatDialogClose, LoaderComponent],
})
export class RecallForEditDialogComponent {
    loading = false;
    error = false;
    readonly dataQa = DATA_QA;

    constructor(
        public dialogRef: MatDialogRef<RecallForEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DiscrepancyIdNo,
        private discrepancyService: DiscrepancyService,
        private snackbarService: SnackbarService,
        private store: Store,
    ) {}

    recall() {
        this.loading = true;
        this.error = false;

        this.discrepancyService
            .recallForEditDiscrepancy(this.data.id)
            .subscribe({
                next: (success) => {
                    this.loading = false;
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.data.discrepancyNo} Recalled to Initial Review state!`,
                            content:
                                'Now you can edit discrepancy metadata and notify station & fixer.',
                        });
                        this.store.dispatch(refreshActionRelatedData());
                        this.dialogRef.close();
                    } else {
                        this.error = true;
                    }
                },
            });
    }
}
