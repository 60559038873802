import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
} from '@angular/core';

import { Priority } from 'app/models/shared.model';

@Directive({
    selector: '[wfePriorityField]',
    standalone: true,
})
export class PriorityColorDirective implements OnChanges {
    @Input() wfePriorityField!: Priority;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(): void {
        const priorityMap = {
            [Priority.Low]: {
                text: 'Low',
                color: 'yellow-green6',
            },
            [Priority.Normal]: {
                text: 'Normal',
                color: 'primary',
            },
            [Priority.High]: {
                text: 'High',
                color: 'status-yellow3',
            },
            [Priority.Critical]: {
                text: 'Critical',
                color: 'status-red4',
            },
        };

        const color = priorityMap[this.wfePriorityField].color;
        const text = priorityMap[this.wfePriorityField].text;

        this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
        this.renderer.setStyle(this.el.nativeElement, 'alignItems', 'center');

        const dot = this.renderer.createElement('span');
        this.renderer.setStyle(dot, 'height', '8px');
        this.renderer.setStyle(dot, 'width', '8px');
        this.renderer.setStyle(dot, 'borderRadius', '50%');
        this.renderer.setAttribute(dot, 'class', `bg-${color}`);
        this.renderer.setStyle(dot, 'marginRight', '8px');
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');

        this.renderer.appendChild(this.el.nativeElement, dot);
        this.renderer.appendChild(
            this.el.nativeElement,
            this.renderer.createText(text),
        );
    }
}
