<form class="p-5" [formGroup]="form">
    <p class="text-lg mb-3">Add Fixer(s)</p>
    <div>
        <div class="grid gap-10">
            <app-autocomplete
                label="PRIMARY FIXER*"
                variant="secondary"
                description="- The main responsible person for correcting this discrepancy."
                placeholder="Search by contact name, email or distribution list"
                [autoActiveFirstOption]="true"
                [options]="fixerOptions"
                [inputControl]="primaryFixer" />
            <app-chips-autocomplete
                label="ADDITIONAL FIXER(S) (OPTIONAL)"
                [options]="fixerOptions"
                [disabledOptions]="disabledOptions"
                [inputControl]="additionalFixers" />
        </div>
        <div class="flex justify-end gap-8 mt-5">
            @if (error) {
                <small class="color-status-red4"> Something went wrong </small>
            }
            <app-flat-button mat-dialog-close> Cancel </app-flat-button>
            <app-flat-button
                variant="cta"
                [disabled]="saveDisabled"
                (clicked)="save()">
                Save
            </app-flat-button>
        </div>
    </div>

    @if (loading) {
        <app-loader type="overlay" position="absolute" />
    }
</form>
