<form class="p-5" [formGroup]="form">
    <p class="text-lg">Duplicate Documents</p>
    <p class="text-sm mt-4 mb-4">
        A correction named “{{ name }}”
        <span class="text-semi">already exists</span> in documents’ versions.
        Please change the name of the new correction you are uploading.
    </p>
    <div style="min-height: 70px">
        <app-input
            label="CORRECTION NAME"
            formControlName="updatedDocumentName"
            [control]="updatedDocumentName"
            [required]="true">
            @if (updatedDocumentName.hasError('duplicateDocName')) {
                <ng-container error>
                    This name already exists in versions. Please enter a
                    different name.
                </ng-container>
            }
        </app-input>
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button
            [dataQa]="dataQa.duplicateDocumentsDialogCancel"
            (clicked)="onCloseClick()">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="cta"
            [dataQa]="dataQa.duplicateDocumentsDialogUpload"
            [disabled]="formInvalid"
            (clicked)="onUploadClick()">
            Upload
        </app-flat-button>
    </div>
</form>
