import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebViewerInstance } from '@pdftron/webviewer';

import { InputComponent } from '@shared/input/input.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { pageRangeValidator } from '@helpers/validators';
import { preparePages } from './utils';

@Component({
    selector: 'app-replace-menu-content',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        TextButtonComponent,
        FlatButtonComponent,
        InputComponent,
    ],
    styleUrl: 'apply-correction-dialog.component.scss',
    template: ` <div
        tabindex="0"
        class="insert-replace-menu color-white p-4"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <div class="grid gap-15">
            <div class="replace-layout text-xs">
                <span class="text-semi"> Replace Page(s) </span>
                <app-input
                    variant="tertiary"
                    class="whitespace-nowrap"
                    [formControl]="pageRangeDestination"
                    [control]="pageRangeDestination">
                </app-input>
                <span>from {{ destinationDocumentName }}</span>
                <span class="text-semi">With page(s)</span>
                <app-input
                    variant="tertiary"
                    class="whitespace-nowrap"
                    [formControl]="pageRangeSource"
                    [control]="pageRangeSource">
                </app-input>
                <span class="">
                    from {{ sourceDocumentName }}
                    <span class="correction-tag"> Uploaded Correction </span>
                </span>
            </div>
        </div>
        <div class="flex items-center justify-end gap-8 mt-3">
            @if (error) {
                <small class="color-status-red2">Enter valid page range</small>
            }
            <app-text-button (clicked)="cancelClicked()">
                Cancel
            </app-text-button>
            <app-flat-button
                variant="cta"
                [disabled]="!canReplace"
                (clicked)="replace()">
                Replace
            </app-flat-button>
        </div>
    </div>`,
})
export class ReplaceMenuContentComponent {
    @Input({ required: true }) vwInstance!: WebViewerInstance;
    @Input() sourceDocumentName: string | undefined = '';
    @Input() destinationDocumentName: string | undefined = '';
    @Output() closeMenu = new EventEmitter();
    @Output() replaced = new EventEmitter<{ log: string }>();

    error = false;
    pageRangeDestination = new FormControl('', [pageRangeValidator()]);
    pageRangeSource = new FormControl('', [pageRangeValidator()]);

    get canReplace(): boolean {
        return !!(
            this.pageRangeDestination.value &&
            this.pageRangeSource.value &&
            !this.pageRangeDestination.hasError('pageRange') &&
            !this.pageRangeSource.hasError('pageRange')
        );
    }

    replace() {
        if (this.pageRangeDestination.value && this.pageRangeSource.value) {
            this.error = false;
            const [dvLeft, dvRight] = this.vwInstance.Core.getDocumentViewers();
            const docLeft = dvLeft.getDocument();
            const docRight = dvRight.getDocument();

            const pagesToRemove = preparePages(this.pageRangeDestination.value);
            const pagesToAdd = preparePages(this.pageRangeSource.value);

            docLeft
                .insertPages(docRight, pagesToAdd, pagesToRemove[0])
                .then(() => {
                    const pageNumbersToRemove = pagesToRemove.map(
                        (i) => i + pagesToAdd.length,
                    );
                    docLeft
                        .removePages(pageNumbersToRemove)
                        .then(() => {
                            dvLeft.setCurrentPage(pageNumbersToRemove[0], true);
                            this.replaced.emit({
                                log: `Page(s) ${pagesToRemove.join(', ')} from ${this.destinationDocumentName} replaced with page(s) ${pagesToAdd.join(', ')} of ${this.sourceDocumentName}`,
                            });
                        })
                        .catch(() => (this.error = true));
                });
        }
    }

    cancelClicked() {
        this.closeMenu.emit();
    }
}
