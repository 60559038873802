import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { WebViewerInstance } from '@pdftron/webviewer';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { WebViewerComponent } from '@shared/webviewer/webviewer.component';
import { DiscrepancySummaryComponent } from './discrepancy-summary.component';
import { ResizableDirective } from '@directives/resizable.directive';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { RevisionDetails } from 'app/models/revision/revision-detail';
import { DiscrepancyDetail } from 'app/models/discrepancy';
import { createPDFDocument } from 'app/utils/helpers/file';
import { selectOriginalDocumentId } from 'app/store/selectors/document.selectors';
import DATA_QA from '@automation/data-qa.json';
import { PDFDocument } from '@constants/file';

@Component({
    standalone: true,
    templateUrl: 'accept-correction-dialog.component.html',
    styleUrls: [
        'accept-correction-dialog.component.scss',
        '../discrepancies-tab/discrepancies-tab.component.scss',
    ],
    imports: [
        MatDividerModule,
        ReactiveFormsModule,
        MatDialogModule,
        FlatButtonComponent,
        LoaderComponent,
        TabsComponent,
        WebViewerComponent,
        DiscrepancySummaryComponent,
        ResizableDirective,
    ],
})
export class AcceptCorrectionDialogComponent implements OnInit {
    error: boolean = false;
    loading: boolean = false;
    tabsData: { label: string }[] = [];
    selectedTabIndex: number = 0;
    discDetail = DiscrepancyDetail.initial();
    discDetailLoading: boolean = false;
    revisionDocument: PDFDocument | null = null;
    originalDocument: PDFDocument | null = null;
    originalDocumentId: number | null = null;
    documentLoading: boolean = false;
    readonly elementsToBeDisabled = ['ribbons', 'viewControlsButton'];
    readonly dataQa = DATA_QA;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { revisionDetails: RevisionDetails },
        public dialogRef: MatDialogRef<AcceptCorrectionDialogComponent>,
        private revisionService: RevisionService,
        private snackbarService: SnackbarService,
        private discrepancyService: DiscrepancyService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.getOriginalDocumentId();
        this.setsTabData();
        this.onTabChange();
        this.getDocuments();
    }

    get onlyOneDiscrepancy(): boolean {
        return this.data.revisionDetails.discrepancies.length === 1;
    }

    get title() {
        return this.onlyOneDiscrepancy
            ? `Accept Correction for Discrepancy ${this.data.revisionDetails.discrepancies[0].discrepancyNo}`
            : 'Accept Correction';
    }

    get discrepanciesText() {
        return this.data.revisionDetails.discrepancies
            .map((item) => `Discrepancy ${item.discrepancyNo}`)
            .join(', ');
    }

    accept() {
        this.error = false;
        this.loading = true;

        this.revisionService
            .acceptRevision(this.data.revisionDetails.id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            header: `Correction is accepted for ${this.discrepanciesText}! `,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    onTabChange(index: number | null = null) {
        this.discDetailLoading = true;
        const discrepancyId =
            this.data.revisionDetails.discrepancies[
                index ?? this.selectedTabIndex
            ].id;

        this.discrepancyService.getDiscrepancyDetails(discrepancyId).subscribe({
            next: (response) => (this.discDetail = response),
            complete: () => (this.discDetailLoading = false),
        });
    }

    onDocumentLoaded(vwInstance: WebViewerInstance) {
        this.insertCorrectedDocumentTag(vwInstance);
        this.hideCloseDocumentButton(vwInstance);
    }

    private insertCorrectedDocumentTag(vwInstance: WebViewerInstance) {
        const node = document.createElement('span');
        node.style.cssText = `color: #A0EC4C;
        border: 1px solid #4F7B00;
        padding: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap`;
        const text = document.createTextNode('Uploaded Correction');
        node.appendChild(text);
        const div = vwInstance.UI.iframeWindow.document.querySelector(
            '#header2 .file-name',
        );

        div?.insertAdjacentElement('afterend', node);
    }

    private hideCloseDocumentButton(vwInstance: WebViewerInstance) {
        const matches =
            vwInstance.UI.iframeWindow.document.querySelectorAll(
                '.control-buttons',
            );

        matches.forEach((match) => {
            const buttons = match.querySelectorAll('button');
            buttons[1].style.display = 'none';
        });
    }

    private getOriginalDocumentId() {
        const originalDocumentId$ = this.store.select(selectOriginalDocumentId);
        originalDocumentId$.subscribe({
            next: (id) => (this.originalDocumentId = id),
        });
    }

    private setsTabData() {
        this.tabsData = [
            ...this.data.revisionDetails.discrepancies.map((item) => ({
                label: `${item.discrepancyNo}`,
            })),
        ];
    }

    private getDocuments() {
        this.documentLoading = true;

        forkJoin([
            this.getRevision(this.data.revisionDetails.id),
            this.getOriginal(),
        ]).subscribe({
            next: ([revisionDoc, originalDoc]) => {
                this.revisionDocument = revisionDoc
                    ? createPDFDocument(revisionDoc)
                    : null;
                this.originalDocument = originalDoc
                    ? createPDFDocument(originalDoc)
                    : null;
            },
            complete: () => (this.documentLoading = false),
        });
    }

    private getRevision(id: number) {
        return this.revisionService.getRevisionFile(id);
    }

    private getOriginal() {
        if (this.originalDocumentId)
            return this.getRevision(this.originalDocumentId);

        return of(null);
    }
}
