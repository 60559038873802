import { Priority } from '@models/shared/priority';

export class UpdateDiscrepancyRequest {
    constructor(
        public priority?: Priority,
        public reason?: number,
        public pageRange?: string,
        public station?: number,
        public fixer?: number,
        public directions?: string,
        public notifyWithOnlySelectedPages?: boolean,
    ) {}
}
