import { Component } from '@angular/core';

import { AuthService } from '@services/api/auth/auth.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    imports: [FlatButtonComponent],
})
export class LoginPageComponent {
    constructor(private authService: AuthService) {}

    login() {
        this.authService.login();
    }
}
