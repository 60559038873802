<div class="p-5">
    <p class="text-lg mb-3">Discard Discrepancy {{ data.discrepancyNo }}?</p>
    <p>Discrepancy {{ data.discrepancyNo }} will be discarded.</p>
    <div class="flex justify-end items-center gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4"> Something went wrong </small>
        }
        <app-flat-button
            mat-dialog-close
            [dataQa]="dataQa.discardDiscrepancyDialogCancel">
            Cancel
        </app-flat-button>
        <app-flat-button
            variant="tertiary"
            [dataQa]="dataQa.discardDiscrepancyDialogDiscard"
            (clicked)="discard()">
            Discard
        </app-flat-button>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
