import { Component, inject, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import {
    SnackbarComponent,
    SnackbarComponentProps,
} from '@shared/snackbar/snackbar.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { BulkActionResultNotification } from '@models/discrepancy';

@Component({
    standalone: true,
    template: `<app-snackbar>
        <div class="grid gap-10">
            @if (succeededDiscrepancies) {
                <div class="flex items-center gap-5">
                    <mat-icon svgIcon="check" class="color-yellow-green8" />
                    <p>{{ succeededDiscrepancies }}</p>
                </div>
            }
            @if (failedDiscrepanciesCount > 0) {
                <div>
                    <div class="flex items-center gap-5">
                        <mat-icon svgIcon="close" class="color-status-red4" />
                        <p style="line-height: 24px;">
                            Selected
                            {{ failedDiscrepanciesCount }} discrepancies cannot
                            be renotified.
                        </p>
                    </div>
                    <div class="pl-7 grid gap-5">
                        @if (invalidStateDiscrepancies) {
                            <p>&#x2022; {{ invalidStateDiscrepancies }}</p>
                        }
                        @if (unauthorizedDiscrepancies) {
                            <p>&#x2022; {{ unauthorizedDiscrepancies }}</p>
                        }
                        @if (failedSystemDiscrepancies) {
                            <p>&#x2022; {{ failedSystemDiscrepancies }}</p>
                        }
                    </div>
                </div>
            }
        </div>
        <app-text-button class="ml-auto mt-2" (clicked)="copyMessage()">
            <mat-icon svgIcon="copy" />
            Copy Message
        </app-text-button>
    </app-snackbar>`,
    imports: [
        SnackbarComponent,
        MatIconModule,
        MatDividerModule,
        TextButtonComponent,
    ],
})
export class SummaryBulkRenotifyComponent implements OnInit {
    succeededDiscrepancies: string | undefined;
    invalidStateDiscrepancies: string | undefined;
    unauthorizedDiscrepancies: string | undefined;
    failedSystemDiscrepancies: string | undefined;
    failedDiscrepanciesCount: number = 0;

    private clipboard = inject(Clipboard);
    private data: SnackbarComponentProps<BulkActionResultNotification> =
        inject(MAT_SNACK_BAR_DATA);

    ngOnInit() {
        this.setSucceededDiscrepancies();
        this.setInvalidStateDiscrepancies();
        this.setUnauthorizedDiscrepancies();
        this.setFailedSystemDiscrepancies();
    }

    setSucceededDiscrepancies() {
        const discrepancies =
            this.data.notification?.succeededDiscrepancies ?? [];
        this.succeededDiscrepancies =
            discrepancies.length > 0
                ? `${discrepancies.join(', ')} renotified succesfully.`
                : undefined;
    }

    setInvalidStateDiscrepancies() {
        const discrepancies =
            this.data.notification?.invalidStateDiscrepancies ?? [];
        this.invalidStateDiscrepancies =
            discrepancies.length > 0
                ? `${discrepancies.join(', ')} (not in Await Correction state)`
                : undefined;
    }

    setUnauthorizedDiscrepancies() {
        const discrepancies =
            this.data.notification?.unauthorizedDiscrepancies ?? [];

        this.unauthorizedDiscrepancies =
            discrepancies.length > 0
                ? `${discrepancies.join(', ')} (no permission)`
                : undefined;
    }

    setFailedSystemDiscrepancies() {
        const discrepancies =
            this.data.notification?.failedLogicAppDiscrepancies ?? [];

        this.failedSystemDiscrepancies =
            discrepancies.length > 0
                ? `${discrepancies.join(', ')} (system failure)`
                : undefined;
    }

    setFailedDiscrepanciesCount() {
        if (this.data.notification) {
            const {
                invalidStateDiscrepancies,
                unauthorizedDiscrepancies,
                failedLogicAppDiscrepancies,
            } = this.data.notification;

            this.failedDiscrepanciesCount =
                invalidStateDiscrepancies.length +
                unauthorizedDiscrepancies.length +
                failedLogicAppDiscrepancies.length;
        }
        this.failedDiscrepanciesCount = 0;
    }

    copyMessage() {
        const message = [
            this.succeededDiscrepancies,
            this.invalidStateDiscrepancies,
            this.unauthorizedDiscrepancies,
            this.failedSystemDiscrepancies,
        ]
            .filter((i) => i)
            .join(' ');

        this.clipboard.copy(message);
    }
}
