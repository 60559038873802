import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { DocumentMetadataEditComponent } from 'app/components/documents-and-discrepancies/detail-dialog/document-metadata-edit/document-metadata-edit.component';

@Component({
    selector: 'app-metadata-page',
    standalone: true,
    imports: [DocumentMetadataEditComponent],
    template: `<app-document-metadata-edit
        [documentId]="documentId"
        (formChange)="onFormChanged($event)" />`,
})
export class MetadataPageComponent implements OnInit, OnDestroy {
    @ViewChild(DocumentMetadataEditComponent)
    metaEditComp!: DocumentMetadataEditComponent;
    documentId!: number;
    unsavedChanges = false;

    constructor(
        private route: ActivatedRoute,
        private unsavedChangesService: UnsavedChangesService,
    ) {}

    ngOnInit(): void {
        const id = this.route.parent?.snapshot.paramMap.get('id');
        if (id) {
            this.documentId = parseInt(id);
        }
    }

    ngOnDestroy(): void {
        this.unsavedChangesService.cleanup();
    }

    onFormChanged(change: boolean) {
        this.unsavedChanges = change;
        this.unsavedChangesService.hasUnsavedChanges = change;
    }

    hasUnsavedChanges(): boolean {
        return this.unsavedChanges;
    }

    save() {
        this.metaEditComp.onSave();
    }
}
