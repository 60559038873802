@if (loading) {
    <div class="w-full h-full flex bg-gray18 items-center justify-center">
        <app-loader size="medium" />
    </div>
} @else {
    <header>
        <app-branding-bar />
        @if (navbarVisible) {
            <app-navbar
                (tabChanged)="updateActiveTab($event)"
                (logoutClicked)="logout()" />
        }
    </header>
    <router-outlet />
}
