<app-panel
    headerPrimaryButtonLabel="Go to Details & Edit"
    [headerTitle]="document?.name ?? ''"
    [headerPrimaryButtonDisabled]="!canEditDocument"
    [headerPrimaryButtonDataQa]="dataQa.summaryPanelGoToDetailsButton"
    (primaryButtonClicked)="goToDetails()"
    (closeClicked)="closePanel()">
    <app-header-document-details headerContent [document]="document" />
    <div class="flex h-full">
        <div class="summary pt-4">
            <app-tabs
                tabLabelClass="ml-5"
                tabContentClass="pl-5"
                [tabs]="tabsData"
                [initialSelectedTabIndex]="initialSelectedTabIndex"
                (tabSelected)="onTabSelected($event)">
                @if (activeTab === tabs.METADATA) {
                    <app-document-metadata-tab [documentId]="documentId" />
                }
                @if (activeTab === tabs.DISCREPANCIES) {
                    <app-discrepancies-tab [documentId]="documentId" />
                }
                @if (activeTab === tabs.WF_INTERNALS) {
                    <app-internals-tab [documentId]="documentId" />
                }
            </app-tabs>
        </div>
        <div class="drag-handle" wfeResizable></div>
        <div class="documents">
            <app-document-revisions
                [documentId]="documentId"
                [canEditDocument]="canEditDocument"
                (revisionClicked)="onRevisionClicked($event)" />
        </div>
    </div>
</app-panel>
