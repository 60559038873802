export class PDFDocument {
    constructor(
        public blob: Blob,
        public name: string,
    ) {}
}

export enum FileType {
    PDF = 'application/pdf',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}

export const fileTypesMap = {
    [FileType.PDF]: '.pdf',
    [FileType.PNG]: '.png',
    [FileType.JPEG]: '.jpg,.jpeg',
};
