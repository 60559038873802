import { createReducer, on } from '@ngrx/store';

import { DocumentState } from '../state/document.state';
import {
    saveOriginalDocumentId,
    saveRevisionList,
    saveSelectedDiscrepancy,
    saveSummaryPanelData,
} from '../actions/document.actions';

export const initialState: DocumentState = {
    revisionList: [],
    originalDocumentId: null,
    selectedDocument: null,
    selectedDiscrepancy: null,
    selectedTab: null,
};

export const documentReducer = createReducer(
    initialState,
    on(
        saveRevisionList,
        (state, { revisionList }): DocumentState => ({
            ...state,
            revisionList,
        }),
    ),

    on(
        saveOriginalDocumentId,
        (state, { originalDocumentId }): DocumentState => ({
            ...state,
            originalDocumentId,
        }),
    ),
    on(
        saveSummaryPanelData,
        (
            state,
            {
                selectedDocument = null,
                selectedDiscrepancy,
                selectedTab = null,
            },
        ): DocumentState => ({
            ...state,
            selectedDocument,
            selectedDiscrepancy,
            selectedTab,
        }),
    ),
    on(
        saveSelectedDiscrepancy,
        (state, { selectedDiscrepancy }): DocumentState => ({
            ...state,
            selectedDiscrepancy,
        }),
    ),
);
