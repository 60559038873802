import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { RepositoryService } from '@services/api/repository/repository.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { WorkflowService } from '@services/api/workflow/workflow.service';
import { UserService } from '@services/api/user/user.service';
import { TableComponent } from '@grid-table/table.component';
import { ToggleComponent } from '@shared/toggle/toggle.component';
import {
    SelectComponent,
    SelectionChangeEvent,
} from '@shared/select/select.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { ChangeTableViewMenuComponent } from './change-table-view-menu/change-table-view-menu.component';
import { DiscrepencyViewComponent } from './discrepancy-view/discrepancy-view.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import { SummaryPanelComponent } from './summary-panel/summary-panel.component';
import { SubscriptionListType, SubscriptionList } from '@helpers/subscription';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { SelectOption } from '@models/shared.model';
import { StatusChipItem } from '@models/discrepancy/status-chip';
import { TableView } from '@constants/table';
import { selectSelectedDocument } from 'app/store/selectors/document.selectors';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import {
    ExcelExportDialogComponent,
    ExcelExportDialogComponentData,
} from './excel-export-dialog/excel-export-dialog.component';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-documents-discrepancies',
    standalone: true,
    templateUrl: './documents-and-discrepancies.component.html',
    styleUrl: './documents-and-discrepancies.component.scss',
    imports: [
        ChangeTableViewMenuComponent,
        TextButtonComponent,
        DiscrepencyViewComponent,
        DocumentViewComponent,
        TableComponent,
        SelectComponent,
        StatusFilterComponent,
        ToggleComponent,
        LoaderComponent,
        SummaryPanelComponent,
    ],
})
export class DocumentAndDiscrepenciesComponent implements OnInit, OnDestroy {
    @ViewChild(DiscrepencyViewComponent)
    discrepancyViewComponent: DiscrepencyViewComponent | undefined;
    @ViewChild(DocumentViewComponent)
    documentViewComponent: DocumentViewComponent | undefined;

    readonly TableView: typeof TableView = TableView;
    readonly dataQa = DATA_QA;
    loading = false;
    selectedTableView: TableView = TableView.Document;
    workflowInstanceOptions: SelectOption[] = [];
    repositoryOptions: SelectOption[] = [];
    statusChipsData: StatusChipItem[] = [];
    selectedWorkflow: number | undefined;
    selectedRepository: number[] = [];
    selectedStatuses: number[] = [];
    showMyItems: boolean = false;
    showDocuments = false;
    isAdmin = false;
    selectedDocument: number | null = null;
    private _workflowAndRepo: WorkflowAndRepository | undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private repositoryService: RepositoryService,
        private workflowService: WorkflowService,
        private discrepancyService: DiscrepancyService,
        private userService: UserService,
        private router: Router,
        private store: Store,
        private dialog: MatDialog,
    ) {
        this.handleTableRefresh();
    }

    get workflowAndRepo(): WorkflowAndRepository | undefined {
        return this._workflowAndRepo;
    }

    set workflowAndRepo(value: WorkflowAndRepository | undefined) {
        this._workflowAndRepo = value;
        this.getStatusChips();
        this.showDocuments = true;
    }

    get selectedViewComponent() {
        return this.documentViewComponent || this.discrepancyViewComponent;
    }

    ngOnInit() {
        this.getSummaryPanelData();
        this.userService.isAdminUser().subscribe((isAdmin) => {
            this.isAdmin = isAdmin;

            if (isAdmin) {
                this.loading = true;
                this._subscriptions['get-workflows'] = this.workflowService
                    .getWorkflows()
                    .subscribe({
                        next: (data) => {
                            this.workflowInstanceOptions = data.map((item) => ({
                                value: item.id,
                                viewValue: item.name,
                            }));
                        },
                        complete: () => {
                            this.loading = false;
                        },
                    });
            } else {
                this.workflowAndRepo = undefined;
            }
        });
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    onExportClicked() {
        const data: ExcelExportDialogComponentData = {
            tableView: this.selectedTableView,
            workflowAndRepo: this.workflowAndRepo,
            mine: this.showMyItems,
            filterModel: this.selectedViewComponent?.filterModel,
            columnState: this.selectedViewComponent?.columnState,
        };

        this.dialog.open(ExcelExportDialogComponent, {
            data,
            maxWidth: '686px',
            minWidth: '200px',
            width: '686px',
        });
    }

    onTableViewChanged(tableView: TableView) {
        this.selectedTableView = tableView;
    }

    onWorkflowInstanceChange(event: SelectionChangeEvent) {
        this.selectedWorkflow = event.value;
        this.repositoryOptions = [];
        this.repositoryService
            .getRepositories(event.value)
            .subscribe((data) => {
                this.repositoryOptions = data.map((item) => ({
                    value: item.id,
                    viewValue: item.name,
                }));

                if (event.isDefaultValue) {
                    this.setPreSelectedRepositoryItems(event.value);
                }
            });
    }

    onRepositoryChange(event: SelectionChangeEvent) {
        this.selectedRepository = event.value;
        this.workflowAndRepo = new WorkflowAndRepository(
            this.selectedWorkflow!,
            this.selectedRepository,
        );
        this.refreshTable();
    }

    onStatusFilterChange(filters: number[]) {
        this.selectedStatuses = [...filters];
        this.refreshTable();
    }

    onToggleMyItems(toggle: boolean) {
        this.showMyItems = toggle;
        this.refreshTable();
    }

    handleTableRefresh() {
        this.router.events.subscribe((val) => {
            if (
                val instanceof NavigationEnd &&
                val.urlAfterRedirects === '/documents-and-discrepancies'
            ) {
                if (
                    this.router.getCurrentNavigation()?.extras.state?.[
                        'refresh'
                    ]
                ) {
                    this.refreshTable();
                    this.getStatusChips();
                }
            }
        });
    }

    resetFilters() {
        const table =
            this.discrepancyViewComponent || this.documentViewComponent;
        table?.resetFilters();
    }

    isResetFiltersDisabled(): boolean {
        const table =
            this.discrepancyViewComponent || this.documentViewComponent;

        return table ? !table.filterApplied : true;
    }

    closePanel() {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: null,
                selectedDiscrepancy: null,
            }),
        );
    }

    private getSummaryPanelData() {
        const selectedDocument$ = this.store.select(selectSelectedDocument);
        selectedDocument$.subscribe({
            next: (data) => (this.selectedDocument = data),
        });
    }

    private refreshTable() {
        const table =
            this.discrepancyViewComponent || this.documentViewComponent;
        table?.refreshData();
    }

    private getStatusChips() {
        this.statusChipsData = [];
        this.discrepancyService
            .getDiscrepanciesStatusChips(this._workflowAndRepo)
            .subscribe((data) => (this.statusChipsData = data));
    }

    private setPreSelectedRepositoryItems(workflow: number) {
        this.selectedRepository = this.repositoryOptions.map(
            (option) => option.value as number,
        );
        this.selectedWorkflow = workflow;
        this.workflowAndRepo = new WorkflowAndRepository(
            this.selectedWorkflow,
            this.selectedRepository,
        );
    }
}
