import { Component, inject, OnDestroy } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TerminateTypeWithoutResolution } from '@models/discrepancy/terminate-types';
import { DiscrepancyIdNo } from '@models/discrepancy';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { interpolate } from '@helpers/index';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    standalone: true,
    templateUrl: 'confirm-final-version-dialog.component.html',
    imports: [LoaderComponent, FlatButtonComponent, MatDialogClose],
})
export class ConfirmFinalVersionDialogComponent implements OnDestroy {
    discrepancyNo!: number;
    triggerType: TerminateTypeWithoutResolution;
    documentNameToPublish = '';
    loading = false;
    error = false;
    buttonLabel = '';
    infoLabel = '';
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private store = inject(Store);
    private snackbarService = inject(SnackbarService);
    private discrepancyService = inject(DiscrepancyService);
    private readonly dialogRef = inject(
        MatDialogRef<ConfirmFinalVersionDialogComponent>,
    );
    readonly data: {
        triggerType: TerminateTypeWithoutResolution;
        documentNameToPublish: string;
    } & DiscrepancyIdNo = inject(MAT_DIALOG_DATA);

    constructor() {
        this.discrepancyNo = this.data.discrepancyNo;
        this.triggerType = this.data.triggerType;
        this.documentNameToPublish = this.data.documentNameToPublish;
        this.buttonLabel = this.getLabel().button;
        this.infoLabel = this.getLabel().info;
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }

    protected confirm() {
        if (this.triggerType === 'Cancel') {
            this.cancel();
        }

        if (this.triggerType === 'Unresolve') {
            this.unresolve();
        }

        if (this.triggerType === 'Discard') {
            this.discard();
        }
    }

    private cancel() {
        this.loading = true;
        this.error = false;

        this._subscriptions['cancel'] = this.discrepancyService
            .cancelDiscrepancy(this.data.id)
            .subscribe({
                next: (success) => {
                    this.loading = false;
                    if (success) {
                        this.onSuccess();
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    private unresolve() {
        this.loading = true;
        this.error = false;

        this._subscriptions['unresolve'] = this.discrepancyService
            .unresolveDiscrepancy(this.data.id)
            .subscribe({
                next: (success) => {
                    this.loading = false;
                    if (success) {
                        this.onSuccess();
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    private discard() {
        this.loading = true;
        this.error = false;

        this._subscriptions['discard'] = this.discrepancyService
            .discardDiscrepancy(this.data.id)
            .subscribe({
                next: (success) => {
                    this.loading = false;
                    if (success) {
                        this.onSuccess();
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    private onSuccess() {
        this.showSuccessMessage();
        this.dialogRef.close();
        this.store.dispatch(refreshActionRelatedData());
    }

    private showSuccessMessage() {
        this.snackbarService.success({
            variant: 'success',
            header: `Discrepancy ${this.data.discrepancyNo} is moved to “Recently Completed” and the WF document is ready to publish!`,
        });
    }

    private getLabel() {
        const label = {
            Cancel: 'Cancelled',
            Unresolve: 'Unresolved',
            Discard: 'Discarded',
        }[this.triggerType];

        return {
            button: interpolate(
                'Confirm Final Version & Mark Discrepancy as {label}',
                { label },
            ),
            info: interpolate('Mark Discrepancy {discrepancyNo} as {label}', {
                discrepancyNo: this.discrepancyNo,
                label,
            }),
        };
    }
}
