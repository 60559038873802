import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { LoaderComponent } from '@shared/loader/loader.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { DocumentService } from '@services/api/document/document.service';
import { DocumentInternals } from '@models/document/internals';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    selector: 'app-internals-tab',
    standalone: true,
    templateUrl: './internals-tab.component.html',
    imports: [LoaderComponent, ContentFieldComponent],
})
export class InternalsTabComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) documentId: number | null = null;

    internals: DocumentInternals | null = null;
    loading = false;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(private documentService: DocumentService) {}

    ngOnInit(): void {
        if (this.documentId) {
            this.getInternals(this.documentId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];

        if (documentIdChange && !documentIdChange.firstChange) {
            this.getInternals(documentIdChange.currentValue);
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    private getInternals(id: number) {
        this.loading = true;
        this._subscriptions['get-internals'] = this.documentService
            .getInternals(id)
            .subscribe({
                next: (data) => (this.internals = data),
                complete: () => (this.loading = false),
            });
    }
}
