export class BulkRequestItem {
    constructor(
        public documentId: number,
        public discrepancies: number[],
    ) {}
}

export class BulkActionResultNotification {
    constructor(
        public kind: NotificationKind,
        public succeededDiscrepancies: number[],
        public invalidStateDiscrepancies: number[],
        public unauthorizedDiscrepancies: number[],
        public failedLogicAppDiscrepancies: number[],
    ) {}
}

export enum NotificationKind {
    Taken = 0,
    Renotify = 1,
}
