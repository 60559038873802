import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
    DiscrepancyColumn,
    DocumentColumn,
    DocumentNestedColumn,
} from '@constants/table';
import { ColumnOption, ColumnToggleOptions } from '@models/shared.model';
import { toggleItem } from '@helpers/array';

@Injectable({
    providedIn: 'root',
})
export class ColumnOptionsService {
    private columnOptions = new BehaviorSubject<
        ColumnToggleOptions<DocumentColumn>
    >({ lastToggled: null, hiddenColumns: [] });
    private nestedColumnOptions = new BehaviorSubject<{
        hiddenColumns: DocumentNestedColumn[];
    }>({ hiddenColumns: [] });
    private discColumnOptions = new BehaviorSubject<
        ColumnToggleOptions<DiscrepancyColumn>
    >({
        lastToggled: null,
        hiddenColumns: [],
    });

    columnOptions$ = this.columnOptions.asObservable();
    nestedColumnOptions$ = this.nestedColumnOptions.asObservable();
    discColumnOptions$ = this.discColumnOptions.asObservable();

    updateColumnOptions(
        option: Pick<ColumnOption<DocumentColumn>, 'id' | 'checked'>,
    ) {
        const currentOptions = this.columnOptions.value;

        this.columnOptions.next({
            lastToggled: option,
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }

    updateNestedColumnOptions(
        option: Pick<ColumnOption<DocumentNestedColumn>, 'id' | 'checked'>,
    ) {
        const currentOptions = this.nestedColumnOptions.value;

        this.nestedColumnOptions.next({
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }

    updateDiscColumnOptions(
        option: Pick<ColumnOption<DiscrepancyColumn>, 'id' | 'checked'>,
    ) {
        const currentOptions = this.discColumnOptions.value;

        this.discColumnOptions.next({
            lastToggled: option,
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }
}
