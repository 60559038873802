import { CommonModule } from '@angular/common';
import {
    AfterContentChecked,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import {
    MatSelect,
    MatSelectChange,
    MatSelectModule,
} from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';

import { SelectOption, SelectOptionWithGroup } from '@models/shared.model';
import { isNil } from '@helpers/index';

/**
 * @title Select
 */
@Component({
    selector: 'app-select',
    standalone: true,
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDividerModule,
        MatCheckboxModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent
    implements ControlValueAccessor, OnInit, AfterContentChecked
{
    @Input() variant: 'primary' | 'secondary' = 'primary';
    @Input() label: string = '';
    @Input() description: string = '';
    @Input() dataQa = '';
    @Input({ required: true }) options:
        | SelectOption[]
        | SelectOptionWithGroup[] = [];
    @Input() value:
        | string
        | string[]
        | number
        | number[]
        | (string | number)[]
        | undefined
        | null;
    @Input() placeholder: string = '';
    @Input() multiple: boolean = false;
    @Input() noneOption: boolean = false;
    @Input() required: boolean = false;
    @Output() selectionChange = new EventEmitter<SelectionChangeEvent>();
    @ViewChild('select') select!: MatSelect;
    isGroupedOptions: boolean = false;
    allSelected = false;

    get groupedOptions(): SelectOptionWithGroup[] {
        return this.options.filter(
            (opt): opt is SelectOptionWithGroup => 'items' in opt,
        );
    }

    get flatOptions(): SelectOption[] {
        return this.options.filter(
            (opt): opt is SelectOption => !('items' in opt),
        );
    }

    get disabled() {
        return this.options.length === 0;
    }

    ngOnInit(): void {
        this.isGroupedOptions =
            this.options.length > 0 && 'items' in this.options[0];
    }
    ngAfterContentChecked() {
        this.allSelected = this.checkAllSelected();
    }

    onChange = (_value: string) => {};

    onTouched = () => {};

    onSelectionChange(event: MatSelectChange) {
        this.selectionChange.emit({ value: event.value });
        this.onChange(event.value);
    }

    onSelectAllClick() {
        const selectedValues = this.checkAllSelected()
            ? []
            : this.flatOptions.map((option) => option.value);
        this.value = selectedValues;
        this.selectionChange.emit({
            value: selectedValues,
        });
    }

    displayText() {
        if (Array.isArray(this.value)) {
            const currentValue: string[] | number[] | (string | number)[] =
                this.value;

            return currentValue.length === 1
                ? this.flatOptions.filter(
                      (option) => option.value === currentValue[0],
                  )[0]?.viewValue
                : `${this.value?.length} Selected`;
        }
        return '';
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private checkAllSelected() {
        return (
            this.select?.options.toArray().every((option) => option.selected) ??
            false
        );
    }
}

export interface SelectionChangeEvent {
    value: any;
    isDefaultValue?: boolean;
}
