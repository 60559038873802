<div headerContent class="document-info flex items-center">
    <app-content-field label="DISCREPANCY PROGRESS" [rowGap]="false">
        <span class="flex items-center gap-7 text-xxs">
            <mat-progress-bar
                mode="determinate"
                style="width: 100px; height: 12px"
                [value]="progressValue" />
            @if (document) {
                {{ document.progress.completed }}/{{ document.progress.total }}
                Completed
            }
        </span>
    </app-content-field>
    @if (document) {
        <app-content-field label="PRIORITY" [rowGap]="false">
            <span [wfePriorityField]="document.priority"></span>
        </app-content-field>
        <app-content-field label="LAST ACTIVITY" [rowGap]="false">
            <span class="flex items-center">
                {{ document.latestActivity.createdAt }}
                <app-tooltip>
                    <mat-icon trigger svgIcon="info" />
                    <div content>
                        {{ document.latestActivity.logMessage }}
                        <b>({{ document.latestActivity.user }})</b>
                    </div>
                </app-tooltip>
            </span>
        </app-content-field>
    }
</div>
