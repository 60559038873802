import { Patterns } from '@constants/patterns';

export const interpolate = (str: string, replacements: any) => {
    return str.replace(Patterns.PLACEHOLDER, (_, key) => {
        return replacements[key] !== undefined ? replacements[key] : `{${key}}`;
    });
};

export const isNil = <T>(val: T) => val == null;

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce(
        (groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        },
        {} as Record<K, T[]>,
    );

export const isNumber = (val: any) => typeof val === 'number' && val === val;

export const parseIfInteger = (value: string) => {
    return Number.isInteger(parseFloat(value)) ? parseInt(value) : value;
};
