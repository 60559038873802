import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { SharedMapper } from '@services/api/shared.mapper';
import { SimpleResponse } from '@models/api.model';
import { interpolate } from 'app/utils/helpers';
import { DropdownItem } from '@models/shared.model';

@Injectable({
    providedIn: 'root',
})
export class RepositoryService {
    private readonly _path = 'workflow/v1';

    constructor(
        private api: ApiService,
        private mapper: SharedMapper,
    ) {}

    getRepositories(workflow: number): Observable<DropdownItem[]> {
        return this.api
            .get<
                DropdownItem[]
            >(this._path + interpolate(endpoints.repositoryOfWorkflow, { workflow }))
            .pipe(
                map((response: SimpleResponse<DropdownItem[]>) =>
                    response.data.map(this.mapper.toDropdownItem),
                ),
                catchError(() => of([])),
            );
    }
}
