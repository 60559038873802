import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { interpolate } from '@helpers/index';
import { SimpleResponse } from '@models/api.model';
import {
    RejectRevisionRequest,
    AcceptRevisionRequest,
    RevisionDetails,
} from '@models/revision';
import { RevisionMapper } from './revision.mapper';

@Injectable({
    providedIn: 'root',
})
export class RevisionService {
    private readonly _path = 'v1';

    constructor(
        private api: ApiService,
        private mapper: RevisionMapper,
    ) {}

    rejectRevision(
        id: number,
        data: RejectRevisionRequest,
    ): Observable<boolean> {
        return this.api
            .post<RejectRevisionRequest>(
                this._path + interpolate(endpoints.rejectRevision, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    acceptRevision(
        id: number,
        data: AcceptRevisionRequest,
    ): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.acceptRevision, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    getRevisionDetails(id: number): Observable<RevisionDetails | null> {
        return this.api
            .get(this._path + interpolate(endpoints.revisionById, { id }))
            .pipe(
                map((response: SimpleResponse<RevisionDetails>) =>
                    this.mapper.toRevisionDetails(response.data),
                ),
                catchError(() => of(null)),
            );
    }

    getRevisionFile(id: number): Observable<HttpResponse<Blob> | null> {
        return this.api
            .getBlob(this._path + interpolate(endpoints.revisionFile, { id }))
            .pipe(catchError(() => of(null)));
    }

    getRevisionLogs(id: number) {
        return this.api
            .get(this._path + interpolate(endpoints.revisionLogs, { id }))
            .pipe(
                map(
                    (response: SimpleResponse<{ logs: string[] }>) =>
                        response.data?.logs ?? [],
                ),
                catchError(() => of([])),
            );
    }

    updateRevision(id: number, formData: FormData) {
        return this.api
            .put(
                this._path + interpolate(endpoints.revisionById, { id }),
                formData,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }
}
