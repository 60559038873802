<div class="page">
    <ng-content select="[header]" />
    <div class="pos-rel w-full h-full">
        @if (error) {
            <div class="h-full p-4 text-s color-white text-wrap">
                {{ errorMessage }}
            </div>
        }
        <div #viewer class="viewer w-full h-full" [class.hidden]="error"></div>
        @if (loading) {
            <app-loader class="loading" />
        }
    </div>
</div>
