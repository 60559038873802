import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';

@Component({
    standalone: true,
    selector: 'app-tabs',
    imports: [CommonModule, MatRippleModule, TooltipComponent],
    templateUrl: './tabs.component.html',
    styleUrl: './tabs.component.scss',
})
export class TabsComponent implements OnInit, OnChanges {
    @Input({ required: true }) tabs: { label: string; tooltip?: string }[] = [];
    @Input() alignment: 'start' | 'center' | 'end' = 'start';
    @Input() initialSelectedTabIndex: number | null = null;
    @Input() tabLabelClass = '';
    @Input() tabContentClass = '';
    @Input() scrollContentStyle = '';
    @Output() tabSelected = new EventEmitter<number>();
    @ViewChild('scrollContent') scrollContent!: ElementRef<HTMLDivElement>;

    selectedTabIndex: number = 0;

    get labelClass() {
        return {
            'justify-start': this.alignment === 'start',
            'justify-end': this.alignment === 'end',
            'justify-center': this.alignment === 'center',
            [this.tabLabelClass]: this.tabLabelClass,
        };
    }

    ngOnInit(): void {
        if (this.initialSelectedTabIndex) {
            this.selectedTabIndex = this.initialSelectedTabIndex;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const initialSelectedTabIndexChange =
            changes['initialSelectedTabIndex'];

        if (
            initialSelectedTabIndexChange &&
            !initialSelectedTabIndexChange.firstChange
        ) {
            this.selectedTabIndex = initialSelectedTabIndexChange.currentValue;
        }
    }

    selectTab(index: number): void {
        this.selectedTabIndex = index;
        this.tabSelected.emit(index);
    }

    canScroll() {
        try {
            const container = this.scrollContent.nativeElement;
            return (
                container.scrollLeft > 0 ||
                container.scrollLeft <
                    container?.scrollWidth - container?.clientWidth
            );
        } catch {
            return false;
        }
    }

    canScrollLeft() {
        const container = this.scrollContent.nativeElement;
        return container.scrollLeft;
    }

    canScrollRight() {
        const container = this.scrollContent.nativeElement;

        return (
            container.scrollLeft + container.clientWidth <
            container.scrollWidth - 5
        );
    }

    scrollLeft() {
        const container = this.scrollContent.nativeElement;
        const totalScrolled = container.scrollLeft;
        const items = Array.from(
            container.querySelectorAll('.tab'),
        ) as HTMLElement[];

        let previousItem: HTMLElement | null = null;
        for (let i = items.length - 1; i >= 0; i--) {
            const item = items[i];

            if (totalScrolled) {
                previousItem = item;
                break;
            }
        }

        if (previousItem) {
            container.scrollBy({
                left: -(previousItem.offsetWidth + 20),
                behavior: 'smooth',
            });
        }
    }

    scrollRight() {
        const container = this.scrollContent.nativeElement;
        const visibleWidth = container.offsetWidth;
        const totalScrolled = container.scrollLeft;
        const items = Array.from(
            container.querySelectorAll('.tab'),
        ) as HTMLElement[];

        let nextItem: HTMLElement | null = null;
        for (let i = items.length - 1; i >= 0; i--) {
            const item = items[i];
            const itemRightEdge = item.offsetLeft + item.offsetWidth;
            if (itemRightEdge > totalScrolled + visibleWidth) {
                nextItem = item;

                break;
            }
        }
        if (nextItem) {
            container.scrollBy({
                left: nextItem.offsetWidth + 15,
                behavior: 'smooth',
            });
        }
    }
}
