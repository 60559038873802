export type DiscrepancyTerminateType =
    | TerminateTypeWithoutResolution
    | TerminateTypeWithResolution;

export type TerminateTypeWithResolution = 'Close Resolve';

export type TerminateTypeWithoutResolution = 'Cancel' | 'Discard' | 'Unresolve';

export const TERMINATE_WITHOUT_RESOLUTION: DiscrepancyTerminateType[] = [
    'Cancel',
    'Discard',
    'Unresolve',
];
