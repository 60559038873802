<div class="p-5" [class.disabled]="loading">
    <p class="mb-1">{{ infoLabel }}</p>
    <p class="text-lg mb-3">
        Please Confirm Final Version to Be Published to RMS
    </p>
    <p class="mt-4">
        <b>{{ documentNameToPublish }}</b> will be marked as “Final Version”
        which will be published to RMS.
    </p>
    <div class="flex flex-wrap justify-end items-center gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4"> Something went wrong </small>
        }
        <app-flat-button mat-dialog-close> Cancel </app-flat-button>
        <app-flat-button variant="cta" (clicked)="confirm()">
            {{ buttonLabel }}
        </app-flat-button>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
