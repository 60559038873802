import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-content-field',
    standalone: true,
    template: `<div>
        <p class="text-xxs color-gray14" [class.mb-1]="rowGap">
            {{ label }} {{ required ? '*' : '' }}
        </p>
        <p class="text-s color-black text-pretty">
            <ng-content />
        </p>
    </div>`,
    styles: `
        :host {
            display: flex;
        }
    `,
})
export class ContentFieldComponent {
    @Input() label: string = '';
    @Input() required: boolean = false;
    @Input() rowGap: boolean = true;
}
