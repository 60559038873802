import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { SimpleResponse } from '@models/api.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationsService {
    private readonly _path = 'v1';
    private tenantId = new BehaviorSubject<number | null>(null);

    tenantId$ = this.tenantId.asObservable();

    private api = inject(ApiService);

    getTenantId() {
        return this.api
            .get(this._path + endpoints.documentServiceTenantId)
            .pipe(
                map(
                    (
                        response: SimpleResponse<{
                            tenantId: number;
                        }>,
                    ) => {
                        this.tenantId.next(response.data.tenantId);
                    },
                ),
            );
    }
}
