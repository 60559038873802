import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '@services/api/auth/auth.service';
import { environment } from '@environments/environment';
import { NotificationMessage } from 'app/models/shared.model';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    private hubConnection!: signalR.HubConnection;
    private readonly apiUrl = environment.apiUrl;
    private readonly _path = 'v1/notifications/hub';
    private notification = new BehaviorSubject<NotificationMessage | null>(
        null,
    );

    notification$ = this.notification.asObservable();

    constructor(private authService: AuthService) {
        this.startConnection();
        this.addListeners();
    }

    stopConnection() {
        this.hubConnection
            .stop()
            .then(() => console.log('Connection stopped'))
            .catch((err) => console.error('Connection could not stop: ', err));
    }

    private startConnection() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl(`${this.apiUrl}/${this._path}`, {
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => this.authService.getToken(),
            })
            .withAutomaticReconnect()
            .build();

        this.hubConnection
            .start()
            .then(() => console.log('Connection started'))
            .catch((err) => console.error('Connection could not start:', err));
    }

    private addListeners() {
        this.hubConnection.on('notification', (msg: NotificationMessage) => {
            this.notification.next(msg);
        });
    }
}
