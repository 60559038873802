import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { DocumentService } from '@services/api/document/document.service';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/api/user/user.service';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { ProgressBarComponent } from '@shared/progress-bar/progress-bar.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { PriorityFieldComponent } from '@shared/priority-field/priority-field.component';
import { PrioritySelectComponent } from '@shared/select/priority-select/priority-select.component';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { WorkflowDocument } from '@models/document';
import {
    Priority,
    priorityMap,
    priorityOptions,
} from '@models/shared/priority';

@Component({
    selector: 'app-header-document-details',
    standalone: true,
    templateUrl: 'header-document-details.component.html',
    styles: `
        .document-info {
            flex-wrap: wrap;
            white-space: nowrap;
            column-gap: 30px;
            row-gap: 10px;
        }
    `,
    imports: [
        MatIconModule,
        ReactiveFormsModule,
        ContentFieldComponent,
        TooltipComponent,
        ProgressBarComponent,
        PrioritySelectComponent,
        PriorityFieldComponent,
    ],
})
export class HeaderDocumentDetailsComponent implements OnInit, OnChanges {
    @Input({ required: true }) document: WorkflowDocument | null = null;
    @Input() mode: 'summary' | 'detail' = 'summary';
    @Input() isAdmin: boolean = false;

    form!: FormGroup;
    formChanged: boolean = false;
    progressValue = 0;
    prioOptions = structuredClone(priorityOptions);
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private formBuilder: FormBuilder,
        private documentService: DocumentService,
        private snackbarService: SnackbarService,
        private userService: UserService,
    ) {}

    get allCompleted() {
        return (
            this.document &&
            this.document.progress.completed === this.document.progress.total
        );
    }

    ngOnInit() {
        this.setUserPermission();
        this.initForm();
        this.handleFormChange();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.progressValue = 0;

        const documentChange = changes['document'];
        if (documentChange?.currentValue?.progress) {
            const currentProgress = documentChange?.currentValue?.progress;
            this.progressValue =
                (currentProgress.completed * 100) / currentProgress.total;
        }
    }

    private initForm() {
        this.form = this.formBuilder.group({
            priority: new FormControl(this.document?.priority),
        });
    }

    private handleFormChange() {
        this._subscriptions['form-value-change'] = this.form.valueChanges
            .pipe(debounceTime(250))
            .pipe(distinctUntilChanged())
            .subscribe(() => this.updateDocumentPriority());
    }

    private updateDocumentPriority() {
        if (this.document) {
            this.documentService
                .updateDocument(this.document.id, this.form.value)
                .subscribe({
                    next: (res) => {
                        if (res) {
                            this.snackbarService.success({
                                header: `Document priority changed to ${priorityMap[this.form.value.priority as Priority].text}`,
                            });
                        } else {
                            this._subscriptions.unsubscribeAllSafe();
                            this.snackbarService.error({
                                header: 'Document priority could not be changed',
                            });
                            this.form.patchValue({
                                priority: this.document!.priority,
                            });
                            this.handleFormChange();
                        }
                    },
                });
        }
    }

    private setUserPermission() {
        this.userService.isAdminUser().subscribe({
            next: (isAdmin) => (this.isAdmin = isAdmin),
        });
    }
}
