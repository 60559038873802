import { IdDisplayPair } from '../shared.model';
import { Priority } from '@models/shared/priority';

export class DiscrepancyResponse {
    constructor(
        public id: number,
        public reason: IdDisplayPair | null,
        public priority: Priority,
        public status: string,
        public createdAt: string,
        public updatedAt: string,
        public taken: string | null,
        public analyst: IdDisplayPair | null,
        public station: IdDisplayPair | null,
        public responsibleFixer: IdDisplayPair | null,
        public documentName: string,
        public documentId: number,
        public isDraft: boolean,
        public discrepancyNo: number,
        public age: number | null,
    ) {}
}

export class Discrepancy {
    constructor(
        public id: number,
        public reason: string,
        public priority: Priority,
        public status: string,
        public createdAt: string,
        public updatedAt: string,
        public taken: string,
        public analyst: string,
        public station: string,
        public responsibleFixer: string,
        public isDraft: boolean,
        public documentName: string,
        public documentId: number,
        public discrepancyNo: number,
        public age: number | null,
    ) {}
}

export type DiscrepancyIdNo = Pick<Discrepancy, 'id' | 'discrepancyNo'>;
