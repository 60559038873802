import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { interpolate } from 'app/utils/helpers';
import { SimpleResponse } from '@models/api.model';
import { UserInfo } from '@models/user/user-info';

@Injectable({
    providedIn: 'root',
})
export class StationService {
    private readonly _path = 'v1';

    private api = inject(ApiService);

    constructor() {}

    getStationFixers(id: number): Observable<UserInfo[]> {
        return this.api
            .get(this._path + interpolate(endpoints.stationFixers, { id }))
            .pipe(
                map(
                    (response: SimpleResponse<{ fixers: UserInfo[] }>) =>
                        response.data.fixers,
                ),
                catchError(() => of([])),
            );
    }
}
