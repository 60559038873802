import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    standalone: true,
    selector: 'app-branding-bar',
    templateUrl: './branding-bar.component.html',
    imports: [MatIconModule],
    styleUrl: './branding-bar.component.scss',
})
export class BrandingBarComponent {
    constructor() {}
}
