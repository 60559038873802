@if (detailsLoading) {
    <app-loader />
} @else {
    <form class="pr-2" [formGroup]="form">
        @if (
            mode === 'detail' &&
            discDetail.currentState &&
            workflowStates.length > 0
        ) {
            <app-stepper
                [steps]="workflowStates"
                [activeStep]="discDetail.currentState.id" />
            <mat-divider />
        }
        <div class="info-section bordered">
            <app-content-field label="ANALYST">
                {{ discDetail.analyst?.displayName | dashIfEmpty }}
            </app-content-field>
            <app-content-field label="STATUS">
                <span wfeGrayBox>
                    {{ discDetail.status }}
                </span>
            </app-content-field>
            <app-content-field label="COMPLETION">
                {{ completion | dashIfEmpty }}
            </app-content-field>
            @if (readOnly || !isAdmin) {
                <app-content-field label="PRIORITY">
                    <app-priority-field
                        [wfePriorityField]="discDetail.priority" />
                </app-content-field>
            } @else {
                <app-priority-select
                    label="PRIORITY"
                    variant="secondary"
                    formControlName="priority"
                    selectStyle="height: 22px"
                    [value]="discDetail.priority"
                    [options]="editOptions.priority" />
            }
            <app-content-field label="CREATE DATE">
                {{ discDetail.createdAt }}
            </app-content-field>
        </div>
        <mat-divider />
        <div class="info-section">
            @if (readOnly) {
                <app-content-field label="REASON">
                    {{ discDetail.reason?.displayName }}
                </app-content-field>
            } @else {
                <app-select
                    label="REASON"
                    formControlName="reason"
                    [required]="true"
                    [options]="editOptions.reason"
                    [value]="discDetail.reason?.backingField" />
            }
            @if (readOnly) {
                <app-content-field label="PAGE RANGE">
                    {{ discDetail.pageRange | dashIfEmpty }}
                </app-content-field>
            } @else {
                <div>
                    <app-input
                        label="PAGE RANGE *"
                        formControlName="pageRange"
                        [control]="pageRange">
                        <app-checkbox
                            class="ml-auto"
                            size="small"
                            label="Send only selected pages"
                            formControlName="notifyWithOnlySelectedPages"
                            [dataQa]="
                                dataQa.notifyWithOnlySelectedPagesCheckbox
                            "
                            [disabled]="docSizeExceedMaxLimit()"
                            [checked]="
                                discDetail.notifyWithOnlySelectedPages
                            " />
                        @if (pageRange.hasError('pageRange')) {
                            <app-input-invalid-page-range error />
                        }
                    </app-input>
                    @if (docSizeExceedMaxLimit()) {
                        <app-page-range-error [discDetail]="discDetail" />
                    }
                </div>
            }
        </div>
        <div class="info-section">
            @if (readOnly) {
                <app-content-field label="RESPONSIBLE STATION">
                    {{ discDetail.station?.displayName }}
                </app-content-field>
            } @else {
                <app-select
                    label="RESPONSIBLE STATION"
                    formControlName="station"
                    [required]="true"
                    [value]="discDetail.station?.backingField"
                    [options]="editOptions.station" />
            }
            <app-fixer-field
                [id]="id"
                [readOnly]="readOnly"
                [discrepancyFixers]="discrepancyFixers"
                [stationId]="discDetail.station?.backingField"
                (fixerUpdated)="updateFixer($event)" />
        </div>
        <div class="info-section">
            @if (readOnly) {
                <app-content-field label="DIRECTIONS">
                    {{ discDetail.directions | dashIfEmpty }}
                </app-content-field>
            } @else {
                <app-textarea
                    label="DIRECTIONS"
                    formControlName="directions"
                    [maxLength]="maxDirectionsLength" />
            }
        </div>
        @if (mode === 'detail' && !readOnly) {
            <div class="info-section">
                <app-select
                    label="SELECT A VERSION TO SEND FOR CORRECTION:"
                    formControlName="revision"
                    [options]="editOptions.revision" />
            </div>
        }
    </form>
    <app-activity-log [discrepancyId]="discDetail.id" />
    @if (mode === 'detail') {
        <ng-container>
            <div class="actions ml-n4 pl-2 pr-4">
                @if (discDetail) {
                    <app-footer-assign-actions
                        [discDetail]="discDetail"
                        [editOptions]="editOptions"
                        [isAdmin]="isAdmin" />
                    <app-footer-state-actions
                        [discDetail]="discDetail"
                        [canSave]="canSave"
                        [canNotify]="form.valid && !!discDetail.primaryFixer"
                        [editForm]="form"
                        [editOptions]="editOptions" />
                }
            </div>
        </ng-container>
    }
}
