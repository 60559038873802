{
    "login": "/account/login",
    "logout": "/account/logout",
    "oidcToken": "account/oidc-token",
    "documentServiceTenantId": "/configurations/document-service/tenant-id",
    "documents": "/documents",
    "documentById": "/documents/{id}",
    "documentFiles": "/documents/{id}/files",
    "documentPublish": "/documents/{id}/publish",
    "documentFilesContent": "/documents/{id}/files/{serviceId}",
    "documentsDiscrepancies": "/documents/{id}/discrepancies",
    "documentsMetadata": "/documents/{id}/metadata",
    "documentsMetadataOptions": "/documents/{id}/metadata/options",
    "documentsFilterOptions": "/documents/filter-options",
    "documentsInternals": "/documents/{id}/internals",
    "documentIsMine": "/documents/{id}/mine",
    "docDiscrepancyCreateOptions": "/documents/{id}/discrepancies/create-options",
    "documentRevisions": "/documents/{id}/revisions",
    "documentRevisionsOriginal": "/documents/{id}/revisions/original",
    "documentExport": "/documents/excel",
    "documentAppliedVersion": "/documents/{id}/revisions/applied-version",
    "documentAppliedVersionById": "/documents/{id}/revisions/{revisionId}/applied-version",
    "documentFinalVersion": "/documents/{id}/revisions/final-version",
    "documentResolutionDecision": "/documents/{id}/resolution-decision",
    "discrepancies": "/discrepancies",
    "discrepancyById": "/discrepancies/{id}",
    "discrepancyDetails": "/discrepancies/{id}/details",
    "discrepancyActivityLogs": "/discrepancies/{id}/activity-logs",
    "discrepancyFixers": "/discrepancies/{id}/fixers",
    "discrepanciesFilterOptions": "/discrepancies/filter-options",
    "discrepancyEditOptions": "/discrepancies/{id}/edit-options",
    "discrepancyStatusChips": "/discrepancies/status-chips",
    "discrepancyRevisions": "/discrepancies/{id}/revisions",
    "takeDiscrepancy": "/discrepancies/{id}/take",
    "putbackDiscrepancy": "/discrepancies/{id}/putback",
    "assignDiscrepancy": "/discrepancies/{id}/assign",
    "unassignDiscrepancy": "/discrepancies/{id}/unassign",
    "notifyDiscrepancy": "/discrepancies/{id}/notify",
    "renotifyDiscrepancy": "/discrepancies/{id}/renotify",
    "cancelDiscrepancy": "/discrepancies/{id}/cancel",
    "unresolveDiscrepancy": "/discrepancies/{id}/unresolve",
    "recallForEditDiscrepancy": "/discrepancies/{id}/recall-for-edit",
    "recallDiscrepancy": "/discrepancies/{id}/recall",
    "discardDiscrepancy": "/discrepancies/{id}/discard",
    "closeResolveDiscrepancy": "/discrepancies/{id}/close-resolve",
    "discrepancyExport": "/discrepancies/excel",
    "bulkRenotify": "/discrepancies/renotify",
    "repositoryOfWorkflow": "/repositories/{workflow}",
    "stationFixers": "/stations/{id}/fixers",
    "userInfo": "/users/me",
    "userInboxChoices": "/users/me/inbox-choices",
    "workflows": "/workflows",
    "workflowById": "/workflows/{id}",
    "workflowStepper": "/workflows/{id}/stepper",
    "revisionById": "/revisions/{id}",
    "rejectRevision": "/revisions/{id}/reject",
    "acceptRevision": "/revisions/{id}/accept",
    "revisionFile": "/revisions/{id}/file",
    "revisionLogs": "/revisions/{id}/logs",
    "getDocument": "/document-service/documents/{id}"
}
