import { createAction, props } from '@ngrx/store';

import { DocumentDetailTab } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';

export const saveRevisionList = createAction(
    '[Document] Save Revision List',
    props<{ revisionList: string[] }>(),
);

export const saveOriginalDocumentId = createAction(
    '[Document] Original DocumentId',
    props<{ originalDocumentId: number | null }>(),
);

export const saveSummaryPanelData = createAction(
    '[Document] Save Summary Panel Data',
    props<{
        selectedDocument?: number | null;
        selectedDiscrepancy: number | null;
        selectedTab?: DocumentDetailTab | null;
    }>(),
);

export const saveSelectedDiscrepancy = createAction(
    '[Document] Save Selected Discrepancy',
    props<{
        selectedDiscrepancy: number | null;
    }>(),
);
