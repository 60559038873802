<div
    class="text-s text-med flex items-center gap-5"
    [matMenuTriggerFor]="rangeFilterMenu">
    <mat-icon svgIcon="module-settings" />
    {{ filterText }}
</div>
<mat-menu
    style="margin-left: 50px"
    #rangeFilterMenu="matMenu"
    xPosition="before"
    yPosition="below"
    [overlapTrigger]="true">
    <div
        tabindex="0"
        style="width: 390px"
        class="overflow-hidden"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <div class="text-sm p-2 pl-4 pb-3">Filter by Age Range</div>
        <mat-divider />
        <form class="p-5 pb-2 text-s" [formGroup]="form">
            <div class="flex items-center gap-5 mb-2">
                <app-input
                    class="flex-1"
                    variant="secondary"
                    type="number"
                    [placeholder]="minPlaceholder"
                    [min]="availableRange.min"
                    [max]="form.value.max"
                    formControlName="min" />
                days
                <mat-divider style="width: 12px; border-top-color: #000" />
                <app-input
                    class="flex-1"
                    variant="secondary"
                    type="number"
                    [placeholder]="maxPlaceholder"
                    [min]="form.value.min"
                    [max]="availableRange.max"
                    formControlName="max" />
                days
            </div>
            <app-range-slider
                [min]="availableRange.min"
                [max]="availableRange.max"
                [formGroup]="form"
                [minControlName]="'min'"
                [maxControlName]="'max'" />
        </form>
        <mat-divider />
        <div class="footer flex justify-space-between p-2 pr-6">
            <app-text-button [disabled]="!currentValue" (clicked)="reset()">
                Reset filter
            </app-text-button>
            <div class="flex gap-10 items-center">
                <app-flat-button (clicked)="close()">Cancel</app-flat-button>
                <app-flat-button
                    variant="cta"
                    [disabled]="applyDisabled"
                    (clicked)="apply()">
                    Apply
                </app-flat-button>
            </div>
        </div>
    </div>
</mat-menu>
