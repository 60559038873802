@if (error) {
    <small class="color-status-red4"> Documents can't be loaded</small>
} @else {
    <app-table
        noRowsText="No workflow documents to show."
        [attr.data-qa]="dataQa.documentViewTable"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [masterDetail]="true"
        [suppressMenu]="true"
        [detailCellRendererParams]="detailCellRendererParams"
        (gridReady)="onGridReady($event)">
    </app-table>
}
