import { IdDisplayPair } from '../shared.model';
import { DiscrepancyAction } from './state-action';
import { Priority } from '@models/shared/priority';

export class DiscrepancyDetail {
    constructor(
        public id: number,
        public pageRange: string,
        public directions: string,
        public createdAt: string,
        public reason: IdDisplayPair | null,
        public priority: Priority,
        public status: string,
        public analyst: IdDisplayPair | null,
        public station: IdDisplayPair | null,
        public responsibleFixer: IdDisplayPair | null,
        public documentServiceId: string,
        public canCancel: boolean,
        public actions: DiscrepancyAction[],
        public revision: DiscrepancyDetailRevision | null,
        public discrepancyNo: number,
        public isDraft: boolean,
        public pageRangeFileSize: number | null,
        public notifyWithOnlySelectedPages: boolean,
        public resolutionState: DiscrepancyResolutionState | null,
    ) {}

    static initial(): DiscrepancyDetail {
        return new DiscrepancyDetail(
            -1,
            '',
            '',
            '',
            null,
            Priority.Normal,
            '',
            null,
            null,
            null,
            '',
            false,
            [],
            null,
            -1,
            false,
            null,
            false,
            null,
        );
    }
}

export class DiscrepancyDetailRevision {
    constructor(
        public id: number,
        public name: string,
        public pageSize: number,
        public size: number,
        public fullFileInclusionLimit: number,
    ) {}
}

export enum DiscrepancyResolutionState {
    FIXED = 0,
    CANCELLED,
    FORCE_CLOSED,
    DISCARDED,
}
