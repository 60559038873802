import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { AutocompleteComponent } from '@shared/autocomplete/autocomplete.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { ChipsAutocompleteComponent } from '@shared/chips-autocomplete/chips-autocomplete.component';
import { SelectOption, SelectOptionWithGroup } from '@models/shared.model';
import { UserInfo, UserKind, UserKindLabel } from '@models/user';
import { DiscrepancyFixers, UpdateFixerRequest } from '@models/discrepancy';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'add-fixers-dialog.component.html',
    imports: [
        MatDialogClose,
        ReactiveFormsModule,
        FlatButtonComponent,
        LoaderComponent,
        AutocompleteComponent,
        ChipsAutocompleteComponent,
    ],
})
export class AddFixersDialogComponent implements OnInit, OnDestroy {
    discrepancyId!: number;
    form!: FormGroup;
    loading = false;
    error = false;
    fixerOptions: SelectOptionWithGroup[] = [];
    disabledOptions: (string | number)[] = [];
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private dialogRef = inject(MatDialogRef<AddFixersDialogComponent>);
    private discrepancyService = inject(DiscrepancyService);
    private formBuilder = inject(FormBuilder);
    private readonly data: {
        id: number;
        currentFixers: DiscrepancyFixers;
        fixerOptions: UserInfo[];
    } = inject(MAT_DIALOG_DATA);

    constructor() {
        this.discrepancyId = this.data.id;
        this.fixerOptions = this.groupByKind(this.data.fixerOptions);
    }

    get primaryFixer() {
        return this.form.get('primaryFixer') as FormControl;
    }

    get additionalFixers() {
        return this.form.get('additionalFixers') as FormControl;
    }

    get saveDisabled() {
        return this.form.invalid || !this.primaryFixer.value.value;
    }

    ngOnInit(): void {
        this.initForm();
        this.listenPrimaryFixerChange();
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }

    protected save() {
        this.loading = true;

        this._subscriptions['save-fixers'] = this.discrepancyService
            .saveDiscrepancyFixers(
                this.discrepancyId,
                new UpdateFixerRequest(
                    this.form.value.primaryFixer.value,
                    this.form.value.additionalFixers.map(
                        (i: SelectOption) => i.value,
                    ),
                ),
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.dialogRef.close({ discrepancyFixers: response });
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private initForm() {
        this.form = this.formBuilder.group({
            primaryFixer: new FormControl<SelectOption | null>(
                this.getCurrentPrimaryFixer(),
                Validators.required,
            ),
            additionalFixers: new FormControl(
                this.getCurrentAdditionalFixers(),
            ),
        });
    }

    private getCurrentPrimaryFixer(): SelectOption | null {
        let currentPrimaryFixer = null;

        if (this.data.currentFixers) {
            const { primaryFixer } = this.data.currentFixers;

            if (primaryFixer) {
                const { id, fullName, email } = primaryFixer;

                currentPrimaryFixer = {
                    value: id,
                    viewValue: `${fullName} (${email})`,
                };
            }
        }

        return currentPrimaryFixer;
    }

    private getCurrentAdditionalFixers(): SelectOption[] {
        return (this.data.currentFixers.secondaryFixers ?? []).map((i) => ({
            value: i.id,
            viewValue: `${i.fullName} (${i.email})`,
        }));
    }

    private listenPrimaryFixerChange() {
        if (this.getCurrentPrimaryFixer()) {
            this.disabledOptions = [this.getCurrentPrimaryFixer()!.value];
        }

        this._subscriptions['primary-fixer-change'] =
            this.primaryFixer.valueChanges.subscribe((data) => {
                if (data && data.value) {
                    this.disabledOptions = [data.value];
                }
            });
    }

    private groupByKind(data: UserInfo[]): SelectOptionWithGroup[] {
        const grouped: Record<string, SelectOption[]> = {
            [UserKind.DistributionList]: [],
            [UserKind.Person]: [],
        };
        data.forEach(({ id, kind, fullName, email }) => {
            const key = kind;
            const viewValue = `${fullName} (${email})`;
            grouped[key].push({ value: id, viewValue });
        });

        return Object.entries(grouped).map(([group, items]) => ({
            group: UserKindLabel[group],
            items,
        }));
    }
}
