<mat-label *ngIf="label" class="label text-xxs color-gray14">
    {{ label.toLocaleUpperCase() }}
</mat-label>
<mat-select
    #select
    [attr.data-qa]="dataQa"
    [disabled]="disabled"
    [multiple]="multiple"
    [placeholder]="placeholder"
    [ngClass]="{
        select: true,
        'select--disabled': disabled,
        'select--secondary': variant === 'secondary',
    }"
    [(value)]="value"
    (selectionChange)="onSelectionChange($event)">
    <mat-select-trigger>
        <span *ngIf="valueExist()" [wfePriorityField]="$any(value)"></span>
    </mat-select-trigger>
    <mat-option *ngIf="noneOption" [value]="null" class="color-gray15">
        None
    </mat-option>
    <mat-option
        *ngFor="let option of options"
        [value]="option.value"
        class="color-gray15">
        <span [wfePriorityField]="$any(option.value)"></span>
    </mat-option>
</mat-select>
