import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '@services/api/auth/auth.service';
import { SnackbarService } from '@services/snackbar.service';
import { NotificationMessage } from '@models/shared.model';
import { environment } from '@environments/environment';
import { SummaryBulkRenotifyComponent } from 'app/components/documents-and-discrepancies/summary-bulk-renotify/summary-bulk-renotify.component';
import {
    BulkActionResultNotification,
    NotificationKind,
} from '@models/discrepancy';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    private hubConnection!: signalR.HubConnection;
    private readonly apiUrl = environment.apiUrl;
    private readonly _path = 'v1/notifications/hub';
    private notification = new BehaviorSubject<NotificationMessage | null>(
        null,
    );

    notification$ = this.notification.asObservable();

    constructor(
        private authService: AuthService,
        private snackbarService: SnackbarService,
    ) {
        this.startConnection();
        this.addListeners();
    }

    stopConnection() {
        this.hubConnection
            .stop()
            .then(() => console.log('Connection stopped'))
            .catch((err) => console.error('Connection could not stop: ', err));
    }

    private startConnection() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl(`${this.apiUrl}/${this._path}`, {
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => this.authService.getUaaToken(),
            })
            .withAutomaticReconnect()
            .build();

        this.hubConnection
            .start()
            .then(() => console.log('Connection started'))
            .catch((err) => console.error('Connection could not start:', err));
    }

    private addListeners() {
        this.hubConnection.on('notification', (msg: NotificationMessage) => {
            this.notification.next(msg);
        });

        this.hubConnection.on(
            'bulk-action',
            (notification: BulkActionResultNotification) => {
                if (notification.kind === NotificationKind.Renotify) {
                    this.snackbarService.custom(SummaryBulkRenotifyComponent, {
                        notification,
                        header: 'Renotification Completed',
                        showContent: true,
                    });
                }
            },
        );
    }
}
