import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
    DocumentColumn,
    DocumentNestedColumn,
    StatusBarDiscrepancyData,
} from '@constants/table';
import { ColumnOption, ColumnToggleOptions } from '@models/shared.model';
import { DocumentRevisionsResponse, WorkflowDocument } from '@models/document';
import { toggleItem } from '@helpers/array';

@Injectable({
    providedIn: 'root',
})
export class DocumentDataService {
    private documentDetail = new BehaviorSubject<WorkflowDocument | null>(null);
    private columnOptions = new BehaviorSubject<
        ColumnToggleOptions<DocumentColumn>
    >({ lastToggled: null, hiddenColumns: [] });
    private nestedColumnOptions = new BehaviorSubject<{
        hiddenColumns: DocumentNestedColumn[];
    }>({ hiddenColumns: [] });
    private selectedRevisionId = new BehaviorSubject<number | null>(null);
    private statusBarDiscrepancyCount = new BehaviorSubject<
        StatusBarDiscrepancyData[]
    >([]);
    private statusBarData: StatusBarDiscrepancyData[] = [];
    private documentRevisions =
        new BehaviorSubject<DocumentRevisionsResponse | null>(null);

    documentDetail$ = this.documentDetail.asObservable();
    columnOptions$ = this.columnOptions.asObservable();
    nestedColumnOptions$ = this.nestedColumnOptions.asObservable();
    selectedRevisionId$ = this.selectedRevisionId.asObservable();
    documentRevisions$ = this.documentRevisions.asObservable();

    constructor() {}

    updateDocumentDetail(document: WorkflowDocument | null) {
        this.documentDetail.next(document);
    }

    updateColumnOptions(
        option: Pick<ColumnOption<DocumentColumn>, 'id' | 'checked'>,
    ) {
        const currentOptions = this.columnOptions.value;

        this.columnOptions.next({
            lastToggled: option,
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }

    updateNestedColumnOptions(
        option: Pick<ColumnOption<DocumentNestedColumn>, 'id' | 'checked'>,
    ) {
        const currentOptions = this.nestedColumnOptions.value;

        this.nestedColumnOptions.next({
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }

    updateSelectedRevisionId(id: number | null) {
        this.selectedRevisionId.next(id);
    }

    updateDiscDataForStatusPanel(newData: StatusBarDiscrepancyData) {
        const index = this.statusBarData.findIndex(
            (item) => item.documentId === newData.documentId,
        );
        if (index !== -1) {
            this.statusBarData[index] = newData;
        } else {
            this.statusBarData.push(newData);
        }

        this.statusBarDiscrepancyCount.next([...this.statusBarData]);
    }

    updateDocumentRevisions(response: DocumentRevisionsResponse) {
        this.documentRevisions.next(response);
    }

    getStatusBarDiscrepancyCount() {
        return this.statusBarDiscrepancyCount.asObservable();
    }
}
