import {
    AdvancedFilterModel,
    FilterModel,
    IServerSideGetRowsRequest,
    SortModelItem,
} from 'ag-grid-community';
import { Discrepancy } from '@models/discrepancy';

export enum DocumentDetailTab {
    METADATA = 'metadata',
    DISCREPANCIES = 'discrepancies',
    WF_INTERNALS = 'wf-internals',
}

export const DocumentDetailTabs = [
    { id: DocumentDetailTab.METADATA, label: 'Document Metadata' },
    { id: DocumentDetailTab.DISCREPANCIES, label: 'Discrepancies' },
    { id: DocumentDetailTab.WF_INTERNALS, label: 'WF Internals' },
];

// Helpers

export const setFilterQuery = (
    filterModel: FilterModel | AdvancedFilterModel | null,
) => {
    const queryParams = {};

    if (filterModel && Object.keys(filterModel).length) {
        Object.keys(filterModel).forEach((key) => {
            Object.assign(queryParams, {
                [key]: (filterModel as any)[key]?.filter?.trim(),
            });
        });
    }
    return queryParams;
};

export const setSortQuery = (sortModel: SortModelItem[]) => {
    const queryParams = {};

    if (sortModel) {
        sortModel.forEach((item) => {
            Object.assign(queryParams, {
                orderBy: item.colId,
                order: item.sort,
            });
        });
    }
    return queryParams;
};

export const setPageIndex = (
    request: IServerSideGetRowsRequest,
    pageSize: number,
): number => {
    const startRow = request?.startRow ?? null;
    const index = startRow ? Math.round(startRow / pageSize) + 1 : 1;
    return index;
};

export const isFilterApplied = (
    request: IServerSideGetRowsRequest,
): boolean => {
    return Object.keys(request.filterModel || {}).length !== 0;
};

export const getAgeInWFValue = (p: { value: string }) => {
    return p.value ? `${p.value} Days` : '';
};

export const getDiscrepancyIdLabelValue = (disc: Discrepancy) =>
    disc?.isDraft ? `${disc.discrepancyNo}*` : `${disc.discrepancyNo}`;

export const getDiscrepancyIdTooltipValue = (disc: Discrepancy) =>
    disc?.isDraft ? 'This discrepancy is in draft state.' : '';
