import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';

import { RevisionDataService } from '@services/data/revision-data-service';
import { SnackbarService } from '@services/snackbar.service';
import { DocumentService } from '@services/api/document/document.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TableComponent } from '@shared/table/table.component';
import { TextFilterComponent } from '@shared/table/filter/text-filter/text-filter.component';
import { DateFilterComponent } from '@shared/table/filter/date-filter/date-filter.component';
import { UploadCorrectionDialogComponent } from '@dialogs/upload-correction-dialog/upload-correction-dialog.component';
import { UnsavedChangesDialogComponent } from '@dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { DiscrepancyHeaderComponent } from './discrepancy-header.component';
import { DocStatusLegendComponent } from './status-legend.component';
import { VersionNameCellComponent } from './version-name-cell.component';
import { DiscrepancyCellComponent } from './discrepancy-cell.component';
import { DragDropDirective } from '@directives/drag-drop.directive';
import { ErrorMessages } from '@constants/error-messages';
import { DocumentRevisionTableItem } from '@models/document';
import DATA_QA from '@automation/data-qa.json';
import { TableExportFileNames } from '@constants/table';
import { removeFileExtension } from '@helpers/file';
import {
    getFilterQuery,
    getSortQuery,
} from '../documents-and-discrepancies.utils';

@Component({
    standalone: true,
    selector: 'app-document-upload',
    templateUrl: 'document-upload.component.html',
    styleUrl: 'document-upload.component.scss',
    imports: [
        MatIconModule,
        MatDividerModule,
        FlatButtonComponent,
        TableComponent,
        DocStatusLegendComponent,
        DragDropDirective,
    ],
})
export class DocumentUploadComponent {
    protected columnDefs: ColDef[] = [];
    protected documentName: string | undefined;
    private _gridApi!: GridApi;
    readonly dataQa = DATA_QA;
    readonly gridOptions: GridOptions = {
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
        defaultExcelExportParams: {
            fileName: TableExportFileNames.DOC_REVISIONS,
        },
        defaultCsvExportParams: {
            fileName: TableExportFileNames.DOC_REVISIONS,
        },
    };

    private dialog = inject(MatDialog);
    private documentService = inject(DocumentService);
    private documentDataService = inject(DocumentDataService);
    private revisionDataService = inject(RevisionDataService);
    private snackbarService = inject(SnackbarService);

    constructor() {
        this.documentName = this.documentDataService.currentDocument?.name;
    }

    get versionCount() {
        try {
            const {
                appliedVersions = [],
                originalDocument,
                corrections = [],
                finalVersion,
            } = this.revisionDataService;

            return (
                corrections.length +
                appliedVersions.length +
                (originalDocument ? 1 : 0) +
                (finalVersion ? 1 : 0)
            );
        } catch {
            return 0;
        }
    }

    get canUpload() {
        const { pickableDiscrepancies = [] } = this.revisionDataService;
        return pickableDiscrepancies.length > 0;
    }

    onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;

        const datasource = this.getDocumentVersions();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    onUploadClick(file: File | null = null) {
        const documentId = this.documentDataService.currentDocument?.id;
        const { pickableDiscrepancies = [] } = this.revisionDataService;

        const dialogRef = this.dialog.open(UploadCorrectionDialogComponent, {
            data: {
                id: documentId,
                discrepancies: pickableDiscrepancies,
                file,
            },
            maxWidth: '600px',
            minWidth: '200px',
            width: '600px',
        });

        dialogRef.afterClosed().subscribe({
            next: (response) => {
                if (response?.success) {
                    this.snackbarService.success({
                        header: `${response.fileName} uploaded.`,
                    });

                    this.refreshData();
                }
            },
        });
    }

    onFileDrop(file: File) {
        const newFile = removeFileExtension(file);
        this.onUploadClick(newFile);
    }

    getOverlayMessage(): string {
        return this.canUpload
            ? 'Drop here to upload correction'
            : ErrorMessages.NO_OPEN_DISC_TO_UPLOAD;
    }

    getRowStyle = (params: { data: DocumentRevisionTableItem }) => {
        const documentRevisionId = this.revisionDataService.selectedRevision;

        if (params.data?.isHeader) {
            return { backgroundColor: '#f2f5f7', borderBottom: 'none' };
        }

        if (params.data?.revisionId === documentRevisionId) {
            return { backgroundColor: '#e6f5ff', borderBottom: 'inherit' };
        }
        return undefined;
    };

    refreshData() {
        if (!this._gridApi.isDestroyed()) {
            this._gridApi.refreshServerSide({ purge: true });
        }
    }

    private getDocumentVersions(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                const queryParams = this.getQueryParams(params.request);
                const documentId = this.documentDataService.currentDocument?.id;

                if (documentId) {
                    params.api.showLoadingOverlay();

                    this.documentService
                        .getDocumentRevisions(documentId, queryParams)
                        .subscribe({
                            complete: () => params.api.hideOverlay(),
                            next: (data) => {
                                if (data) {
                                    this.revisionDataService.updateDocumentRevisions(
                                        data,
                                    );
                                    const rowData =
                                        this.revisionDataService.createRevisionTableData(
                                            data,
                                        );
                                    params.success({
                                        rowData: rowData,
                                        rowCount: rowData.length,
                                    });
                                    this.setColumnDefs();
                                } else {
                                    params.fail();
                                }
                            },
                        });
                }
            },
        };
    }

    private setColumnDefs() {
        const { discrepancyNos = [] } = this.revisionDataService;

        if (this._gridApi) {
            this._gridApi.setGridOption(
                'columnDefs',
                this.getColumnDefs(discrepancyNos),
            );
            this._gridApi.redrawRows();
            this.autoSizeColumns();
        }
    }

    private getColumnDefs(discrepancyNos: number[] = []) {
        return [
            {
                field: 'revisionName',
                headerName: 'Version Name',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
                cellRenderer: VersionNameCellComponent,
                cellRendererParams: {
                    onClick: (node: IRowNode<DocumentRevisionTableItem>) => {
                        this.onDocumentNameClick(node.data!.revisionId!);
                    },
                },
                cellStyle: (params: { data: DocumentRevisionTableItem }) => {
                    if (params?.data?.isHeader) {
                        return { overflow: 'visible' };
                    }
                    return undefined;
                },
            },
            {
                field: 'discrepancyRelations',
                floatingFilter: false,
                flex: 2,
                headerComponent: DiscrepancyHeaderComponent,
                headerComponentParams: {
                    discrepancyRelations: discrepancyNos,
                },
                cellRenderer: DiscrepancyCellComponent,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            {
                field: 'createdBy',
                headerName: 'By',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: 'createdAt',
                minWidth: 150,
                headerName: 'Date',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
        ];
    }

    private onDocumentNameClick(id: number) {
        if (this.revisionDataService.unsavedDocumentId) {
            this.dialog
                .open(UnsavedChangesDialogComponent, {
                    data: {
                        unsavedChangeArea: this.getSelectedCorrectionName(),
                    },
                    maxWidth: '430px',
                    minWidth: '200px',
                    width: '430px',
                })
                .afterClosed()
                .subscribe({
                    next: (result) => {
                        if (result.save) {
                            this.revisionDataService.triggerRevisionSave();
                        } else {
                            if (result.leave) {
                                this.updateSelectedRevisionId(id);
                            }
                        }
                    },
                });
        } else {
            this.updateSelectedRevisionId(id);
        }
    }

    private getSelectedCorrectionName() {
        const { corrections = [], selectedRevision } = this.revisionDataService;

        return corrections.find((cv) => cv.id == selectedRevision)
            ?.revisionName;
    }

    private updateSelectedRevisionId(id: number) {
        if (id) {
            this.revisionDataService.updateSelectedRevisionId(id);
            this.setColumnDefs();
        }
    }

    private autoSizeColumns() {
        setTimeout(() => {
            this._gridApi!.autoSizeColumns(
                ['revisionName', 'discrepancyRelations'],
                false,
            );
        }, 100);
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        return {
            ...getFilterQuery(request.filterModel),
            ...getSortQuery(request.sortModel),
        };
    }
}
