import { Component, inject, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    IFloatingFilterParams,
    ISimpleFilter,
    TextFilterModel,
} from 'ag-grid-community';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';

import { InputComponent } from '@shared/input/input.component';
import { SliderComponent } from '@shared/range-slider/range-slider.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { Range } from '@models/shared.model';

export interface CustomParams {
    availableRange: Range<number>;
}

@Component({
    standalone: true,
    templateUrl: './range-filter.component.html',
    imports: [
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        InputComponent,
        InputComponent,
        TextButtonComponent,
        FlatButtonComponent,
        SliderComponent,
        ReactiveFormsModule,
        FormsModule,
    ],
    styles: `
        .wrapper {
            width: 390px;
        }
    `,
})
export class RangeFilterComponent implements IFloatingFilterAngularComp {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
    availableRange: Range<number> = new Range(0, 180);
    currentValue: Range<number> | undefined;
    form!: FormGroup;
    private formChanged: boolean = false;
    private params!: IFloatingFilterParams<ISimpleFilter> & CustomParams;

    private fb = inject(FormBuilder);

    get minPlaceholder() {
        return this.availableRange.min !== null
            ? this.availableRange.min.toString()
            : '';
    }

    get maxPlaceholder() {
        return this.availableRange.max !== null
            ? this.availableRange.max.toString()
            : '';
    }

    get filterText() {
        if (this.formChanged && this.currentValue) {
            const { min, max } = this.currentValue;
            return min === max ? min : min + '-' + max;
        } else {
            return 'Filter';
        }
    }

    agInit(params: IFloatingFilterParams<ISimpleFilter> & CustomParams): void {
        this.params = params;
        this.availableRange = params.availableRange;
        this.initForm();
    }

    onParentModelChanged(_parentModel: TextFilterModel): void {
        if (_parentModel?.filter) {
            const filter = JSON.parse(_parentModel.filter);
            this.form.patchValue({ min: filter.min, max: filter.max });
            this.currentValue = new Range(filter.min, filter.max);
            this.formChanged = true;
        } else {
            this.formChanged = false;
            this.currentValue = undefined;
            this.initForm();
        }
    }

    refresh(params: IFloatingFilterParams<ISimpleFilter> & CustomParams) {
        this.params = params;
        this.availableRange = params.availableRange;
        if (this.currentValue) {
            this.form = this.fb.group({
                min: [this.form.value.min],
                max: [this.form.value.max],
            });
        } else this.initForm();
    }

    onFilterChanged() {
        this.params.parentFilterInstance((instance: ISimpleFilter) => {
            const minAndMax = this.formChanged
                ? this.getRangeValues()
                : undefined;

            instance.onFloatingFilterChanged('contains', minAndMax);
        });
    }

    reset() {
        this.params.api?.refreshHeader();
        const coldId = this.params.column.getColId();
        const newFilterModel = this.params.api.getFilterModel();
        newFilterModel[coldId] = null;
        this.params.api.setFilterModel(newFilterModel);
        this.params.api?.onFilterChanged();
    }

    close() {
        if (this.trigger) this.trigger.closeMenu();
    }

    apply() {
        this.formChanged = true;
        this.onFilterChanged();
        if (this.trigger) this.trigger.closeMenu();
    }

    private initForm() {
        this.form = this.fb.group({
            min: [this.availableRange.min],
            max: [this.availableRange.max],
        });
    }

    private getRangeValues() {
        const { min, max } = this.form.value;
        let _min = parseFloat(min);
        let _max = parseFloat(max);

        if (_min > _max) _min = _max;
        if (_max <= _min) _max = _min;
        if (_min < this.availableRange.min) _min = this.availableRange.min;
        if (_max > this.availableRange.max) _max = this.availableRange.max;

        this.form.patchValue({ min: _min, max: _max });
        this.currentValue = new Range(_min, _max);
        const minAndMax = JSON.stringify(this.currentValue);

        return minAndMax;
    }
}
