<form class="p-5" [formGroup]="form">
    <p class="text-lg mb-3">Reject Correction</p>
    <div class="flex flex-column gap-20">
        <div class="p-3 text-sm summary">
            You will be rejecting this correction for:
            <span class="text-semi">{{ discrepancies }}</span>
        </div>
        <div class="text-s">
            <p>
                &#x2022; We will notify the station that the correction has been
                rejected for selected discrepancies with provided reason.
            </p>
            <p>
                &#x2022; Rejected discrepancies will return to Await Correction
                state after rejection.
            </p>
        </div>
        <app-textarea
            label="REASON"
            formControlName="reason"
            [placeholder]="textPlaceholder"
            [required]="true"
            [max]="500"></app-textarea>
    </div>
    <div class="flex justify-end gap-8 mt-5">
        @if (error) {
            <small class="color-status-red4">Something went wrong</small>
        }
        <app-flat-button (clicked)="cancel()">Cancel</app-flat-button>
        <app-flat-button
            variant="danger"
            (clicked)="reject()"
            [disabled]="!reason.getRawValue()">
            Reject Correction
        </app-flat-button>
    </div>
</form>

@if (loading) {
    <app-loader type="overlay" />
}
