@if (loading) {
    <app-loader type="overlay" />
}
@if (isAdmin && workflowInstanceOptions.length > 0) {
    <div class="instance-select">
        <div class="instance-select__wrapper">
            <app-select
                [dataQa]="dataQa.workflowInstanceSelect"
                label="Workflow Instance"
                [options]="workflowInstanceOptions"
                [value]="workflowInstanceOptions[0].value"
                (selectionChange)="onWorkflowInstanceChange($event)" />
            <app-select
                [dataQa]="dataQa.repositorySelect"
                label="Repository"
                [options]="repositoryOptions"
                [value]="selectedRepository"
                [multiple]="true"
                (selectionChange)="onRepositoryChange($event)" />
        </div>
    </div>
}
<div class="filter-select">
    <app-status-filter
        [statusChips]="statusChipsData"
        [selectedStatuses]="selectedStatuses"
        (filterChange)="onStatusFilterChange($event)" />
    <app-toggle
        id="showMyItemsToggle"
        label="Show only my items"
        style="height: 30px"
        [initialToggled]="showMyItems"
        (toggle)="onToggleMyItems($event)"
        [dataQa]="dataQa.showOnlyMyItemsToggle" />
</div>
<ng-container>
    <div class="flex justify-space-between">
        <div></div>
        <div class="flex items-center">
            <app-text-button (clicked)="onExportClicked()">
                Export
            </app-text-button>
            <app-change-view-menu
                [selected]="selectedTableView"
                (tableViewChanged)="onTableViewChanged($event)" />
            <app-text-button
                [dataQa]="dataQa.resetFiltersButton"
                [disabled]="isResetFiltersDisabled()"
                (clicked)="resetFilters()">
                Reset filters
            </app-text-button>
        </div>
    </div>
    @if (showDocuments) {
        <div>
            @if (selectedTableView === TableView.Document) {
                <app-document-view
                    [workflowAndRepo]="workflowAndRepo"
                    [statuses]="selectedStatuses"
                    [showMyItems]="showMyItems" />
            }
            @if (selectedTableView === TableView.Discrepancy) {
                <app-discrepancy-view
                    [workflowAndRepo]="workflowAndRepo"
                    [statuses]="selectedStatuses"
                    [showMyItems]="showMyItems" />
            }
        </div>
    }
</ng-container>

@if (selectedDocument) {
    <div style="height: 45vh"></div>
    <app-documents-summary-panel
        [documentId]="selectedDocument"
        (panelClose)="closePanel()" />
}
