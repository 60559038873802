import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import {
    DropdownItem,
    IdDisplayPair,
    SelectOption,
} from 'app/models/shared.model';
import { Formats } from 'app/utils/constants/formats';

@Injectable({
    providedIn: 'root',
})
export class SharedMapper {
    constructor(private datePipe: DatePipe) {}

    toDropdownItem = (item: DropdownItem) =>
        new DropdownItem(item?.id, item?.name || '-');

    toDate = (date: string | Date, withHour: boolean = false): string => {
        return (
            this.datePipe.transform(
                date,
                withHour ? Formats.DATE_WITH_HOUR : Formats.DATE,
            ) ?? '-'
        );
    };

    toIdDisplayPair = (item: IdDisplayPair): IdDisplayPair =>
        new IdDisplayPair(item.backingField, item?.displayName || '-');

    toSelectOption = (item: IdDisplayPair): SelectOption => ({
        value: item.backingField,
        viewValue: item.displayName,
    });
}
