import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { PriorityColorDirective } from 'app/directives/priority-color.directive';

@Component({
    standalone: true,
    template: `
        <span class="priority flex">
            <span [wfePriorityField]="params.value"></span>
        </span>
    `,
    styleUrl: './priority-cell.component.scss',
    imports: [PriorityColorDirective],
})
export class PriorityCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
