import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FlatButtonComponent } from '../button/flat-button/flat-button.component';
import { slideInOutAnimation } from 'app/utils/animation';

@Component({
    selector: 'app-panel',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, FlatButtonComponent],
    templateUrl: './panel.component.html',
    styleUrl: './panel.component.scss',
    animations: [slideInOutAnimation],
})
export class PanelComponent implements AfterViewInit {
    @Input() headerTitle = '';
    @Input() headerPrimaryButtonLabel = '';
    @Input() headerPrimaryButtonDisabled = false;
    @Output() closeClicked = new EventEmitter();
    @Output() primaryButtonClicked = new EventEmitter();

    private readonly _minHeight = 100;
    private readonly _maxHeight = window.innerHeight - 100;
    private _isResizing = false;
    private _resizable: HTMLElement | undefined;
    private _dragHandle: HTMLElement | undefined;

    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        this._resizable = this.el.nativeElement.querySelector('#resizable');
        this._dragHandle = this.el.nativeElement.querySelector('#drag-handle');

        this._dragHandle?.addEventListener('mousedown', () =>
            this.onMouseDown(),
        );
    }

    onCloseClick() {
        this.closeClicked.emit();
    }

    onPrimaryButtonClick() {
        this.primaryButtonClicked.emit();
    }

    private onMouseDown() {
        this._isResizing = true;
        document.body.style.cursor = 'ns-resize';
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    }

    private onMouseMove = (e: MouseEvent) => {
        if (!this._isResizing) return;
        const height = Math.max(
            this._minHeight,
            Math.min(window.innerHeight - e.clientY, this._maxHeight),
        );
        if (this._resizable) this._resizable.style.height = `${height}px`;
    };

    private onMouseUp = () => {
        this._isResizing = false;
        document.body.style.cursor = 'default';
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    };
}
