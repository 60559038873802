import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { DocumentRevisionStatus } from '@models/document/revision';
import { documentRevisionStatusMap } from '@constants/table';
import { RevisionKind } from '@models/revision/revision-kind';

@Component({
    standalone: true,
    imports: [MatIconModule],
    template: `@if (!params.data.isHeader) {
        <div class="flex items-center">
            <mat-icon [svgIcon]="icon" class="mr-1" />
            <span
                [class.text-semi]="isFinalVersion"
                [class.color-yellow-green8]="isFinalVersion">
                {{ label }}
            </span>
        </div>
    }`,
    styles: ``,
})
export class StatusCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    icon: string = '';
    label: string = '';

    get isFinalVersion() {
        return this.params?.data.kind === RevisionKind.FINAL_VERSION;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.setStatusInfo();
    }

    refresh() {
        return false;
    }

    private setStatusInfo() {
        if (!this.params.data.isHeader) {
            try {
                const { icon, label } =
                    documentRevisionStatusMap[
                        this.params.data.relationKind as DocumentRevisionStatus
                    ];
                this.icon = icon;
                this.label = label;
            } catch {
                this.icon = '';
                this.label = '';
            }
        }
    }
}
