export function toggleItem<T>(array: T[], item: T): T[] {
    const index = array.indexOf(item);

    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }

    return array;
}

/**
 * Generates an array of numbers in the specified range (inclusive).
 * @param {number} min - The starting number .
 * @param {number} max - The ending number .
 * @returns {number[]} An array of numbers from min to max
 */
export function generateNumbers(min: number, max: number): number[] {
    const numbers: number[] = [];
    for (let i = min; i <= max; i++) {
        numbers.push(i);
    }

    return numbers;
}

/**
 * Generates an reversed array.
 */
export function reverse<T>(array: T[]): T[] {
    return array.map((_: T, idx: number) => array[array.length - 1 - idx]);
}
