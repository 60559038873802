import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { StationService } from '@services/api/station/station.service';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { AddFixersDialogComponent } from '@dialogs/add-fixers-dialog/add-fixers-dialog.component';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { UserInfo, UserKind, UserKindLabel } from '@models/user';
import { DiscrepancyFixers } from '@models/discrepancy';
import { DashIfEmptyPipe } from '@pipes/dash-if-empty.pipe';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    selector: 'app-fixer-field',
    template: `@if (readOnly) {
            <app-content-field label="FIXER(S)">
                <ng-container *ngTemplateOutlet="fixerInformationTemplate" />
            </app-content-field>
        } @else {
            <div class="pos-rel">
                <p class="label text-xxs color-gray14 mb-1">FIXER(S)</p>
                <app-text-button
                    class="new-fixer-button"
                    [dataQa]="dataQa.addEditFixerButton"
                    [disabled]="fixerOptions.length === 0"
                    (clicked)="openNewFixerDialog()">
                    <span class="flex items-center text-sm">
                        <mat-icon
                            [svgIcon]="primaryFixer ? 'edit' : 'add'"
                            [style.width]="'18px'" />
                        {{ primaryFixer ? 'Edit' : 'New' }}
                    </span>
                </app-text-button>
                @if (fixerOptions.length === 0) {
                    <p class="text-s color-gray10 text-wrap">
                        No fixer in this station. To add a fixer, change the
                        station and save.
                    </p>
                } @else {
                    <p class="fixer text-s">
                        @if (primaryFixer) {
                            <ng-container
                                *ngTemplateOutlet="fixerInformationTemplate" />
                        } @else {
                            <span class="text-s color-gray10">
                                No fixer added yet.
                            </span>
                        }
                    </p>
                }
            </div>
        }
        <ng-template #fixerInformationTemplate>
            @if (primaryFixer) {
                <span class="text-s text-semi">Primary: </span>
                {{ primaryFixer.fullName | dashIfEmpty }}
                ({{ primaryFixer.email }})
                @if (additionalFixers.length > 0) {
                    <div class="flex items-center color-gray13">
                        Additional: {{ additionalInfo }}
                        <app-tooltip>
                            <mat-icon trigger svgIcon="info" />
                            <div content>
                                @for (
                                    fixers of groupedAdditionalFixers;
                                    track $index
                                ) {
                                    @if (fixers.items.length > 0) {
                                        <p class="color-gray12 text-semi mb-1">
                                            {{ fixers.group }} ({{
                                                fixers.items.length
                                            }})
                                        </p>
                                        <ul>
                                            @for (
                                                item of fixers.items;
                                                track $index
                                            ) {
                                                <li>{{ item }}</li>
                                            }
                                        </ul>
                                    }
                                }
                            </div>
                        </app-tooltip>
                    </div>
                }
            } @else {
                -
            }
        </ng-template>`,
    styles: `
        .new-fixer-button {
            right: 0;
            position: absolute;
            top: -5px;
        }

        .fixer {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `,
    imports: [
        NgTemplateOutlet,
        DashIfEmptyPipe,
        ContentFieldComponent,
        MatIconModule,
        TextButtonComponent,
        TooltipComponent,
    ],
})
export class FixerFieldComponent implements OnInit, OnDestroy {
    @Input({ required: true }) id!: number;
    @Input({ required: true }) discrepancyFixers = DiscrepancyFixers.initial();
    @Input({ required: true }) stationId: number | undefined;
    @Input({ required: true }) readOnly: boolean = true;
    @Output() fixerUpdated = new EventEmitter<UserInfo | null>();
    fixerOptions: UserInfo[] = [];
    additionalInfo: string = '';
    groupedAdditionalFixers: { group: string; items: string[] }[] = [];
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private dialog = inject(MatDialog);
    private stationService = inject(StationService);

    get primaryFixer(): UserInfo | null {
        return this.discrepancyFixers.primaryFixer;
    }

    get additionalFixers(): UserInfo[] {
        return this.discrepancyFixers.secondaryFixers;
    }

    ngOnInit(): void {
        this.getFixerOptions();
        this.setAdditionalInfo();
        this.groupAdditionalFixers();
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribeAllSafe();
    }

    openNewFixerDialog() {
        this.dialog
            .open(AddFixersDialogComponent, {
                data: {
                    id: this.id,
                    fixerOptions: this.fixerOptions,
                    currentFixers: this.discrepancyFixers,
                },
                maxWidth: '700px',
                minWidth: '200px',
                width: '700px',
                maxHeight: '90vh',
                disableClose: true,
            })
            .afterClosed()
            .subscribe({
                next: (response: { discrepancyFixers: DiscrepancyFixers }) => {
                    if (response) {
                        this.discrepancyFixers = response.discrepancyFixers;
                        this.setAdditionalInfo();
                        this.groupAdditionalFixers();
                        this.fixerUpdated.emit(
                            response.discrepancyFixers.primaryFixer,
                        );
                    }
                },
            });
    }

    private setAdditionalInfo() {
        const fixers = [];
        const { secondaryFixers } = this.discrepancyFixers;
        const { DistributionList, Person } = UserKind;

        const dlCount = secondaryFixers.filter(
            (i) => i.kind === DistributionList,
        ).length;
        const peopleCount = secondaryFixers.filter(
            (i) => i.kind === Person,
        ).length;

        if (dlCount > 0) fixers.push(`${dlCount} Distribution List(s)`);
        if (peopleCount > 0) fixers.push(`${peopleCount} People`);

        this.additionalInfo = fixers.join(', ');
    }

    private getFixerOptions() {
        if (this.stationId)
            this._subscriptions['get-fixer-options'] = this.stationService
                .getStationFixers(this.stationId)
                .subscribe({
                    next: (fixers) => {
                        this.fixerOptions = fixers;
                    },
                });
    }

    private groupAdditionalFixers() {
        const grouped: Record<string, string[]> = {
            [UserKind.DistributionList]: [],
            [UserKind.Person]: [],
        };
        this.additionalFixers.forEach(({ kind, fullName, email }) => {
            const key = kind;
            grouped[key].push(`${fullName} (${email})`);
        });

        this.groupedAdditionalFixers = Object.entries(grouped).map(
            ([group, items]) => ({
                group: UserKindLabel[group],
                items,
            }),
        );
    }
}
