import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn } from '@angular/router';
import { map, of } from 'rxjs';

import { UnsavedChangesDialogComponent } from '@dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component';

export interface HasUnsavedChanges {
    hasUnsavedChanges(): boolean;
    save: () => void;
    unsavedChangeArea: string;
}

export const UnsavedChangesGuard: CanDeactivateFn<HasUnsavedChanges> = (
    component: HasUnsavedChanges,
) => {
    if (!component?.hasUnsavedChanges()) {
        return of(true);
    }

    const dialog = inject(MatDialog);
    const dialogRef = dialog.open(UnsavedChangesDialogComponent, {
        data: {
            unsavedChangeArea: component.unsavedChangeArea,
        },
        maxWidth: '430px',
        minWidth: '200px',
        width: '430px',
    });

    return dialogRef.afterClosed().pipe(
        map((result) => {
            if (result.save) component.save();
            return result.leave === true;
        }),
    );
};
