import { Injectable } from '@angular/core';

import { SharedMapper } from '../shared.mapper';
import { QuestionBase, QuestionDataType } from '@shared/question/question-base';
import { DropdownQuestion } from '@shared/question/question-dropdown';
import { TextboxQuestion } from '@shared/question/question-textbox';
import { DocumentInternals } from 'app/models/document/internals';
import {
    DocumentFilterOptions,
    DocumentFilterOptionsResponse,
} from 'app/models/document/filter-options';
import { DocumentRevisionsResponse } from 'app/models/document/revision';
import {
    DocDiscrepancyCreateOptionsResponse,
    DocDiscrepancyCreateOptions,
} from 'app/models/document/discrepancy-create-options';
import {
    MetadataItem,
    MetadataOptionResponse,
} from 'app/models/document/metadata';
import { WorkflowDocument } from 'app/models/document/item';

@Injectable({
    providedIn: 'root',
})
export class DocumentMapper {
    constructor(private sharedMapper: SharedMapper) {}

    toWorkflowDocument = (item: WorkflowDocument) =>
        new WorkflowDocument(
            item?.id ?? '-',
            item?.name || '-',
            item?.documentType || '-',
            item?.repository || '-',
            item.priority,
            this.sharedMapper.toDate(item.createdAt, true),
            item.station || '-',
            item.documentServiceId || null,
            item?.maximumDiscrepancyCount ?? null,
            item?.canAddDiscrepancy ?? false,
            item.age ?? null,
            item.latestActivity,
            item.progress,
        );

    toDocumentFilterOptions = (
        item: DocumentFilterOptionsResponse,
    ): DocumentFilterOptions =>
        new DocumentFilterOptions(
            (item?.documentType || [])?.map(this.sharedMapper.toSelectOption) ??
                [],
            (item?.priority || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.repository || [])?.map(this.sharedMapper.toSelectOption) ??
                [],
            (item?.station || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            item?.maxAge ?? null,
        );

    toMetadata = (item: MetadataItem) =>
        new MetadataItem(
            item.fieldLabel,
            item.fieldValue || '-',
            item.ndx,
            item.isRequired ?? false,
        );

    toMetadataQuestion = (
        item: MetadataOptionResponse,
    ): QuestionBase<any> | null => {
        try {
            const options = {
                fieldName: item.fieldName,
                label: item.label,
                value: item.value,
                options: (item.options ?? []).map((option: string) => ({
                    value: option,
                    viewValue: option,
                })),
                required: item.isRequired,
                order: item.order,
                section: item.section,
                dataType: item.dataType as QuestionDataType,
                maxLength: item.maxLength,
            };
            if (item.fieldType === 'ListBox') {
                return new DropdownQuestion(options);
            }
            if (item.fieldType === 'TextBox') {
                return new TextboxQuestion(options);
            }
        } catch (e) {
            console.error(e);
            return null;
        }
        return null;
    };

    toInternals = (item: DocumentInternals): DocumentInternals =>
        new DocumentInternals(
            item?.tenantId ?? '',
            item?.repositoryId ?? '',
            item?.workflowId ?? '',
            item?.workflowDocumentId ?? '',
            item?.createdAt ?? '',
        );

    toDocDiscrepancyCreateOptions = (
        item: DocDiscrepancyCreateOptionsResponse,
    ): DocDiscrepancyCreateOptions =>
        new DocDiscrepancyCreateOptions(
            (item?.reason || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.station || [])?.map(this.sharedMapper.toSelectOption) ?? [],
            (item?.fixer || [])?.map(this.sharedMapper.toSelectOption) ?? [],
        );

    toDocumentRevisions = (
        item: DocumentRevisionsResponse,
    ): DocumentRevisionsResponse =>
        new DocumentRevisionsResponse(
            item.interactionsStation ?? '',
            item.discrepancyNos ?? [],
            item.versions ?? [],
            item.originalRevision ?? null,
            item.pickableDiscrepancies ?? [],
        );
}
