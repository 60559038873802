<div class="navbar">
    <div class="tabs">
        @for (tab of tabs; track $index) {
            <div
                tabindex="0"
                class="tab"
                [attr.data-id]="tab.id"
                [class.active]="tab.id === activeTabId"
                (click)="setActiveTab(tab)"
                (keyup.enter)="setActiveTab(tab)">
                <mat-icon class="tab-icon" [svgIcon]="tab.icon" />
                {{ tab.label }}
                @if (tab.isMenu) {
                    <mat-icon svgIcon="chevron-down" style="display: none" />
                }
            </div>
        }
    </div>
    <span class="spacer"></span>
    <button
        mat-icon-button
        aria-label="User"
        class="mr-2"
        [matMenuTriggerFor]="userMenu">
        <mat-icon class="color-gray16" svgIcon="user" />
    </button>
</div>

<mat-menu #userMenu="matMenu">
    <div style="width: 150px">
        <button mat-menu-item>Account Settings</button>
        <button mat-menu-item (click)="logout()">Logout</button>
    </div>
</mat-menu>
