{
    "acceptCorrectionDialogCancel": "acceptCorrectionDialogCancel",
    "acceptCorrectionDialogAccept": "acceptCorrectionDialogAccept",
    "addNewDiscrepancyButton": "addNewDiscrepancyButton",
    "addDiscrepancyDialogCancel": "addDiscrepancyDialogCancel",
    "addDiscrepancyDialogCreate": "addDiscrepancyDialogCreate",
    "assignDiscrepancyDialogCancel": "assignDiscrepancyDialogCancel",
    "assignDiscrepancyDialogAssign": "assignDiscrepancyDialogAssign",
    "cancelDiscrepancyDialogReturnToPage": "cancelDiscrepancyDialogReturnToPage",
    "cancelDiscrepancyDialogCancel": "cancelDiscrepancyDialogCancel",
    "discardDiscrepancyDialogCancel": "discardDiscrepancyDialogCancel",
    "discardDiscrepancyDialogDiscard": "discardDiscrepancyDialogDiscard",
    "discrepancyViewToggle": "discrepancyViewToggle",
    "documentViewToggle": "documentViewToggle",
    "documentPreviewAcceptButton": "documentPreviewAcceptButton",
    "documentPreviewRejectButton": "documentPreviewRejectButton",
    "documentPreviewApplyButton": "documentPreviewApplyButton",
    "documentUploadButton": "documentUploadButton",
    "duplicateDocumentsDialogCancel": "duplicateDocumentsDialogCancel",
    "duplicateDocumentsDialogUpload": "duplicateDocumentsDialogUpload",
    "repositorySelect": "repositorySelect",
    "resetFiltersButton": "resetFiltersButton",
    "showOnlyMyItemsToggle": "showOnlyMyItemsToggle",
    "statusChipFilter": "statusChipFilter",
    "footerRecallDiscrepancy": "footerRecallDiscrepancy",
    "footerCancelDiscrepancy": "footerCancelDiscrepancy",
    "footerDiscardDiscrepancy": "footerDiscardDiscrepancy",
    "footerSaveDiscrepancy": "footerSaveDiscrepancy",
    "footerNotifyDiscrepancy": "footerNotifyDiscrepancy",
    "footerRenotifyDiscrepancy": "footerRenotifyDiscrepancy",
    "metadataEditSaveButton": "metadataEditSaveButton",
    "tableViewMenuClose": "tableViewMenuClose",
    "tableViewButton": "tableViewButton",
    "workflowInstanceSelect": "workflowInstanceSelect"
}
