import { QuestionFieldType } from '@shared/question/question-base';

export class MetadataOptionResponse {
    constructor(
        public value: string,
        public fieldName: string,
        public label: string,
        public isRequired: boolean,
        public order: number,
        public fieldType: QuestionFieldType,
        public dataType: string,
        public options: string[],
        public maxLength: number,
        public readonly: boolean,
        public section: string,
        public size: number,
    ) {}
}

export class MetadataItem {
    constructor(
        public fieldLabel: string,
        public fieldValue: string,
        public ndx: number,
        public isRequired: boolean,
    ) {}
}
