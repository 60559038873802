<div>
    <label>
        <span class="flex items-start mb-1">
            <span
                class="text-xxs color-gray14"
                [class.color-status-red4]="
                    (control && control.invalid) || (required && !value)
                ">
                {{ getLabel() }}
            </span>
            <ng-content />
        </span>
        <textarea
            class="input block w-full"
            [value]="value"
            [attr.placeholder]="placeholder"
            [attr.maxlength]="max"
            (input)="onInput($event)">
        </textarea>
        @if (max) {
            <div class="text-xs text-end mt-1 color-gray14">
                {{ value.length }}/{{ max }}
            </div>
        }
    </label>
</div>
