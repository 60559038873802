<div headerContent class="document-info flex items-center" [formGroup]="form">
    <app-content-field label="DISCREPANCY PROGRESS" [rowGap]="false">
        <span class="flex items-center gap-7 text-xxs">
            <app-progress-bar
                variant="secondary"
                mode="determinate"
                style="height: 12px; width: 100px"
                [progress]="progressValue" />
            @if (document) {
                <span
                    class="flex items-center whitespace-nowrap"
                    [class.color-yellow-green7]="allCompleted">
                    {{ document.progress.completed }}/{{
                        document.progress.total
                    }}
                    Completed
                    @if (allCompleted) {
                        <mat-icon svgIcon="check" style="height: 16px" />
                    }
                </span>
            }
        </span>
    </app-content-field>
    @if (document) {
        @if (mode === 'detail' && isAdmin) {
            <app-priority-select
                label="PRIORITY"
                variant="secondary"
                formControlName="priority"
                [value]="document.priority"
                [options]="prioOptions"
                style="width: 110px"
                selectStyle=" height: 22px" />
        } @else {
            <app-content-field label="PRIORITY" [rowGap]="false">
                <app-priority-field [wfePriorityField]="document.priority" />
            </app-content-field>
        }
        <app-content-field label="LAST ACTIVITY" [rowGap]="false">
            <span class="flex items-center whitespace-nowrap">
                {{ document.latestActivity.createdAt }}
                <app-tooltip>
                    <mat-icon trigger svgIcon="info" />
                    <div content>
                        {{ document.latestActivity.logMessage }}
                        <b>({{ document.latestActivity.user }})</b>
                    </div>
                </app-tooltip>
            </span>
        </app-content-field>
    }
</div>
