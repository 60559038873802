import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DocumentState } from '../state/document.state';

export const selectDocumentState =
    createFeatureSelector<DocumentState>('document');

export const selectSelectedDocument = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.selectedDocument,
);

export const selectSelectedDiscrepancy = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.selectedDiscrepancy,
);

export const selectSelectedTab = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.selectedTab,
);
