import { DiscrepancyIdNo } from '../discrepancy/item';

export class DocumentRevisionsResponse {
    constructor(
        public discrepancyNos: number[],
        public corrections: DocumentRevisionVersion[],
        public originalRevision: DocumentRevisionVersion | null,
        public pickableDiscrepancies: DiscrepancyIdNo[],
        public appliedVersions: DocumentRevisionVersion[],
        public finalVersion: DocumentRevisionVersion | null,
    ) {}
}

export class DocumentRevisions extends DocumentRevisionsResponse {}

export class DocumentRevisionVersion {
    constructor(
        public revisionName: string,
        public discrepancyRelations: DocumentRevisionStatus[],
        public createdBy: string,
        public createdAt: string,
        public documentServiceId: string,
        public id: number,
    ) {}
}

export class DocumentRevisionTableItem {
    constructor(
        public id: number | string,
        public isHeader: boolean,
        public revisionName?: string,
        public discrepancyRelations?: DocumentRevisionStatus[],
        public createdBy?: string,
        public createdAt?: string,
        public documentServiceId?: string,
        public sentForConnection?: boolean,
        public revisionId?: number,
        public additionalInfo?: string,
    ) {}
}

export enum DocumentRevisionStatus {
    ORIGIN,
    PENDING,
    REJECTED,
    ACCEPTED,
    APPLIED,
}
