import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { PriorityFieldComponent } from '@shared/priority-field/priority-field.component';

@Component({
    standalone: true,
    template: `
        <span class="priority flex">
            <app-priority-field [wfePriorityField]="params.value" />
        </span>
    `,
    styleUrl: './priority-cell.component.scss',
    imports: [PriorityFieldComponent],
})
export class PriorityCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
