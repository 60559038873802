import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-add-discrepancy-button',
    standalone: true,
    imports: [MatIconModule, FlatButtonComponent, TooltipComponent],
    template: ` @if (disabled) {
            <app-tooltip class="add-discrepancy">
                <app-flat-button
                    trigger
                    variant="tertiary"
                    [disabled]="true"
                    [dataQa]="dataQa.addNewDiscrepancyButton">
                    <span class="flex items-center text-sm">
                        <mat-icon svgIcon="add" />New
                    </span>
                </app-flat-button>
                <div content>
                    You can create only
                    <strong>{{ maxDiscCount }}</strong>
                    discrepancies.
                </div>
            </app-tooltip>
        } @else {
            <app-flat-button
                class="add-discrepancy"
                variant="tertiary"
                (clicked)="onAddClick()"
                [dataQa]="dataQa.addNewDiscrepancyButton">
                <span class="flex items-center text-sm">
                    <mat-icon svgIcon="add" />New</span
                >
            </app-flat-button>
        }`,
})
export class AddDiscrepancyButtonComponent {
    @Input({ required: true }) disabled: boolean = false;
    @Input({ required: true }) maxDiscCount!: number;
    @Output() addClicked = new EventEmitter();

    readonly dataQa = DATA_QA;

    onAddClick() {
        this.addClicked.emit();
    }
}
