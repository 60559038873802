import { Injectable } from '@angular/core';

import {
    RevisionDetailDiscrepancy,
    RevisionDetails,
} from '@models/revision/revision-detail';

@Injectable({
    providedIn: 'root',
})
export class RevisionMapper {
    constructor() {}

    toRevisionDetails = (item: RevisionDetails) =>
        new RevisionDetails(
            item.id,
            item.createdAt,
            item.name,
            item.documentServiceId,
            (item?.discrepancies ?? []).map(this.toDiscrepancy),
            item.isEditable ?? false,
            item.kind,
            item.documentId,
        );

    toDiscrepancy = (item: RevisionDetailDiscrepancy) =>
        new RevisionDetailDiscrepancy(
            item.id,
            item.discrepancyNo,
            item.relation,
            item?.actions ?? [],
        );
}
