<div
    class="h-full flex flex-column"
    [attr.data-qa]="dataQa.applyCorrectionDialog"
    [class.disabled]="saveLoading">
    <div class="flex flex-column pt-5 pr-5 pl-5 overflow-hidden">
        <div class="flex items-start justify-space-between mb-3">
            <p class="text-lg">
                Apply Correction for Discrepancy {{ discrepancyNo }}
            </p>
            <div class="flex gap-10">
                <app-flat-button
                    [dataQa]="dataQa.applyCorrectionDialogDiscard"
                    (clicked)="discard()">
                    Discard
                </app-flat-button>
                <app-flat-button
                    variant="tertiary"
                    [dataQa]="dataQa.applyCorrectionDialogSave"
                    [disabled]="!documentChanged"
                    (clicked)="save()">
                    Save
                </app-flat-button>
                @if (closeResolveVisible) {
                    <app-flat-button
                        variant="success"
                        type="solid"
                        [dataQa]="dataQa.applyCorrectionDialogCloseResolve"
                        [disabled]="!isAppliedVersion() && !documentChanged"
                        (clicked)="closeResolveClicked()">
                        Close-Resolve
                    </app-flat-button>
                }
            </div>
        </div>
        @if (discDetailLoading) {
            <app-loader class="mt-2" />
        } @else {
            <div class="flex overflow-hidden flex-1">
                <app-discrepancy-summary
                    class="overflow-auto flex-1"
                    [discDetail]="discDetail" />
                <div class="logs flex-1 overflow-auto">
                    <p class="color-gray13">
                        {{ logsTitle.toLocaleUpperCase() }}
                    </p>
                    @if ((combinedLogs | async)?.length === 0) {
                        <p class="text-s color-gray9 ml-2 mt-2">
                            No changes to show
                        </p>
                    }
                    <ul class="text-s mt-2">
                        @for (log of combinedLogs | async; track $index) {
                            <li
                                [attr.data-qa]="
                                    dataQa.applyCorrectionDialogChangeLogItem
                                ">
                                {{ log }}
                            </li>
                        }
                    </ul>
                </div>
            </div>
        }
    </div>
    <div wfeResizable orientation="vertical"></div>
    <div class="flex flex-column document bg-gray18 overflow-auto flex-1">
        @if (sourceDocument && destinationDocument) {
            @if (showInsertAndReplace) {
                <div
                    class="page-actions flex justify-center items-center gap-10">
                    <app-flat-button
                        #insertTrigger="matMenuTrigger"
                        variant="neon-blue"
                        [dataQa]="dataQa.applyCorrectionDialogInsertMenuTrigger"
                        [matMenuTriggerFor]="insertMenu"
                        (menuOpened)="replaceTrigger.closeMenu()">
                        Insert
                    </app-flat-button>
                    <app-flat-button
                        #replaceTrigger="matMenuTrigger"
                        variant="neon-blue"
                        [dataQa]="
                            dataQa.applyCorrectionDialogReplaceMenuTrigger
                        "
                        [matMenuTriggerFor]="replaceMenu"
                        (menuOpened)="insertTrigger.closeMenu()">
                        Replace
                    </app-flat-button>
                </div>
            }
            <app-web-viewer
                class="h-full"
                [document]="destinationDocument"
                [documentToCompare]="sourceDocument"
                [elementsToBeDisabled]="elementsToBeDisabled"
                (documentLoaded)="onDocumentLoaded($event)" />
        }
    </div>
</div>

<mat-menu
    #insertMenu="matMenu"
    yPosition="above"
    wfeCenter
    [hasBackdrop]="false"
    [overlapTrigger]="true">
    <app-insert-menu-content
        sourceDocumentTag="uploaded correction"
        [vwInstance]="vwInstance"
        [destinationDocumentPageCount]="destinationDocumentPageCount"
        [sourceDocumentName]="sourceDocument?.name"
        [destinationDocumentName]="destinationDocument?.name"
        (inserted)="onDocumentChanged($event)"
        (closeMenu)="insertTrigger?.closeMenu()" />
</mat-menu>

<mat-menu
    #replaceMenu="matMenu"
    yPosition="above"
    wfeCenter
    [hasBackdrop]="false"
    [overlapTrigger]="true">
    <app-replace-menu-content
        sourceDocumentTag="uploaded correction"
        [vwInstance]="vwInstance"
        [sourceDocumentName]="sourceDocument?.name"
        [destinationDocumentName]="destinationDocument?.name"
        (replaced)="onDocumentChanged($event)"
        (closeMenu)="replaceTrigger?.closeMenu()" />
</mat-menu>

@if (saveLoading) {
    <app-loader type="overlay" />
}
