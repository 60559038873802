import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { TableComponent } from '@grid-table/table.component';
import { getSortQuery } from '../documents-and-discrepancies.utils';
import { TableExportFileNames } from '@constants/table';

@Component({
    selector: 'app-activity-log',
    standalone: true,
    templateUrl: 'activity-log.component.html',
    styleUrl: 'activity-log.component.scss',
    imports: [TableComponent, MatIconModule, TextButtonComponent],
})
export class ActivityLogComponent {
    @Input({ required: true }) discrepancyId!: number;
    @ViewChild('activityLogEnd') activityLogEnd!: ElementRef;

    toggled = false;
    columnDefs: ColDef[] = this.getColumnDefs();
    private _gridApi!: GridApi;
    readonly gridOptions: GridOptions = {
        defaultExcelExportParams: {
            fileName: TableExportFileNames.ACTIVITY_LOG,
        },
        defaultCsvExportParams: {
            fileName: TableExportFileNames.ACTIVITY_LOG,
        },
    };

    private discrepancyService = inject(DiscrepancyService);

    constructor() {}

    get toggleText() {
        return `${this.toggled ? 'Hide' : 'Show'} Activity Log`;
    }

    protected onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;
        const datasource = this.getActivityLogs();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    protected toggleActivityLog() {
        this.toggled = !this.toggled;
    }

    private scrollToArea() {
        this.activityLogEnd.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        return {
            ...getSortQuery(request.sortModel),
        };
    }

    private getActivityLogs(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                this.discrepancyService
                    .getDiscrepancyActivityLogs(
                        this.discrepancyId,
                        this.getQueryParams(params.request),
                    )
                    .subscribe((data) => {
                        if (data) {
                            params.success({
                                rowData: data,
                                rowCount: data.length,
                            });
                            if (data.length === 0) {
                                params.api.showNoRowsOverlay();
                            }
                            this.scrollToArea();
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }

    private getColumnDefs(): ColDef[] {
        return [
            { field: 'user', sortable: false },
            {
                field: 'logMessage',
                headerName: 'activity',
                flex: 2,
                sortable: false,
            },
            {
                field: 'createdAt',
                headerName: 'date',
                resizable: false,
                sort: 'desc',
                sortingOrder: ['asc', 'desc'],
            },
        ];
    }
}
