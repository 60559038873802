<div class="dialog-content" [class.disabled]="saveLoading">
    <div class="half pt-5 pr-5 pl-5 overflow-hidden">
        <div class="flex items-start justify-space-between mb-3">
            <p class="text-lg">{{ title }}</p>
            <div class="flex gap-10">
                <app-flat-button (clicked)="discard()">
                    Discard
                </app-flat-button>
                <app-flat-button
                    variant="tertiary"
                    [disabled]="!documentChanged"
                    (clicked)="save()">
                    Save
                </app-flat-button>
                <app-flat-button
                    variant="success"
                    type="solid"
                    [disabled]="!documentChanged"
                    (clicked)="closeResolve()">
                    Close-Resolve
                </app-flat-button>
            </div>
        </div>
        @if (acceptedDiscrepancies.length > 1) {
            <div class="summary-box">
                <p class="text-semi text-s">
                    Select which discrepancy to apply this correction for *
                </p>
                <div class="flex gap-8 mt-2">
                    @for (item of acceptedDiscrepancies; track $index) {
                        <app-radio-button
                            name="selectedDiscrepancy"
                            [value]="item.id"
                            [(ngModel)]="selectedDiscrepancy"
                            (ngModelChange)="onDiscrepancyChanged()">
                            Discrepancy
                            {{ item.discrepancyNo }}
                        </app-radio-button>
                    }
                </div>
            </div>
        }
        @if (discDetailLoading) {
            <app-loader class="mt-2" />
        } @else {
            <app-discrepancy-summary
                class="overflow-auto"
                [discDetail]="discDetail" />
        }
    </div>
    <div wfeResizable orientation="vertical"></div>
    <div class="half document bg-gray18 overflow-auto flex-1">
        @if (documentLoading) {
            <div class="h-full flex items-center justify-center">
                <app-loader />
            </div>
        } @else {
            @if (sourceDocument && destinationDocument) {
                @if (showInsertAndReplace) {
                    <div
                        class="page-actions flex justify-center items-center gap-10">
                        <app-flat-button
                            #insertTrigger="matMenuTrigger"
                            variant="neon-blue"
                            [matMenuTriggerFor]="insertMenu"
                            (menuOpened)="replaceTrigger.closeMenu()">
                            Insert
                        </app-flat-button>
                        <app-flat-button
                            #replaceTrigger="matMenuTrigger"
                            variant="neon-blue"
                            [matMenuTriggerFor]="replaceMenu"
                            (menuOpened)="insertTrigger.closeMenu()">
                            Replace
                        </app-flat-button>
                    </div>
                }
                <app-web-viewer
                    class="h-full"
                    [document]="destinationDocument"
                    [documentToCompare]="sourceDocument"
                    [elementsToBeDisabled]="['insertPage', 'replacePage']"
                    (documentLoaded)="onDocumentLoaded($event)" />
            }
        }
    </div>
</div>

<mat-menu
    #insertMenu="matMenu"
    yPosition="above"
    wfeCenter
    [hasBackdrop]="false"
    [overlapTrigger]="true">
    <app-insert-menu-content
        [vwInstance]="vwInstance"
        [destinationDocumentPageCount]="destinationDocumentPageCount"
        [sourceDocumentName]="sourceDocument?.name"
        [destinationDocumentName]="destinationDocument?.name"
        (inserted)="onDocumentChanged($event)"
        (closeMenu)="insertTrigger?.closeMenu()" />
</mat-menu>

<mat-menu
    #replaceMenu="matMenu"
    yPosition="above"
    wfeCenter
    [hasBackdrop]="false"
    [overlapTrigger]="true">
    <app-replace-menu-content
        [vwInstance]="vwInstance"
        [sourceDocumentName]="sourceDocument?.name"
        [destinationDocumentName]="destinationDocument?.name"
        (replaced)="onDocumentChanged($event)"
        (closeMenu)="replaceTrigger?.closeMenu()" />
</mat-menu>

@if (saveLoading) {
    <app-loader type="overlay" />
}
