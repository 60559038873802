import { DocumentRevisionStatus } from '@models/document/revision';
import { RevisionKind } from '@models/revision/revision-kind';

export class DiscrepancyRevisions {
    constructor(
        public id: number,
        public revisions: DiscrepancyRevisionItem[],
    ) {}
}

export class DiscrepancyRevisionItem {
    constructor(
        public id: number,
        public kind: RevisionKind,
        public relationKind: DocumentRevisionStatus,
        public name: string,
        public documentServiceId: string,
    ) {}
}

export class DiscrepancyRevisionTableItem {
    constructor(
        public id: number | string,
        public revisionName: string,
        public isHeader: boolean,
        public kind?: RevisionKind,
        public relationKind?: DocumentRevisionStatus,
        public documentServiceId?: string,
        public additionalInfo?: string,
    ) {}
}
