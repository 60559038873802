<label class="toggle">
    <input
        type="checkbox"
        (change)="onToggle($event)"
        [checked]="toggled"
        [attr.data-qa]="dataQa" />
    <span class="slider"></span>
</label>
@if (label) {
    <span class="ml-1 text-sm">{{ label }}</span>
}
