@if (loading) {
    <app-loader />
} @else {
    @if (discrepancies.length <= 0) {
        No discrepancy found.
    } @else {
        <app-tabs
            tabLabelClass="text-s"
            tabContentClass="ml-n4 pl-4"
            [tabs]="tabsData"
            [initialSelectedTabIndex]="selectedTabIndex"
            (tabSelected)="handleTabChange($event)"
            (newClicked)="openAddDiscModal()">
            @if (detailsLoading) {
                <app-loader />
            } @else {
                @if (discDetail.id !== -1) {
                    <form class="pr-2" [formGroup]="form">
                        <div class="info-section bordered">
                            <app-content-field label="ANALYST">
                                {{ discDetail.analyst?.displayName ?? '-' }}
                            </app-content-field>
                            <app-content-field label="STATUS">
                                <span wfeGrayBox>
                                    {{ discDetail.status }}
                                </span>
                            </app-content-field>
                            @if (readOnly || !isAdmin) {
                                <app-content-field label="PRIORITY">
                                    <span
                                        [wfePriorityField]="
                                            discDetail.priority
                                        "></span>
                                </app-content-field>
                            } @else {
                                <app-priority-select
                                    label="PRIORITY"
                                    variant="secondary"
                                    formControlName="priority"
                                    [value]="discDetail.priority"
                                    [options]="editOptions.priority"
                                    style="width: 130px" />
                            }
                            <app-content-field label="CREATE DATE">
                                {{ discDetail.createdAt }}
                            </app-content-field>
                        </div>
                        <mat-divider />
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="REASON">
                                    {{ discDetail.reason?.displayName }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="REASON"
                                    formControlName="reason"
                                    [required]="true"
                                    [options]="editOptions.reason"
                                    [value]="discDetail.reason?.backingField" />
                            }
                            @if (readOnly) {
                                <app-content-field label="PAGE RANGE">
                                    {{ discDetail.pageRange || '-' }}
                                </app-content-field>
                            } @else {
                                <div>
                                    <app-input
                                        label="PAGE RANGE *"
                                        formControlName="pageRange"
                                        [control]="pageRange">
                                        <app-checkbox
                                            class="ml-auto"
                                            size="small"
                                            label="Send only selected pages"
                                            formControlName="notifyWithOnlySelectedPages"
                                            [disabled]="docSizeExceedMaxLimit()"
                                            [checked]="
                                                discDetail.notifyWithOnlySelectedPages
                                            " />
                                        @if (pageRange.hasError('pageRange')) {
                                            <app-input-invalid-page-range
                                                error />
                                        }
                                    </app-input>
                                    @if (docSizeExceedMaxLimit()) {
                                        <app-page-range-error
                                            [discDetail]="discDetail" />
                                    }
                                </div>
                            }
                        </div>
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="RESPONSIBLE STATION">
                                    {{ discDetail.station?.displayName }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="RESPONSIBLE STATION"
                                    formControlName="station"
                                    [required]="true"
                                    [value]="discDetail.station?.backingField"
                                    [options]="editOptions.station" />
                            }
                            @if (readOnly) {
                                <app-content-field label="RESPONSIBLE FIXER">
                                    {{
                                        discDetail.responsibleFixer
                                            ?.displayName ?? '-'
                                    }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="RESPONSIBLE FIXER"
                                    formControlName="responsibleFixer"
                                    [required]="true"
                                    [value]="
                                        discDetail.responsibleFixer
                                            ?.backingField
                                    "
                                    [options]="fixerOptions" />
                            }
                        </div>
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="DIRECTIONS">
                                    {{ discDetail.directions }}
                                </app-content-field>
                            } @else {
                                <app-input
                                    label="DIRECTIONS"
                                    formControlName="directions"
                                    [maxLength]="maxDirectionsLength" />
                            }
                        </div>
                        @if (mode === 'detail' && !readOnly) {
                            <div class="info-section">
                                <app-select
                                    label="SELECT A VERSION TO SEND FOR CORRECTION:"
                                    formControlName="revision"
                                    [options]="editOptions.revision" />
                            </div>
                        }
                    </form>
                    <app-activity-log [discrepancyId]="discDetail.id" />
                    @if (mode === 'detail') {
                        <ng-container>
                            <div class="actions ml-n4 pl-2 pr-4">
                                @if (discDetail) {
                                    <app-footer-assign-actions
                                        [discDetail]="discDetail"
                                        [editOptions]="editOptions"
                                        [isAdmin]="isAdmin"
                                        (assigneeChanged)="
                                            getDiscrepancyDetails($event)
                                        " />
                                    <app-footer-state-actions
                                        [discDetail]="discDetail"
                                        [documentId]="documentId"
                                        [canSave]="canSave"
                                        [editForm]="form"
                                        [editOptions]="editOptions"
                                        (getDiscrepancies)="
                                            getDiscrepancies($event)
                                        "
                                        (getDiscrepancyDetails)="
                                            getDiscrepancyDetails($event)
                                        " />
                                }
                            </div>
                        </ng-container>
                    }
                } @else {
                    Something went wrong.
                }
                @if (mode === 'detail' && documentDetails) {
                    <app-document-upload
                        [discrepancies]="discrepancies"
                        [documentDetails]="documentDetails" />
                }
            }
            @if (mode === 'detail' && documentDetails && maxDiscrepancyCount) {
                <app-add-discrepancy-button
                    headerContent
                    class="ml-auto mr-4 mb-2"
                    [disabled]="!documentDetails.canAddDiscrepancy"
                    [maxDiscCount]="maxDiscrepancyCount"
                    (addClicked)="openAddDiscModal()" />
            }
        </app-tabs>
    }
}
