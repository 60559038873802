import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    Input,
    model,
    OnChanges,
    OnInit,
    signal,
    SimpleChanges,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent,
    MatOption,
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

import { SelectOption, SelectOptionWithGroup } from '@models/shared.model';

/**
 * @title Chips Autocomplete
 */
@Component({
    standalone: true,
    selector: 'app-chips-autocomplete',
    templateUrl: 'chips-autocomplete.component.html',
    styleUrl: 'chips-autocomplete.component.scss',
    imports: [
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        FormsModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsAutocompleteComponent implements OnInit, OnChanges {
    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() options: SelectOption[] | SelectOptionWithGroup[] = [];
    @Input() disabledOptions: (number | string)[] = [];
    @Input() inputControl = new FormControl<SelectOption[]>([]);
    isGroupedOptions: boolean = false;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    readonly current = model<any>(null);
    readonly selectedOptions = signal<SelectOption[]>([]);

    readonly filteredOptions = computed(() => {
        if (this.current()?.viewValue) {
            return this.flatOptions.slice();
        } else {
            const current = this.current();

            return current
                ? this.filter(this.flatOptions, current)
                : this.flatOptions.slice();
        }
    });

    readonly filteredGroupOptions = computed(() => {
        if (this.current()?.viewValue) {
            return this.groupedOptions.slice();
        } else {
            const current = this.current();

            return current
                ? this.filterGroup(current)
                : this.groupedOptions.slice();
        }
    });

    get groupedOptions(): SelectOptionWithGroup[] {
        return this.options.filter(
            (opt): opt is SelectOptionWithGroup => 'items' in opt,
        );
    }

    get flatOptions(): SelectOption[] {
        return this.options.filter(
            (opt): opt is SelectOption => !('items' in opt),
        );
    }

    constructor() {
        effect(() => {
            this.inputControl.setValue(this.selectedOptions(), {
                emitEvent: false,
            });
        });
    }

    ngOnInit() {
        this.selectedOptions.update(() => [
            ...(this.inputControl.value as SelectOption[]),
        ]);

        this.isGroupedOptions =
            this.options.length > 0 && 'items' in this.options[0];
    }

    ngOnChanges(changes: SimpleChanges): void {
        const disabledOptionsChange = changes['disabledOptions'];

        if (disabledOptionsChange) {
            this.removeDisabledOptions();
        }
    }

    add(event: MatAutocompleteSelectedEvent): void {
        const option = event.option || '';
        if (option && !this.isOptionSelected(option)) {
            this.selectedOptions.update((options) => [...options, option]);
        }
        this.current.set('');
    }

    remove(option: SelectOption): void {
        this.selectedOptions.update((options) => {
            const index = options.indexOf(option);
            if (index < 0) {
                return options;
            }

            options.splice(index, 1);
            return [...options];
        });
    }

    removeDisabledOptions() {
        this.selectedOptions.update((options) => {
            const filteredOptions = options.filter(
                (opt) => !this.disabledOptions.includes(opt.value),
            );

            return [...filteredOptions];
        });
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const option = event.option || '';

        if (!this.isOptionSelected(option)) {
            this.selectedOptions.update((options) => [
                ...options,
                event.option.value,
            ]);

            this.current.set('');
            event.option.deselect();
        }
    }

    isOptionDisabled(value: string | number) {
        return this.disabledOptions.some(
            (disabledOption) => disabledOption === value,
        );
    }

    private filter(options: SelectOption[], name: string): SelectOption[] {
        const filterValue = name.toLowerCase();

        return options.filter((option) =>
            option.viewValue.toLowerCase().includes(filterValue),
        );
    }

    private filterGroup(value: string): SelectOptionWithGroup[] {
        if (value) {
            return this.groupedOptions.map((opt) => ({
                group: opt.group,
                items: this.filter(opt.items, value),
            }));
        }

        return this.groupedOptions;
    }

    private isOptionSelected(option: MatOption) {
        return this.selectedOptions().some(
            (i) => i.value === option.value.value,
        );
    }
}
