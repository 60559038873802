import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesService {
    private _hasUnsavedChanges = false;

    get hasUnsavedChanges(): boolean {
        return this._hasUnsavedChanges;
    }

    set hasUnsavedChanges(value: boolean) {
        if (value) {
            this.addListener();
        } else this.cleanup();
        this._hasUnsavedChanges = value;
    }

    beforeUnloadListener = (event: BeforeUnloadEvent): void => {
        if (this._hasUnsavedChanges) {
            event.preventDefault();
        }
    };

    addListener() {
        window.removeEventListener('beforeunload', this.beforeUnloadListener);
        window.addEventListener('beforeunload', this.beforeUnloadListener);
    }

    cleanup() {
        window.removeEventListener('beforeunload', this.beforeUnloadListener);
    }
}
