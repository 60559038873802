import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import {
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';

import { SelectComponent } from '../select.component';
import { PriorityFieldComponent } from '@shared/priority-field/priority-field.component';
import { isNil } from '@helpers/index';

@Component({
    selector: 'app-priority-select',
    standalone: true,
    templateUrl: './priority-select.component.html',
    styleUrl: '../select.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDividerModule,
        MatCheckboxModule,
        PriorityFieldComponent,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PrioritySelectComponent),
            multi: true,
        },
    ],
})
export class PrioritySelectComponent extends SelectComponent {
    @Input() selectStyle = '';

    valueExist() {
        return !isNil(this.value);
    }
}
