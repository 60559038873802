import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { PriorityColorDirective } from '@directives/priority-color.directive';
import { WorkflowDocument } from '@models/document';

@Component({
    selector: 'app-header-document-details',
    standalone: true,
    templateUrl: 'header-document-details.component.html',
    styles: `
        .document-info {
            white-space: nowrap;
            column-gap: 40px;
            row-gap: 10px;
        }
    `,
    imports: [
        MatIconModule,
        MatProgressBarModule,
        ContentFieldComponent,
        TooltipComponent,
        PriorityColorDirective,
    ],
})
export class HeaderDocumentDetailsComponent implements OnChanges {
    @Input({ required: true }) document: WorkflowDocument | null = null;

    progressValue = 0;

    ngOnChanges(changes: SimpleChanges) {
        this.progressValue = 0;

        const documentChange = changes['document'];

        this.progressValue =
            (documentChange.currentValue.progress.completed * 100) /
            documentChange.currentValue.progress.total;
    }
}
