import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { SharedMapper } from '@services/api/shared.mapper';
import { WorkflowDataService } from '@services/data/workflow-data.service';
import { ApiService } from '@services/api/api.service';
import { interpolate } from '@helpers/index';
import { DropdownItem } from '@models/shared.model';
import { SimpleResponse } from '@models/api.model';
import {
    WorkflowState,
    WorkflowStepper,
    WorkflowTransition,
} from '@models/workflow';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    private readonly _path = 'v1';

    constructor(
        private api: ApiService,
        private workflowDataService: WorkflowDataService,
        private sharedMapper: SharedMapper,
    ) {}

    getWorkflows(): Observable<DropdownItem[]> {
        return this.api
            .get<DropdownItem[]>(this._path + endpoints.workflows)
            .pipe(
                map((response: SimpleResponse<DropdownItem[]>) =>
                    response.data.map(this.sharedMapper.toDropdownItem),
                ),
                catchError(() => of([])),
            );
    }

    getWorkflowStepper(id: number) {
        return this.api
            .get<WorkflowStepper>(
                this._path + interpolate(endpoints.workflowStepper, { id }),
            )
            .pipe(
                map((response: SimpleResponse<WorkflowStepper>) => {
                    const workflow = response.data;
                    this.saveWorkflowStates(
                        workflow.states,
                        workflow.transitions,
                    );

                    return workflow;
                }),
                catchError(() => of(null)),
            );
    }

    private saveWorkflowStates(
        states: WorkflowState[],
        transitions: WorkflowTransition[],
    ) {
        const initial = states.find((state) => state.isInitialState);
        const sorted = [initial];
        let currentId = initial?.id;
        let _states: WorkflowState[] = [];

        try {
            while (sorted.length < states.length) {
                const current = transitions.find((t) => t.from === currentId);
                const nextState = states.find((s) => s.id === current?.to);
                sorted.push(nextState);
                currentId = nextState!.id;
            }
            _states = sorted.filter((i) => i !== undefined);
        } catch {
            _states = [];
        }

        this.workflowDataService.updateWorkflowStates(_states);
    }
}
