@if (error) {
    <small class="color-status-red4"> Documents can't be loaded</small>
} @else {
    <app-table
        noRowsText="No workflow documents to show."
        [columnDefs]="columnDefs"
        [masterDetail]="true"
        [suppressMenu]="true"
        [detailCellRendererParams]="detailCellRendererParams"
        (rowGroupOpened)="onRowGroupOpened($event)"
        (gridReady)="onGridReady($event)">
    </app-table>
}
