import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@services/snackbar.service';
import { UserDataService } from '@services/data/user-data.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { AssignDiscrepancyDialogComponent } from '@dialogs/assign-discrepancy-dialog/assign-discrepancy-dialog.component';
import { DiscrepancyEditOptions, DiscrepancyDetail } from '@models/discrepancy';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-footer-assign-actions',
    standalone: true,
    template: ` <div>
        <app-text-button
            (clicked)="takeDiscrepancy()"
            [dataQa]="dataQa.footerTakeDiscrepancyButton"
            [disabled]="!canTake">
            Take
        </app-text-button>
        <app-text-button
            (clicked)="putbackDiscrepancy()"
            [dataQa]="dataQa.footerPutbackDiscrepancyButton"
            [disabled]="!isAssignedToMe">
            Put back
        </app-text-button>
        @if (isAdmin) {
            <app-text-button
                [disabled]="!isAdmin"
                [dataQa]="dataQa.footerAssignDiscrepancyButton"
                (clicked)="assignDiscrepancy()">
                Assign
            </app-text-button>
            <app-text-button
                [disabled]="!canUnassign"
                [dataQa]="dataQa.footerUnassignDiscrepancyButton"
                (clicked)="unassignDiscrepancy()">
                Unassign
            </app-text-button>
        }
    </div>`,
    imports: [TextButtonComponent],
})
export class FooterAssignActionsComponent implements OnInit, OnDestroy {
    @Input({ required: true }) discDetail!: DiscrepancyDetail;
    @Input({ required: true }) editOptions!: DiscrepancyEditOptions;
    @Input({ required: true }) isAdmin!: boolean;

    isAssignedToMe = false;
    canTake = false;
    canUnassign = false;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private store = inject(Store);
    private dialog = inject(MatDialog);
    private userDataService = inject(UserDataService);
    private snackbarService = inject(SnackbarService);
    private discrepancyService = inject(DiscrepancyService);

    ngOnInit(): void {
        this.setActionPermissions();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    takeDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId) {
            this._subscriptions['take-discrepancy'] = this.discrepancyService
                .takeDiscrepancy(discId)
                .subscribe({
                    next: (success) => {
                        if (success) {
                            this.snackbarService.success({
                                variant: 'success',
                                header: `Discrepancy ${this.discDetail.discrepancyNo} Has Been Taken`,
                            });
                            this.store.dispatch(refreshActionRelatedData());
                        }
                    },
                });
        }
    }

    putbackDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId && this.isAssignedToMe) {
            this._subscriptions['putback-discrepancy'] = this.discrepancyService
                .putbackDiscrepancy(discId)
                .subscribe({
                    next: (success) => {
                        if (success) {
                            this.snackbarService.success({
                                variant: 'success',
                                header: `Discrepancy ${this.discDetail.discrepancyNo} Has Been Put Back`,
                            });
                            this.store.dispatch(refreshActionRelatedData());
                        }
                    },
                });
        }
    }

    assignDiscrepancy() {
        const discId = this.discDetail?.id;
        this.dialog.open(AssignDiscrepancyDialogComponent, {
            data: {
                id: discId,
                discrepancyNo: this.discDetail.discrepancyNo,
                options: this.editOptions.analyst,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });
    }

    unassignDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId) {
            this._subscriptions['unassign-discrepancy'] =
                this.discrepancyService.unassignDiscrepancy(discId).subscribe({
                    next: (success) => {
                        if (success) {
                            this.snackbarService.success({
                                variant: 'success',
                                header: `Discrepancy ${this.discDetail.discrepancyNo} Has Been Unassigned`,
                            });
                            this.store.dispatch(refreshActionRelatedData());
                        }
                    },
                });
        }
    }

    private setActionPermissions() {
        const userId = this.userDataService.currentUser?.id;
        this.isAssignedToMe = this.discDetail.analyst?.backingField == userId;
        this.canTake = this.canTakeDiscrepancy();
        this.canUnassign = this.canUassignDiscrepancy();
    }

    private canTakeDiscrepancy() {
        if (this.isAdmin) return !this.isAssignedToMe;
        const noAnalyst = this.discDetail.analyst === null;
        if (noAnalyst || !this.isAssignedToMe) return true;
        return false;
    }

    private canUassignDiscrepancy() {
        const noAnalyst = this.discDetail.analyst === null;
        if (this.isAdmin) return !this.isAssignedToMe && !noAnalyst;
        return false;
    }
}
