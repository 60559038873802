import {
    Component,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { Store } from '@ngrx/store';

import { RouterService } from '@services/router.service';
import { ColumnOptionsService } from '@services/column-options.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { TextFilterComponent } from '@grid-table/filter/text-filter/text-filter.component';
import { PriorityCellComponent } from '@grid-table/cell/priority-cell/priority-cell.component';
import { TableComponent } from '@grid-table/table.component';
import { ClickableCellComponent } from '@grid-table/cell/clickable-cell/clickable-cell.compoent';
import { GrayBoxCellComponent } from '@grid-table/cell/gray-box-cell/gray-box-cell.component';
import { SelectFilterComponent } from '@grid-table/filter/select-filter/select-filter.component';
import { DateFilterComponent } from '@grid-table/filter/date-filter/date-filter.component';
import { RangeFilterComponent } from '@grid-table/filter/range-filter/range-filter.component';
import { WorkflowAndRepository } from '@models/workflow/workflow-and-repository';
import { DiscrepancyFilterOptions, BulkRequestItem } from '@models/discrepancy';
import { Range } from '@models/shared.model';
import { DiscrepancyColumn } from '@constants/table';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import {
    DocumentDetailTab,
    getAgeInWFValue,
    getDiscrepancyId,
    isFilterApplied,
    getFilterQuery,
    getPageIndex,
    getSortQuery,
} from '../documents-and-discrepancies.utils';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import DATA_QA from '@automation/data-qa.json';

@Component({
    selector: 'app-discrepancy-view',
    standalone: true,
    templateUrl: './discrepancy-view.component.html',
    imports: [TableComponent],
})
export class DiscrepencyViewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() workflowAndRepo: WorkflowAndRepository | undefined;
    @Input() statuses: number[] = [];
    @Input() showMyItems: boolean = false;

    error = false;
    filterApplied: boolean = false;
    columnDefs: ColDef[] = [];
    gridOptions: GridOptions = {
        onRowSelected: this.onRowSelected.bind(this),
        onPaginationChanged: this.onRowSelected.bind(this),
    };
    hiddenColumnOptions: DiscrepancyColumn[] = [];
    selectedBulkData: BulkRequestItem[] = [];
    resultCount: number | null = null;
    private _gridApi!: GridApi;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly dataQa = DATA_QA;

    private store = inject(Store);
    private routerService = inject(RouterService);
    private discrepancyService = inject(DiscrepancyService);
    private columnOptionsService = inject(ColumnOptionsService);

    get filterModel() {
        return this._gridApi.getFilterModel();
    }

    get columnState() {
        return this._gridApi.getColumnState();
    }

    get hasSelectedRow() {
        return this.selectedBulkData.length > 0;
    }

    get selectedRowsCount() {
        return this.selectedBulkData.reduce((sum, item) => {
            return sum + item.discrepancies.length;
        }, 0);
    }

    ngOnInit(): void {
        this.columnDefs = [
            ...this.getColumnDefs(DiscrepancyFilterOptions.initial()),
        ];
        this.handleColumnOptions();
        this.getFilterOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const workflowAndRepoChange = changes['workflowAndRepo'];
        if (workflowAndRepoChange && !workflowAndRepoChange.firstChange) {
            this.resetFilters();
            this.getFilterOptions();
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const datasource = this.getAllDiscrepancies();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
        this.hideHiddenColumns();
    }

    setSelectedRows() {
        const result = new Map<number, number[]>();

        if (this._gridApi)
            this._gridApi.forEachNode((rowNode) => {
                if (rowNode.isSelected()) {
                    const { documentId, id } = rowNode.data;

                    if (!result.has(documentId)) {
                        result.set(documentId, []);
                    }
                    result.get(documentId)!.push(id);
                }
            });

        this.selectedBulkData = Array.from(result.entries()).map(
            ([documentId, discrepancies]) => ({ documentId, discrepancies }),
        );
    }

    resetFilters() {
        this._gridApi.refreshHeader();
        this._gridApi.setFilterModel(null);
    }

    refreshData() {
        if (!this._gridApi.isDestroyed()) {
            this._gridApi.refreshServerSide({ purge: true });
        }
    }

    private onRowSelected() {
        this.setSelectedRows();
    }

    private getFilterOptions() {
        this._subscriptions['filter-options'] = this.discrepancyService
            .getDiscrepanciesFilterOptions(this.workflowAndRepo)
            .subscribe({
                next: (response: DiscrepancyFilterOptions) => {
                    this.columnDefs = [...this.getColumnDefs(response)];
                    setTimeout(() => {
                        this.hideHiddenColumns();
                    }, 1000);
                },
            });
    }

    private handleColumnOptions() {
        this._subscriptions['column-options'] =
            this.columnOptionsService.discColumnOptions$.subscribe({
                next: (options) => {
                    this.hiddenColumnOptions = options.hiddenColumns;
                    const option = options.lastToggled;
                    if (
                        option &&
                        this._gridApi &&
                        !this._gridApi.isDestroyed()
                    ) {
                        this._gridApi.setColumnsVisible(
                            [option.id],
                            option.checked,
                        );
                        this._gridApi.destroyFilter(option.id);
                    }
                },
            });
    }

    private getAllDiscrepancies(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                params.api.showLoadingOverlay();
                this.filterApplied = isFilterApplied(params.request);
                if (this.filterApplied) this._gridApi.deselectAll();

                this._subscriptions['get-all-discrepancies'] =
                    this.discrepancyService
                        .getAllDiscrepancies(
                            this.getQueryParams(params.request),
                        )
                        .subscribe({
                            next: (data) => {
                                params.api.hideOverlay();

                                if (data) {
                                    params.success({
                                        rowData: data.items,
                                        rowCount: data.totalItems ?? -1,
                                    });
                                    if (data.items.length === 0) {
                                        params.api.showNoRowsOverlay();
                                    }
                                    this.hideHiddenColumns();
                                    this.resultCount = data.totalItems ?? null;
                                } else {
                                    params.fail();
                                }
                            },
                            error: () => {
                                params.api.hideOverlay();
                                this.error = true;
                            },
                        });
            },
        };
    }

    private hideHiddenColumns() {
        if (this._gridApi && !this._gridApi.isDestroyed) {
            this._gridApi.setColumnsVisible(this.hiddenColumnOptions, false);
            setTimeout(() => {
                this._gridApi.refreshCells();
                this._gridApi.sizeColumnsToFit();
            }, 0);
        }
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        const pageSize = this._gridApi.paginationGetPageSize();
        const pageIndex = getPageIndex(request, pageSize);

        // getFilterQuery should be after workflowAndRepo to update repository query of dropdown

        return {
            ...this.workflowAndRepo,
            ...{ status: this.statuses },
            ...{ mine: this.showMyItems },
            ...getFilterQuery(request.filterModel),
            ...getSortQuery(request.sortModel),
            pageIndex,
            pageSize,
        };
    }

    private onDiscrepancyIdClick(node: IRowNode) {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: node.data.documentId,
                selectedDiscrepancy: node.data.id,
                selectedTab: DocumentDetailTab.DISCREPANCIES,
            }),
        );
    }

    private onDiscrepancyIdDoubleClick(node: IRowNode) {
        this.routerService.goDocumentDetails(
            parseInt(node.data.documentId),
            DocumentDetailTab.DISCREPANCIES,
            parseInt(node.data.id),
        );
    }

    private getColumnDefs(filterOptions: DiscrepancyFilterOptions): ColDef[] {
        return [
            {
                field: DiscrepancyColumn.ID,
                headerName: 'Discrepancy ID',
                filter: 'agTextColumnFilter',
                minWidth: 145,
                valueFormatter: (params) => getDiscrepancyId(params.data).label,
                cellRenderer: ClickableCellComponent,
                cellRendererParams: {
                    dataQa: this.dataQa.discrepancyViewDiscrepancyIdCell,
                    tooltipGetter: (params: ICellRendererParams) =>
                        getDiscrepancyId(params.data).tooltip,
                    onClick: (node: IRowNode) =>
                        this.onDiscrepancyIdClick(node),
                    onDoubleClick: (node: IRowNode) =>
                        this.onDiscrepancyIdDoubleClick(node),
                },
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: {
                    class: 'pl-11',
                    type: 'number',
                },
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                field: DiscrepancyColumn.DOC_NAME,
                minWidth: 180,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: DiscrepancyColumn.REASON,
                filter: 'agTextColumnFilter',
                minWidth: 130,
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.reason,
                },
            },
            {
                field: DiscrepancyColumn.PRIORITY,
                maxWidth: 130,
                cellRenderer: PriorityCellComponent,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.priority,
                    priority: true,
                },
            },
            {
                field: DiscrepancyColumn.STATUS,
                minWidth: 180,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.status,
                },
            },
            {
                field: DiscrepancyColumn.AGE_IN_WF,
                headerName: 'Age In WF',
                maxWidth: 120,
                cellRenderer: GrayBoxCellComponent,
                valueFormatter: getAgeInWFValue,
                cellStyle: { textOverflow: 'clip' },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: RangeFilterComponent,
                floatingFilterComponentParams: {
                    availableRange: new Range(0, filterOptions.maxAge),
                },
            },
            {
                field: DiscrepancyColumn.TAKEN,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
            {
                field: DiscrepancyColumn.ANALYST,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: DiscrepancyColumn.STATION,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.station,
                    autocomplete: true,
                },
            },
            {
                field: DiscrepancyColumn.PRIMARY_FIXER,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.primaryFixer,
                    autocomplete: true,
                },
            },
        ];
    }
}
