import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpErrorResponse,
    HttpRequest,
    HttpEventType,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import {
    DefaultParams,
    PagedResponse,
    PostProgressResponse,
    SimpleResponse,
} from '@models/api.model';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private apiUrl = environment.apiUrl;
    private defaultParams: DefaultParams = {
        pageSize: 10,
        pageIndex: 1,
        order: 'desc',
    };

    constructor(private http: HttpClient) {}

    get<T>(
        path: string,
        queryParams?: any,
        isPaged: boolean = false,
        httpHeaders?: { [key: string]: string },
    ): Observable<any> {
        const pageParams = isPaged ? this.defaultParams : {};
        const params = this.buildHTTPParams({
            ...pageParams,
            ...queryParams,
        });
        const headers = this.buildHTTPHeaders(httpHeaders);

        return this.http
            .get<any>(`${this.apiUrl}/${path}`, { params, headers })
            .pipe(
                map((response) => {
                    if (isPaged) {
                        return new PagedResponse<T>(
                            response.pageIndex,
                            response.pageSize,
                            response.totalItems,
                            response?.items ?? [],
                        );
                    } else {
                        return new SimpleResponse<T>(response);
                    }
                }),
                catchError(this.handleError),
            );
    }

    post<T>(path: string, body?: any, options?: any): Observable<any> {
        return this.http.post<T>(`${this.apiUrl}/${path}`, body).pipe(
            map((response) => {
                return new SimpleResponse<T>(response);
            }),
            catchError(this.handleError),
        );
    }

    put<T>(path: string, body: any): Observable<any> {
        return this.http.put<T>(`${this.apiUrl}/${path}`, body).pipe(
            map((response) => {
                return new SimpleResponse<T>(response);
            }),
            catchError(this.handleError),
        );
    }

    patch<T>(path: string, body?: any): Observable<T> {
        return this.http.patch<T>(`${this.apiUrl}/${path}`, body);
    }

    delete<T>(path: string): Observable<T> {
        return this.http.delete<T>(`${this.apiUrl}/${path}`);
    }

    getBlob(path: string, queryParams?: any): Observable<any> {
        const params = this.buildHTTPParams({
            ...queryParams,
        });
        return this.http.get(`${this.apiUrl}/${path}`, {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob' as 'json',
            observe: 'response',
            params,
        });
    }

    postProgress(
        path: string,
        formData: FormData,
    ): Observable<PostProgressResponse> {
        const reg = new HttpRequest(
            'POST',
            `${this.apiUrl}/${path}`,
            formData,
            { reportProgress: true },
        );

        return this.http.request(reg).pipe(
            map((event): PostProgressResponse => {
                switch (event.type) {
                    case HttpEventType.UploadProgress: {
                        const progress = Math.round(
                            (100 * event.loaded) / (event.total ?? 1),
                        );
                        return {
                            progress,
                            status: 'progress',
                        };
                    }
                    case HttpEventType.Response:
                        return {
                            progress: 100,
                            status: 'success',
                        };
                    default:
                        return {
                            progress: 0,
                            status: 'unknown',
                        };
                }
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(() => error);
            }),
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            console.error('An error occurred:', error.error);
        } else {
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error ?? '-',
            );
        }
        return throwError(
            () => new Error('Something bad happened; please try again later.'),
        );
    }

    private buildHTTPParams(queryParams?: any): HttpParams {
        let params = new HttpParams();
        if (queryParams) {
            const queryParamKeys = Object.keys(queryParams);

            queryParamKeys.forEach((key) => {
                const isArray = queryParams[key] instanceof Array;

                if (isArray) {
                    queryParams[key].forEach((item: any) => {
                        params = params.append(`${key.toString()}`, item);
                    });
                } else {
                    params = params.append(key.toString(), queryParams[key]);
                }
            });
        }
        return params;
    }

    private buildHTTPHeaders(headers?: { [key: string]: string }): HttpHeaders {
        let httpHeaders = new HttpHeaders();
        if (headers) {
            Object.keys(headers).forEach((key) => {
                httpHeaders = httpHeaders.set(key, headers[key]);
            });
        }
        return httpHeaders;
    }
}
