<div class="container ml-n4 mt-n4">
    @if (questions.length > 0) {
        <div class="content mt-2">
            <form [formGroup]="form" class="dynamic-form">
                @for (group of grouped | keyvalue; track group.key) {
                    <div class="section">
                        <p class="mb-2 text-md hidden">{{ group.key }}</p>
                        <div class="section__items">
                            @for (question of group.value; track $index) {
                                <div
                                    [class.full-width]="
                                        group.value.length === 1
                                    ">
                                    @if (question.readonly) {
                                        <app-content-field
                                            [required]="question.required"
                                            [label]="
                                                question.label.toLocaleUpperCase()
                                            ">
                                            {{ question.value }}
                                        </app-content-field>
                                    } @else {
                                        <app-question
                                            [question]="question"
                                            [form]="form" />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </form>
        </div>
        @if (form) {
            <div class="footer">
                @if (error) {
                    <small class="mr-4 color-status-red4">
                        Metadata could not be updated.
                    </small>
                }
                <app-flat-button
                    variant="tertiary"
                    [dataQa]="dataQa.metadataEditSaveButton"
                    [disabled]="!form.valid || !formChanged || loading"
                    (clicked)="onSave()">
                    Save
                </app-flat-button>
            </div>
        }
    }
</div>

@if (loading) {
    <app-loader type="overlay" />
}
