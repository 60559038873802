<div class="header flex justify-space-between p-2 pl-3">
    <p class="flex items-center h-full">Documents</p>
    <app-select
        class="disc-select"
        [options]="discrepancies"
        [value]="selectedDiscrepancy"
        (selectionChange)="onDiscrepancyChange($event)" />
</div>
<app-table
    variant="secondary"
    class="flex pt-2 pl-3"
    [columnDefs]="columnDefs"
    [suppressMenu]="true"
    [suppressMovableColumns]="true"
    [suppressRowHoverHighlight]="true"
    [suppressCellFocus]="true"
    [pagination]="false"
    [pageSize]="-1"
    [getRowStyle]="getRowStyle"
    (gridReady)="onGridReady($event)" />
