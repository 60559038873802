import { IdDisplayPair, SelectOption } from '../shared.model';

export class DiscrepancyEditOptionsResponse {
    constructor(
        public priority: IdDisplayPair[],
        public reason: IdDisplayPair[],
        public station: IdDisplayPair[],
        public fixer: IdDisplayPair[],
        public canEdit: boolean,
        public analyst: IdDisplayPair[] | null,
        public revision: IdDisplayPair[],
    ) {}
}

export class DiscrepancyEditOptions {
    constructor(
        public priority: SelectOption[],
        public reason: SelectOption[],
        public station: SelectOption[],
        public fixer: SelectOption[],
        public canEdit: boolean,
        public analyst: SelectOption[],
        public revision: SelectOption[],
    ) {
        this.priority = priority;
        this.reason = reason;
        this.station = station;
        this.fixer = fixer;
        this.canEdit = canEdit;
        this.analyst = analyst;
        this.revision = revision;
    }

    static initial(): DiscrepancyEditOptions {
        return new DiscrepancyEditOptions([], [], [], [], false, [], []);
    }
}
