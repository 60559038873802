import { Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'app-tooltip',
    standalone: true,
    imports: [MatMenuModule],
    template: `
        <div>
            <div
                [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger"
                (mouseenter)="mouseEnter(menuTrigger)"
                (mouseleave)="mouseLeave(menuTrigger)">
                <ng-content select="[trigger]" />
            </div>
            <mat-menu
                #menu="matMenu"
                [hasBackdrop]="false"
                class="tooltip fs-12 p-1 mt-2 mb-2">
                <div
                    class="pl-2 pr-2"
                    (mouseenter)="mouseEnter(menuTrigger)"
                    (mouseleave)="mouseLeave(menuTrigger)">
                    <ng-content select="[content]" />
                </div>
            </mat-menu>
        </div>
    `,
    styleUrl: './tooltip.component.scss',
})
export class TooltipComponent {
    private timedOutCloser: ReturnType<typeof setTimeout> | undefined;
    private timedOutTrigger: ReturnType<typeof setTimeout> | undefined;

    constructor() {}

    mouseEnter(trigger: { openMenu: () => void }) {
        if (this.timedOutCloser) clearTimeout(this.timedOutCloser);

        this.timedOutTrigger = setTimeout(() => {
            trigger.openMenu();
        }, 500);
    }

    mouseLeave(trigger: { closeMenu: () => void }) {
        if (this.timedOutTrigger) clearTimeout(this.timedOutTrigger);

        this.timedOutCloser = setTimeout(() => {
            trigger.closeMenu();
        }, 50);
    }
}
