import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { DocumentRevisionStatus } from '@models/document/revision';
import { documentRevisionStatusMap } from '@constants/table';

interface CustomParams {
    discrepancyRelations: DocumentRevisionStatus[];
}

@Component({
    standalone: true,
    imports: [MatIconModule],
    template: `@if (!params.data.isHeader) {
        <div class="flex gap-8 justify-space-evenly h-full">
            @for (item of params.data.discrepancyRelations; track $index) {
                <div class="item flex items-center justify-center">
                    @if (item !== null) {
                        <mat-icon [svgIcon]="getIconType(item)" />
                    } @else {
                        -
                    }
                </div>
            }
        </div>
    }`,
    styles: `
        .item {
            width: 24px;
        }
    `,
})
export class DiscrepancyCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams & CustomParams;

    agInit(params: ICellRendererParams & CustomParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }

    getIconType(kind: DocumentRevisionStatus): string {
        return documentRevisionStatusMap[kind].icon;
    }
}
