import { IdDisplayPair, SelectOption } from '../shared.model';

export class DocDiscrepancyCreateOptionsResponse {
    constructor(
        public reason: IdDisplayPair[],
        public station: IdDisplayPair[],
        public fixer: IdDisplayPair[],
    ) {}
}

export class DocDiscrepancyCreateOptions {
    constructor(
        public reason: SelectOption[],
        public station: SelectOption[],
        public fixer: SelectOption[],
    ) {}

    static initial(): DocDiscrepancyCreateOptions {
        return new DocDiscrepancyCreateOptions([], [], []);
    }
}
