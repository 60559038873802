import {
    Component,
    ViewChild,
    OnInit,
    ElementRef,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    inject,
} from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import WebViewer, {
    Core,
    WebViewerInstance,
    WebViewerOptions,
} from '@pdftron/webviewer';
import { forkJoin } from 'rxjs';

import endpoints from '@endpoints';
import { environment } from '@environments/environment';
import { AuthService } from '@services/api/auth/auth.service';
import { ConfigurationsService } from '@services/api/configurations/configurations.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { ErrorMessages } from '@constants/error-messages';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { removeDropArea } from '@helpers/webviewer';
import { interpolate } from '@helpers/index';

export interface WebViewerDocument {
    id: string;
    name: string;
}

@Component({
    selector: 'app-web-viewer',
    standalone: true,
    imports: [MatIconModule, LoaderComponent],
    templateUrl: './webviewer.component.html',
    styleUrls: ['./webviewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebViewerComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @Input({ required: true }) document!: WebViewerDocument;
    @Input() documentToCompare: WebViewerDocument | null = null;
    @Input() elementsToBeDisabled: string[] = [];
    @Output() documentLoaded = new EventEmitter<WebViewerInstance>();
    @ViewChild('viewer', { static: true }) viewer!: ElementRef;

    loading = true;
    error: boolean = false;
    instance!: WebViewerInstance;
    private tenantId: number | null = null;
    private oidcToken: string | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;
    readonly errorMessage = ErrorMessages.DOCUMENT_LOADING;
    readonly vwOptions: WebViewerOptions = {
        path: 'assets/lib',
        licenseKey: environment.apryseLicenseKey,
    };

    private cdr = inject(ChangeDetectorRef);
    private authService = inject(AuthService);
    private configurationsService = inject(ConfigurationsService);

    get loadOptions() {
        return {
            filename: 'test',
            withCredentials: false,
            customHeaders: {
                'X-Tenant-Id': this.tenantId,
                'X-App-Name': 'workflow',
                Authorization: `Bearer ${this.oidcToken}`,
            },
        } as Core.LoadDocumentOptions;
    }

    ngOnInit() {
        this.setTenantId();
        this.setOidcToken();

        this.onDocumentLoaded = this.onDocumentLoaded.bind(this);
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.instance) {
            const { Core } = this.instance;
            const docToCompareChange = changes['documentToCompare'];

            if (docToCompareChange && !docToCompareChange.firstChange) {
                const [_, dv] = Core.getDocumentViewers();
                this.loadDocumentFromViewer(
                    dv,
                    docToCompareChange.currentValue,
                );
            }

            const docChange = changes['document'];
            if (docChange && !docChange.firstChange) {
                const [dv] = Core.getDocumentViewers();
                this.loadDocumentFromViewer(dv, docChange.currentValue);
            }
        }
    }

    ngAfterViewInit(): void {
        WebViewer(this.vwOptions, this.viewer.nativeElement).then(
            (instance: WebViewerInstance) => {
                this.instance = instance;
                this.setListeners();
                this.loadDocument();
                this.setDisabledElements();
                this.setOpenElements();
                this.setTheme();
            },
        );
    }

    private getDocumentPath(id: string) {
        return `${environment.apiUrl}${interpolate(endpoints.getDocument, { id })}`;
    }

    private loadDocument() {
        this.loading = true;
        const { Core, UI } = this.instance;

        if (this.documentToCompare) {
            const { enterMultiViewerMode } = UI;
            enterMultiViewerMode();

            UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                const [documentViewerOne, documentViewerTwo] =
                    Core.getDocumentViewers();

                this.loadDocumentFromViewer(documentViewerOne, this.document);
                this.loadDocumentFromViewer(
                    documentViewerTwo,
                    this.documentToCompare!,
                );
            });
        } else {
            const [documentViewer] = Core.getDocumentViewers();
            this.loadDocumentFromViewer(documentViewer, this.document);
        }
    }

    private onDocumentLoaded(): void {
        this.documentLoaded.emit(this.instance);
    }

    private setDisabledElements() {
        this.instance.UI.disableElements([
            'toolsHeader',
            'panToolButton',
            'selectToolButton',
            'toggleNotesButton',
            'searchButton',
            'ribbons',
            'settingsKeyboardButton',
            'contextMenuPopup',
            'dropdown-item-PNG (*.png)',
            ...this.elementsToBeDisabled,
        ]);
    }

    private setOpenElements() {
        this.instance.UI.openElements(['leftPanel']);
    }

    private setTheme() {
        this.instance.UI.setTheme(this.instance.UI.Theme.DARK);
    }

    private setListeners() {
        const { documentViewer } = this.instance.Core;

        documentViewer.addEventListener(
            'documentLoaded',
            this.onDocumentLoaded,
        );
    }

    private loadDocumentFromViewer(
        viewer: Core.DocumentViewer,
        document: WebViewerDocument,
    ) {
        this.loading = true;
        this.error = false;

        viewer
            .loadDocument(this.getDocumentPath(document.id), {
                ...this.loadOptions,
                filename: document.name,
                extension: 'pdf',
            })
            .catch((err) => {
                console.error(err);
                if (
                    err?.serverResponse?.status === HttpStatusCode.Unauthorized
                ) {
                    forkJoin([
                        this.configurationsService.getTenantId(),
                        this.authService.setOidcToken(),
                    ]).subscribe({
                        next: () => this.loadDocument(),
                    });
                } else {
                    this.error = true;
                }
            })
            .finally(() => {
                this.loading = false;
                this.cdr.detectChanges();
                removeDropArea(this.instance);
            });
    }

    private setTenantId() {
        this._subscriptions['tenant-id'] =
            this.configurationsService.tenantId$.subscribe({
                next: (id) => (this.tenantId = id),
            });
    }

    private setOidcToken() {
        this._subscriptions['oidc-token'] =
            this.authService.oidcToken$.subscribe({
                next: (token) => {
                    this.oidcToken = token;
                },
            });
    }
}
