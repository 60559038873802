import { UserInfo } from '@models/user/user-info';

export class DiscrepancyFixersResponse {
    constructor(
        public primaryFixer: UserInfo | null,
        public secondaryFixers: UserInfo[],
    ) {}
}

export class DiscrepancyFixers extends DiscrepancyFixersResponse {
    static initial(): DiscrepancyFixers {
        return new DiscrepancyFixers(null, []);
    }
}
