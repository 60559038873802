export enum DiscrepancyActionCode {
    NOTIFY,
    RENOTIFY,
    CANCEL,
    RECALL,
    ACCEPT,
    REJECT,
    APPLY,
    CLOSE_RESOLVE,
}

export class DiscrepancyAction {
    constructor(
        public id: number,
        public action: DiscrepancyActionCode,
        public label: string,
    ) {}
}
