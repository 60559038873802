@if (files) {
    <div class="file">
        <div class="flex items-center self-start ml-4">
            <div class="text-sm">
                <span class="color-primary mr-1"> {{ files[0].name }}</span>
                @if (progress) {
                    <span>(%{{ progress }})</span>
                }
            </div>
            <button
                mat-icon-button
                aria-label="Close"
                (click)="onCloseClick()"
                class="ml-auto">
                <mat-icon svgIcon="close" />
            </button>
        </div>
        @if (progress && progress > 0 && progress <= 100) {
            <div>
                <app-progress-bar mode="determinate" [progress]="progress" />
            </div>
        }
    </div>
} @else {
    <div
        class="dropzone"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        [class.dragging]="isDragging"
        (click)="onClick()"
        (keypress)="onClick()"
        tabindex="0">
        <p class="text-md flex">
            <span class="color-primary cursor-pointer flex mr-1">
                <mat-icon svgIcon="upload"></mat-icon>Upload
            </span>
            Or Drag & Drop here
        </p>
        <p class="text-xs">(.PDF format)</p>
        @if (errorMessage) {
            <div class="error-message">
                {{ errorMessage }}
            </div>
        }
    </div>
    <input
        type="file"
        #fileInput
        (change)="onFileSelected($event)"
        style="display: none"
        multiple
        [accept]="acceptContentTypes" />
}
