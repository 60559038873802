import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { RevisionKind } from '@models/revision/revision-kind';
import { DiscrepancyRevisionItem, DiscrepancyIdNo } from '@models/discrepancy';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'recall-discrepancy-dialog.component.html',
    imports: [FlatButtonComponent, MatDialogClose, LoaderComponent],
})
export class RecallDiscrepancyDialogComponent implements OnInit, OnDestroy {
    loading = false;
    revisionLoading = false;
    error = false;
    appliedVersions: DiscrepancyRevisionItem[] = [];
    corrections: DiscrepancyRevisionItem[] = [];
    readonly dataQa = DATA_QA;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private store = inject(Store);
    private snackbarService = inject(SnackbarService);
    private discrepancyService = inject(DiscrepancyService);
    readonly data: DiscrepancyIdNo = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<RecallDiscrepancyDialogComponent>);

    get appliedVersionList() {
        return this.appliedVersions
            .map((i) => `Document “${i.name}”`)
            .join(', ');
    }

    get correctionList() {
        return this.corrections.map((i) => `“${i.name}”`).join(', ');
    }

    ngOnInit(): void {
        this.revisionLoading = true;

        this._subscriptions['get-discrepancy-revisions'] =
            this.discrepancyService
                .getDiscrepancyRevisions(this.data.id)
                .subscribe({
                    next: (response) => {
                        if (response) {
                            this.appliedVersions = response?.revisions.filter(
                                (i) => i.kind === RevisionKind.APPLIED_VERSION,
                            );
                            this.corrections = response?.revisions.filter(
                                (i) => i.kind === RevisionKind.CORRECTION,
                            );
                        }
                    },
                    complete: () => (this.revisionLoading = false),
                });
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    recall() {
        this.loading = true;
        this.error = false;
        const { id, discrepancyNo } = this.data;

        this._subscriptions['recall-to-await'] = this.discrepancyService
            .recallDiscrepancy(id)
            .subscribe({
                next: (success) => {
                    this.loading = false;
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${discrepancyNo} Recalled to Await Correction state!`,
                        });
                        this.store.dispatch(refreshActionRelatedData());
                        this.dialogRef.close();
                    } else {
                        this.error = true;
                    }
                },
            });
    }
}
