import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { forkJoin, of, Subscription } from 'rxjs';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { StationService } from '@services/api/station/station.service';
import { UserService } from '@services/api/user/user.service';
import { InputComponent } from '@shared/input/input.component';
import { SelectComponent } from '@shared/select/select.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { PrioritySelectComponent } from '@shared/select/priority-select/priority-select.component';
import { InvalidPageRangeErrorComponent } from '@shared/validation-error/invalid-page-range.component';
import { PriorityFieldComponent } from '@shared/priority-field/priority-field.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';
import { FooterStateActionsComponent } from './footer-state.actions.component';
import { PageRangeErrorComponent } from './page-range-error.component';
import { FooterAssignActionsComponent } from './footer-assign-actions.component';
import { SelectOption } from '@models/shared.model';
import { DiscrepancyDetail, DiscrepancyEditOptions } from '@models/discrepancy';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import { pageRangeValidator } from '@helpers/validators';
import { isNil } from '@helpers/index';
import { DiscrepancyDataService } from '@services/data/discrepancy-data.service';
import { DiscrepancyResolutionStateToLabel } from '../documents-and-discrepancies.utils';

@Component({
    selector: 'app-discrepancy-details',
    standalone: true,
    imports: [
        PrioritySelectComponent,
        SelectComponent,
        InputComponent,
        ContentFieldComponent,
        CheckboxComponent,
        PageRangeErrorComponent,
        InvalidPageRangeErrorComponent,
        MatDividerModule,
        ReactiveFormsModule,
        ActivityLogComponent,
        FooterAssignActionsComponent,
        FooterStateActionsComponent,
        LoaderComponent,
        PriorityFieldComponent,
    ],
    styleUrl: 'discrepancy-details.component.scss',
    templateUrl: 'discrepancy-details.component.html',
})
export class DiscrepancyDetailsComponent implements OnChanges {
    @ViewChild(FooterStateActionsComponent)
    stateActionsComp!: FooterStateActionsComponent;
    @Input() id!: number;
    @Input() mode: 'summary' | 'detail' = 'summary';
    @Output() formChange = new EventEmitter();

    form!: FormGroup;
    fixerOptions: SelectOption[] = [];
    discDetail = DiscrepancyDetail.initial();
    detailsLoading = false;
    editFormChanged = false;
    editOptions = DiscrepancyEditOptions.initial();
    isAdmin = false;
    private editFormSubscription: Subscription | undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private formBuilder: FormBuilder,
        private discrepancyService: DiscrepancyService,
        private discrepancyDataService: DiscrepancyDataService,
        private stationService: StationService,
        private userService: UserService,
    ) {}

    get pageRange() {
        return this.form.get('pageRange') as FormControl;
    }

    get readOnly() {
        return !this.editOptions.canEdit;
    }

    get canSave() {
        return !this.form.invalid && this.editFormChanged;
    }

    get completion() {
        return this.discDetail.resolutionState
            ? DiscrepancyResolutionStateToLabel[this.discDetail.resolutionState]
            : '-';
    }

    ngOnChanges(changes: SimpleChanges): void {
        const idChange = changes['id'];

        if (idChange) {
            this.initForm();
            this.getDiscrepancyDetails();
            this.setUserPermission();
            this.formChange.emit(false);
        }
    }

    docSizeExceedMaxLimit() {
        return (
            !isNil(this.discDetail.revision) &&
            this.discDetail.revision!.size >
                this.discDetail.revision!.fullFileInclusionLimit
        );
    }

    saveChanges() {
        this.stateActionsComp.onSave();
    }

    private initForm() {
        this.form = this.formBuilder.group({
            priority: new FormControl(null),
            reason: new FormControl(null),
            station: new FormControl(null),
            responsibleFixer: new FormControl(null),
            pageRange: new FormControl('', [
                Validators.required,
                pageRangeValidator(),
            ]),
            directions: new FormControl('', Validators.maxLength(300)),
            revision: new FormControl(null),
            notifyWithOnlySelectedPages: new FormControl(null),
        });
    }

    private getDiscrepancyDetails() {
        this.editFormSubscription?.unsubscribe();
        this.detailsLoading = true;

        forkJoin([
            this.getEditOptions(this.id),
            this.discrepancyService.getDiscrepancyDetails(this.id),
        ]).subscribe({
            next: ([editOptionsData, discDetailsData]) => {
                this.editOptions = editOptionsData;
                this.discDetail = discDetailsData;
                this.setStationAndFixer(discDetailsData);

                if (this.mode === 'detail' && !this.readOnly) {
                    this.handleStationChange();
                    this.fillEditForm(discDetailsData);
                    this.onEditFormValueChange();
                }
            },
            complete: () => (this.detailsLoading = false),
        });
    }

    private setStationAndFixer(disc: DiscrepancyDetail) {
        this.discrepancyDataService.updateSelectedDiscStationAndFixer({
            station: disc.station?.backingField ?? null,
            fixer: disc.responsibleFixer?.backingField ?? null,
        });
    }

    private getEditOptions(id: number) {
        if (this.mode === 'detail') {
            return this.discrepancyService.getDiscrepancyEditOptions(id);
        }
        return of(DiscrepancyEditOptions.initial());
    }

    private fillEditForm({
        priority,
        pageRange,
        reason,
        station,
        directions,
        responsibleFixer,
        revision,
        notifyWithOnlySelectedPages,
    }: DiscrepancyDetail) {
        this.form.patchValue({
            priority,
            pageRange,
            reason: reason?.backingField,
            station: station?.backingField,
            responsibleFixer: responsibleFixer?.backingField,
            directions,
            revision: revision?.id,
            notifyWithOnlySelectedPages,
        });
    }

    private onEditFormValueChange() {
        this.editFormChanged = false;
        const initialValue = this.form.value;
        this.editFormSubscription = this.form.valueChanges.subscribe(() => {
            this.editFormChanged = Object.keys(initialValue).some(
                (key) => this.form.value[key] != initialValue[key],
            );
            this.formChange.emit(this.editFormChanged);
        });
    }

    private handleStationChange(): void {
        if (this.mode === 'detail' && !this.readOnly) {
            this._subscriptions['station-change'] = this.form.controls[
                'station'
            ].valueChanges.subscribe((change) => {
                this.form.patchValue({ responsibleFixer: null });
                this.getFixers(change);
            });
        } else {
            this._subscriptions.unsubscribeSafe('station-change');
        }
    }

    private getFixers(stationId: number) {
        if (stationId)
            this._subscriptions['get-fixers'] = this.stationService
                .getStationFixers(stationId)
                .subscribe({
                    next: (fixers) => {
                        this.fixerOptions = fixers;
                        if (fixers.length === 1) {
                            this.form.patchValue({
                                responsibleFixer: fixers[0].value,
                            });
                        }
                    },
                });
    }

    private setUserPermission() {
        this._subscriptions['is-admin-user'] = this.userService
            .isAdminUser()
            .subscribe({
                next: (isAdmin) => (this.isAdmin = isAdmin),
            });
    }
}
