import { Component, Input } from '@angular/core';

import { Priority, priorityMap } from '@models/shared/priority';

@Component({
    selector: 'app-priority-field',
    standalone: true,
    template: `<span class="flex items-center">
        <span class="icon" [style.backgroundImage]="priorityIcon"></span>
        {{ priorityText }}
    </span>`,
    styles: `
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }
    `,
})
export class PriorityFieldComponent {
    @Input() wfePriorityField!: Priority;

    get priorityIcon() {
        const icon = priorityMap[this.wfePriorityField].icon;
        return `url('/assets/icons/${icon}.svg'`;
    }

    get priorityText() {
        const text = priorityMap[this.wfePriorityField].text;
        return text;
    }
}
