import { DiscrepancyAction } from '../discrepancy';
import { DocumentRevisionStatus } from '../document';
import { RevisionKind } from './revision-kind';

export class RevisionDetails {
    constructor(
        public id: number,
        public createdAt: string,
        public name: string,
        public documentServiceId: string,
        public discrepancies: RevisionDetailDiscrepancy[],
        public isEditable: boolean,
        public kind: RevisionKind,
        public documentId: number,
    ) {}
}

export class RevisionDetailDiscrepancy {
    constructor(
        public id: number,
        public discrepancyNo: number,
        public relation: DocumentRevisionStatus,
        public actions: DiscrepancyAction[],
    ) {}
}
