import {
    Component,
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewContainerRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FileType } from '@constants/file';

import { ErrorMessages } from 'app/utils/constants/error-messages';

@Directive({
    selector: '[wfeDragDrop]',
    standalone: true,
})
export class DragDropDirective implements OnInit {
    @Input() overlayMessage: string = '';
    @Input() maxFileCount: number = 1;
    @Input() externalError: boolean = false;
    @Output() fileDropped = new EventEmitter<File>();

    acceptedFiles: FileType[] = [FileType.PDF];
    private _hasError = false;
    private _overlayElement: ComponentRef<DragDropOverlayComponent> | null =
        null;

    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this._hasError = this.externalError;
    }

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showOverlay(true, event);
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        const target = event.relatedTarget as HTMLElement;
        if (!this.el.nativeElement.contains(target)) {
            this.showOverlay(false);
        }
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.showOverlay(false);

        if (!this._hasError && event.dataTransfer?.files.length) {
            const file = event.dataTransfer.files[0];
            this.fileDropped.emit(file);
        }
    }

    private checkFileCountError(event: DragEvent): boolean {
        const fileCountExceeded =
            this.maxFileCount < (event.dataTransfer?.items.length ?? 0);

        if (fileCountExceeded) {
            this.showErrorComponent(
                this.maxFileCount === 1
                    ? ErrorMessages.ONE_FILE_ALLOWED
                    : `Maximum file count is: ${this.maxFileCount}`,
            );
        }

        return fileCountExceeded;
    }

    private checkFileTypeError(event: DragEvent): boolean {
        const fileTypeError = !this.acceptedFiles.includes(
            event?.dataTransfer?.items[0].type as FileType,
        );
        if (fileTypeError) {
            this.showErrorComponent(
                'Unsupported file type. Please upload PDF files only ',
            );
        }
        return fileTypeError;
    }

    private showOverlay(isVisible: boolean, event: DragEvent | null = null) {
        if (isVisible) {
            if (this.externalError) {
                this.showErrorComponent();
            } else {
                if (event) {
                    const unsupportedFileType = this.checkFileTypeError(event);
                    const fileCountExceeded = this.checkFileCountError(event);
                    this._hasError = fileCountExceeded || unsupportedFileType;
                    if (!this._hasError) {
                        this.showDefaultOverlay();
                    }
                }
            }
        } else {
            this.hideOverlay();
        }
    }

    private showErrorComponent(message: string | null = null) {
        if (!this._overlayElement) {
            this._overlayElement = this.viewContainerRef.createComponent(
                DragDropOverlayComponent,
            );
            this.el.nativeElement.appendChild(
                this._overlayElement.location.nativeElement,
            );
            this._overlayElement.instance.message =
                message || this.overlayMessage;
            this._overlayElement.instance.type = 'error';
        }
    }

    private hideOverlay() {
        if (this._overlayElement) {
            this._overlayElement.destroy();
            this._overlayElement = null;
        }
    }

    private showDefaultOverlay() {
        if (!this._overlayElement) {
            this._overlayElement = this.viewContainerRef.createComponent(
                DragDropOverlayComponent,
            );
            this.el.nativeElement.appendChild(
                this._overlayElement.location.nativeElement,
            );
            this._overlayElement.instance.message = this.overlayMessage;
        }
    }
}

@Component({
    standalone: true,
    imports: [MatIconModule],
    template: `
        @if (type === 'error') {
            <mat-icon
                svgIcon="status-cancel"
                class="error-icon color-status-red4" />
        }
        <p>{{ message }}</p>
    `,
    styles: [
        `
            :host {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: black;
                z-index: 1000;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-wrap: wrap;
                justify-content: center;
                text-align: center;
                padding: 30px;

                .error-icon {
                    height: 44px;
                    width: 44px;
                }
            }
        `,
    ],
})
class DragDropOverlayComponent {
    @Input() message: string = '';
    @Input() type: 'default' | 'error' = 'default';

    @HostBinding('style.background') get backgroundColor() {
        return this.type == 'error' ? '#f5f0f0cc' : '#eefbffcc';
    }
    @HostBinding('style.border') get border() {
        return this.type == 'error'
            ? '2px solid #632323'
            : '2px dashed #4C6472';
    }
}
