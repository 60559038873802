<app-text-button
    #tableViewToggle
    class="color-primary cursor-pointer"
    [attr.data-qa]="dataQa.tableViewButton"
    [matMenuTriggerFor]="changeTableViewMenu"
    (menuOpened)="menuOpened = true"
    (menuClosed)="menuOpened = false">
    Table view
    <mat-icon [svgIcon]="menuOpened ? 'chevron-up' : 'chevron-down'" />
</app-text-button>
<mat-menu #changeTableViewMenu="matMenu" class="p-3 m-2">
    <div
        tabindex="0"
        class="change-table-view-menu"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <p>Table View Options</p>
        <mat-button-toggle-group
            name="tableView"
            aria-label="Table View"
            [value]="selected">
            <mat-button-toggle
                [value]="tableView.Document"
                [attr.data-qa]="dataQa.documentViewToggle"
                (change)="changeTableView($event.value)">
                Document View
            </mat-button-toggle>
            <mat-button-toggle
                [value]="tableView.Discrepancy"
                [attr.data-qa]="dataQa.discrepancyViewToggle"
                (change)="changeTableView($event.value)">
                Discrepancy View
            </mat-button-toggle>
        </mat-button-toggle-group>
        <p class="text-xs">{{ description }}</p>
        <mat-divider class="w-full" />
        @if (selected === tableView.Document) {
            <app-document-view-column-options />
        } @else {
            <app-dicrepancy-view-column-options />
        }
        <app-flat-button
            class="justify-self-end mt-2"
            [dataQa]="dataQa.tableViewMenuClose"
            (clicked)="closeMenu()">
            Close
        </app-flat-button>
    </div>
</mat-menu>
