<div class="snackbar" [attr.data-qa]="data.dataQa ?? 'snackbar'">
    <div class="snackbar__header">
        @switch (data.variant) {
            @case ('success') {
                <mat-icon
                    svgIcon="check2"
                    style="height: 20px"
                    class="color-yellow-green7" />
            }
            @case ('error') {
                <mat-icon svgIcon="status-cancel" class="color-status-red4" />
            }
            @case ('progress') {
                <app-loader size="small" />
            }
            @default {
                <mat-icon svgIcon="info" class="color-primary-default" />
            }
        }
        <span class="header text-sm text-bold color-gray15">
            {{ data.header }}
        </span>
        <mat-icon svgIcon="close" class="cursor-pointer" (click)="close()" />
    </div>
    @if (data.showContent || data.content) {
        <div class="snackbar__content text-xs mt-2">
            <div></div>
            <div class="grid">{{ data.content }} <ng-content /></div>
            <div></div>
        </div>
    }
</div>
