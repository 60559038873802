import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { WebViewerInstance } from '@pdftron/webviewer';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';

import { RevisionDataService } from '@services/data/revision-data-service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import {
    WebViewerComponent,
    WebViewerDocument,
} from '@shared/webviewer/webviewer.component';
import { DiscrepancySummaryComponent } from '../../discrepancy-summary/discrepancy-summary.component';
import { ResizableDirective } from '@directives/resizable.directive';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { AcceptRevisionRequest, RevisionDetails } from '@models/revision';
import { DiscrepancyDetail } from '@models/discrepancy';
import {
    insertCorrectedDocumentTag,
    hideCloseDocumentButton,
    insertCurrentPageNumber,
} from '@helpers/webviewer';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';
import DATA_QA from '@automation/data-qa.json';

@Component({
    standalone: true,
    templateUrl: 'accept-correction-dialog.component.html',
    styleUrl: 'accept-correction-dialog.component.scss',
    imports: [
        MatDividerModule,
        ReactiveFormsModule,
        MatDialogModule,
        FlatButtonComponent,
        LoaderComponent,
        WebViewerComponent,
        DiscrepancySummaryComponent,
        ResizableDirective,
    ],
})
export class AcceptCorrectionDialogComponent implements OnInit, OnDestroy {
    error: boolean = false;
    loading: boolean = false;
    selectedTabIndex: number = 0;
    discDetail = DiscrepancyDetail.initial();
    discDetailLoading: boolean = false;
    revisionDocument: WebViewerDocument | null = null;
    originalDocument: WebViewerDocument | null = null;
    documentLoading: boolean = false;
    discrepancy!: number;
    readonly elementsToBeDisabled = [
        'thumbnailControl',
        'documentControl',
        'zoomOverlayButton',
        'fullScreenButton',
        'leftPanelTabs',
    ];
    readonly dataQa = DATA_QA;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private readonly data: {
        revisionDetails: RevisionDetails;
        discrepancy: number;
    } = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef<AcceptCorrectionDialogComponent>);
    private discrepancyService = inject(DiscrepancyService);
    private revisionService = inject(RevisionService);
    private snackbarService = inject(SnackbarService);
    private revisionDataService = inject(RevisionDataService);
    private store = inject(Store);

    constructor() {
        this.discrepancy = this.data.discrepancy;
    }

    ngOnInit(): void {
        this.getDiscrepancy();
        this.getDocuments();
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    get title() {
        return `Accept Correction for Discrepancy ${this.discrepancyNo}`;
    }

    get discrepancyNo() {
        return this.data.revisionDetails.discrepancies.find(
            (disc) => disc.id === this.discrepancy,
        )?.discrepancyNo;
    }

    protected accept() {
        this.error = false;
        this.loading = true;

        this._subscriptions['accept-revision'] = this.revisionService
            .acceptRevision(
                this.data.revisionDetails.id,
                new AcceptRevisionRequest(this.discrepancy),
            )
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            header: `Correction is accepted for ${this.discrepancyNo}! `,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                        this.revisionDataService.updateSelectedRevisionId(
                            this.data.revisionDetails.id,
                        );
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    protected cancel() {
        this.dialogRef.close();
    }

    protected onDocumentLoaded(vwInstance: WebViewerInstance) {
        insertCorrectedDocumentTag(vwInstance);
        insertCurrentPageNumber(vwInstance);
        hideCloseDocumentButton(vwInstance);
    }

    private getDiscrepancy() {
        this.discDetailLoading = true;

        this._subscriptions['get-disc-details'] = this.discrepancyService
            .getDiscrepancyDetails(this.discrepancy)
            .subscribe({
                next: (response) => (this.discDetail = response),
                complete: () => (this.discDetailLoading = false),
            });
    }

    private getDocuments() {
        this.documentLoading = true;

        forkJoin([
            this.getRevisionDetails(this.data.revisionDetails.id),
            this.getOriginalDetails(),
        ]).subscribe({
            next: ([revision, original]) => {
                this.revisionDocument = {
                    id: `${revision!.documentServiceId}`,
                    name: revision?.name ?? '',
                };
                this.originalDocument = {
                    id: `${original!.documentServiceId}`,
                    name: original?.name ?? '',
                };
            },
            complete: () => (this.documentLoading = false),
        });
    }

    private getRevisionDetails(id: number) {
        return this.revisionService.getRevisionDetails(id);
    }

    private getOriginalDetails() {
        const originalDocumentId =
            this.revisionDataService.originalDocument?.id;

        if (originalDocumentId)
            return this.getRevisionDetails(originalDocumentId);

        return of(null);
    }
}
