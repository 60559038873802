import {
    AdvancedFilterModel,
    FilterModel,
    IServerSideGetRowsRequest,
    SortModelItem,
    TextFilterModel,
    GridApi,
} from 'ag-grid-community';

import { TabItem } from '@shared/tabs/tabs.component';
import { Discrepancy, DiscrepancyResolutionState } from '@models/discrepancy';

export enum DocumentDetailTab {
    METADATA = 'metadata',
    DISCREPANCIES = 'discrepancies',
    WF_INTERNALS = 'wf-internals',
}

export const DocumentDetailTabs: (TabItem & { id: DocumentDetailTab })[] = [
    {
        id: DocumentDetailTab.METADATA,
        label: 'Document Metadata',
        path: 'metadata',
    },
    {
        id: DocumentDetailTab.DISCREPANCIES,
        label: 'Discrepancies',
        path: 'discrepancies',
    },
    {
        id: DocumentDetailTab.WF_INTERNALS,
        label: 'WF Internals',
        path: 'wf-internals',
    },
];

export const DiscrepancyResolutionStateToLabel = {
    [DiscrepancyResolutionState.CANCELLED]: 'Cancelled',
    [DiscrepancyResolutionState.DISCARDED]: 'Discarded ',
    [DiscrepancyResolutionState.FIXED]: 'Resolved',
    [DiscrepancyResolutionState.FORCE_CLOSED]: 'Unresolved',
};

// Helpers

export const getFilterQuery = (
    filterModel: FilterModel | AdvancedFilterModel | null,
) => {
    const queryParams = {};

    if (filterModel) {
        const filters = Object.fromEntries(
            Object.entries(filterModel).filter(
                ([_, value]) => value.filter !== null,
            ),
        );
        const filterKeys = Object.keys(filters);

        if (filterKeys.length) {
            filterKeys.forEach((key) => {
                const filterValue = (filters as any)[key]?.filter
                    .toString()
                    .trim();

                Object.assign(queryParams, {
                    [key]: filterValue,
                });
            });
        }
    }

    return queryParams;
};

export const getSortQuery = (sortModel: SortModelItem[]) => {
    const queryParams = {};

    if (sortModel) {
        sortModel.forEach((item) => {
            Object.assign(queryParams, {
                orderBy: item.colId,
                order: item.sort,
            });
        });
    }
    return queryParams;
};

export const getPageIndex = (
    request: IServerSideGetRowsRequest,
    pageSize: number,
): number => {
    const startRow = request?.startRow ?? null;
    const index = startRow ? Math.round(startRow / pageSize) + 1 : 1;

    return index;
};

export const isFilterApplied = (
    request: IServerSideGetRowsRequest,
): boolean => {
    return Object.keys(request.filterModel || {}).length !== 0;
};

export const getAgeInWFValue = (p: { value: string }) => {
    return p.value ? `${p.value} Days` : '';
};

export const getDiscrepancyId = (disc: Discrepancy) => {
    return {
        label: disc ? `${disc.discrepancyNo}${disc.isDraft ? '*' : ''}` : '',
        tooltip: disc?.isDraft ? 'This discrepancy is in draft state.' : '',
    };
};

export const highlightSelectedDiscrepancy = (id: number) => {
    const rows = document.querySelectorAll(`[row-id^="nested"]`);

    rows.forEach((el) => {
        const rowId = el.getAttribute('row-id');
        (el as HTMLElement).style.backgroundColor =
            rowId === `nested-${id}` ? '#e6f5ff' : '';
    });
};

export const getFilterModel = <T extends Record<string, any>>(
    data: T,
): TextFilterModel => {
    const filterModel = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
            key,
            {
                filter:
                    value !== null
                        ? value instanceof Object
                            ? JSON.stringify(value)
                            : value.toString()
                        : value,
                filterType: 'text',
                type: 'contains',
            },
        ]),
    );

    return filterModel;
};

export const setFilterAndSorting = <T extends Record<string, any>>(
    gridApi: GridApi,
    data: T,
) => {
    const { order, orderBy, ...filterFields } = data;
    const filterModel = getFilterModel(filterFields);

    gridApi.setFilterModel(filterModel);
    gridApi.applyColumnState({
        state: [
            {
                colId: orderBy,
                sort: order,
            },
        ],
    });
};
