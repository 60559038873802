@if (loading) {
    <app-loader type="overlay" />
}
@if (isAdmin && workflowInstanceOptions.length > 0) {
    <div class="instance-select">
        <div class="instance-select__wrapper">
            <app-select
                label="Workflow Instance"
                [dataQa]="dataQa.workflowInstanceSelect"
                [options]="workflowInstanceOptions"
                [value]="selectedWorkflow"
                (selectionChange)="onWorkflowInstanceChange($event)" />
            <app-select
                label="Repository"
                [dataQa]="dataQa.repositorySelect"
                [options]="repositoryOptions"
                [value]="selectedRepository"
                [multiple]="true"
                (selectionChange)="onRepositoryChange($event)" />
        </div>
    </div>
}
<div class="filter-select grid grap-10 items-start">
    <app-status-filter
        [statusChips]="statusChipsData"
        [selectedStatuses]="selectedStatuses"
        (filterChange)="onStatusFilterChange($event)" />
    <app-toggle
        id="showMyItemsToggle"
        label="Show only my items"
        [dataQa]="dataQa.showOnlyMyItemsToggle"
        [initialToggled]="showMyItems"
        (toggledTo)="onToggleMyItems($event)" />
</div>
<div>
    <div class="flex justify-space-between items-center">
        <div>
            <app-flat-button
                variant="tertiary"
                [disabled]="!canBulkRenotify"
                [dataQa]="dataQa.bulkRenotifyButton"
                (clicked)="renotify()">
                Re-Notify
            </app-flat-button>
        </div>
        <div class="flex items-center">
            <app-text-button
                [dataQa]="dataQa.openExcelDialogButton"
                (clicked)="onExportClicked()">
                Export
            </app-text-button>
            <app-change-view-menu
                [selected]="selectedTableView"
                (tableViewChanged)="onTableViewChanged($event)" />
            <app-text-button
                [dataQa]="dataQa.resetFiltersButton"
                [disabled]="isResetFiltersDisabled()"
                (clicked)="resetFilters()">
                Reset filters
            </app-text-button>
        </div>
    </div>
    <div class="mt-1">
        @if (resultCount) {
            <span class="text-xs">
                @if (selectedRowsCount) {
                    <span class="text-semi">{{ selectedRowsCount }}/</span
                    >{{ resultCount }} Selected
                } @else {
                    {{ resultCount }} Results
                }
            </span>
        }
    </div>
</div>

@if (showDocuments && !inboxDataLoading) {
    <div>
        @if (selectedTableView === TableView.Document) {
            <app-document-view
                [workflowAndRepo]="workflowAndRepo"
                [statuses]="selectedStatuses"
                [showMyItems]="showMyItems" />
        }
        @if (selectedTableView === TableView.Discrepancy) {
            <app-discrepancy-view
                [workflowAndRepo]="workflowAndRepo"
                [statuses]="selectedStatuses"
                [showMyItems]="showMyItems" />
        }
    </div>
}

@if (selectedDocument) {
    <div style="height: 45vh"></div>
    <app-documents-summary-panel
        [documentId]="selectedDocument"
        (panelClose)="closePanel()" />
}

@if (renotifyLoading) {
    <app-loader type="overlay" />
}
