import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DocumentMetadataEditComponent } from 'app/components/documents-and-discrepancies/detail-dialog/document-metadata-edit/document-metadata-edit.component';

@Component({
    selector: 'app-metadata-page',
    standalone: true,
    imports: [DocumentMetadataEditComponent],
    template: `<app-document-metadata-edit [documentId]="documentId" />`,
})
export class MetadataPageComponent implements OnInit {
    documentId!: number;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        const id = this.route.parent?.snapshot.paramMap.get('id');
        if (id) {
            this.documentId = parseInt(id);
        }
    }
}
