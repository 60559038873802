import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DocumentDetailTab } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    constructor(private router: Router) {}

    goBack(refresh: boolean = false) {
        this.router.navigate(['..'], { state: { refresh } });
    }

    goLogin() {
        this.router.navigate(['/login']);
    }

    goHome() {
        this.router.navigate(['/']);
    }

    goDocumentDetails(id: number, tab: DocumentDetailTab, discId?: number) {
        const commands = [
            '/documents-and-discrepancies',
            id,
            tab,
            discId,
        ].filter((i) => i);
        this.router.navigate(commands);
    }
}
