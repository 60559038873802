import {
    Component,
    ViewChild,
    OnInit,
    ElementRef,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';

import endpoints from '@endpoints';
import { environment } from '@environments/environment';
import { AuthService } from '@services/api/auth/auth.service';
import { interpolate } from '@helpers/index';
import { UserService } from '@services/api/user/user.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { ErrorMessages } from '@constants/error-messages';

export interface WebViewerDocument {
    id: string;
    name: string;
}

@Component({
    selector: 'app-web-viewer',
    standalone: true,
    imports: [MatIconModule, LoaderComponent],
    templateUrl: './webviewer.component.html',
    styleUrls: ['./webviewer.component.scss'],
})
export class WebViewerComponent implements OnInit, AfterViewInit {
    @Input({ required: true }) document!: WebViewerDocument;
    @Input() documentToCompare: WebViewerDocument | null = null;
    @Input() elementsToBeDisabled: string[] = [];
    @Output() documentLoaded = new EventEmitter<WebViewerInstance>();
    @ViewChild('viewer', { static: true }) viewer!: ElementRef;

    instance!: WebViewerInstance;
    loading: boolean = true;
    error: boolean = false;
    errorMessage = ErrorMessages.DOCUMENT_LOADING;
    tenantId: number | null = null;
    oidcToken: string | null = null;

    constructor(
        private authService: AuthService,
        private userService: UserService,
    ) {}

    get loadOptions() {
        return {
            filename: 'test',
            withCredentials: false,
            customHeaders: {
                'X-Tenant-Id': this.tenantId,
                'X-App-Name': 'workflow',
                Authorization: `Bearer ${this.oidcToken}`,
            },
        };
    }

    ngOnInit() {
        this.userService.tenantId$.subscribe({
            next: (id) => (this.tenantId = id),
        });
        this.authService.oidcToken$.subscribe({
            next: (token) => {
                this.oidcToken = token;
            },
        });
        this.onDocumentLoaded = this.onDocumentLoaded.bind(this);
        this.onAnnotationsLoaded = this.onAnnotationsLoaded.bind(this);
    }

    ngAfterViewInit(): void {
        WebViewer(
            {
                path: 'assets/lib',
                licenseKey: environment.apryseLicenseKey,
            },
            this.viewer.nativeElement,
        ).then((instance: WebViewerInstance) => {
            this.instance = instance;
            this.setListeners();
            this.loadDocument();
            this.setDisabledElements();
            this.setOpenElements();
            this.setTheme();
        });
    }

    getDocumentPath(id: string) {
        return `${environment.apiUrl}${interpolate(endpoints.getDocument, { id })}`;
    }

    private loadDocument() {
        this.loading = true;
        const { Core, UI } = this.instance;
        const { enterMultiViewerMode } = UI;

        if (this.documentToCompare) {
            enterMultiViewerMode();
            this.addFullScreenButtonToHeader();

            UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                const [documentViewerOne, documentViewerTwo] =
                    Core.getDocumentViewers();

                this.loadDocumentFromViewer(documentViewerOne, this.document);
                this.loadDocumentFromViewer(
                    documentViewerTwo,
                    this.documentToCompare!,
                );
            });
        } else {
            const [documentViewer] = Core.getDocumentViewers();
            this.loadDocumentFromViewer(documentViewer, this.document);
        }
    }

    private onDocumentLoaded(): void {
        const { annotationManager, Annotations } = this.instance.Core;
        const rectangle = new Annotations.RectangleAnnotation();
        rectangle.PageNumber = 1;
        rectangle.X = 100;
        rectangle.Y = 100;
        rectangle.Width = 250;
        rectangle.Height = 250;
        rectangle.StrokeThickness = 5;
        rectangle.Author = annotationManager.getCurrentUser();
        this.documentLoaded.emit(this.instance);
    }

    private onAnnotationsLoaded(): void {
        //  console.log('annotations loaded');
    }

    private setDisabledElements() {
        this.instance.UI.disableElements([
            'toolsHeader',
            'panToolButton',
            'selectToolButton',
            'toggleNotesButton',
            'searchButton',
            'ribbons',
            'settingsKeyboardButton',
            'dropdown-item-PNG (*.png)',
            ...this.elementsToBeDisabled,
        ]);
    }

    private setOpenElements() {
        this.instance.UI.openElements(['leftPanel']);
    }

    private setTheme() {
        this.instance.UI.setTheme(this.instance.UI.Theme.DARK);
    }

    private addFullScreenButtonToHeader() {
        this.instance.UI.setHeaderItems((header) => {
            header.push({
                type: 'actionButton',
                img: 'icon-header-full-screen',
                onClick: () => this.instance.UI.toggleFullScreen(),
                dataElement: 'alertButton',
                hidden: ['mobile'],
            });
        });
    }

    private setListeners() {
        const { documentViewer } = this.instance.Core;

        documentViewer.addEventListener(
            'annotationsLoaded',
            this.onAnnotationsLoaded,
        );
        documentViewer.addEventListener(
            'documentLoaded',
            this.onDocumentLoaded,
        );
    }

    private loadDocumentFromViewer(
        viewer: Core.DocumentViewer,
        document: WebViewerDocument,
    ) {
        this.error = false;
        viewer
            .loadDocument(this.getDocumentPath(document.id), {
                ...this.loadOptions,
                filename: document.name,
                extension: 'pdf',
            })
            .catch((err) => {
                if (
                    err?.serverResponse?.status === HttpStatusCode.Unauthorized
                ) {
                    this.authService.setOidcToken().subscribe({
                        next: () => this.loadDocument(),
                    });
                } else {
                    this.error = true;
                }
            })
            .finally(() => (this.loading = false));
    }
}
