import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { StationAndFixer } from '@models/discrepancy/station-and-fixer';

@Injectable({
    providedIn: 'root',
})
export class DiscrepancyDataService {
    private selectedDiscStationAndFixer =
        new BehaviorSubject<StationAndFixer | null>(null);
    private discrepancySaveTrigger = new Subject<void>();
    private discrepancySaved = new Subject<void>();
    private discrepancyResetTrigger = new Subject<void>();

    selectedDiscStationAndFixer$ =
        this.selectedDiscStationAndFixer.asObservable();
    discrepancySaveTrigger$ = this.discrepancySaveTrigger.asObservable();
    discrepancySaved$ = this.discrepancySaved.asObservable();
    discrepancyResetTrigger$ = this.discrepancyResetTrigger.asObservable();
    unsavedDiscrepancy: boolean = false;

    updateSelectedDiscStationAndFixer(data: {
        station: number | null;
        fixer: number | null;
    }) {
        this.selectedDiscStationAndFixer.next(data);
    }

    triggerDiscrepancySaveAction() {
        this.discrepancySaveTrigger.next();
    }

    triggerDiscrepancyResetAction() {
        this.discrepancyResetTrigger.next();
    }

    triggerDiscrepancySaved() {
        this.discrepancySaved.next();
    }
}
