import { ApplicationConfig } from '@angular/core';
import { DatePipe } from '@angular/common';
import { provideRouter, withViewTransitions } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { WorkflowService } from '@services/api/workflow/workflow.service';
import { DocumentService } from '@services/api/document/document.service';
import { RepositoryService } from '@services/api/repository/repository.service';
import { authInterceptor } from '@interceptors/auth.interceptor';
import { blobErrorInterceptor } from '@interceptors/blob-error.interceptor';
import { documentReducer } from './store/reducers/document.reducer';
import { discrepancyReducer } from './store/reducers/discrepancy.reducer';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        WorkflowService,
        DocumentService,
        RepositoryService,
        UnsavedChangesService,
        DatePipe,
        provideRouter(routes, withViewTransitions()),
        provideHttpClient(
            withInterceptors([authInterceptor, blobErrorInterceptor]),
        ),
        provideAnimationsAsync(),
        provideDialogDefaultOptions(),
        provideStore({
            document: documentReducer,
            discrepancy: discrepancyReducer,
        }),
        provideEffects(),
    ],
};

function provideDialogDefaultOptions() {
    return {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: { panelClass: 'm-4', autoFocus: false },
    };
}
