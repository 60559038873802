import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay } from 'rxjs';

import { RevisionDataService } from '@services/data/revision-data-service';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { WorkflowService } from '@services/api/workflow/workflow.service';
import { DocumentService } from '@services/api/document/document.service';
import { RevisionService } from '@services/api/revision/revision.service';
import { RouterService } from '@services/router.service';
import { DocumentDataService } from '@services/data/document-data.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { DocumentPreviewComponent } from 'app/components/documents-and-discrepancies/document-preview/document-preview.component';
import { HeaderDocumentDetailsComponent } from 'app/components/documents-and-discrepancies/header-document-details/header-document-details.component';
import {
    DocumentDetailTab,
    DocumentDetailTabs,
} from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import { ResizableDirective } from '@directives/resizable.directive';
import { WorkflowDocument } from '@models/document';
import { RevisionDetails } from '@models/revision/revision-detail';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { saveSummaryPanelData } from 'app/store/actions/document.actions';
import { SubscriptionList, SubscriptionListType } from '@helpers/subscription';

@Component({
    standalone: true,
    templateUrl: 'document-detail.component.html',
    styleUrl: 'document-detail.component.scss',
    imports: [
        MatIconModule,
        MatButtonModule,
        TabsComponent,
        ResizableDirective,
        LoaderComponent,
        DocumentPreviewComponent,
        RouterOutlet,
        HeaderDocumentDetailsComponent,
    ],
})
export class DocumentDetailPageComponent implements OnInit, OnDestroy {
    @ViewChild(DocumentPreviewComponent)
    documentPreviewComp!: DocumentPreviewComponent;
    selectedDocumentRevisionId: number | null = null;
    tabsData = DocumentDetailTabs;
    documentDetails: WorkflowDocument | null = null;
    documentLoading = false;
    revisionDetails: RevisionDetails | null = null;
    initialSelectedTabIndex: number | null = null;
    unsavedChanges = false;
    unsavedChangeArea = '';
    private documentId!: number;
    readonly tabs: typeof DocumentDetailTab = DocumentDetailTab;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    private store = inject(Store);
    private actions$ = inject(Actions);
    private route = inject(ActivatedRoute);
    private routerService = inject(RouterService);
    private documentService = inject(DocumentService);
    private revisionService = inject(RevisionService);
    private workflowService = inject(WorkflowService);
    private documentDataService = inject(DocumentDataService);
    private revisionDataService = inject(RevisionDataService);
    private unsavedChangesService = inject(UnsavedChangesService);

    constructor() {
        this.setDocumentIdFromRouter();
        this.getDocumentDetails(this.documentId);
    }

    ngOnInit(): void {
        this.setDocumentDetails();
        this.setSelectedTab();
        this.subscribeSelectedRevision();
        this.handleComponentRefresh();
    }

    ngOnDestroy(): void {
        this.unsavedChangesService.cleanup();
        this.revisionDataService.updateSelectedRevisionId(null);
        this._subscriptions.unsubscribeAllSafe();
    }

    hasUnsavedChanges(): boolean {
        return this.unsavedChanges;
    }

    save() {
        this.documentPreviewComp.saveDocument();
    }

    onCloseClick() {
        this.routerService.goHome();
    }

    onDocumentChanged(change: boolean) {
        this.unsavedChanges = change;
        this.unsavedChangesService.hasUnsavedChanges = change;
    }

    onTabSelected(tabIndex: number) {
        this.store.dispatch(
            saveSummaryPanelData({
                selectedDocument: this.documentId,
                selectedTab: this.tabsData[tabIndex].id,
                selectedDiscrepancy: null,
            }),
        );
    }

    private setDocumentIdFromRouter() {
        this.route.paramMap.subscribe({
            next: (params) => {
                const id = parseFloat(params.get('id')!);
                if (isNaN(id)) {
                    this.routerService.goHome();
                } else {
                    this.documentId = id;
                }
            },
        });
    }

    private setDocumentDetails() {
        this._subscriptions['document-details'] =
            this.documentDataService.documentDetail$.subscribe({
                next: (detail) => {
                    this.documentDetails = detail;
                    if (!this.selectedDocumentRevisionId && detail) {
                        this.revisionDataService.updateSelectedRevisionId(
                            detail.originalRevisionId!,
                        );
                    }
                },
            });
    }

    private setSelectedTab() {
        let activeTab = DocumentDetailTab.METADATA;
        this.route.children.forEach((child) => {
            activeTab = (child.routeConfig?.path || '') as DocumentDetailTab;
        });
        this.initialSelectedTabIndex = this.tabsData.findIndex(
            (item) => item.id === activeTab,
        );
    }

    private subscribeSelectedRevision() {
        this._subscriptions['document-revision'] =
            this.revisionDataService.selectedRevisionId$.subscribe({
                next: (id) => {
                    if (id) {
                        this.selectedDocumentRevisionId = id;
                        this.getRevisionDetails(
                            this.selectedDocumentRevisionId!,
                        );
                    }
                },
            });
    }

    private getRevisionDetails(id: number) {
        this._subscriptions['get-revision-details'] = this.revisionService
            .getRevisionDetails(id)
            .pipe(delay(500))
            .subscribe({
                next: (response) => {
                    this.revisionDetails = response;
                    this.unsavedChangeArea = response?.name ?? '';
                },
            });
    }

    private handleComponentRefresh() {
        this._subscriptions['component-refresh'] = this.actions$
            .pipe(ofType(refreshActionRelatedData))
            .subscribe({
                next: (refresh) => {
                    if (refresh) {
                        this.getDocumentDetails(this.documentId);
                    }
                },
            });
    }

    private getDocumentDetails(id: number) {
        this.documentLoading = true;
        this._subscriptions['get-document-details'] = this.documentService
            .getDocumentDetails(id)
            .subscribe({
                next: (document) => {
                    if (document) {
                        this.documentLoading = false;
                        this.getWorkflowStepper(document.workflowId!);
                        this.getRevisionDetails(
                            this.selectedDocumentRevisionId!,
                        );
                    } else this.routerService.goHome();
                },
            });
    }

    private getWorkflowStepper(id: number) {
        this._subscriptions['get-workflow-stepper'] = this.workflowService
            .getWorkflowStepper(id)
            .subscribe();
    }
}
