import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ColumnToggleOptions } from '@models/shared.model';
import { StationAndFixer } from '@models/discrepancy/station-and-fixer';
import { toggleItem } from '@helpers/array';
import { DiscrepancyColumn } from '@constants/table';

@Injectable({
    providedIn: 'root',
})
export class DiscrepancyDataService {
    private selectedDiscStationAndFixer =
        new BehaviorSubject<StationAndFixer | null>(null);
    private discColumnOptions = new BehaviorSubject<
        ColumnToggleOptions<DiscrepancyColumn>
    >({
        lastToggled: null,
        hiddenColumns: [],
    });

    discColumnOptions$ = this.discColumnOptions.asObservable();
    selectedDiscStationAndFixer$ =
        this.selectedDiscStationAndFixer.asObservable();

    constructor() {}

    updateSelectedDiscStationAndFixer(data: {
        station: number | null;
        fixer: number | null;
    }) {
        this.selectedDiscStationAndFixer.next(data);
    }

    updateDiscColumnOptions(option: any) {
        const currentOptions = this.discColumnOptions.value;

        this.discColumnOptions.next({
            lastToggled: option,
            hiddenColumns: toggleItem(currentOptions.hiddenColumns, option.id),
        });
    }
}
